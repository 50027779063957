import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';

export function authGuard() {
  const router = inject(Router);
  const authService = inject(AuthService);
  const document = inject(DOCUMENT);

  return authService.isAuthenticated$.pipe(
    map(isLoggedIn => {
      if (!isLoggedIn) {
        window.location.href = '/login';
      }
      return isLoggedIn;
    })
  );
}

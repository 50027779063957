import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { APP_INITIALIZER, importProvidersFrom } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { provideAuth0 } from '@auth0/auth0-angular';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { provideState, provideStore } from '@ngrx/store';
import {
  Environment,
  loadEnvironment,
  Auth0Env,
  loadAuth0Environment,
} from 'env';
import { authInterceptor } from '../../../libs/auth/src/lib/auth-interceptor';
import * as authEffects from '../../../libs/auth/src/lib/store/auth.effects';
import { authFeature } from '../../../libs/auth/src/lib/store/auth.feature';
import * as currentUserEffects from '../../../libs/auth/src/lib/store/current-user/current-user.effects';
import { currentUserFeature } from '../../../libs/auth/src/lib/store/current-user/current-user.features';
import { Auth0Environment } from '../../../libs/env/src/environments/auth0_env';
import { APPROUTES } from './app/app-routes';
import { AppComponent } from './app/app.component';
import { CONSTANTS, Constants } from './app/application-config/email-pattern';
import { appInitialise } from './app/functions/app-initialise';
import { apiRequestInterceptor } from './app/interceptors/api-request-interceptor';
import * as settingEffects from './app/settings/setting.effects';
import * as usersFeature from '../../../libs/services/src/lib/services/maintenance/store/features';
import * as usersEffects from '../../../libs/services/src/lib/services/maintenance/store/effects';

loadEnvironment().then((environment) => {
  loadAuth0Environment().then((auth0Env) => {
    bootstrapApplication(AppComponent, {
      providers: [
        { provide: Environment, useValue: environment },
        { provide: Auth0Env, useValue: auth0Env },
        provideRouter([...APPROUTES]),
        provideState(authFeature),
        provideState(currentUserFeature),
        provideState(usersFeature.usersFeature),
        provideEffects([
          authEffects,
          currentUserEffects,
          settingEffects,
          usersEffects,
        ]),
        provideAuth0({
          ...Auth0Environment.auth,
          httpInterceptor: {
            ...Auth0Environment.httpInterceptor,
          },
          cacheLocation: 'localstorage',
          useRefreshTokens: true,
        }),
        provideAnimations(),
        provideStore(
          {},
          {
            runtimeChecks: {
              strictStateImmutability: true,
              strictActionImmutability: true,
              strictStateSerializability: true,
              strictActionWithinNgZone: true,
            },
          }
        ),
        MatDialog,
        importProvidersFrom(NgxMatMomentModule),
        importProvidersFrom(MatDialogModule),
        importProvidersFrom(MatSnackBarModule),
        provideHttpClient(
          withInterceptors([apiRequestInterceptor, authInterceptor])
        ),
        provideRouterStore(),
        provideState('router', routerReducer),
        { provide: Constants, useValue: CONSTANTS },
        { provide: APP_INITIALIZER, useFactory: appInitialise },
      ],
      // eslint-disable-next-line no-console
    }).catch((err) => console.error(err));
  });
});

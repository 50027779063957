import { InjectionToken } from '@angular/core';

export const CONSTANTS = {
  emailPattern: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,7}$',
} as const;

export const Constants = new InjectionToken('Constants', {
  factory() {
    return CONSTANTS;
  },
});

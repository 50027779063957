import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { ClientLocationsService } from '../../client-locations.service';
import { catchError, map, mergeMap, of, switchMap, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ClientLocationActions } from '../actions/client-locations.actions';
import { ClientLocation } from '../../interfaces/client-locations.interface';
import { Store } from '@ngrx/store';

export const loadUserClientLocations = createEffect(
  (
    actions = inject(Actions),
    store = inject(Store),
    clientLocationService = inject(ClientLocationsService)
  ) => {
    return actions.pipe(
      ofType(ClientLocationActions.load_User_Client_Locations),
      mergeMap(() =>
        clientLocationService.loadUserClientLocations().pipe(
          map((res: ClientLocation[]) =>
            ClientLocationActions.load_User_Client_Locations_Success({
              clientLocations: res,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              ClientLocationActions.load_User_Client_Locations_Failure({
                error,
              })
            )
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const loadClientLocationsByLocationId = createEffect(
  (
    actions = inject(Actions),
    clientLocationsService = inject(ClientLocationsService)
  ) => {
    return actions.pipe(
      ofType(ClientLocationActions.load_Client_Locations_By_LocationId),
      switchMap(({ locationId }) =>
        clientLocationsService.loadClientLocationsByLocationId(locationId).pipe(
          map((clientLocations) =>
            ClientLocationActions.load_Client_Locations_By_LocationId_Success({
              clientLocations,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              ClientLocationActions.load_Client_Locations_By_LocationId_Error({
                error,
              })
            )
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

import { createFeature, createReducer, createSelector } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { tankTypeInitialState } from '../states/tank-type.state';
import { TankTypeActions } from '../actions/tank-type.actions';

export const tankTypesReducer = createReducer(
  tankTypeInitialState,
  immerOn(
    TankTypeActions.load_Tank_Types,
    TankTypeActions.remove_Tank_Type,
    (state) => {
      state.loading.list = true;
    }
  ),
  immerOn(TankTypeActions.update_Tank_Type_Queries, (state, { query }) => {
    state.query = query;
    state.loading.list = true;
  }),
  immerOn(TankTypeActions.load_Tank_Types_Success, (state, { tankTypes }) => {
    state.tankTypes = tankTypes;
    state.loading.list = false;
  }),
  immerOn(
    TankTypeActions.load_Tank_Types_Failure,
    TankTypeActions.remove_Tank_Type_Success,
    TankTypeActions.remove_Tank_Type_Failure,
    (state) => {
      state.loading.list = false;
    }
  ),
  immerOn(
    TankTypeActions.add_Tank_Type,
    TankTypeActions.edit_Tank_Type,
    (state) => {
      state.loading.createEdit = true;
    }
  ),
  immerOn(
    TankTypeActions.add_Tank_Type_Success,
    TankTypeActions.add_Tank_Type_Failure,
    TankTypeActions.edit_Tank_Type_Success,
    TankTypeActions.edit_Tank_Type_Failure,
    (state) => {
      state.loading.createEdit = false;
    }
  )
);

export const tankTypesFeature = createFeature({
  name: 'tankTypes',
  reducer: tankTypesReducer,
  extraSelectors: ({ selectLoading }) => ({
    selectListLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.list
    ),
    selectCreateEditLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.createEdit
    ),
  }),
});

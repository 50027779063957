<div mat-dialog-content cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle
  cdkDragBoundary=".cdk-overlay-container">
  <div mat-dialog-title>
    <h5 *ngIf="data.titles?.title">{{ data.titles.title }}</h5>
    <ng-container *ngIf="data.titles?.titles">
      <h6 *ngFor="let item of data.titles.titles">{{ item }}</h6>
    </ng-container>
  </div>
  <div mat-dialog-content *ngIf="data.titles.body">
    <p>{{ data.titles.body }}</p>
  </div>
  <mat-dialog-actions align="end">
    <button mat-raised-button color="primary" style="margin-right: 10px" (click)="accept()">
      {{ data.titles.btnConfirm ?? 'Ok' }}
    </button>
    <button mat-raised-button color="warn" type="button" (click)="reject()">
      {{ data.titles.btnReject ?? 'Cancel' }}
    </button>
    <button mat-raised-button color="info" *ngIf="data.titles.btnCancel" type="button" (click)="cancel()">
      {{ data.titles.btnCancel }}
    </button>
  </mat-dialog-actions>
</div>

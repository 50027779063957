import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from '../interfaces/app-settings.interface';
import { Asset } from 'libs/services/src/lib/services/maintenance/interfaces/asset.interface';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private readonly http = inject(HttpClient);

  getSettings(): Observable<AppSettings> {
    return this.http.get<AppSettings>('/api/setting');
  }

  addSettings(
    currency: string,
    settingsDefaultInitialPortId: string
  ): Observable<AppSettings> {
    return this.http.post<AppSettings>('/api/setting', {
      currency,
      settingsDefaultInitialPortId,
    });
  }

  editSettings(
    currency: string,
    settingsDefaultInitialPortId: string,
    id: string
  ): Observable<AppSettings> {
    return this.http.put<AppSettings>(`/api/setting/${id}`, {
      currency,
      settingsDefaultInitialPortId,
    });
  }

  loadPorts(): Observable<Asset[]> {
    return this.http.get<Asset[]>('/api/asset/ports/');
  }
}

import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Location } from './interfaces/location.interface';
import { Operator } from './interfaces/operator.interface';
import { ReportType } from './interfaces/report-type.interface';

@Injectable({ providedIn: 'root' })
export class OperatorsService {
  private readonly http = inject(HttpClient);

  loadOperators(): Observable<Operator[]> {
    return this.http.get<Operator[]>('/api/client');
  }

  removeOperator(id: string): Observable<Operator> {
    return this.http.delete<Operator>(`/api/client/${id}`);
  }

  addOperator(operator: Operator): Observable<Operator> {
    return this.http.post<Operator>('/api/client', operator);
  }

  editOperator(id: string, operator: Operator): Observable<Operator> {
    return this.http.put<Operator>(`/api/client/${id}`, operator);
  }

  loadReportTypeList(): Observable<ReportType[]> {
    return this.http.get<ReportType[]>('/api/reporttype');
  }

  loadLocationsList(): Observable<Location[]> {
    return this.http.get<Location[]>('/api/location');
  }
}

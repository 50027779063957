<div
  *ngIf="cargoTypeState()"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  cdkDragBoundary=".cdk-overlay-container"
>
  <div mat-dialog-title>
    <h4>{{ isAdd ? 'Add ' : 'Edit ' }} Cargo Type</h4>
  </div>
  <mat-dialog-content *lhaLoading="cargoTypeState().loading.createEdit">
    <form [formGroup]="form" (ngSubmit)="saveCargoType()">
      <div class="form__block">
        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>Name</mat-label>
            <input matInput formControlName="name" />
          </mat-form-field>
        </div>

        <div class="form__box">
          <lha-single-select
            [options]="cargoFamilies()"
            formControlName="cargoFamilyId"
            bindValue="cargoFamilyId"
            bindLabel="name"
            placeholder="Family"
          />
          <div
            *ngIf="
              form.controls.cargoFamilyId.invalid && form.controls.cargoFamilyId.touched
            "
          >
            <mat-error *ngIf="form.controls.cargoFamilyId.hasError('required')">
              Family is required.
            </mat-error>
          </div>
        </div>
      </div>
      <mat-dialog-actions align="end">
        <button mat-raised-button color="primary" type="submit">
          {{ isAdd ? 'Add ' : 'Save ' }}
        </button>
        <button
          mat-raised-button
          color="warn"
          type="button"
          mat-dialog-close=""
        >
          Cancel
        </button>
      </mat-dialog-actions>
    </form>
  </mat-dialog-content>
</div>

import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { errorProps } from 'libs/components/src/lib/functions/utility.functions';
import { Vendor } from '../../interfaces/vendor.interface';
import { SearchQuery } from 'libs/components/src/lib/interfaces/search-query.interface';

export const VendorsActions = createActionGroup({
  source: '[Vendor]',
  events: {
    update_Vendor_Queries: props<{ query: SearchQuery }>(),
    Load_Vendors_Page: emptyProps(),
    Load_Vendors: emptyProps(),
    Load_Vendors_Success: props<{ vendorList: Vendor[] }>(),
    Load_Vendors_Failure: errorProps(),
    Load_Vendors_By_Voyage_Id: props<{ voyageId: string }>(),
    Load_Vendors_By_Voyage_Id_Success: props<{ vendorList: Vendor[] }>(),
    Load_Vendors_By_Voyage_Id_Error: errorProps(),
    Load_Vendors_By_Location_Id: props<{ locationId: string }>(),
    Load_Vendors_By_Location_Id_Success: props<{ vendors: Vendor[] }>(),
    Load_Vendors_By_Location_Id_Error: errorProps(),

    Load_Vendor_Addresses_By_Voyage_Id: props<{ voyageId: string }>(),
    Load_Vendor_Addresses_By_Voyage_Id_Success: props<{
      addressesList: string[];
    }>(),
    Load_Vendor_Addresses_By_Voyage_Id_Error: errorProps(),

    remove_Vendor: props<{ vendorId: string }>(),
    remove_Vendor_Success: props<{
      successMessage: string;
    }>(),
    remove_Vendor_Failure: errorProps(),

    add_Vendor: props<{ vendor: Vendor }>(),
    add_Vendor_Success: props<{
      vendor: Vendor;
      successMessage: string;
    }>(),
    add_Vendor_Failure: errorProps(),

    edit_Vendor: props<{ vendorId: string; vendor: Vendor }>(),
    edit_Vendor_Success: props<{
      vendor: Vendor;
      successMessage: string;
    }>(),
    edit_Vendor_Failure: errorProps(),

    open_Vendor_Dialog: props<{ vendor: Vendor | null }>(),
  },
});

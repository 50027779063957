import {createFeature, createReducer, createSelector} from "@ngrx/store";
import {blockingActivityInitialState} from "../states/blocking-activity.state";
import {immerOn} from "ngrx-immer/store";
import {BlockingActivityActions} from "../actions/blocking-activity.actions";

export const blockingActivityReducer = createReducer(
  blockingActivityInitialState,
  immerOn(BlockingActivityActions.remove_Blocking_Activity, (state) => {
    state.loading.list = true;
  }),
  immerOn(BlockingActivityActions.update_Blocking_Activity_Queries, (state, { query }) => {
    state.query = query;
    state.loading.list = true;
  }),

  immerOn(BlockingActivityActions.load_Blocking_Activities_Success, (state, { blockingActivities }) => {
    state.blockingActivities = blockingActivities;
    state.loading.list = false;
  }),
  immerOn(
    BlockingActivityActions.load_Blocking_Activities_Failure,
    BlockingActivityActions.remove_Blocking_Activity_Success,
    BlockingActivityActions.remove_Blocking_Activity_Failure,
    (state) => {
      state.loading.list = false;
    }
  ),
  immerOn(BlockingActivityActions.add_Blocking_Activity, BlockingActivityActions.edit_Blocking_Activity, (state) => {
    state.loading.createEdit = true;
  }),
  immerOn(
    BlockingActivityActions.add_Blocking_Activity_Success,
    BlockingActivityActions.add_Blocking_Activity_Failure,
    BlockingActivityActions.edit_Blocking_Activity_Success,
    BlockingActivityActions.edit_Blocking_Activity_Failure,
    (state) => {
      state.loading.createEdit = false;
    }
  )
);

export const blockingActivityFeature = createFeature({
  name: 'blockingActivities',
  reducer: blockingActivityReducer,
  extraSelectors: ({ selectLoading }) => ({
    selectListLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.list
    ),
    selectCreateEditLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.createEdit
    ),
  }),
});

<div
  class="dialog-container"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  cdkDragBoundary=".cdk-overlay-container"
>
  <lha-table
    [columns]="columns"
    [data]="data.assetHistory"
    [canExportTable]="false"
    tableName="Assets History"
  >
    <h2 tableHeaderLeft>History</h2>
    <button
      mat-icon-button
      color="warn"
      mat-dialog-close=""
      tableHeaderRightBtn
      class="asset-close"
    >
      <mat-icon>close</mat-icon>
    </button>
    <ng-template lhaCellTemplate="startDateTime" let-item>
      {{ item.startDateTime | date : 'dd/MM/yyyy HH:mm' }}
    </ng-template>
    <ng-template lhaCellTemplate="endDateTime" let-item>
      <lha-custom-chip
        *ngIf="!item.endDateTime"
        [cssClass]="'off'"
        [text]="'Current'"
      >
      </lha-custom-chip>
      <ng-container *ngIf="item.endDateTime">{{
        item.endDateTime | date : 'dd/MM/yyyy HH:mm'
      }}</ng-container>
    </ng-template>
  </lha-table>
</div>

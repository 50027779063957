import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SearchQuery } from '../../../../../../../components/src/lib/interfaces/search-query.interface';
import { Employee } from '../../interfaces/employee.interface';
import { errorProps } from '../../../../../../../components/src/lib/functions/utility.functions';

export const EmployeeActions = createActionGroup({
  source: 'Employee',
  events: {
    update_Employee_Queries: props<{ query: SearchQuery }>(),
    load_Employees: emptyProps(),
    load_Employees_Success: props<{ employees: Employee[] }>(),
    load_Employees_Failure: errorProps(),
    remove_Employee: props<{ id: string }>(),
    remove_Employee_Success: props<{
      employee: Employee;
      successMessage: string;
    }>(),
    remove_Employee_Failure: errorProps(),
    open_Employee_Dialog: props<{ employee: Employee | null }>(),
    add_Employee: props<{ employee: Employee }>(),
    add_Employee_Success: props<{
      employee: Employee;
      successMessage: string;
    }>(),
    add_Employee_Failure: errorProps(),
    edit_Employee: props<{
      employeeId: string;
      employee: Employee;
    }>(),
    edit_Employee_Success: props<{
      employee: Employee;
      successMessage: string;
    }>(),
    edit_Employee_Failure: errorProps(),
  },
});

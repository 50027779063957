import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SearchQuery } from '../../../../../../../components/src/lib/interfaces/search-query.interface';
import { Asset } from '../../interfaces/asset.interface';
import { Constant } from 'libs/services/src/lib/services/shared/constant.interface';
import { errorProps } from '../../../../../../../components/src/lib/functions/utility.functions';
import { AssetHistory } from '../../interfaces/asset-history.interface';

export const AssetActions = createActionGroup({
  source: 'Asset',
  events: {
    update_Asset_Queries: props<{ query: SearchQuery }>(),
    load_Asset_Page: emptyProps(),
    load_Assets: emptyProps(),
    load_Assets_Success: props<{ assets: Asset[] }>(),
    load_Assets_Failure: errorProps(),
    load_Asset_Types: emptyProps(),
    load_Asset_Types_Success: props<{ assetTypes: Constant[] }>(),
    load_Asset_Types_Failure: errorProps(),
    load_Cluster_Heads: props<{ id: string }>(),
    load_Cluster_Heads_Success: props<{ assetsHead: Asset[] }>(),
    load_Cluster_Heads_Failure: errorProps(),
    remove_Asset: props<{ id: string }>(),
    remove_Asset_Success: props<{
      asset: Asset;
      successMessage: string;
    }>(),
    remove_Asset_Failure: errorProps(),
    add_Asset: props<{ asset: Asset }>(),
    add_Asset_Success: props<{
      asset: Asset;
      successMessage: string;
    }>(),
    add_Asset_Failure: errorProps(),
    edit_Asset: props<{
      assetId: string;
      asset: Asset;
      addNewHistory: boolean;
    }>(),
    edit_Asset_Success: props<{
      asset: Asset;
      successMessage: string;
    }>(),
    edit_Asset_Failure: errorProps(),
    open_Asset_Dialog: props<{ asset?: Asset }>(),
    close_Asset_Dialog: emptyProps(),
    open_Asset_History_Dialog: props<{
      assetHistory: AssetHistory[];
    }>(),
    export_Assets: emptyProps(),
    export_Assets_Success: emptyProps(),
    export_Assets_Failure: errorProps(),

  },
});

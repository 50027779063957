export enum ActivityType {
  WetBulk = 0,
  DryBulk = 1,
  Personnel = 2,
  Lifting = 3
}

export enum SvgPaths {
  './assets/wetbulk.svg',
  './assets/drybulk.svg',
  './assets/personnel.svg',
  './assets/lifting.svg',
}

import { createActionGroup, emptyProps, props, ActionCreatorProps } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { User } from '../../interfaces/user.interface';

export const errorProps = (): ActionCreatorProps<{
  error: HttpErrorResponse;
  customMessage?: string;
}> => {
  return props<{ error: HttpErrorResponse; customMessage?: string }>();
};

export const CurrentUserActions = createActionGroup({
  source: 'Current User',
  events: {
    'load_Current_User': emptyProps(),
    'load_Current_User_Success': props<{ user: User }>(),
    'load_Current_User_Failure': errorProps(),
  },
});

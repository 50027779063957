import { createFeature, createReducer, createSelector } from '@ngrx/store';
import { activityCategoryInitialState } from '../states/activity-category.state';
import { ActivityCategoryActions } from '../actions/activity-category.actions';
import { immerOn } from 'ngrx-immer/store';

export const activityCategoryReducer = createReducer(
  activityCategoryInitialState,
  immerOn(
    ActivityCategoryActions.load_Activity_Category,
    ActivityCategoryActions.remove_Activity_Category,
    (state) => {
      state.loading.list = false;
    }
  ),
  immerOn(
    ActivityCategoryActions.update_Activity_Category_Queries,
    (state, { query }) => {
      state.query = query;
      state.loading.list = true;
    }
  ),
  immerOn(
    ActivityCategoryActions.load_Activity_Category_Success,
    (state, { activityCategory }) => {
      state.activityCategories = activityCategory;
      state.loading.list = false;
    }
  ),
  immerOn(
    ActivityCategoryActions.add_Activity_Category,
    ActivityCategoryActions.edit_Activity_Category,
    (state) => {
      state.loading.createEdit = true;
    }
  ),
  immerOn(
    ActivityCategoryActions.load_Activity_Category_Failure,
    ActivityCategoryActions.remove_Activity_Category_Success,
    ActivityCategoryActions.remove_Activity_Category_Failure,
    ActivityCategoryActions.load_Activity_Category_Type_List_Failure,
    ActivityCategoryActions.load_Activity_Category_Type_List_Success,
    ActivityCategoryActions.add_Activity_Category_Success,
    ActivityCategoryActions.edit_Activity_Category_Success,
    ActivityCategoryActions.add_Activity_Category_Failure,
    ActivityCategoryActions.edit_Activity_Category_Failure,
    (state) => {
      state.loading.list = false;
    }
  )
);

export const activityCategoryFeature = createFeature({
  name: 'activityCategory',
  reducer: activityCategoryReducer,
  extraSelectors: ({
    selectLoading,
    selectActivityCategories,
    selectActivityCategoryTypeList,
  }) => ({
    selectListLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.list
    ),
    selectCreateEditLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.createEdit
    ),
    selectFilteredActivities: createSelector(
      selectActivityCategories,
      (selectActivityCategories) =>
        selectActivityCategories.filter((item) => item.name)
    ),
    selectFilteredActivityCategoryType: createSelector(
      selectActivityCategoryTypeList,
      (selectActivityCategoryTypeList) =>
        selectActivityCategoryTypeList.filter((item) => item)
    ),
  }),
});

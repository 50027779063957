import { createFeature, createReducer, createSelector } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { AssetMobileWellActions } from '../actions/assets-mobile-well.actions';
import { assetMobileWellState } from '../states/asset-mobile-well.state';
import { assetsFeature } from './assets.features';

export const assetMobileWellReducer = createReducer(
  assetMobileWellState,
  immerOn(
    AssetMobileWellActions.remove_Asset_Mobile_Well,
    AssetMobileWellActions.load_Asset_Mobile_Wells,
    (state) => {
      state.loading.list = true;
    }
  ),
  immerOn(
    AssetMobileWellActions.set_Asset_Mobile_Well_Id,
    (state, { id }) => {
      state.assetMobileWellId = id;
    }
  ),
  immerOn(
    AssetMobileWellActions.load_Asset_Mobile_Wells_Success,
    (state, { assetMobileWells }) => {
      state.assetMobileWells = assetMobileWells;
      state.loading.list = false;
    }
  ),
  immerOn(AssetMobileWellActions.add_Asset_Mobile_Well_Row, (state) => {
    state.assetMobileWells.unshift({
      mobileId: '',
      wellId: null,
      startDateTime: null,
      endDateTime: null,
      mobileWellId: 'add',
      deleted: false,
      mobileName: '',
      wellName: '',
    });
  }),
  immerOn(AssetMobileWellActions.remove_Asset_Mobile_Well_Row, (state) => {
    state.assetMobileWells = state.assetMobileWells.filter(
      (item) => item.mobileWellId !== 'add'
    );
  }),
  immerOn(
    AssetMobileWellActions.load_Asset_Mobile_Wells_Failure,
    AssetMobileWellActions.remove_Asset_Mobile_Well_Success,
    AssetMobileWellActions.remove_Asset_Mobile_Well_Failure,
    (state) => {
      state.loading.list = false;
    }
  ),
  immerOn(
    AssetMobileWellActions.add_Asset_Mobile_Well,
    AssetMobileWellActions.edit_Asset_Mobile_Well,
    (state) => {
      state.loading.createEdit = true;
    }
  ),
  immerOn(
    AssetMobileWellActions.add_Asset_Mobile_Well_Success,
    AssetMobileWellActions.add_Asset_Mobile_Well_Failure,
    AssetMobileWellActions.edit_Asset_Mobile_Well_Success,
    AssetMobileWellActions.edit_Asset_Mobile_Well_Failure,
    (state) => {
      state.loading.createEdit = false;
    }
  )
);

export const assetMobileWellFeature = createFeature({
  name: 'assetMobileWell',
  reducer: assetMobileWellReducer,
  extraSelectors: ({ selectLoading }) => ({
    selectListLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.list
    ),
    selectCreateEditLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.createEdit
    ),
    selectAssetByType: (assetType: string[]) =>
      createSelector(assetsFeature.selectAssets, (assets) =>
        assets.filter((item) => assetType.includes(item.assetType))
      ),
  }),
});

<div
  *ngIf="activityCategoryState()"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  cdkDragBoundary=".cdk-overlay-container"
>
  <div mat-dialog-title>
    <div class="dialog_info_header">
      <h4>{{ isAdd ? 'Add ' : 'Edit ' }} Activity Category</h4>
      <div class="dialog_info" *ngIf="!isAdd">
        <div class="dialog_info__text">i</div>
        <div class="custom-tooltip">
          <div
            class="custom-tooltip__text"
            *ngIf="data.activityCategory.createdDate"
          >
            <span *ngIf="data.activityCategory.createdByName"
              >Created By: {{ data.activityCategory.createdByName }}<br
            /></span>
            Created Date:
            {{ data.activityCategory.createdDate | date : 'dd-MM-yyyy' }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <mat-dialog-content>
    <form
      [formGroup]="form"
      (ngSubmit)="saveActivity()"
      style="margin-top: 16px"
    >
      <div class="form__block">
        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>Activity Category Name</mat-label>
            <input matInput formControlName="name" />
            <mat-error *ngIf="form.controls.name.hasError('required')">
              Category name is required.
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form__box">
          <lha-single-select
            [options]="activityOptions"
            formControlName="activityType"
            bindValue="value"
            bindLabel="name"
            placeholder="Activity Type"
          />
          <div
            *ngIf="
              form.controls.activityType.invalid &&
              form.controls.activityType.touched
            "
          >
            <mat-error *ngIf="form.controls.activityType.hasError('required')">
              Activity Type is required.
            </mat-error>
          </div>
        </div>

        <div class="form__box">
          <mat-slide-toggle formControlName="isHidden"
            >Hidden from client :
            {{ form.controls.isHidden.value ? 'Yes' : 'No' }}
          </mat-slide-toggle>
        </div>
      </div>

      <mat-dialog-actions align="end">
        <button mat-raised-button color="primary" type="submit">
          {{ isAdd ? 'Add ' : 'Save ' }}
        </button>
        <button
          mat-raised-button
          color="warn"
          type="button"
          mat-dialog-close=""
        >
          Cancel
        </button>
      </mat-dialog-actions>
    </form>
  </mat-dialog-content>
</div>

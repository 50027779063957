import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ExcelService {
  exportToExel(table: HTMLTableElement, name: string): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).TableToExcel.convert(table, {
      name: `${name}.xlsx`,
    });
  }
}

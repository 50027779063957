import { Actions, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { UsersService } from '../../services/users.service';
import { catchError, map, mergeMap, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { CurrentUserActions } from './current-user.actions';
import { User } from '../../interfaces/user.interface';
import { AuthActions } from '../../../../../../libs/auth/src/lib/store/auth.actions';
import {Router} from "@angular/router";
import {AuthService} from "@auth0/auth0-angular";
import {DOCUMENT} from "@angular/common";

export const loadCurrentUser = createEffect(
  (actions = inject(Actions),
   usersService = inject(UsersService),
   router = inject(Router),
   doc = inject(DOCUMENT),
   authService = inject(AuthService)) => {
    return actions.pipe(
      ofType(
        CurrentUserActions.load_Current_User,
        AuthActions.refresh_Token_Success
      ),
      mergeMap(() =>
        usersService.getCurrentUser().pipe(
          map((res: User) =>
            CurrentUserActions.load_Current_User_Success({ user: res })
          ),
          catchError((error: HttpErrorResponse) => {
            // Log out the user and redirect to the login page
            router.navigate(['/usernotfound']);
            const redirectUrl = `${doc.location.origin}/usernotfound`;
            authService.logout({ logoutParams: { returnTo: redirectUrl } });
            return of(CurrentUserActions.load_Current_User_Failure({ error }));
          })
        )
      )
    );
  },
  {
    functional: true,
  }
);

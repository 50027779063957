<div
  *ngIf="bulks()"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  cdkDragBoundary=".cdk-overlay-container"
>
  <div mat-dialog-title>
    <h4>{{ isAdd ? 'Add ' : 'Edit ' }} Bulk Type</h4>
  </div>
  <mat-dialog-content *lhaLoading="bulks().loading.createEdit">
    <form [formGroup]="form" (ngSubmit)="saveBulkType()">
      <div class="form__block">
        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>Bulk Name</mat-label>
            <input matInput formControlName="name" />
            <mat-error *ngIf="form.controls.name.hasError('required')">
              Bulk name is required.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form__box">
          <lha-single-select
            [options]="units()"
            formControlName="unitName"
            bindValue="name"
            placeholder="Unit"
          />
          <div
            *ngIf="
              form.controls.unitName.invalid && form.controls.unitName.touched
            "
          >
            <mat-error *ngIf="form.controls.unitName.hasError('required')">
              Unit is required.
            </mat-error>
          </div>
        </div>
        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>Fluid Type</mat-label>
            <mat-select formControlName="fluidType">
              <mat-option
                *ngFor="let item of bulks().typeList"
                [value]="item.value"
              >
                {{ item.description }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="form.controls.fluidType.hasError('required')">
              Fluid Type is required.
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <mat-dialog-actions align="end">
        <button mat-raised-button color="primary" type="submit">
          {{ isAdd ? 'Add ' : 'Save ' }}
        </button>
        <button
          mat-raised-button
          color="warn"
          type="button"
          mat-dialog-close=""
        >
          Cancel
        </button>
      </mat-dialog-actions>
    </form>
  </mat-dialog-content>
</div>

import { inject, Injectable } from '@angular/core';
import { SearchQuery } from '../../../../../components/src/lib/interfaces/search-query.interface';
import { Observable } from 'rxjs';
import { BulkType } from './interfaces/bulk-type.interface';
import { Unit } from '../interfaces/unit.interface';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class BulkTypeService {
  private readonly http = inject(HttpClient);

  loadBulkTypes(query: SearchQuery): Observable<BulkType[]> {
    return this.http.get<BulkType[]>('/api/bulktype');
  }

  loadBulkTypesList(): Observable<BulkType[]> {
    return this.http.get<BulkType[]>('/api/bulktype');
  }

  removeBulkType(id: string): Observable<BulkType> {
    return this.http.delete<BulkType>(`/api/bulktype/${id}`);
  }

  addBulkType(bulkType: BulkType): Observable<BulkType> {
    return this.http.post<BulkType>('/api/bulktype', bulkType);
  }

  editBulkType(id: string, bulkType: BulkType): Observable<BulkType> {
    return this.http.put<BulkType>(`/api/bulktype/${id}`, bulkType);
  }
}

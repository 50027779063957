import {inject, Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {Site} from "./interfaces/site.interface";

@Injectable({providedIn: 'root'})
export class SiteService {
  private readonly http = inject(HttpClient);

  loadSites(): Observable<Site[]> {
    return this.http.get<Site[]>('/api/site');
  }

  loadSitesById(id: string): Observable<Site[]> {
    return this.http.get<Site[]>(`/api/site/${id}`);
  }

  loadSitesByLocationId(id: string): Observable<Site[]> {
    return this.http.get<Site[]>(`/api/site/byLocationid/${id}`)
  }

  removeSite(id: string): Observable<Site> {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const headers = new HttpHeaders().set('Time-Zone', timezone);
    return this.http.delete<Site>(`/api/site/${id}`, {
      headers,
    });
  }

  addSite(site: Site): Observable<Site> {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const headers = new HttpHeaders().set('Time-Zone', timezone);
    return this.http.post<Site>(
      '/api/site',
      site,
      {headers}
    );
  }

  editSite(id: string, site: Site): Observable<Site> {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const headers = new HttpHeaders().set('Time-Zone', timezone);
    return this.http.put<Site>(
      `/api/site/${id}`,
      site,
      {headers}
    );
  }
}

import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { errorProps } from 'libs/components/src/lib/functions/utility.functions';
import { LoadCell } from '../../interfaces/load-cell.interface';
import { SearchQuery } from 'libs/components/src/lib/interfaces/search-query.interface';

export const LoadCellActions = createActionGroup({
  source: 'LoadCell',
  events: {
    load_Load_Cells_Page: emptyProps(),
    update_Load_Cell_Queries: props<{ query: SearchQuery }>(),
    load_Cell_List: emptyProps(),
    load_Cell_List_Success: props<{ cellList: LoadCell[] }>(),
    load_Cell_List_Failure: errorProps(),
    load_Cell_List_By_Location_Id: props<{ locationId: string }>(),
    load_Cell_List_By_Location_Id_Success: props<{ cellList: LoadCell[] }>(),
    load_Cell_List_By_Location_Id_Failure: errorProps(),
    load_By_Id: props<{ loadCellId: string }>(),
    load_By_Id_Success: props<{ loadCell: LoadCell }>(),
    load_By_Id_Failure: errorProps(),
    add_Load_Cell: props<{ loadCell: LoadCell }>(),
    add_Load_Cell_Success: props<{
      loadCell: LoadCell;
      successMessage: string;
    }>(),
    add_Load_Cell_Failure: errorProps(),
    update_Load_Cell: props<{ loadCell: LoadCell; loadCellId: string }>(),
    update_Load_Cell_Success: props<{
      loadCell: LoadCell;
      successMessage: string;
    }>(),
    update_Load_Cell_Failure: errorProps(),
    delete_Load_Cell: props<{ loadCellId: string }>(),
    delete_Load_Cell_Success: props<{
      loadCellId: string;
      successMessage: string;
    }>(),
    delete_Load_Cell_Failure: errorProps(),
    open_Load_Cell_Dialog: props<{ loadCell: LoadCell | null }>(),
  },
});

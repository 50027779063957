import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { SquadsService } from '../../squad.service';
import { catchError, map, mergeMap, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { SquadActions } from '../actions/squad.actions';
import { Squad } from '../../interfaces/squad.interface';
import { Store } from '@ngrx/store';
import { squadsFeature } from '../features';
import { MatDialog } from '@angular/material/dialog';
import { switchMap, tap } from 'rxjs/operators';
import { SquadAddEditComponent } from '../../../../../../../../apps/allocate/src/app/maintenance/shared/components/squad-add-edit/squad-add-edit.component';
import { DialogOptions } from '../../../config/dialog-options';
import { LocationActions } from '../actions/locations.actions';
import { EmployeeActions } from '../actions/employee.actions';

export const loadSquadsPage = createEffect(
  (actions = inject(Actions)) => {
    return actions.pipe(
      ofType(SquadActions.load_Squads_Page),
      switchMap(() =>
        of(
          SquadActions.load_Squads(),
          LocationActions.load_Locations(),
          EmployeeActions.load_Employees()
        )
      )
    );
  },
  { functional: true }
);

export const loadSquads = createEffect(
  (
    actions = inject(Actions),
    store = inject(Store),
    squadsService = inject(SquadsService)
  ) => {
    return actions.pipe(
      ofType(
        SquadActions.load_Squads,
        SquadActions.update_Squad_Queries,
        SquadActions.remove_Squad_Success,
        SquadActions.add_Squad_Success,
        SquadActions.edit_Squad_Success
      ),
      concatLatestFrom(() => store.select(squadsFeature.selectQuery)),
      map(([, res]) => res),
      mergeMap(() =>
        squadsService.loadSquads().pipe(
          map((res: Squad[]) =>
            SquadActions.load_Squads_Success({
              squads: res,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(SquadActions.load_Squads_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const removeSquad = createEffect(
  (actions = inject(Actions), squadsService = inject(SquadsService)) => {
    return actions.pipe(
      ofType(SquadActions.remove_Squad),
      mergeMap((action) =>
        squadsService.removeSquad(action.id).pipe(
          map((res: Squad) =>
            SquadActions.remove_Squad_Success({
              squad: res,
              successMessage: 'Squad removed successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(SquadActions.remove_Squad_Failure({ error: error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const addSquad = createEffect(
  (actions = inject(Actions), squadsService = inject(SquadsService)) => {
    return actions.pipe(
      ofType(SquadActions.add_Squad),
      mergeMap((action) =>
        squadsService.addSquad(action.squad).pipe(
          map((res: Squad) =>
            SquadActions.add_Squad_Success({
              squad: res,
              successMessage: 'Squad added successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(SquadActions.add_Squad_Failure({ error: error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const editSquad = createEffect(
  (actions = inject(Actions), squadsService = inject(SquadsService)) => {
    return actions.pipe(
      ofType(SquadActions.edit_Squad),
      mergeMap((action) =>
        squadsService.editSquad(action.squadId, action.squad).pipe(
          map((res: Squad) =>
            SquadActions.edit_Squad_Success({
              squad: res,
              successMessage: 'Squad edited successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(SquadActions.edit_Squad_Failure({ error: error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const openSquadDialog = createEffect(
  (
    actions = inject(Actions),
    dialog = inject(MatDialog),
    dialogOptions = inject(DialogOptions)
  ) => {
    return actions.pipe(
      ofType(SquadActions.open_Squad_Dialog),
      tap((action) => {
        dialog.open(SquadAddEditComponent, {
          ...dialogOptions,
          data: {
            squad: action.squad,
          },
        });
      })
    );
  },
  {
    functional: true,
    dispatch: false,
  }
);

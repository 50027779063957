import { inject, Injectable } from '@angular/core';
import { SearchQuery } from '../../../../../components/src/lib/interfaces/search-query.interface';
import { Observable } from 'rxjs';
import { ReportType } from './interfaces/report-type.interface';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class ReportTableService {
  private readonly http = inject(HttpClient);

  loadReportTypes(query: SearchQuery): Observable<ReportType[]> {
    return this.http.get<ReportType[]>('/api/reporttype');
    /**
     * It will be used after creating pagination, sorting, and search on back-end
     */
    // return this.loadMaintenance<ActivityType[]>(query, 'reporttype');
  }

  removeReportType(id: string): Observable<ReportType> {
    return this.http.delete<ReportType>(`/api/reporttype/${id}`);
  }

  addReportType(reportType: ReportType): Observable<ReportType> {
    return this.http.post<ReportType>('/api/reporttype', reportType);
  }

  editReportType(id: string, reportType: ReportType): Observable<ReportType> {
    return this.http.put<ReportType>(`/api/reporttype/${id}`, reportType);
  }
}

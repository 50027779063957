import { NgClass, NgIf, NgStyle, NgTemplateOutlet } from '@angular/common';
import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'lha-page-container',
  standalone: true,
  imports: [NgStyle, NgClass, NgTemplateOutlet, NgIf],
  templateUrl: './page-container.component.html',
  styleUrls: ['./page-container.component.scss'],
})
export class PageContainerComponent {
  @Input() contentRelative = false;
  @Input() headerBorder = false;
  @Input() tableContainer = false;
  @Input() headerTemplate: TemplateRef<unknown> | null = null;
  @Input() footerTemplate: TemplateRef<unknown> | null = null;
}

import {inject, Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {Area} from "./interfaces/area.interface";

@Injectable({providedIn: 'root'})
export class AreaService {
  private readonly http = inject(HttpClient);

  loadAreas(): Observable<Area[]> {
    return this.http.get<Area[]>('/api/area');
  }

  loadAreasById(id: string): Observable<Area[]> {
    return this.http.get<Area[]>(`/api/area/${id}`);
  }

  loadAreasBySiteId(id: string): Observable<Area[]> {
   return this.http.get<Area[]>(`/api/area/bySiteId/${id}`)
  }

  removeArea(id: string): Observable<Area> {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const headers = new HttpHeaders().set('Time-Zone', timezone);
    return this.http.delete<Area>(`/api/area/${id}`, {
      headers,
    });
  }

  addArea(area: Area): Observable<Area> {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const headers = new HttpHeaders().set('Time-Zone', timezone);
    return this.http.post<Area>(
      '/api/area',
      area,
      { headers }
    );
  }

  editArea(id: string, area: Area): Observable<Area> {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const headers = new HttpHeaders().set('Time-Zone', timezone);
    return this.http.put<Area>(
      `/api/area/${id}`,
      area,
      { headers }
    );
  }

  loadAreasByLocationId(locationId: string): Observable<Area[]> {
    return this.http.get<Area[]>( `/api/area/getAllByLocationId/${locationId}`);
  }
}

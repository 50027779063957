<div class="table {{tableClass}}">
  <ng-template #header>
    <div class="table__header">
      <ng-content select="[tableHeaderTitle]"></ng-content>
      <div class="table__header_container">
        <div class="table__header_left">
          <ng-content select="[tableHeaderLeft]"></ng-content>
          <ng-container *ngFor="let filter of filters">
            <mat-form-field appearance="outline" subscriptSizing="dynamic">
              <mat-label>{{filter.placeholder}}</mat-label>
              <mat-select
                [(value)]="filter.selected"
                (selectionChange)="applySelectFilter(filter)"
              >
                <mat-option [value]="">All</mat-option>
                <mat-option
                  *ngFor="let option of filter.options | async"
                  [value]="option[filter.propertyId]"
                >
                  {{option[filter.propertyName]}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>
          <ng-content select="[tableHeaderFilterRight]"></ng-content>
        </div>
        <div class="table__header_right">
          <div class="table__header_box">
            <ng-content select="[tableHeaderRight]"></ng-content>
            <lha-search
              *ngIf="showSearch"
              [value]="searchValue"
              class="al"
              (querySearch)="applyFilter($event)"
            ></lha-search>
          </div>
          <div class="table__header_box">
            <ng-content select="[tableHeaderRightBtn]"></ng-content>
            <button
              mat-raised-button
              *ngIf="canExportTable"
              color="primary"
              class="export_btn"
              [disabled]="exportLoading"
              (click)="onExportTable()"
            >
              <span [ngStyle]="{'opacity': exportLoading ? '0' : '1'}"
                >Export</span
              >
              <mat-icon class="mat-icon--spinner" *ngIf="exportLoading"
                ><mat-spinner diameter="25"></mat-spinner
              ></mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <lha-page-container
    [contentRelative]="true"
    [tableContainer]="true"
    [headerTemplate]="header"
    [footerTemplate]="footer"
  >
    <div class="table__content" #TABLE>
      <!--will change it in the future-->
      <div class="table__loader" *ngIf="loading">
        <mat-spinner diameter="50"></mat-spinner>
      </div>
      <ng-container>
        <table
          mat-table
          [dataSource]="dataSource"
          (matSortChange)="onChangeQuery($event)"
          matSort
        >
          <ng-container
            *ngFor="let column of columns; let i = index"
            [matColumnDef]="$any(column.property)"
          >
            <ng-container *ngIf="column.data.sortHeader">
              <th
                mat-header-cell
                mat-sort-header
                [lhaAttrData]="column.data.attributeHeaderData"
                [ngClass]="{'column_group_line': indexGroupArr.includes(i + 1)}"
                *matHeaderCellDef="let row"
              >
                <ng-container
                  *ngIf="headerCellTemplates.has($any(column.property)); else defaultTh"
                >
                  <ng-container
                    *ngTemplateOutlet="
                    headerCellTemplates.get($any(column.property))!;
                    context: { $implicit: row }"
                  ></ng-container>
                </ng-container>
                <ng-template #defaultTh> {{ column.title }} </ng-template>
              </th>
            </ng-container>
            <ng-container *ngIf="!column.data.sortHeader">
              <th
                mat-header-cell
                [lhaAttrData]="column.data.attributeHeaderData"
                [ngClass]="{'column_group_line': indexGroupArr.includes(i + 1)}"
                *matHeaderCellDef="let row"
              >
                <ng-container
                  *ngIf="headerCellTemplates.has($any(column.property)); else defaultTh"
                >
                  <ng-container
                    *ngTemplateOutlet="
                    headerCellTemplates.get($any(column.property))!;
                    context: { $implicit: row }"
                  ></ng-container>
                </ng-container>
                <ng-template #defaultTh> {{ column.title }} </ng-template>
              </th>
            </ng-container>
            <td
              mat-cell
              *matCellDef="let row"
              [routerLink]="selectableRows ? [link, row[propertyId]] : null"
              [routerLinkActive]="'active'"
              [ngClass]="{'column_group_line': indexGroupArr.includes(i + 1)}"
              [lhaAttrData]="column.data.attributeData"
            >
              <ng-container
                *ngIf="cellTemplates.has($any(column.property)); else defaultT"
              >
                <ng-container
                  *ngTemplateOutlet="
                cellTemplates.get($any(column.property))!;
                context: { $implicit: row }
              "
                ></ng-container>
              </ng-container>
              <ng-template #defaultT> {{ row[column.property] }} </ng-template>
              <a
                *ngIf="selectableRows"
                [routerLink]="[ link, row[propertyId] ]"
                class="flex-cell-link"
              ></a>
            </td>
          </ng-container>

          <!-- Column Group -->
          <ng-container
            *ngFor="let column of columnsGroup"
            [matColumnDef]="column.columnGroup"
          >
            <th
              mat-header-cell
              *matHeaderCellDef
              class="column_group_cell column_group_line"
              [attr.colspan]="column.colspan"
            >
              {{column.columnName}}
            </th>
          </ng-container>

          <ng-container *ngIf="columnsGroup.length">
            <tr
              mat-header-row
              *matHeaderRowDef="columnsGroup | columnGroups"
            ></tr>
          </ng-container>
          <!-- // Column Group -->
          <tr
            mat-header-row
            *matHeaderRowDef="(columns | columnHeaders); sticky: stickyHeader"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: columns | columnHeaders"
            (click)="rowClick.emit(row)"
          ></tr>

          <tr class="mdc-data-table__row" *matNoDataRow>
            <td
              class="mat-mdc-cell mdc-data-table__cell cdk-cell"
              [attr.colspan]="columns.length"
            >
              No results found.
            </td>
          </tr>
        </table>
        <ng-template #footer>
          <mat-paginator
            *ngIf="pagination"
            [pageSizeOptions]="!loading ? getPageSizes() : [5, 10]"
            [length]="length"
            [pageSize]="pageSize"
            [pageIndex]="pageIndex"
            (page)="onChangeQuery($event)"
            showFirstLastButtons
          >
          </mat-paginator>
        </ng-template>
      </ng-container>
    </div>
  </lha-page-container>
</div>

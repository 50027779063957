import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SearchQuery } from '../../../../../../../components/src/lib/interfaces/search-query.interface';
import { Squad } from '../../interfaces/squad.interface';
import { errorProps } from '../../../../../../../components/src/lib/functions/utility.functions';

export const SquadActions = createActionGroup({
  source: 'Squad',
  events: {
    update_Squad_Queries: props<{ query: SearchQuery }>(),
    load_Squads_Page: emptyProps(),
    load_Squads: emptyProps(),
    load_Squads_Success: props<{ squads: Squad[] }>(),
    load_Squads_Failure: errorProps(),
    remove_Squad: props<{ id: string }>(),
    remove_Squad_Success: props<{
      squad: Squad;
      successMessage: string;
    }>(),
    remove_Squad_Failure: errorProps(),
    open_Squad_Dialog: props<{ squad: Squad | null }>(),
    add_Squad: props<{ squad: Squad }>(),
    add_Squad_Success: props<{
      squad: Squad;
      successMessage: string;
    }>(),
    add_Squad_Failure: errorProps(),
    edit_Squad: props<{
      squadId: string;
      squad: Squad;
    }>(),
    edit_Squad_Success: props<{
      squad: Squad;
      successMessage: string;
    }>(),
    edit_Squad_Failure: errorProps(),
  },
});

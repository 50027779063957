import { createFeature, createReducer, createSelector } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { cargoSizeInitialState } from '../states/cargo-size.state';
import { CargoeSizeActions } from '../actions/cargo-size.actions';

export const cargoSizeReducer = createReducer(
  cargoSizeInitialState,
  immerOn(
    CargoeSizeActions.load_CargoSize_List,
    (state) => {
      state.loading.list = true;
    }
  ),
  immerOn(CargoeSizeActions.load_CargoSizes_Success, (state, { CargoSizes }) => {
    state.cargoSizes = CargoSizes;
    state.loading.list = false;
  }),
  immerOn(CargoeSizeActions.load_Cargo_Sizes_Success, (state, { cargoSizes }) => {
    state.cargoSizes = cargoSizes;
    state.loading.list = false;
  }),
  immerOn(CargoeSizeActions['load_CargoSizes_By-Family_Id_Success'], (state, { CargoSizes }) => {
    state.cargoSizes = CargoSizes;
    state.loading.list = false;
  }),
  immerOn(
    CargoeSizeActions.load_CargoSizes_Failure,
    (state) => {
      state.loading.list = false;
    }
  ),
  immerOn(
    CargoeSizeActions.clear_CargoSizes,
    (state) => {
      state.cargoSizes = [];
    }
  )
);

export const cargoSizeFeature = createFeature({
  name: 'cargoSize',
  reducer: cargoSizeReducer,
  extraSelectors: ({ selectLoading }) => ({
    selectListLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.list
    ),
    selectCreateEditLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.createEdit
    ),
  }),
});

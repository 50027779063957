import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ActivityCategory } from '../../interfaces/activity-category.interface';
import { SearchQuery } from '../../../../../../../components/src/lib/interfaces/search-query.interface';
import { Constant } from 'libs/services/src/lib/services/shared/constant.interface';
import { errorProps } from '../../../../../../../components/src/lib/functions/utility.functions';

export const ActivityCategoryActions = createActionGroup({
  source: 'Activity Category',
  events: {
    update_Activity_Category_Queries: props<{ query: SearchQuery }>(),
    load_Activity_Category: emptyProps(),
    load_Activity_Category_Success: props<{
      activityCategory: ActivityCategory[];
    }>(),
    load_Activity_Category_Failure: errorProps(),
    open_Activity_Category_Dialog: props<{
      activityCategory?: ActivityCategory;
    }>(),
    remove_Activity_Category: props<{ id: string }>(),
    remove_Activity_Category_Success: props<{
      activityCategory: ActivityCategory;
      successMessage: string;
    }>(),
    remove_Activity_Category_Failure: errorProps(),
    add_Activity_Category: props<{ activityCategory: ActivityCategory }>(),
    add_Activity_Category_Success: props<{
      activityCategory: ActivityCategory;
      successMessage: string;
    }>(),
    add_Activity_Category_Failure: errorProps(),
    edit_Activity_Category: props<{
      activityCategoryId: string;
      activityCategory: ActivityCategory;
    }>(),
    edit_Activity_Category_Success: props<{
      activityCategory: ActivityCategory;
      successMessage: string;
    }>(),
    edit_Activity_Category_Failure: errorProps(),
    load_Activity_Category_Type_List: emptyProps(),
    load_Activity_Category_Type_List_Success: props<{
      activityCategoryTypeList: Constant[];
    }>(),
    load_Activity_Category_Type_List_Failure: errorProps(),
    load_Activity_Types: emptyProps(),
    load_Activity_Types_Success: props<{ activityTypes: string[] }>(),
    load_Activity_Types_Failure: errorProps(),
  },
});

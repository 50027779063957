import { ValidatorFn } from '@angular/forms';
import { AbstractControl } from '@angular/forms';
import { ValidationErrors } from '@angular/forms';

export function fieldsMustNotMatch(
  firstControl: string,
  secondControl: string
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const firstField = control.get(firstControl);
    let firstFieldValue = null;
    if (firstField) {
      firstFieldValue = firstField.value;
    }

    const secondField = control.get(secondControl);
    let secondFieldValue = null;
    if (secondField) {
      secondFieldValue = secondField.value;
    }

    if (
      firstFieldValue &&
      secondFieldValue &&
      firstFieldValue === secondFieldValue
    ) {
      const firstFieldErrors = {
        ...firstField?.errors,
        match: 'true',
      };
      const secondFieldErrors = {
        ...firstField?.errors,
        match: 'true',
      };
      firstField?.setErrors(firstFieldErrors);
      secondField?.setErrors(secondFieldErrors);
      firstField?.markAsTouched();
      secondField?.markAsTouched();
      return { match: true };
    }

    if (firstFieldValue) {
      const errors = { ...firstField?.errors };
      delete errors['match'];
      firstField?.setErrors(Object.keys(errors).length ? errors : null);
    }

    if (secondFieldValue) {
      const errors = { ...secondField?.errors };
      delete errors['match'];
      secondField?.setErrors(Object.keys(errors).length ? errors : null);
    }

    return null;
  };
}

import { Employee } from '../../interfaces/employee.interface';
import { MaintenanceState } from '../../interfaces/maintenance-state.interface';
import { maintenanceInitialState } from './maintenance.state';

export const employeeInitialState: EmployeeState = {
  employees: [],
  ...maintenanceInitialState,
};

export interface EmployeeState extends MaintenanceState {
  employees: Employee[];
}

import { createFeature, createReducer, createSelector } from '@ngrx/store';
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LighthouseState {}

export const lighthouseInitialState: LighthouseState = {};

export const lighthouseReducer = createReducer(lighthouseInitialState);

export const lighthouseFeature = createFeature({
  name: 'lighthouse',
  reducer: lighthouseReducer,
});

import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { distancesFeature } from '../../../../../../../../libs/services/src/lib/services/maintenance/store/features/distance.features';
import { LoadingDirective } from '../../../../../../../../libs/components/src/lib/directives/loading.directive';
import { Distance } from '../../../../../../../../libs/services/src/lib/services/maintenance/interfaces/distance.interface';
import { DistanceActions } from '../../../../../../../../libs/services/src/lib/services/maintenance/store/actions/distance.actions';
import { fieldsMustNotMatch } from '../../../../../../../../libs/components/src/lib/validators/fieldsMustNotMatch';
import { SingleSelectComponent } from '../../../../../../../../libs/components/src/lib/components/single-select/single-select.component';
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { greaterThan } from '../../../../../../../../libs/components/src/lib/validators/greaterThan';
import { assetsFeature } from 'libs/services/src/lib/services/maintenance/store/features';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'lha-distance-add-edit',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    NgForOf,
    AsyncPipe,
    LoadingDirective,
    NgIf,
    SingleSelectComponent,
    CdkDrag,
    CdkDragHandle,
  ],
  templateUrl: './distance-add-edit.component.html',
  styleUrls: ['./distance-add-edit.component.scss'],
})
export class DistanceAddEditComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  dialogRef = inject(MatDialogRef<DistanceAddEditComponent>);
  data: { distance: Distance } = inject(MAT_DIALOG_DATA);
  store = inject(Store);
  action = inject(Actions);
  distanceState = this.store.selectSignal(
    distancesFeature.selectDistancesState
  );
  assets = this.store.selectSignal(assetsFeature.selectAssetsForDistances);
  isAdd = true;
  unsubscribe: Subject<boolean> = new Subject();
  distance!: Distance;
  form = new FormGroup(
    {
      baseAssetId: new FormControl<string>('', [Validators.required]),
      toAssetId: new FormControl<string>('', [Validators.required]),
      distanceInMiles: new FormControl<null | number>(null, [
        Validators.required,
        greaterThan(0),
      ]),
    },
    fieldsMustNotMatch('baseAssetId', 'toAssetId')
  );

  ngOnInit(): void {
    this.subCloseDialog();
    this.initAddEdit();
  }

  private initAddEdit(): void {
    this.isAdd = !this.data.distance;
    if (!this.isAdd) {
      this.distance = this.data.distance;
      this.pathForm(this.distance);
    }
  }

  private subCloseDialog(): void {
    this.action
      .pipe(
        ofType(
          DistanceActions.add_Distance_Success,
          DistanceActions.edit_Distance_Success
        )
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  private pathForm(distance: Distance): void {
    this.form.patchValue({
      baseAssetId: distance.baseAssetId,
      toAssetId: distance.toAssetId,
      distanceInMiles: distance.distanceInMiles,
    });
  }

  saveDistance(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    if (this.isAdd) {
      this.store.dispatch(
        DistanceActions.add_Distance({ distance: this.form.value as Distance })
      );
    } else {
      const model = {
        ...this.distance,
        ...this.form.value,
      } as Distance;
      this.store.dispatch(
        DistanceActions.edit_Distance({
          distance: model,
          distanceId: this.distance.distanceId,
        })
      );
    }
  }
}

import { Component, inject, OnInit, OnDestroy } from '@angular/core';
import { AsyncPipe, DOCUMENT, NgIf, NgSwitch, NgForOf } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Router, RouterOutlet } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Store } from '@ngrx/store';
import { currentUserFeature } from '../../../../../libs/auth/src/lib/store/current-user/current-user.features';
import { CurrentUserActions } from '../../../../../libs/auth/src/lib/store/current-user/current-user.actions';
import { HeaderComponent } from '../../../../../libs/components/src/lib/components/header/header.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { PortalDirective } from '../../../../../libs/components/src/lib/directives/portal.directive';
import { ClientLocationActions } from '../../../../../libs/services/src/lib/services/client-locations/store/actions/client-locations.actions';
import { clientLocationsFeature } from '../../../../../libs/services/src/lib/services/client-locations/store/features/client-locations.features';
import { locationsFeature } from '../../../../../libs/services/src/lib/services/maintenance/store/features';
import { LocationActions } from '../../../../../libs/services/src/lib/services/maintenance/store/actions/locations.actions';

@Component({
  selector: 'app-switcher',
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    NgSwitch,
    ReactiveFormsModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    HeaderComponent,
    MatSidenavModule,
    RouterOutlet,
    PortalDirective,
    NgForOf,
  ],
  templateUrl: './app-switcher.component.html',
  styleUrls: ['./app-switcher.component.scss'],
})
export class AppSwitcherComponent implements OnInit {
  router = inject(Router);
  auth = inject(AuthService);
  doc = inject(DOCUMENT);
  store = inject(Store);
  currentUser$ = this.store.select(currentUserFeature.selectUser);
  clientLocations$ = this.store.select(
    clientLocationsFeature.selectClientLocations
  );
  locations$ = this.store.select(locationsFeature.selectLocations);
  url = window.location.origin;

  ngOnInit() {
    this.store.dispatch(CurrentUserActions.load_Current_User());
    this.store.dispatch(ClientLocationActions.load_User_Client_Locations());
    this.store.dispatch(LocationActions.load_Locations());
  }

  logout() {
    this.auth.logout({ logoutParams: { returnTo: this.doc.location.origin } });
  }
}

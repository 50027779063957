import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { errorProps } from '../../../../../../../components/src/lib/functions/utility.functions';
import { CargoType } from '../../interfaces/carrgo-type.interface';

export const CargoeTypeActions = createActionGroup({
  source: 'CargoType',
  events: {
    'load_CargoType_List': emptyProps(),
    'load_CargoTypes_Success': props<{ CargoTypes: CargoType[] }>(),
    'load_Cargotypes_Failure': errorProps(),
    'load_CargoType_List_By-Family_Id': props<{ familyId: string }>(),
    'load_CargoTypes_By-Family_Id_Success': props<{ CargoTypes: CargoType[] }>(),
    'load_Cargotypes_By-Family_Id_Failure': errorProps(),
    'clear_CargoTypes': emptyProps(),

    load_Cargo_Types_Page: emptyProps(),
    load_Cargo_Types: emptyProps(),
    load_Cargo_Types_Success: props<{ cargoTypes: CargoType[] }>(),
    load_Cargo_Types_Failure: errorProps(),
    remove_Cargo_Type: props<{ id: string }>(),
    remove_Cargo_Type_Success: props<{
      cargoType: CargoType;
      successMessage: string;
    }>(),
    remove_Cargo_Type_Failure: errorProps(),
    open_Cargo_Type_Dialog: props<{
      cargoType: CargoType | null;
    }>(),
    add_Cargo_Type: props<{ cargoType: CargoType }>(),
    add_Cargo_Type_Success: props<{
      cargoType: CargoType;
      successMessage: string;
    }>(),
    add_Cargo_Type_Failure: errorProps(),
    edit_Cargo_Type: props<{
      cargoTypeId: string;
      cargoType: CargoType;
    }>(),
    edit_Cargo_Type_Success: props<{
      cargoType: CargoType;
      successMessage: string;
    }>(),
    edit_Cargo_Type_Failure: errorProps(),
  },
});

import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SearchQuery } from '../../../../../../../components/src/lib/interfaces/search-query.interface';
import { Location } from '../../interfaces/location.interface';
import { errorProps } from '../../../../../../../components/src/lib/functions/utility.functions';
import { TimezoneInfo } from '../../interfaces/timezone-info.interface';

export const LocationActions = createActionGroup({
  source: 'Location',
  events: {
    update_Location_Queries: props<{ query: SearchQuery }>(),
    load_Location_Page: emptyProps(),
    load_Locations: emptyProps(),
    load_Locations_Success: props<{ locations: Location[] }>(),
    load_Locations_Failure: errorProps(),
    remove_Location: props<{ id: string }>(),
    remove_Location_Success: props<{
      location: Location;
      successMessage: string;
    }>(),
    remove_Location_Failure: errorProps(),
    open_Location_Dialog: props<{ location: Location | null }>(),
    add_Location: props<{ location: Location }>(),
    add_Location_Success: props<{
      location: Location;
      successMessage: string;
    }>(),
    add_Location_Failure: errorProps(),
    edit_Location: props<{ locationId: string; location: Location }>(),
    edit_Location_Success: props<{
      location: Location;
      successMessage: string;
    }>(),
    edit_Location_Failure: errorProps(),
    open_location_Dialog: props<{ id: string; location?: Location }>(),
    load_timezones: emptyProps(),
    load_timezones_Success: props<{ timezones: TimezoneInfo[] }>(),
    load_timezones_Failure: errorProps(),
    open_Site_Dialog: props<{ location?: Location }>(),
  },
});

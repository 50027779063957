import { Component, forwardRef, Input, OnChanges } from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
  selector: 'lha-multiselect',
  standalone: true,
  imports: [NgSelectModule, FormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiselectComponent),
      multi: true,
    },
  ],
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
})
export class MultiselectComponent implements ControlValueAccessor, OnChanges {
  @Input() placeholder = 'Select items';
  @Input() bindLabel = 'name';
  @Input() bindValue = 'value';
  @Input() disabled = false;
  @Input() items: any[] = [];

  currentValue: any[] = [];

  ngOnChanges(): void {
    // capitalize only first letter
    if (this.items && this.items.length > 0) {
      this.items = this.items.map((item) => {
        return {
          ...item,
          [this.bindLabel]: capitalizeFirstLetter(
            item[this.bindLabel].toLowerCase()
          ),
        };
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: (value: string[]) => void = () => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: () => void = () => {};

  registerOnChange(fn: (value: string[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: string[]): void {
    if (value) {
      this.currentValue = value;
    }
  }

  changeValue(event: []): void {
    this.onChange(this.mapToValue(event));
    this.onTouched();
  }

  mapToValue(arr: any[]): string[] {
    return arr.map((item) => item[this.bindValue]);
  }

}

export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

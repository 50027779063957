import { HttpInterceptorFn } from '@angular/common/http';
import { mergeMap, catchError, switchMap, throwError, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthActions } from './store/auth.actions';
import { ActionsSubject } from '@ngrx/store';
import { ofType } from '@ngrx/effects';
import { Environment } from '../../../env/src/environments/enviroment-provider';

let refreshing = false;

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const store = inject(Store);
  const actions$ = inject(ActionsSubject);
  const environment = inject(Environment);
  const url = environment.apiUrl;
  const authReq = req.clone();

  return next(authReq).pipe(
    catchError((error) => {
      //if error has occurred, and it was from a refreshToken request, immediately throw error
      if (authReq.url.includes('auth/refreshToken')) {
        refreshing = false;
        return throwError(() => error);
      }
      if (
        error instanceof HttpErrorResponse &&
        !authReq.url.includes('auth/token') &&
        error.status === 401 &&
        authReq.url.startsWith(url)
      ) {
        if (!refreshing) {
          refreshing = true;
          store.dispatch(AuthActions.refresh_Token());
        }
        return of(null).pipe(
          switchMap(() =>
            actions$.pipe(
              ofType(AuthActions.refresh_Token_Success),
              mergeMap(() => {
                refreshing = false;
                return next(
                  authReq.clone({
                    withCredentials: true,
                  })
                );
              })
            )
          )
        );
      }
      refreshing = false;
      return throwError(() => error);
    })
  );
};

import {createActionGroup, emptyProps, props} from "@ngrx/store";
import {SearchQuery} from "../../../../../../../components/src/lib/interfaces/search-query.interface";
import {errorProps} from "../../../../../../../auth/src/lib/store/auth.actions";
import {BlockingActivity} from "../../interfaces/blocking-activity.interface";

export const BlockingActivityActions = createActionGroup({
  source: 'BlockingActivity',
  events: {
    'update_Blocking_Activity_Queries': props<{ query: SearchQuery}>(),
    'open_Blocking_Activity_Dialog': props<{ blockingActivity: BlockingActivity | null }>(),
    'load_Blocking_Activities': emptyProps(),
    'load_Blocking_Activities_Success': props<{ blockingActivities: BlockingActivity[]; }>(),
    'load_Blocking_Activities_Failure': errorProps(),
    'remove_Blocking_Activity': props<{ id: string }>(),
    'remove_Blocking_Activity_Success': props<{blockingActivity: BlockingActivity; successMessage: string;}>(),
    'remove_Blocking_Activity_Failure': errorProps(),
    'add_Blocking_Activity': props<{blockingActivity: BlockingActivity}>(),
    'add_Blocking_Activity_Success': props<{blockingActivity: BlockingActivity; successMessage: string;}>(),
    'add_Blocking_Activity_Failure': errorProps(),
    'edit_Blocking_Activity': props<{ id: string; blockingActivity: BlockingActivity; }>(),
    'edit_Blocking_Activity_Success': props<{blockingActivity: BlockingActivity; successMessage: string;}>(),
    'edit_Blocking_Activity_Failure': errorProps(),
  }
})

import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SearchQuery } from '../../../../../../../components/src/lib/interfaces/search-query.interface';
import { Cargo } from '../../interfaces/cargoes.interface';
import { errorProps } from '../../../../../../../components/src/lib/functions/utility.functions';
import { CargoUnitType } from '../../interfaces/cargo-unit-type.interface';

export const CargoesActions = createActionGroup({
  source: 'Cargoes',
  events: {
    update_Cargo_Queries: props<{ query: SearchQuery }>(),
    load_Cargo_Page: emptyProps(),
    load_Cargos: props<{
      vendorId: string | undefined;
      showPending: boolean;
      from?: string | null;
      to?: string | null;
    }>(),
    load_Cargos_Success: props<{ Cargos: Cargo[] }>(),
    load_Cargos_Failure: errorProps(),
    load_Cargos_By_Location: props<{ locationId: string }>(),
    load_Cargos_By_Location_Success: props<{ Cargos: Cargo[] }>(),
    load_Cargos_By_Location_Failure: errorProps(),
    remove_Cargo: props<{ id: string }>(),
    remove_Cargo_Success: props<{
      Cargo: Cargo;
      successMessage: string;
      vendorId: string | undefined;
      showPending: boolean;
      from?: string | null;
      to?: string | null;
    }>(),
    remove_Cargo_Failure: errorProps(),
    open_Cargo_Dialog: props<{
      Cargo: Cargo | null;
      showPending: boolean;
      from?: string | null;
      to?: string | null;
    }>(),
    add_Cargo: props<{
      Cargo: Cargo;
      showPending: boolean;
      from?: string | null;
      to?: string | null;
    }>(),
    add_Cargo_Success: props<{
      Cargo: Cargo;
      successMessage: string;
      vendorId: string | undefined;
      showPending: boolean;
      from?: string | null;
      to?: string | null;
    }>(),
    add_Cargo_Failure: errorProps(),
    edit_Cargo: props<{
      CargoId: string;
      Cargo: Cargo;
      showPending: boolean;
      from?: string | null;
      to?: string | null;
    }>(),
    edit_Cargo_Success: props<{
      Cargo: Cargo;
      successMessage: string;
      vendorId: string | undefined;
      showPending: boolean;
      from?: string | null;
      to?: string | null;
    }>(),
    edit_Cargo_Failure: errorProps(),
    approve_Cargo: props<{
      CargoId: string;
      showPending: boolean;
      from?: string | null;
      to?: string | null;
    }>(),
    approve_Cargo_Success: props<{
      successMessage: string;
      vendorId: string | undefined;
      showPending: boolean;
      from?: string | null;
      to?: string | null;
    }>(),
    approve_Cargo_Failure: errorProps(),
    load_Cargo_Descriptions: emptyProps(),
    load_Cargo_Descriptions_Success: props<{
      cargoDescriptions: CargoUnitType[];
    }>(),
    load_Cargo_Descriptions_Failure: errorProps(),
    add_One_Off_Cargo: props<{
      oneOffCargo: Cargo;
      cargoExpectedDeliveryTime: Date;
      weight: number;
    }>(),
  },
});

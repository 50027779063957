import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, debounceTime, from, map, mergeMap, of, switchMap, combineLatest, tap } from 'rxjs';
import { AuthenticationService } from '../services/auth.service';
import { AuthActions } from './auth.actions';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { TokenExpirationService } from '../services/token-expiration.service';
import {CookieService} from "ngx-cookie-service";

const PASSWORD_RESET_REQUEST_MESSAGE = 'If an account is associated with that email, you should receive a password reset link via that email.';

export const login = createEffect(
  (actions = inject(Actions), authService = inject(AuthService)) => {
    return actions.pipe(
      ofType(AuthActions.login),
      tap(() => localStorage.setItem('UserAttemptedLogin', 'true')), 
      mergeMap(({ orgId }) => {
        if (orgId) {
          return from(
            authService.loginWithRedirect({
              authorizationParams: {
                organization: orgId,
                redirect_uri: `${window.location.origin}/callback`,
              },
              appState: { target: '/appswitcher' },
            })
          ).pipe(
            catchError((error) => {
              console.error('Error during login', error); 
              return of(
                AuthActions.login_Failure({
                  error: new HttpErrorResponse({
                    status: 400,
                    statusText: error.statusText || 'Unknown error during login',
                    error: error.message || 'Unknown error',
                  }),
                }),
              );
            }),
          );
        } else {
          return of(
            AuthActions.login_Failure({
              error: new HttpErrorResponse({
                status: 400,
                statusText: 'Missing Organisation ID',
                error: 'Organisation could not be found.',
              }),
            }),
          );
        }
      }),
    );
  },
  { functional: true, dispatch: false },
);

export const logout = createEffect(
  (
    actions = inject(Actions),
    authenticationService = inject(AuthService),
  ) => {
    return actions.pipe(
      ofType(AuthActions.logout),
      mergeMap(() =>
        authenticationService.logout({ logoutParams: { returnTo: document.location.origin } })
        .pipe(
          map(() => AuthActions.refresh_Token_Failure), // assuming you want to dispatch this action if logout is successful
          catchError(() => of(AuthActions.refresh_Token_Failure())) // dispatch if there is an error
        )
      )
    );
  },
  {
    dispatch: true,
    functional: true,
  },
);

export const hydrateState = createEffect(
  (
    actions = inject(Actions),
    auth = inject(AuthService),
    tokenExpirationService = inject(TokenExpirationService)
  ) => {
    return actions.pipe(
      ofType(AuthActions.hydrate_State),
      switchMap(() =>
        combineLatest([
          auth.isAuthenticated$,
          tokenExpirationService.isTokenExpired(),
        ]).pipe(
          mergeMap(([isLoggedIn, isTokenExpired]) => {
            if (isLoggedIn && isTokenExpired) {
              return of(AuthActions.logout());
            } else {
              return of(AuthActions.set_State({ loggedIn: isLoggedIn, tokenExpired: isTokenExpired }));
            }
          }),
        )
      )
    );
  },
  { functional: true }
);

export const refreshToken = createEffect(
  (
    actions = inject(Actions),
    authenticationService = inject(AuthenticationService),
    cookieService = inject(CookieService),
    router = inject(Router),
  ) => {
    return actions.pipe(
      ofType(AuthActions.refresh_Token),
      debounceTime(200),
      mergeMap(() => authenticationService.refreshToken()
        .pipe(
          map(() => {
            return AuthActions.refresh_Token_Success();
          }),
          catchError(() => {
            cookieService.delete('is_LoggedIn');
            authenticationService.deleteCookies();
            router.navigate(['/login']);
            return of(AuthActions.refresh_Token_Failure());
          },
        ),
      ),
    ),
  );},
  {
    functional: true,
  },
);

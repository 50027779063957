import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CargoType } from './interfaces/carrgo-type.interface';

@Injectable({ providedIn: 'root' })
export class CargoTypeService {
  private readonly http = inject(HttpClient);

  loadCargoTypes(): Observable<CargoType[]> {
    return this.http.get<CargoType[]>(`/api/CargoType`);
  }

  loadCargoTypesByFamilyId(familyId: string): Observable<CargoType[]> {
    return this.http.get<CargoType[]>(`/api/CargoType/byFamily?familyId=${familyId}`);
  }

  deleteCargoType(id: string): Observable<void> {
    return this.http.delete<void>(`/api/CargoType/${id}`);
  }

  createCargoType(cargoType: CargoType): Observable<CargoType> {
    return this.http.post<CargoType>(`/api/CargoType`, cargoType);
  }

  updateCargoType(cargoTypeId: string, cargoType: CargoType): Observable<CargoType> {
    return this.http.put<CargoType>(`/api/CargoType/${cargoTypeId}`, cargoType);
  }
}

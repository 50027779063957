<div
  class="asset-mobile-well dialog-container"
  *ngIf="activityCategoryTypeState()"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  cdkDragBoundary=".cdk-overlay-container"
>
  <lha-table
    [columns]="columns"
    [data]="activityCategoryTypes()"
    [loading]="activityCategoryTypeState().loading.createEdit"
    [canExportTable]="false"
    tableName="Activity Category Types"
  >
    <h2 tableHeaderLeft>
      Add Category Type to {{ data.activityCategory.name }}
    </h2>
    <ng-container tableHeaderRightBtn>
      <button
        mat-raised-button
        (click)="addActivityCategoryType()"
        color="primary"
      >
        Add
      </button>
      <button
        mat-icon-button
        color="warn"
        mat-dialog-close=""
        class="asset-close"
      >
        <mat-icon>close</mat-icon>
      </button>
    </ng-container>

    <ng-template lhaCellTemplate="name" let-item>
      <ng-container *ngIf="!editRows.get(item.activityCategoryTypeId)">
        {{ item.name }}
      </ng-container>
      <ng-container
        *ngIf="editRows.get(item.activityCategoryTypeId)"
        [formGroup]="getFg(item.activityCategoryTypeId)"
      >
        <mat-form-field appearance="outline">
          <mat-label>Activity Category Type</mat-label>
          <input
            matInput
            formControlName="name"
            placeholder="Enter Category Type"
          />
          <mat-error
            *ngIf="
              getFg(item.activityCategoryTypeId)?.get('name')?.invalid &&
              getFg(item.activityCategoryTypeId)?.get('name')?.touched
            "
          >
            Activity Category Type is required.
          </mat-error>
        </mat-form-field>
      </ng-container>
    </ng-template>
    <ng-template lhaCellTemplate="isInboundLifting" let-item>
      <ng-container
        class="center-icon"
        *ngIf="!editRows.get(item.activityCategoryTypeId)"
      >
        <mat-icon
          [ngClass]="
            item.isInboundLifting ? 'mat-icon--accept' : 'mat-icon--cancel'
          "
          >{{ item.isInboundLifting ? 'check_circle' : 'cancel' }}</mat-icon
        >
      </ng-container>
      <ng-container
        *ngIf="editRows.get(item.activityCategoryTypeId)"
        [formGroup]="getFg(item.activityCategoryTypeId)"
      >
        <mat-slide-toggle formControlName="isInboundLifting"></mat-slide-toggle>
      </ng-container>
    </ng-template>

    <ng-template lhaCellTemplate="createOutOfPortActivity" let-item>
      <ng-container
        class="center-icon"
        *ngIf="!editRows.get(item.activityCategoryTypeId)"
      >
        <mat-icon
          [ngClass]="
            item.createOutOfPortActivity
              ? 'mat-icon--accept'
              : 'mat-icon--cancel'
          "
          >{{
            item.createOutOfPortActivity ? 'check_circle' : 'cancel'
          }}</mat-icon
        >
      </ng-container>
      <ng-container
        *ngIf="editRows.get(item.activityCategoryTypeId)"
        [formGroup]="getFg(item.activityCategoryTypeId)"
      >
        <mat-slide-toggle
          disable="true"
          formControlName="createOutOfPortActivity"
        ></mat-slide-toggle>
      </ng-container>
    </ng-template>

    <ng-template lhaCellTemplate="createdDate" let-item>
      {{ item.createdByName }} {{ item.createdDate | date : 'dd-MM-yyyy' }}
    </ng-template>

    <ng-template lhaCellTemplate="activityCategoryTypeId" let-item>
      <button
        mat-icon-button
        color="primary"
        class="btn-save"
        *ngIf="editRows.get(item.activityCategoryTypeId)"
        (click)="saveActivityCategoryType(item)"
      >
        <mat-icon>check_circle</mat-icon>
      </button>
      <button
        mat-icon-button
        color="warn"
        *ngIf="editRows.get(item.activityCategoryTypeId)"
        (click)="cancelActivityCategoryType(item)"
      >
        <mat-icon>cancel</mat-icon>
      </button>
      <button
        mat-icon-button
        color="primary"
        *ngIf="!editRows.get(item.activityCategoryTypeId)"
        (click)="editActivityCategoryType(item)"
      >
        <mat-icon>edit</mat-icon>
      </button>
      <button
        mat-icon-button
        color="warn"
        *ngIf="!editRows.get(item.activityCategoryTypeId)"
        (click)="removeActivityCategoryType(item)"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </ng-template>
  </lha-table>
</div>

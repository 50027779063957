import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Squad } from '../../../../../../../../libs/services/src/lib/services/maintenance/interfaces/squad.interface';
import { SquadActions } from '../../../../../../../../libs/services/src/lib/services/maintenance/store/actions/squad.actions';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { NgIf } from '@angular/common';
import {
  squadsFeature,
  locationsFeature,
  employeesFeature,
} from '../../../../../../../../libs/services/src/lib/services/maintenance/store/features';
import { LoadingDirective } from '../../../../../../../../libs/components/src/lib/directives/loading.directive';
import { SingleSelectComponent } from '../../../../../../../../libs/components/src/lib/components/single-select/single-select.component';
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MultiselectComponent } from '../../../../../../../../libs/components/src/lib/components/multiselect/multiselect.component';

@Component({
  selector: 'lha-squads-add-edit',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    LoadingDirective,
    NgIf,
    SingleSelectComponent,
    CdkDrag,
    CdkDragHandle,
    MultiselectComponent,
  ],
  templateUrl: './squad-add-edit.component.html',
  styleUrls: ['./squad-add-edit.component.scss'],
})
export class SquadAddEditComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  dialogRef = inject(MatDialogRef<SquadAddEditComponent>);
  data: { squad: Squad } = inject(MAT_DIALOG_DATA);
  store = inject(Store);
  action = inject(Actions);
  squadsState = this.store.selectSignal(squadsFeature.selectSquadsState);
  locations = this.store.selectSignal(locationsFeature.selectLocations);
  employees = this.store.selectSignal(employeesFeature.selectEmployees);
  isAdd = true;
  squad!: Squad;
  form = new FormGroup({
    squadName: new FormControl<string>('', [Validators.required]),
    locationId: new FormControl<string>('', [Validators.required]),
    squadEmployees: new FormControl<string[]>([], [Validators.required]),
  });

  ngOnInit(): void {
    this.subCloseDialog();
    this.initAddEdit();
  }

  private initAddEdit(): void {
    this.isAdd = !this.data.squad;
    if (!this.isAdd) {
      this.squad = this.data.squad;
      this.pathForm(this.squad);
      const employeeIds = this.squad.squadEmployees.map(
        (emp) => emp.employeeId
      );
      this.form.patchValue({ squadEmployees: employeeIds });
    }
  }

  private subCloseDialog(): void {
    this.action
      .pipe(
        ofType(SquadActions.add_Squad_Success, SquadActions.edit_Squad_Success)
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  private pathForm(squad: Squad): void {
    this.form.patchValue({
      squadName: squad.squadName,
      locationId: squad.locationId,
      squadEmployees: squad.squadEmployees.map((emp) => emp.employeeId),
    });
  }

  saveSquad(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const squadEmployees = this.form.value.squadEmployees!.map(
      (employeeId: string) => ({
        employeeId,
      })
    );

    const model = {
      ...this.form.value,
      squadEmployees,
    } as Squad;

    if (this.isAdd) {
      this.store.dispatch(SquadActions.add_Squad({ squad: model }));
    } else {
      this.store.dispatch(
        SquadActions.edit_Squad({
          squad: model,
          squadId: this.squad.squadId,
        })
      );
    }
  }
}

import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { UnitsService } from '../../units.service';
import { catchError, map, mergeMap, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { UnitActions } from '../actions/units.actions';
import { Unit } from '../../../interfaces/unit.interface';
import { Store } from '@ngrx/store';
import { unitsFeature } from '../features';
import { MatDialog } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import { UnitAddEditComponent } from '../../../../../../../../apps/allocate/src/app/maintenance/shared/components/unit-add-edit/unit-add-edit.component';
import { DialogOptions } from '../../../config/dialog-options';

export const loadUnits = createEffect(
  (
    actions = inject(Actions),
    store = inject(Store),
    unitsService = inject(UnitsService)
  ) => {
    return actions.pipe(
      ofType(
        UnitActions.load_Units,
        UnitActions.update_Unit_Queries,
        UnitActions.remove_Unit_Success,
        UnitActions.add_Unit_Success,
        UnitActions.edit_Unit_Success
      ),
      concatLatestFrom(() => store.select(unitsFeature.selectQuery)),
      map(([, res]) => res),
      mergeMap((action) =>
        unitsService.loadUnits(action).pipe(
          map((res: Unit[]) => UnitActions.load_Units_Success({ units: res })),
          catchError((error: HttpErrorResponse) =>
            of(UnitActions.load_Units_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const removeUnit = createEffect(
  (actions = inject(Actions), unitsService = inject(UnitsService)) => {
    return actions.pipe(
      ofType(UnitActions.remove_Unit),
      mergeMap((action) =>
        unitsService.removeUnit(action.id).pipe(
          map((res: Unit) =>
            UnitActions.remove_Unit_Success({
              unit: res,
              successMessage: 'Unit removed successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(UnitActions.remove_Unit_Failure({ error: error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const addUnit = createEffect(
  (actions = inject(Actions), unitsService = inject(UnitsService)) => {
    return actions.pipe(
      ofType(UnitActions.add_Unit),
      mergeMap((action) =>
        unitsService.addUnit(action.unit).pipe(
          map((res: Unit) =>
            UnitActions.add_Unit_Success({
              unit: res,
              successMessage: 'Unit added successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(UnitActions.add_Unit_Failure({ error: error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const editUnit = createEffect(
  (actions = inject(Actions), unitsService = inject(UnitsService)) => {
    return actions.pipe(
      ofType(UnitActions.edit_Unit),
      mergeMap((action) =>
        unitsService.editUnit(action.unitId, action.unit).pipe(
          map((res: Unit) =>
            UnitActions.edit_Unit_Success({
              unit: res,
              successMessage: 'Unit edited successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(UnitActions.edit_Unit_Failure({ error: error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const openUnitDialog = createEffect(
  (
    actions = inject(Actions),
    dialog = inject(MatDialog),
    dialogOptions = inject(DialogOptions)
  ) => {
    return actions.pipe(
      ofType(UnitActions.open_Unit_Dialog),
      tap((action) => {
        dialog.open(UnitAddEditComponent, {
          ...dialogOptions,
          data: {
            unit: action.unit,
          },
        });
      })
    );
  },
  {
    functional: true,
    dispatch: false,
  }
);

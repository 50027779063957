import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SearchQuery } from '../../../../../../../components/src/lib/interfaces/search-query.interface';
import { Crane } from '../../interfaces/crane.interface';
import { errorProps } from '../../../../../../../components/src/lib/functions/utility.functions';

export const CraneActions = createActionGroup({
  source: 'Cranes',
  events: {
    update_Crane_Queries: props<{ query: SearchQuery }>(),
    load_Cranes_Page: emptyProps(),
    load_Cranes: emptyProps(),
    load_Cranes_Success: props<{ cranes: Crane[] }>(),
    load_Cranes_Failure: errorProps(),
    remove_Crane: props<{ craneId: string }>(),
    remove_Crane_Success: props<{
      successMessage: string;
    }>(),
    remove_Crane_Failure: errorProps(),
    open_Crane_Dialog: props<{ crane: Crane | null }>(),
    add_Crane: props<{ crane: Crane }>(),
    add_Crane_Success: props<{
      crane: Crane;
      successMessage: string;
    }>(),
    add_Crane_Failure: errorProps(),
    edit_Crane: props<{ craneId: string; crane: Crane }>(),
    edit_Crane_Success: props<{
      crane: Crane;
      successMessage: string;
    }>(),
    edit_Crane_Failure: errorProps(),
  },
});

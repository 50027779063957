import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AsyncPipe, DatePipe, NgForOf, NgIf } from '@angular/common';
import { LoadingDirective } from '../../../../../../../../libs/components/src/lib/directives/loading.directive';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MultiselectComponent } from '../../../../../../../../libs/components/src/lib/components/multiselect/multiselect.component';
import { SingleSelectComponent } from '../../../../../../../../libs/components/src/lib/components/single-select/single-select.component';
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivityCategory } from '../../../../../../../../libs/services/src/lib/services/maintenance/interfaces/activity-category.interface';
import { activityCategoryFeature } from '../../../../../../../../libs/services/src/lib/services/maintenance/store/features/activity-category.feature';
import { ActivityCategoryActions } from '../../../../../../../../libs/services/src/lib/services/maintenance/store/actions/activity-category.actions';
import { MatIconModule } from '@angular/material/icon';
import { ActivityTypes } from './types/activity-types';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'lha-activity-category-add-edit',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    NgForOf,
    AsyncPipe,
    LoadingDirective,
    NgIf,
    MatSlideToggleModule,
    MultiselectComponent,
    SingleSelectComponent,
    CdkDrag,
    CdkDragHandle,
    MatTooltipModule,
    DatePipe,
    MatIconModule,
  ],
  templateUrl: './activity-category-add-edit.component.html',
  styleUrls: ['./activity-category-add-edit.component.scss'],
})
export class ActivityCategoryAddEditComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  dialogRef = inject(MatDialogRef<ActivityCategoryAddEditComponent>);
  data: { activityCategory: ActivityCategory } = inject(MAT_DIALOG_DATA);
  store = inject(Store);
  action = inject(Actions);
  activityCategoryState = this.store.selectSignal(
    activityCategoryFeature.selectActivityCategoryState
  );
  activityCategory!: ActivityCategory;
  isAdd = true;
  activityOptions = ActivityTypes;

  form = new FormGroup({
    name: new FormControl<string>('', [Validators.required]),
    activityType: new FormControl<number | null>(null, [Validators.required]),
    isHidden: new FormControl<boolean>(false, [Validators.required]),
  });

  ngOnInit(): void {
    this.subCloseDialog();
    this.initAddEdit();
  }

  private initAddEdit(): void {
    this.isAdd = !this.data.activityCategory;
    if (!this.isAdd) {
      this.activityCategory = this.data.activityCategory;
      this.pathForm(this.activityCategory);
    }
  }

  private pathForm(activityCategory: ActivityCategory): void {
    this.form.patchValue({
      name: activityCategory.name,
      activityType: activityCategory.activityType,
      isHidden: activityCategory.isHidden,
    });
  }

  private subCloseDialog(): void {
    this.action
      .pipe(
        ofType(
          ActivityCategoryActions.add_Activity_Category_Success,
          ActivityCategoryActions.edit_Activity_Category_Success
        )
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  saveActivity(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const model = {
      ...this.form.value,
    } as ActivityCategory;

    if (this.isAdd) {
      this.store.dispatch(
        ActivityCategoryActions.add_Activity_Category({
          activityCategory: model,
        })
      );
    } else {
      this.store.dispatch(
        ActivityCategoryActions.edit_Activity_Category({
          activityCategoryId: this.activityCategory.activityCategoryId,
          activityCategory: model,
        })
      );
    }
  }
}

import {
  Component,
  DestroyRef,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AsyncPipe, DatePipe, NgForOf, NgIf } from '@angular/common';
import { LoadingDirective } from '../../../../../../../../libs/components/src/lib/directives/loading.directive';
import { Activity } from '../../../../../../../../libs/services/src/lib/services/maintenance/interfaces/activity.interface';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { activityConfigFeature } from '../../../../../../../../libs/services/src/lib/services/maintenance/store/features';
import { Subject, takeUntil } from 'rxjs';
import { ActivityConfigActions } from '../../../../../../../../libs/services/src/lib/services/maintenance/store/actions/activity-config.actions';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MultiselectComponent } from '../../../../../../../../libs/components/src/lib/components/multiselect/multiselect.component';
import { SingleSelectComponent } from '../../../../../../../../libs/components/src/lib/components/single-select/single-select.component';
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { MatTooltipModule } from '@angular/material/tooltip';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'lha-activity-config-add-edit',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    NgForOf,
    AsyncPipe,
    LoadingDirective,
    NgIf,
    MatSlideToggleModule,
    MultiselectComponent,
    SingleSelectComponent,
    CdkDrag,
    CdkDragHandle,
    MatTooltipModule,
    DatePipe,
  ],
  templateUrl: './activity-config-add-edit.component.html',
  styleUrls: ['./activity-config-add-edit.component.scss'],
})
export class ActivityConfigAddEditComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  dialogRef = inject(MatDialogRef<ActivityConfigAddEditComponent>);
  data: { activity: Activity } = inject(MAT_DIALOG_DATA);
  store = inject(Store);
  action = inject(Actions);
  activityConfigState = this.store.selectSignal(
    activityConfigFeature.selectActivityConfigState
  );
  activities = this.store.selectSignal(
    activityConfigFeature.selectFilteredActivities
  );
  chargeabilityList = this.store.selectSignal(
    activityConfigFeature.selectChargeabilityMapList
  );
  isAdd = true;
  activity!: Activity;
  activityDep = ['INT', 'PASS', 'NCHPASS'];
  activityChar = ['HCL', 'HCD', 'PASS', 'INT'];
  form = new FormGroup({
    name: new FormControl<string>('', [Validators.required]),
    chargeability: new FormControl<string>('', [Validators.required]),
    code: new FormControl<string>('', [Validators.required]),
    type: new FormControl<string>('', [Validators.required]),
    parallelActivityIds: new FormControl<string[]>({
      value: [],
      disabled: this.activityDep.includes(this.data.activity?.type),
    }),
  });

  ngOnInit(): void {
    this.subCloseDialog();
    this.initAddEdit();
  }

  private initAddEdit(): void {
    this.isAdd = !this.data.activity;
    if (!this.isAdd) {
      this.activity = this.data.activity;
      this.pathForm(this.activity);
      this.store.dispatch(
        ActivityConfigActions.set_Activity_Type({
          activityType: this.activity.type,
        })
      );
    }
  }

  private subCloseDialog(): void {
    this.action
      .pipe(
        ofType(
          ActivityConfigActions.add_Activity_Config_Success,
          ActivityConfigActions.edit_Activity_Config_Success
        )
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.dialogRef.close();
        ActivityConfigActions.set_Activity_Type({ activityType: '' });
      });
  }

  private pathForm(activity: Activity): void {
    this.form.patchValue({
      ...activity,
    });
  }

  changeActivityType(value: string): void {
    if (this.activityDep.includes(value)) {
      this.form.controls.parallelActivityIds.disable();
      this.form.controls.parallelActivityIds.patchValue([]);
    } else {
      this.form.controls.parallelActivityIds.enable();
    }
    this.store.dispatch(
      ActivityConfigActions.set_Activity_Type({ activityType: value })
    );
    if (
      this.activityChar.includes(value) &&
      this.form.controls.chargeability.value === 'NCH'
    ) {
      this.form.controls.chargeability.patchValue(null);
    }
  }

  saveActivity(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const model = {
      ...this.form.value,
    } as Activity;

    if (this.isAdd) {
      this.store.dispatch(
        ActivityConfigActions.add_Activity_Config({ activity: model })
      );
    } else {
      this.store.dispatch(
        ActivityConfigActions.edit_Activity_Config({
          activity: model,
          activityId: this.activity.activityId,
        })
      );
    }
  }
}

import { NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { importProvidersFrom } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { Routes } from '@angular/router';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { authGuard } from '../../../../libs/auth/src/lib/guards/auth.guard';
import { unauthGuard } from '../../../../libs/auth/src/lib/guards/unauth.guard';
import * as lighthouseEffects from '../../../../libs/components/src/lib/store/lighthouse.effects';
import { lighthouseFeature } from '../../../../libs/components/src/lib/store/lighthouse.feature';
import * as confirmEffects from '../../../../libs/components/src/lib/store/confirm.effects';
import * as clientLocationEffects from '../../../../libs/services/src/lib/services/client-locations/store/effects/client-locations.effects';
import { clientLocationsFeature } from '../../../../libs/services/src/lib/services/client-locations/store/features/client-locations.features';
import * as locationEffects from '../../../../libs/services/src/lib/services/maintenance/store/effects/locations.effects'
import { locationsFeature } from '../../../../libs/services/src/lib/services/maintenance/store/features';

export const DATE_FORMAT = {
  parse: {
    dateInput: 'DD-MMMM-YYYY',
  },
  display: {
    dateInput: 'DD-MMMM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'MMMM',
    monthYearA11yLabel: 'YYYY',
  },
};

export const TIME_FORMAT = {
  parse: {
    dateInput: 'DD-MMMM-YYYY, HH:mm',
  },
  display: {
    dateInput: 'DD-MMMM-YYYY, HH:mm',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'MMMM',
    monthYearA11yLabel: 'YYYY',
  },
};


export const APPROUTES: Routes = [
  {
    path: '',
    providers: [
      provideState(lighthouseFeature),
      provideEffects([lighthouseEffects, confirmEffects]),
      importProvidersFrom(MatDialogModule),
      {
        provide: DateAdapter,
        useClass: MomentDateAdapter,
        deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
      },
      {
        provide: MAT_DATE_FORMATS,
        useValue: DATE_FORMAT,
      },
      {
        provide: NGX_MAT_DATE_FORMATS,
        useValue: TIME_FORMAT,
      },
    ],
    children: [
      {
        path: 'callback',
        loadComponent: () =>
          import('./auth-callback/auth-callback.component').then((c) => c.AuthCallbackComponent),
      },
      {
        path: 'login',
        canActivate: [unauthGuard],
        loadComponent: () =>
          import('./login/login.component').then((c) => c.LoginComponent),
      },
      {
        path: 'appswitcher',
        canActivate: [authGuard],
        providers:[
          provideEffects([clientLocationEffects, locationEffects]),
          provideState(clientLocationsFeature),
          provideState(locationsFeature)
        ],
        loadComponent: () =>
          import('./app-switcher/app-switcher.component').then((c) => c.AppSwitcherComponent),
      },
      {
        path: 'usernotfound',
        loadComponent: () =>
          import('./user-not-found/user-not-found.component').then((c) => c.UserNotFoundComponent)
      },
      { path: '', redirectTo: 'appswitcher', pathMatch: 'full' },
    ]
  },

];

import {
  Component,
  DestroyRef,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { LoadingDirective } from '../../../../../../../../libs/components/src/lib/directives/loading.directive';
import { TankType } from '../../../../../../../../libs/services/src/lib/services/maintenance/interfaces/tank-type.interface';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import {
  tankTypesFeature,
  unitsFeature,
} from '../../../../../../../../libs/services/src/lib/services/maintenance/store/features';
import { Subject, takeUntil } from 'rxjs';
import { TankTypeActions } from '../../../../../../../../libs/services/src/lib/services/maintenance/store/actions/tank-type.actions';
import { SingleSelectComponent } from '../../../../../../../../libs/components/src/lib/components/single-select/single-select.component';
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'lha-tank-type-add-edit',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    NgForOf,
    AsyncPipe,
    LoadingDirective,
    NgIf,
    SingleSelectComponent,
    CdkDrag,
    CdkDragHandle,
  ],
  templateUrl: './tank-type-add-edit.component.html',
  styleUrls: ['./tank-type-add-edit.component.scss'],
})
export class TankTypeAddEditComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  dialogRef = inject(MatDialogRef<TankTypeAddEditComponent>);
  data: { tankType: TankType } = inject(MAT_DIALOG_DATA);
  store = inject(Store);
  action = inject(Actions);
  tankTypeState = this.store.selectSignal(
    tankTypesFeature.selectTankTypesState
  );
  units = this.store.selectSignal(unitsFeature.selectUnits);
  isAdd = true;
  tankType!: TankType;
  form = new FormGroup({
    name: new FormControl<string>('', [Validators.required]),
    unitName: new FormControl<string>('', [Validators.required]),
  });

  ngOnInit(): void {
    this.subCloseDialog();
    this.initAddEdit();
  }

  private initAddEdit(): void {
    this.isAdd = !this.data.tankType;
    if (!this.isAdd) {
      this.tankType = this.data.tankType;
      this.pathForm(this.tankType);
    }
  }

  private subCloseDialog(): void {
    this.action
      .pipe(
        ofType(
          TankTypeActions.add_Tank_Type_Success,
          TankTypeActions.edit_Tank_Type_Success
        )
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  private pathForm(tankType: TankType): void {
    this.form.patchValue({
      name: tankType.name,
      unitName: tankType.unitName,
    });
  }

  saveTankType(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const model = {
      ...this.form.value,
    } as TankType;

    if (this.isAdd) {
      this.store.dispatch(TankTypeActions.add_Tank_Type({ tankType: model }));
    } else {
      this.store.dispatch(
        TankTypeActions.edit_Tank_Type({
          tankType: model,
          tankId: this.tankType.tankTypeId,
        })
      );
    }
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { Vendor } from 'libs/services/src/lib/services/maintenance/interfaces/vendor.interface';
@Injectable({ providedIn: 'root' })
export class VendorService {
  private readonly http = inject(HttpClient);

  loadVendorsList() {
    return this.http.get<Vendor[]>('/api/vendor/');
  }

  loadVendorsByVoyageId(voyageId: string) {
    return this.http.get<Vendor[]>(`/api/vendor/list/${voyageId}`);
  }

  loadVendorById(vendorId: string) {
    return this.http.get<Vendor>(`/api/vendor/${vendorId}`);
  }

  loadVendorsByLocationId(locationId: string) {
    return this.http.get<Vendor[]>(`/api/vendor/bylocation/${locationId}`);
  }

  loadVendorAddressesByVoyageId(voyageId: string) {
    return this.http.get<string[]>(`/api/vendor/addresses/${voyageId}`);
  }

  createVendor(vendor: Vendor) {
    return this.http.post<Vendor>('/api/vendor/', vendor);
  }

  updateVendor(vendorId: string, vendor: Vendor) {
    return this.http.put<Vendor>(`/api/vendor/${vendorId}`, vendor);
  }

  deleteVendor(vendorId: string) {
    return this.http.delete(`/api/vendor/${vendorId}`);
  }
}

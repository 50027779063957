// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.json` with `environment.prod.ts`.
// List of file replacements can be found in `angular.json`.
import config from './assets/auth_config.json';

const { domain, clientId, authorizationParams: { audience }, apiUri, errorPath } = config as {
  domain: string;
  clientId: string;
  authorizationParams: {
  audience?: string;
  },
  apiUri: string;
  errorPath: string;
};

export const Auth0Environment = {
  production: false,
  auth: {
    domain,
    clientId,
    authorizationParams: {
      ...(audience && audience !== 'YOUR_API_IDENTIFIER' ? { audience } : null),
      redirect_uri: window.location.origin,
    },
    errorPath,
  },
  httpInterceptor: {
    allowedList: [`${apiUri}/*`],
  },
};

import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { Component, DestroyRef, OnInit, inject, signal } from '@angular/core';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
  FormGroup,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LoadingDirective } from '../../../../../../../../libs/components/src/lib/directives/loading.directive';
import { User } from '../../../../../../../../libs/auth/src/lib/interfaces/user.interface';
import { UserActions } from '../../../../../../../../libs/services/src/lib/services/maintenance/store/actions/users.actions';
import {
  locationsFeature,
  operatorsFeature,
  usersFeature,
} from '../../../../../../../../libs/services/src/lib/services/maintenance/store/features';
import { InitialCapitalizeDirective } from '../../../../../../../../libs/components/src/lib/directives/initial-capitalize.directive';
import { Constants } from '../../../../shared/application-config/email-pattern';
import { SingleSelectComponent } from '../../../../../../../../libs/components/src/lib/components/single-select/single-select.component';
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { ApplicationRole } from 'libs/auth/src/lib/interfaces/application-role.interface';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RoleData } from 'libs/auth/src/lib/interfaces/role-data.interface';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Location } from 'libs/services/src/lib/services/maintenance/interfaces/location.interface';
import { currentUserFeature } from 'libs/auth/src/lib/store/current-user/current-user.features';
import { tap } from 'rxjs';
@Component({
  selector: 'lha-user-add-edit',
  standalone: true,
  imports: [
    FormsModule,
    LoadingDirective,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatOptionModule,
    MatSelectModule,
    NgForOf,
    ReactiveFormsModule,
    AsyncPipe,
    NgIf,
    MatSlideToggleModule,
    InitialCapitalizeDirective,
    SingleSelectComponent,
    CdkDrag,
    CdkDragHandle,
  ],
  templateUrl: './user-add-edit.component.html',
  styleUrls: ['./user-add-edit.component.scss'],
})
export class UserAddEditComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  dialogRef = inject(MatDialogRef<UserAddEditComponent>);
  data: { user: User } = inject(MAT_DIALOG_DATA);
  store = inject(Store);
  action = inject(Actions);
  constants = inject(Constants);

  usersState = this.store.selectSignal(usersFeature.selectUsersState);
  operators = this.store.selectSignal(operatorsFeature.selectOperators);
  applicationRoles = this.store.selectSignal(
    usersFeature.selectApplicationRoles
  );
  currentUser = this.store.selectSignal(currentUserFeature.selectUser);
  locations = this.store.selectSignal(locationsFeature.selectLocations);
  filteredLocations = signal<Location[]>([]);
  user!: User;
  isAdd = true;
  selectedApplicationRoles: ApplicationRole[] = [];
  preselectedApplicationRoles: ApplicationRole[] = [];
  preselectedRoleValues: { [key: string]: string[] } = {};
  initalLocationId = '';

  form = new FormGroup({
    lastname: new FormControl<string>('', [Validators.required]),
    firstname: new FormControl<string>('', [Validators.required]),
    emailAddress: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(this.constants.emailPattern),
    ]),
    clientId: new FormControl<string>('', [Validators.required]),
    locationId: new FormControl<string>('', [Validators.required]),
    disabled: new FormControl<boolean>(false, [Validators.required]),
  });
  ngOnInit(): void {
    this.initalLocationId = this.form.get('locationId')?.value ?? '';
    this.disableAssetControl();
    this.subCloseDialog();
    this.initAddEdit();
  }

  getPreselectedRoles(applicationName: string): string[] | ApplicationRole[] {
    if (this.preselectedRoleValues[applicationName.toLocaleLowerCase()]) {
      return this.preselectedRoleValues[applicationName.toLocaleLowerCase()];
    }
    return this.selectedApplicationRoles;
  }

  private initAddEdit(): void {
    this.isAdd = !this.data.user;
    if (!this.isAdd) {
      this.user = this.data.user;
      this.pathForm(this.user);
    }
  }

  private subCloseDialog(): void {
    this.action
      .pipe(ofType(UserActions.add_User_Success, UserActions.edit_User_Success))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((action) => {
        this.dialogRef.close();
        const updatedUser = action.user;
        if (
          updatedUser.locationId !== this.initalLocationId &&
          updatedUser.userId === this.currentUser()?.userId
        ) {
          window.location.reload();
        }
      });
  }

  selectedRoles(event: any, application: string): void {
    const existingRoleIndex = this.selectedApplicationRoles.findIndex(
      (role) => role.application === application
    );
    const rolesDictionary = event.value.reduce(
      (acc: any, curr: any, index: any) => {
        acc[`Role ${index + 1}`] = curr;
        return acc;
      },
      {}
    );
    if (Object.keys(rolesDictionary).length === 0) {
      this.selectedApplicationRoles = this.selectedApplicationRoles.filter(
        (item) => {
          if (item.application === application) {
            return false;
          }
          return true;
        }
      );
    } else if (existingRoleIndex !== -1) {
      this.selectedApplicationRoles[existingRoleIndex].roles = rolesDictionary;
    } else {
      this.selectedApplicationRoles.push({
        application: application,
        roles: rolesDictionary,
      });
    }
  }

  getRoleValues(roles: { [key: string]: string }): string[] {
    return Object.keys(roles);
  }

  disableAssetControl() {
    this.form.get('locationId')?.disable();
    this.form.get('clientId')?.valueChanges.subscribe((clientId) => {
      if (clientId) {
        this.form.get('locationId')?.enable();
        this.filteredLocations.set(
          this.locations().filter((x) =>
            x.clientLocations.some((y) => y.clientId === clientId)
          ) ?? []
        );
      } else {
        this.form.get('locationId')?.reset();
        this.form.get('locationId')?.disable();
      }
    });
  }

  mapApplicationRoles(selectedUserRoles: RoleData[]): void {
    selectedUserRoles.forEach((selectedApplicationRole) => {
      const matchingAppRole = this.applicationRoles().find(
        (appRole) =>
          appRole.application.toLowerCase() ===
          selectedApplicationRole.application
      );
      if (matchingAppRole) {
        const mappedRoles: { [key: string]: string } = {};
        selectedApplicationRole.roles.forEach((role) => {
          if (matchingAppRole.roles[role]) {
            mappedRoles[role] = matchingAppRole.roles[role];
          }
        });

        const entries = Object.entries(mappedRoles);
        const swapped = entries.map(([key, value]) => [value, key]);
        const swappedObject = Object.fromEntries(swapped);

        this.preselectedApplicationRoles.push({
          application: selectedApplicationRole.application,
          roles: mappedRoles,
        });
        this.selectedApplicationRoles.push({
          application: selectedApplicationRole.application,
          roles: swappedObject,
        });
        this.preselectedRoleValues[selectedApplicationRole.application] =
          Object.keys(mappedRoles);
      }
    });
  }

  isApplicationRolesEmpty(): boolean {
    return this.selectedApplicationRoles.length ? false : true;
  }

  private pathForm(user: User): void {
    if (user !== null) {
      this.mapApplicationRoles(user.roles);
    }
    this.form.patchValue({
      lastname: user.lastname,
      firstname: user.firstname,
      emailAddress: user.emailAddress,
      disabled: user.disabled,
      clientId: user.clientId,
      locationId: user.locationId,
    });
  }

  saveUser(): void {
    if (this.form.invalid || !this.selectedApplicationRoles.length) {
      this.form.markAllAsTouched();
      return;
    }

    const model = {
      ...this.form.value,
      applicationRoles: this.selectedApplicationRoles,
    } as User;
    if (this.isAdd) {
      this.store.dispatch(UserActions.add_User({ user: model }));
    } else {
      this.store.dispatch(
        UserActions.edit_User({ user: model, userId: this.user.userId })
      );
    }
  }
}

import { createFeature, createReducer, createSelector } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { unitsInitialState } from '../states/units.state';
import { UnitActions } from '../actions/units.actions';

export const unitsReducer = createReducer(
  unitsInitialState,
  immerOn(UnitActions.remove_Unit, (state) => {
    state.loading.list = true;
  }),
  immerOn(UnitActions.update_Unit_Queries, (state, { query }) => {
    state.query = query;
    state.loading.list = true;
  }),

  immerOn(UnitActions.load_Units_Success, (state, { units }) => {
    state.units = units;
    state.loading.list = false;
  }),
  immerOn(
    UnitActions.load_Units_Failure,
    UnitActions.remove_Unit_Success,
    UnitActions.remove_Unit_Failure,
    (state) => {
      state.loading.list = false;
    }
  ),
  immerOn(UnitActions.add_Unit, UnitActions.edit_Unit, (state) => {
    state.loading.createEdit = true;
  }),
  immerOn(
    UnitActions.add_Unit_Success,
    UnitActions.add_Unit_Failure,
    UnitActions.edit_Unit_Success,
    UnitActions.edit_Unit_Failure,
    (state) => {
      state.loading.createEdit = false;
    }
  )
);

export const unitsFeature = createFeature({
  name: 'units',
  reducer: unitsReducer,
  extraSelectors: ({ selectLoading }) => ({
    selectListLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.list
    ),
    selectCreateEditLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.createEdit
    ),
  }),
});

import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Crane } from './interfaces/crane.interface';

@Injectable({ providedIn: 'root' })
export class CraneService {
  private readonly http = inject(HttpClient);

  loadCranes(): Observable<Crane[]> {
    return this.http.get<Crane[]>('/api/crane');
  }

  loadCranesById(id: string): Observable<Crane[]> {
    return this.http.get<Crane[]>(`/api/crane/${id}`);
  }

  loadCranesByLocationId(id: string): Observable<Crane[]> {
    return this.http.get<Crane[]>(`/api/crane/bylocationid/${id}`);
  }

  removeCrane(id: string): Observable<Crane> {
    return this.http.delete<Crane>(`/api/crane/${id}`);
  }

  addCrane(crane: Crane): Observable<Crane> {
    return this.http.post<Crane>('/api/crane', crane);
  }

  editCrane(id: string, crane: Crane): Observable<Crane> {
    return this.http.put<Crane>(`/api/crane/${id}`, crane);
  }
}

import { inject, Injectable } from '@angular/core';
import { SearchQuery } from '../../../../../components/src/lib/interfaces/search-query.interface';
import { Observable } from 'rxjs';
import { TankType } from './interfaces/tank-type.interface';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class TankTypeService {
  private readonly http = inject(HttpClient);

  loadTankTypes(query: SearchQuery): Observable<TankType[]> {
    return this.http.get<TankType[]>('/api/tanktype/');
    // TODO: It will be used after creating pagination, sorting, and search on back-end
    // return this.loadMaintenance<TankType[]>(query, 'tanktype');
  }

  removeTankType(id: string): Observable<TankType> {
    return this.http.delete<TankType>(`/api/tanktype/${id}`);
  }

  addTankType(bulkType: TankType): Observable<TankType> {
    return this.http.post<TankType>('/api/tanktype', bulkType);
  }

  editTankType(id: string, tankType: TankType): Observable<TankType> {
    return this.http.put<TankType>(`/api/tanktype/${id}`, tankType);
  }

  loadTankTypesList(): Observable<TankType[]> {
    return this.http.get<TankType[]>('/api/tanktype/');
  }
}

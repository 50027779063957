import { Actions, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { catchError, map, mergeMap, of, switchMap, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { CraneService } from '../../crane.service';
import { CraneActions } from '../actions/crane.actions';
import { Crane } from '../../interfaces/crane.interface';
import { LocationActions } from '../actions/locations.actions';
import { MatDialog } from '@angular/material/dialog';
import { DialogOptions } from '../../../config/dialog-options';
import { CranesAddEditComponent } from '../../../../../../../../apps/allocate/src/app/maintenance/shared/components/cranes-add-edit/cranes-add-edit.component';

export const loadCranesPage = createEffect(
  (actions = inject(Actions)) => {
    return actions.pipe(
      ofType(CraneActions.load_Cranes_Page),
      switchMap(() =>
        of(CraneActions.load_Cranes(), LocationActions.load_Locations())
      )
    );
  },
  {
    functional: true,
  }
);

export const AddEditRemoveCranesSuccess = createEffect(
  (actions = inject(Actions)) => {
    return actions.pipe(
      ofType(
        CraneActions.add_Crane_Success,
        CraneActions.edit_Crane_Success,
        CraneActions.remove_Crane_Success
      ),
      switchMap(() => of(CraneActions.load_Cranes()))
    );
  },
  {
    functional: true,
  }
);

export const loadCranes = createEffect(
  (actions = inject(Actions), craneService = inject(CraneService)) => {
    return actions.pipe(
      ofType(CraneActions.load_Cranes),
      switchMap(() =>
        craneService.loadCranes().pipe(
          map((res: Crane[]) =>
            CraneActions.load_Cranes_Success({
              cranes: res,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(CraneActions.load_Cranes_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const addCrane = createEffect(
  (actions = inject(Actions), craneService = inject(CraneService)) => {
    return actions.pipe(
      ofType(CraneActions.add_Crane),
      switchMap(({ crane }) =>
        craneService.addCrane(crane).pipe(
          map((crane) =>
            CraneActions.add_Crane_Success({
              crane: crane,
              successMessage: 'Crane Added Successfully',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(CraneActions.add_Crane_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const editCrane = createEffect(
  (actions = inject(Actions), craneService = inject(CraneService)) => {
    return actions.pipe(
      ofType(CraneActions.edit_Crane),
      switchMap(({ craneId, crane }) =>
        craneService.editCrane(craneId, crane).pipe(
          map((res: Crane) =>
            CraneActions.edit_Crane_Success({
              crane: res,
              successMessage: 'Crane Edited Successfully',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              CraneActions.edit_Crane_Failure({
                error,
              })
            )
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const deleteCrane = createEffect(
  (actions = inject(Actions), craneService = inject(CraneService)) => {
    return actions.pipe(
      ofType(CraneActions.remove_Crane),
      switchMap(({ craneId }) =>
        craneService.removeCrane(craneId).pipe(
          switchMap(() => [
            CraneActions.remove_Crane_Success({
              successMessage: 'Crane deleted Successfully',
            }),
          ]),
          catchError((error: HttpErrorResponse) =>
            of(
              CraneActions.remove_Crane_Failure({
                error,
              })
            )
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const openCraneDialog = createEffect(
  (
    actions = inject(Actions),
    dialog = inject(MatDialog),
    dialogOptions = inject(DialogOptions)
  ) => {
    return actions.pipe(
      ofType(CraneActions.open_Crane_Dialog),
      tap((action) => {
        dialog.open(CranesAddEditComponent, {
          ...dialogOptions,
          data: {
            crane: action.crane,
          },
        });
      })
    );
  },
  {
    functional: true,
    dispatch: false,
  }
);

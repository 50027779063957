<div
  *ngIf="assetState()"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  cdkDragBoundary=".cdk-overlay-container"
>
  <div mat-dialog-title>
    <div class="dialog_info_header">
      <h4>{{ isAdd ? 'Add ' : 'Edit ' }} Asset</h4>
      <div class="dialog_info" *ngIf="!isAdd">
        <div class="dialog_info__text">i</div>
        <div class="custom-tooltip">
          <div class="custom-tooltip__text" *ngIf="data.asset.createdByName">
            Created By: {{ data.asset.createdByName }}<br />
            Created Date: {{ data.asset.createdDate | date : 'dd-MM-yyyy' }}
          </div>
          <div class="custom-tooltip__text" *ngIf="data.asset.updatedByName">
            Last Updated By: {{ data.asset.updatedByName }}<br />
            Updated Date: {{ data.asset.updatedDate | date : 'dd-MM-yyyy' }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <mat-dialog-content *lhaLoading="assetState().loading.createEdit">
    <form [formGroup]="form" (ngSubmit)="saveAsset()">
      <div class="form__block">
        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>Asset Name</mat-label>
            <input matInput formControlName="name" />
            <mat-error *ngIf="form.controls.name.hasError('required')">
              Asset name is required.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form__box">
          <lha-single-select
            [options]="assetState().assetTypesList"
            bindLabel="description"
            formControlName="assetType"
            placeholder="Asset Type"
          />
          <div
            *ngIf="
              form.controls.assetType.invalid && form.controls.assetType.touched
            "
          >
            <mat-error *ngIf="form.controls.assetType.hasError('required')">
              Asset type is required.
            </mat-error>
          </div>
        </div>
        <div
          class="form__box form__box--date"
          *ngIf="
            locDependencies.locTypeOff.includes(
              form.controls.assetType.value ?? ''
            )
          "
        >
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>Start Date Time</mat-label>
            <input
              matInput
              [max]="timeAfter"
              [ngxMatDatetimePicker]="loadDateTime"
              (dateChange)="changeStartDate($event.value)"
              formControlName="startDateTime"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="$any(loadDateTime)"
            ></mat-datepicker-toggle>
            <mat-datepicker-toggle
              class="toggle-clear"
              matSuffix
              (click)="clearStartDate($event)"
              [disabled]="form.controls.startDateTime.disabled"
            >
              <mat-icon matDatepickerToggleIcon>clear</mat-icon>
            </mat-datepicker-toggle>
            <ngx-mat-datetime-picker
              [defaultTime]="defaultTime"
              #loadDateTime
            ></ngx-mat-datetime-picker>
            <mat-error *ngIf="form.controls.startDateTime.hasError('required')"
              >Start Date Time is required</mat-error
            >
          </mat-form-field>
        </div>
        <div
          class="form__box form__box--date"
          *ngIf="
            locDependencies.locTypeOff.includes(
              form.controls.assetType.value ?? ''
            )
          "
        >
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>End Date Time</mat-label>
            <input
              matInput
              [min]="timeBefore"
              [ngxMatDatetimePicker]="endDateTime"
              (dateChange)="changeEndDDate($event.value)"
              formControlName="endDateTime"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="$any(endDateTime)"
            ></mat-datepicker-toggle>
            <mat-datepicker-toggle
              class="toggle-clear"
              matSuffix
              (click)="clearEndDate($event)"
              [disabled]="form.controls.endDateTime.disabled"
            >
              <mat-icon matDatepickerToggleIcon>clear</mat-icon>
            </mat-datepicker-toggle>
            <ngx-mat-datetime-picker
              [defaultTime]="defaultTime"
              #endDateTime
            ></ngx-mat-datetime-picker>
            <mat-error *ngIf="form.controls.endDateTime.hasError('required')"
              >End Date Time is required</mat-error
            >
          </mat-form-field>
        </div>
        <div
          class="form__box"
          *ngIf="
            locDependencies.locTypeOff.includes(
              form.controls.assetType.value ?? ''
            )
          "
        >
          <lha-single-select
            [options]="operators()"
            formControlName="clientId"
            bindValue="clientId"
            placeholder="Operator"
            (selectChange)="changeOperator($event)"
          />
          <div
            *ngIf="
              form.controls.clientId.invalid && form.controls.clientId.touched
            "
          >
            <mat-error *ngIf="form.controls.clientId.hasError('required')">
              Operator is required.
            </mat-error>
          </div>
        </div>
        <div
          class="form__box"
          *ngIf="
            locDependencies.locTypeOff.includes(
              form.controls.assetType.value ?? ''
            )
          "
        >
          <lha-single-select
            [options]="assetsHeads()"
            [loading]="
              assetState().loading.loadHead &&
              form.controls.clusterHeadId.enabled
            "
            formControlName="clusterHeadId"
            bindValue="assetId"
            placeholder="Cluster Head (optional)"
          />
          <div
            class="disable-label disable-label--single"
            *ngIf="asset?.isClusterHead && form.controls.clusterHeadId.disabled"
          >
            Asset is a cluster head
          </div>
        </div>
        <div
          class="form__box form__box--mb20"
          *ngIf="
            !isAdd &&
            locDependencies.locTypeOff.includes(
              form.controls.assetType.value ?? ''
            )
          "
        >
          <mat-slide-toggle
            (change)="onAddNewHistoryChange($event.checked)"
            formControlName="addNewHistory"
            >Add New History</mat-slide-toggle
          >
        </div>
        <div class="form__box">
          <lha-multiselect
            formControlName="locationIds"
            bindValue="locationId"
            bindLabel="name"
            [items]="locations()"
            placeholder="Locations"
          >
          </lha-multiselect>
        </div>
      </div>
      <mat-dialog-actions align="end">
        <button mat-raised-button color="primary" type="submit">
          {{ isAdd ? 'Add ' : 'Save ' }}
        </button>
        <button
          mat-raised-button
          color="warn"
          type="button"
          mat-dialog-close=""
        >
          Cancel
        </button>
      </mat-dialog-actions>
    </form>
  </mat-dialog-content>
</div>

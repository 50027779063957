import { MaintenanceState } from '../../interfaces/maintenance-state.interface';
import { Vendor } from '../../interfaces/vendor.interface';
import { maintenanceInitialState } from './maintenance.state';

export interface VendorsState extends MaintenanceState {
  addressesList: string[];
  voyageVendorsList: Vendor[];
  vendorsList: Vendor[];
  locationVendorsList: Vendor[];
}

/** Vendors State */
export const initializeVendorsState: VendorsState = {
  addressesList: [],
  voyageVendorsList: [],
  vendorsList: [],
  locationVendorsList: [],
  ...maintenanceInitialState,
};

import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { errorProps } from '../../../../../../../components/src/lib/functions/utility.functions';
import { CargoFamily } from '../../interfaces/carrgo-family.interface';

export const CargoeFamilyActions = createActionGroup({
  source: 'CargoFamily',
  events: {
    'load_CargoFamily_List': emptyProps(),
    'load_CargoFamilies_Success': props<{ CargoFamilies: CargoFamily[] }>(),
    'load_CargoFamilies_Failure': errorProps(),

    load_Cargo_Families_Page: emptyProps(),
    load_Cargo_Families: emptyProps(),
    load_Cargo_Families_Success: props<{ cargoFamilys: CargoFamily[] }>(),
    load_Cargo_Families_Failure: errorProps(),
    remove_Cargo_Family: props<{ id: string }>(),
    remove_Cargo_Family_Success: props<{
      cargoFamily: CargoFamily;
      successMessage: string;
    }>(),
    remove_Cargo_Family_Failure: errorProps(),
    open_Cargo_Family_Dialog: props<{
      cargoFamily: CargoFamily | null;
    }>(),
    add_Cargo_Family: props<{ cargoFamily: CargoFamily }>(),
    add_Cargo_Family_Success: props<{
      cargoFamily: CargoFamily;
      successMessage: string;
    }>(),
    add_Cargo_Family_Failure: errorProps(),
    edit_Cargo_Family: props<{
      cargoFamilyId: string;
      cargoFamily: CargoFamily;
    }>(),
    edit_Cargo_Family_Success: props<{
      cargoFamily: CargoFamily;
      successMessage: string;
    }>(),
    edit_Cargo_Family_Failure: errorProps(),
  },
});

import { LocationState } from '../../interfaces/location-state.interface';
import { maintenanceInitialState } from './maintenance.state';

export const locationsInitialState: LocationState = {
  locations: [],
  siteList: [],
  site: '',
  timezones: [],
  ...maintenanceInitialState,
};

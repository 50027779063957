export interface AttributeData {
  name: string;
  value: string;
}
export interface ColumnData {
  attributeData?: AttributeData[];
  attributeHeaderData?: AttributeData[];
  sortHeader?: boolean;
}

export interface ColumnGroup {
  columnName: string;
  columnGroup: string;
  colspan: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class Column<T = Record<string, any>> {
  constructor(
    public property: keyof T,
    public title: string,
    public data: ColumnData = {} as ColumnData
  ) {}
}

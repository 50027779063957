import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { SearchQuery } from '../../../../../../../components/src/lib/interfaces/search-query.interface';
import { errorProps } from '../../../../../../../components/src/lib/functions/utility.functions';
import { LiftingPauseReason } from '../../interfaces/lifting-pause-reason.interface';

export const LiftingPauseReasonsActions = createActionGroup({
  source: 'liftingPauseReasons',
  events: {
    load_Lifting_Pause_Reasons_Page: emptyProps(),

    load_Lifting_Pause_Reasons: emptyProps(),
    load_Lifting_Pause_Reasons_Success: props<{ liftingPauseReasons: LiftingPauseReason[] }>(),
    load_Lifting_Pause_Reasons_Failure: errorProps(),

    load_Active_Lifting_Pause_Reasons: emptyProps(),
    load_Active_Lifting_Pause_Reasons_Success: props<{ liftingPauseReasons: LiftingPauseReason[] }>(),
    load_Active_Lifting_Pause_Reasons_Failure: errorProps(),

    update_Lifting_Pause_Reasons_Queries: props<{ query: SearchQuery }>(),

    remove_Lifting_Pause_Reason: props<{ id: string }>(),
    remove_Lifting_Pause_Reason_Success: props<{
      liftingPauseReason: LiftingPauseReason;
      successMessage: string;
    }>(),
    remove_Lifting_Pause_Reason_Failure: errorProps(),

    add_Lifting_Pause_Reason: props<{ liftingPauseReason: LiftingPauseReason }>(),
    add_Lifting_Pause_Reason_Success: props<{
      liftingPauseReason: LiftingPauseReason;
      successMessage: string;
    }>(),
    add_Lifting_Pause_Reason_Failure: errorProps(),

    edit_Lifting_Pause_Reason: props<{ liftingPauseReason: LiftingPauseReason }>(),
    edit_Lifting_Pause_Reason_Success: props<{
      liftingPauseReason: LiftingPauseReason
      successMessage: string;
    }>(),
    edit_Lifting_Pause_Reason_Failure: errorProps()
  }
});

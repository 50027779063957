<ng-template #header>
  <div class="page_container__header" [ngClass]="{ 'page_container__header--border': headerBorder }">
    <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
  </div>
</ng-template>
<ng-template #footer>
  <div class="page_container__footer">
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </div>
</ng-template>

<div class="page_container" [ngClass]="{
    'page_container--steel': !headerTemplate,
    'page_container--table': tableContainer,
    'page_container--footer': footerTemplate
  }">
  <ng-template *ngIf="headerTemplate; then header"></ng-template>
  <div class="page_container__content" [ngStyle]="{ position: contentRelative ? 'relative' : 'static' }">
    <ng-content></ng-content>
  </div>
  <ng-template *ngIf="footerTemplate; then footer"></ng-template>
</div>
import { Component, Input } from '@angular/core';
import { LowerCasePipe, NgStyle, UpperCasePipe } from '@angular/common';

@Component({
  selector: 'lha-custom-chip',
  standalone: true,
  imports: [NgStyle, UpperCasePipe, LowerCasePipe],
  templateUrl: './custom-chip.component.html',
  styleUrls: ['./custom-chip.component.scss'],
})
export class CustomChipComponent {
  @Input() text = '';
  @Input() cssClass = '';
  @Input() size = 100;
  @Input() toUppercase = true;
  protected readonly toString = toString;
}

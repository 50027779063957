import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { LoadingDirective } from '../../../../../../../../libs/components/src/lib/directives/loading.directive';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { ReportType } from '../../../../../../../../libs/services/src/lib/services/maintenance/interfaces/report-type.interface';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { reportTypesFeature } from '../../../../../../../../libs/services/src/lib/services/maintenance/store/features';
import { Subject, takeUntil } from 'rxjs';
import { ReportTypeActions } from '../../../../../../../../libs/services/src/lib/services/maintenance/store/actions/report-type.actions';
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';

@Component({
  selector: 'lha-report-type-add-edit',
  standalone: true,
  imports: [
    FormsModule,
    LoadingDirective,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    NgForOf,
    ReactiveFormsModule,
    AsyncPipe,
    NgIf,
    CdkDrag,
    CdkDragHandle,
  ],
  templateUrl: './report-type-add-edit.component.html',
  styleUrls: ['./report-type-add-edit.component.scss'],
})
export class ReportTypeAddEditComponent implements OnInit, OnDestroy {
  dialogRef = inject(MatDialogRef<ReportTypeAddEditComponent>);
  data: { reportType: ReportType } = inject(MAT_DIALOG_DATA);
  store = inject(Store);
  action = inject(Actions);
  loading$ = this.store.select(reportTypesFeature.selectCreateEditLoader);
  isAdd = true;
  unsubscribe: Subject<boolean> = new Subject();
  reportType!: ReportType;
  form = new FormGroup({
    name: new FormControl<string>('', [Validators.required]),
    description: new FormControl<string>('', [Validators.required]),
  });

  ngOnInit(): void {
    this.subCloseDialog();
    this.initAddEdit();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  private initAddEdit(): void {
    this.isAdd = !this.data.reportType;
    if (!this.isAdd) {
      this.reportType = this.data.reportType;
      this.pathForm(this.reportType);
    }
  }

  private subCloseDialog(): void {
    this.action
      .pipe(
        ofType(
          ReportTypeActions.add_Report_Type_Success,
          ReportTypeActions.edit_Report_Type_Success
        )
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  private pathForm(reportType: ReportType): void {
    this.form.patchValue({
      name: reportType.name,
      description: reportType.description,
    });
  }

  saveReportType(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const model = {
      ...this.form.value,
    } as ReportType;

    if (this.isAdd) {
      this.store.dispatch(
        ReportTypeActions.add_Report_Type({ reportType: model })
      );
    } else {
      this.store.dispatch(
        ReportTypeActions.edit_Report_Type({
          reportType: model,
          reportTypeId: this.reportType.reportTypeId,
        })
      );
    }
  }
}

import {Actions, concatLatestFrom, createEffect, ofType} from "@ngrx/effects";
import {inject} from "@angular/core";
import {Store} from "@ngrx/store";
import {AreaActions} from "../actions/area.actions";
import {catchError, map, mergeMap, of} from "rxjs";
import {filter, tap} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";
import {MatDialog} from "@angular/material/dialog";
import {SiteService} from "../../site.service";
import {SiteActions} from "../actions/site.actions";
import {Site} from "../../interfaces/site.interface";
import {
  SiteAddEditComponent
} from "../../../../../../../../apps/allocate/src/app/maintenance/shared/components/site-add-edit/site-add-edit.component";
import {siteFeature} from "../features/site.features";

export const loadSites = createEffect(
  (
    actions = inject(Actions),
    store = inject(Store),
    siteService = inject(SiteService)
  ) => {
    return actions.pipe(
      ofType(
        SiteActions.set_Site_Id,
        SiteActions.load_Sites,
        SiteActions.remove_Site_Success,
        SiteActions.add_Site_Success,
        SiteActions.edit_Site_Success
      ),
      concatLatestFrom(() =>
        store.select(siteFeature.selectSiteId)
      ),
      map(([, res]) => res),
      filter((res) => !!res),
      mergeMap((action) =>
        siteService.loadSitesByLocationId(action).pipe(
          map((res: Site[]) =>
            SiteActions.load_Sites_Success({
              sites: res,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              AreaActions.load_Areas_Failure({
                error,
              })
            )
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const loadSiteByLocationId = createEffect(
  (
    actions$ = inject(Actions),
    areaService = inject(SiteService)
  ) => {
    return actions$.pipe(
      ofType(
        SiteActions.load_Sites_By_Location_Id,),
      mergeMap((action) =>
        areaService.loadSitesByLocationId(action.id).pipe(
          map((sites: Site[]) =>
            SiteActions.load_Sites_By_Location_Id_Success({
              sites,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(SiteActions.load_Sites_By_Location_Id_Failure({ error }))
          )
        )
      )
    );
  },
  { functional: true }
);

export const removeSite = createEffect(
  (
    actions = inject(Actions),
    areaService = inject(SiteService)
  ) => {
    return actions.pipe(
      ofType(SiteActions.remove_Site),
      mergeMap((action) =>
        areaService.removeSite(action.id).pipe(
          map((res: Site) =>
            SiteActions.remove_Site_Success({
              site: res,
              successMessage: 'Site removed successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              SiteActions.remove_Site_Failure({
                error: error,
              })
            )
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const addSite = createEffect(
  (
    actions = inject(Actions),
    areaService = inject(SiteService)
  ) => {
    return actions.pipe(
      ofType(SiteActions.add_Site),
      mergeMap((action) =>
        areaService
          .addSite(action.site)
          .pipe(
            map((res: Site) =>
              SiteActions.add_Site_Success({
                site: res,
                successMessage: 'Site added successfully!',
              })
            ),
            catchError((error: HttpErrorResponse) =>
              of(
                SiteActions.add_Site_Failure({
                  error: error,
                })
              )
            )
          )
      )
    );
  },
  {
    functional: true,
  }
);

export const editSite = createEffect(
  (
    actions = inject(Actions),
    areaService = inject(SiteService)
  ) => {
    return actions.pipe(
      ofType(SiteActions.edit_Site),
      mergeMap((action) =>
        areaService
          .editSite(action.id, action.site)
          .pipe(
            map((res: Site) =>
              SiteActions.edit_Site_Success({
                site: res,
                successMessage: 'Site edited successfully!',
              })
            ),
            catchError((error: HttpErrorResponse) =>
              of(
                SiteActions.edit_Site_Failure({
                  error: error,
                })
              )
            )
          )
      )
    );
  },
  {
    functional: true,
  }
);

export const openSiteDialog = createEffect(
  (actions = inject(Actions), dialog = inject(MatDialog)) => {
    return actions.pipe(
      ofType(SiteActions.open_Site_Dialog),
      tap((action) => {
        dialog.open(SiteAddEditComponent, {
          width: '80vw',
          panelClass: 'asset_dialog',
          autoFocus: false,
          disableClose: true,
          height: '80vh',
          data: {
            id: action.id,
            location: action.location,
          },
        });
      })
    );
  },
  {
    functional: true,
    dispatch: false,
  }
);

<ng-select
  [items]="options"
  [multiple]="false"
  [hideSelected]="false"
  appearance="outline"
  appendTo="body"
  [bindLabel]="bindLabel"
  [bindValue]="bindValue"
  [placeholder]="hideLabelAfterSelect && selected ? '' : placeholder"
  (change)="changeValue($event)"
  (blur)="onTouched()"
  [(ngModel)]="selected"
  [disabled]="disabled"
  [loading]="loading"
/>

import { Component, inject, OnInit, OnDestroy } from '@angular/core';
import { AsyncPipe, DOCUMENT, NgIf } from '@angular/common';
import { Router, RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { authFeature } from '../../../../libs/auth/src/lib/store/auth.feature';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { AppSwitcherComponent } from './app-switcher/app-switcher.component';
import { HeaderComponent } from '../../../../libs/components/src/lib/components/header/header.component';
import { currentUserFeature } from '../../../../libs/auth/src/lib/store/current-user/current-user.features';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'lha-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatSidenavModule,
    MatGridListModule,
    MatCardModule,
    RouterOutlet,
    NgIf,
    AsyncPipe,
    AppSwitcherComponent,
    HeaderComponent,
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'lighthouse';
  store = inject(Store);
  router = inject(Router);
  isLoggedIn$ = this.store.select(authFeature.selectIsLoggedIn);
  currentUser$ = this.store.select(currentUserFeature.selectUser);
  unsubscribe: Subject<boolean> = new Subject();
  doc = inject(DOCUMENT);
  snackBar = inject(MatSnackBar);
  authService = inject(AuthService);
  message: string = 'You do not have access to this organisation!';

  handleUserAttemptedLogin(loggedInuser: boolean): void {
    if (loggedInuser) {
      localStorage.removeItem('UserAttemptedLogin');
    } else if (localStorage.getItem('UserAttemptedLogin')) {
      localStorage.removeItem('UserAttemptedLogin');
      this.router.navigate(['/login']);
      this.snackBar.open(this.message, 'Close', {
        duration: 10000,
        panelClass: 'snackbar--error',
      });
    }
  }

  ngOnInit() {
    this.authService.isLoading$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((isLoading) => {
        if (!isLoading) {
          this.authService.user$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((user) => {
              const loggedInUser = user ? true : false;
              this.handleUserAttemptedLogin(loggedInUser);
            });
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }
}

import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from './app-settings.interface';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private readonly http = inject(HttpClient);

  getSettings(): Observable<AppSettings> {
    return this.http.get<AppSettings>('/api/setting');
  }

  addSettings(currency: string): Observable<AppSettings> {
    return this.http.post<AppSettings>('/api/setting', { currency });
  }

  editSettings(currency: string, id: string): Observable<AppSettings> {
    return this.http.put<AppSettings>(`/api/setting/${id}`, { currency });
  }
}

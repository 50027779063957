import { createActionGroup, emptyProps, props, ActionCreatorProps } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

export const errorProps = (): ActionCreatorProps<{
  error: HttpErrorResponse;
  customMessage?: string;
}> => {
  return props<{ error: HttpErrorResponse; customMessage?: string }>();
};

export const AuthActions = createActionGroup({
  source: 'Auth',
  events: {
    Login: props<{ orgId: string | undefined }>(),
    'login_Success': emptyProps(),
    'login_Failure': errorProps(),
    Logout: emptyProps(),
    'hydrate_State': emptyProps(),
    'set_State': props<{ loggedIn: boolean, tokenExpired: boolean }>(),
    'refresh_Token': emptyProps(),
    'refresh_Token_Success': emptyProps(),
    'refresh_Token_Failure': emptyProps(),
  },
});

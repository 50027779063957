import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SearchQuery } from '../../../../../../../components/src/lib/interfaces/search-query.interface';
import {
  Distance,
  DistancePageTableData,
} from '../../interfaces/distance.interface';
import { errorProps } from '../../../../../../../components/src/lib/functions/utility.functions';

export const DistanceActions = createActionGroup({
  source: 'Distances',
  events: {
    update_Distance_Queries: props<{ query: SearchQuery }>(),
    load_Distance_Page: emptyProps(),
    load_Distances: emptyProps(),
    load_Distances_Success: props<{ distances: DistancePageTableData }>(),
    load_Distances_Failure: errorProps(),
    remove_Distance: props<{ id: string }>(),
    remove_Distance_Success: props<{
      distance: Distance;
      successMessage: string;
    }>(),
    remove_Distance_Failure: errorProps(),
    open_Distance_Dialog: props<{ distance: Distance | null }>(),
    add_Distance: props<{ distance: Distance }>(),
    add_Distance_Success: props<{
      distance: Distance;
      successMessage: string;
    }>(),
    add_Distance_Failure: errorProps(),
    edit_Distance: props<{ distanceId: string; distance: Distance }>(),
    edit_Distance_Success: props<{
      distance: Distance;
      successMessage: string;
    }>(),
    edit_Distance_Failure: errorProps(),
  },
});

import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { catchError, map, mergeMap, of, switchMap, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { CargoSizeService } from '../../cargo-size.service';
import { CargoeSizeActions } from '../actions/cargo-size.actions';
import { CargoSize } from '../../interfaces/carrgo-size.interface';
import { Store } from '@ngrx/store';
import { cargoSizeFeature } from '../features/cargo-size.feature';
import { CargoeFamilyActions } from '../actions/cargo-family.actions';
import { cargotypeFeature } from '../features';
import { DialogOptions } from '../../../config/dialog-options';
import { MatDialog } from '@angular/material/dialog';
import { CargoSizeAddEditComponent } from 'apps/allocate/src/app/maintenance/shared/components/cargo-size-add-edit/cargo-size-add-edit.component';

export const loadCargoSizes = createEffect(
  (
    actions = inject(Actions),
    store = inject(Store),
    cargoSizeService = inject(CargoSizeService)
  ) => {
    return actions.pipe(
      ofType(CargoeSizeActions.load_CargoSize_List),
      concatLatestFrom(() => store.select(cargoSizeFeature.selectQuery)),
      map(([, res]) => res),
      mergeMap(() =>
        cargoSizeService.loadCargoSizes().pipe(
          map((res: CargoSize[]) =>
            CargoeSizeActions.load_CargoSizes_Success({ CargoSizes: res })
          ),
          catchError((error: HttpErrorResponse) =>
            of(CargoeSizeActions.load_CargoSizes_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const loadCargoSizesByFamilyId = createEffect(
  (
    actions = inject(Actions),
    store = inject(Store),
    cargoSizeService = inject(CargoSizeService)
  ) => {
    return actions.pipe(
      ofType(CargoeSizeActions['load_CargoSize_List_By-Family_Id']),
      mergeMap((action) =>
        cargoSizeService.loadCargoSizesByFamilyId(action.familyId).pipe(
          map((res: CargoSize[]) =>
            CargoeSizeActions['load_CargoSizes_By-Family_Id_Success']({
              CargoSizes: res,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              CargoeSizeActions['load_CargoSizes_By-Family_Id_Failure']({
                error,
              })
            )
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const loadCargoSizesPage = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(CargoeSizeActions.load_Cargo_Sizes_Page),
      switchMap(() =>
        of(
          CargoeSizeActions.load_Cargo_Sizes(),
          CargoeFamilyActions.load_Cargo_Families()
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const loadCargoSizesList = createEffect(
  (
    actions = inject(Actions),
    store = inject(Store),
    cargoSizeService = inject(CargoSizeService)
  ) => {
    return actions.pipe(
      ofType(
        CargoeSizeActions.load_Cargo_Sizes,
        CargoeSizeActions.remove_Cargo_Size_Success,
        CargoeSizeActions.add_Cargo_Size_Success,
        CargoeSizeActions.edit_Cargo_Size_Success
      ),
      concatLatestFrom(() => store.select(cargotypeFeature.selectQuery)),
      map(([, res]) => res),
      mergeMap((action) =>
        cargoSizeService.loadCargoSizes().pipe(
          map((res: CargoSize[]) =>
            CargoeSizeActions.load_Cargo_Sizes_Success({
              cargoSizes: res,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(CargoeSizeActions.load_Cargo_Sizes_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const removeCargoSize = createEffect(
  (actions = inject(Actions), cargoSizeService = inject(CargoSizeService)) => {
    return actions.pipe(
      ofType(CargoeSizeActions.remove_Cargo_Size),
      mergeMap((action) =>
        cargoSizeService.deleteCargoSize(action.id).pipe(
          map(() =>
            CargoeSizeActions.remove_Cargo_Size_Success({
              cargoSize: {} as CargoSize,
              successMessage: 'Cargo Size successfully removed!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              CargoeSizeActions.remove_Cargo_Size_Failure({
                error: error,
              })
            )
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const addCargoSize = createEffect(
  (actions = inject(Actions), cargoSizeService = inject(CargoSizeService)) => {
    return actions.pipe(
      ofType(CargoeSizeActions.add_Cargo_Size),
      mergeMap((action) =>
        cargoSizeService.createCargoSize(action.cargoSize).pipe(
          map((res: CargoSize) =>
            CargoeSizeActions.add_Cargo_Size_Success({
              cargoSize: res,
              successMessage: 'Cargo Size added successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(CargoeSizeActions.add_Cargo_Size_Failure({ error: error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const editCargoSize = createEffect(
  (actions = inject(Actions), cargoSizeService = inject(CargoSizeService)) => {
    return actions.pipe(
      ofType(CargoeSizeActions.edit_Cargo_Size),
      mergeMap((action) =>
        cargoSizeService
          .updateCargoSize(action.cargoSizeId, action.cargoSize)
          .pipe(
            map((res: CargoSize) =>
              CargoeSizeActions.edit_Cargo_Size_Success({
                cargoSize: res,
                successMessage: 'Cargo Size edited successfully!',
              })
            ),
            catchError((error: HttpErrorResponse) =>
              of(
                CargoeSizeActions.edit_Cargo_Size_Failure({
                  error: error,
                })
              )
            )
          )
      )
    );
  },
  {
    functional: true,
  }
);

export const openCargoSizeDialog = createEffect(
  (
    actions = inject(Actions),
    dialog = inject(MatDialog),
    dialogOptions = inject(DialogOptions)
  ) => {
    return actions.pipe(
      ofType(CargoeSizeActions.open_Cargo_Size_Dialog),
      tap((action) => {
        dialog.open(CargoSizeAddEditComponent, {
          ...dialogOptions,
          data: {
            CargoSize: action.cargoSize,
          },
        });
      })
    );
  },
  {
    functional: true,
    dispatch: false,
  }
);

import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { LoadCell } from './interfaces/load-cell.interface';

@Injectable({ providedIn: 'root' })
export class LoadCellService {
  private readonly http = inject(HttpClient);

  loadCellLists() {
    return this.http.get<LoadCell[]>('/api/loadCell');
  }

  loadCellListsByLocationId(locationId: string) {
    return this.http.get<LoadCell[]>(`/api/loadCell/list/${locationId}`);
  }

  loadById(loadCellId: string) {
    return this.http.get<LoadCell>(`/api/loadCell/${loadCellId}`);
  }

  addLoadCell(loadCell: LoadCell) {
    return this.http.post<LoadCell>('/api/loadCell', loadCell);
  }

  updateLoadCell(loadCellId: string, loadCell: LoadCell) {
    return this.http.put<LoadCell>(`/api/loadCell/${loadCellId}`, loadCell);
  }

  deleteLoadCell(loadCellId: string) {
    return this.http.delete(`/api/loadCell/${loadCellId}`);
  }
}

import { Component, inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { MatButtonModule } from '@angular/material/button';
import { Confirm } from '../../interfaces/confirm.interface';
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { NgForOf, NgIf } from '@angular/common';

@Component({
  selector: 'lha-confirm',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    CdkDrag,
    CdkDragHandle,
    NgIf,
    NgForOf,
  ],
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent {
  store = inject(Store);
  dialogRef = inject(MatDialogRef<ConfirmComponent>);
  data: Confirm = inject(MAT_DIALOG_DATA);

  accept(): void {
    this.store.dispatch(this.data.confirm);
    this.dialogRef.close('confirmed');
  }

  reject(): void {
    if (this.data?.reject) {
      this.store.dispatch(this.data.reject);
    }
    this.dialogRef.close('rejected');
  }

  cancel() {
    if (this.data?.cancel) {
      this.store.dispatch(this.data.cancel);
    }
    this.dialogRef.close('cancelled');
  }
}
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SearchQuery } from '../../../../../../../components/src/lib/interfaces/search-query.interface';
import { TankType } from '../../interfaces/tank-type.interface';
import { errorProps } from '../../../../../../../components/src/lib/functions/utility.functions';

export const TankTypeActions = createActionGroup({
  source: 'Tank Type',
  events: {
    update_Tank_Type_Queries: props<{ query: SearchQuery }>(),
    load_Tank_Type_Page: emptyProps(),
    load_Tank_Types: emptyProps(),
    load_Tank_Types_Success: props<{ tankTypes: TankType[] }>(),
    load_Tank_Types_Failure: errorProps(),
    remove_Tank_Type: props<{ id: string }>(),
    remove_Tank_Type_Success: props<{
      tankType: TankType;
      successMessage: string;
    }>(),
    remove_Tank_Type_Failure: errorProps(),
    open_Tank_Type_Dialog: props<{ tankType: TankType | null }>(),
    add_Tank_Type: props<{ tankType: TankType }>(),
    add_Tank_Type_Success: props<{
      tankType: TankType;
      successMessage: string;
    }>(),
    add_Tank_Type_Failure: errorProps(),
    edit_Tank_Type: props<{ tankId: string; tankType: TankType }>(),
    edit_Tank_Type_Success: props<{
      tankType: TankType;
      successMessage: string;
    }>(),
    edit_Tank_Type_Failure: errorProps(),
  },
});

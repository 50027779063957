import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { ReportTableService } from '../../report-table.service';
import { catchError, map, mergeMap, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ReportTypeActions } from '../actions/report-type.actions';
import { ReportType } from '../../interfaces/report-type.interface';
import { Store } from '@ngrx/store';
import { reportTypesFeature } from '../features';
import { MatDialog } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import { ReportTypeAddEditComponent } from '../../../../../../../../apps/allocate/src/app/maintenance/shared/components/report-type-add-edit/report-type-add-edit.component';
import { DialogOptions } from '../../../config/dialog-options';

export const loadReportTypes = createEffect(
  (
    actions = inject(Actions),
    store = inject(Store),
    reportTableService = inject(ReportTableService)
  ) => {
    return actions.pipe(
      ofType(
        ReportTypeActions.load_Report_Types,
        ReportTypeActions.update_Report_Type_Queries,
        ReportTypeActions.remove_Report_Type_Success,
        ReportTypeActions.add_Report_Type_Success,
        ReportTypeActions.edit_Report_Type_Success
      ),
      concatLatestFrom(() => store.select(reportTypesFeature.selectQuery)),
      map(([, res]) => res),
      mergeMap((action) =>
        reportTableService.loadReportTypes(action).pipe(
          map((res: ReportType[]) =>
            ReportTypeActions.load_Report_Types_Success({
              reportTypes: res.filter((item) => !item.deleted),
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(ReportTypeActions.load_Report_Types_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const removeReportType = createEffect(
  (
    actions = inject(Actions),
    reportTableService = inject(ReportTableService)
  ) => {
    return actions.pipe(
      ofType(ReportTypeActions.remove_Report_Type),
      mergeMap((action) =>
        reportTableService.removeReportType(action.id).pipe(
          map((res: ReportType) =>
            ReportTypeActions.remove_Report_Type_Success({
              reportType: res,
              successMessage: 'Report Type removed successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(ReportTypeActions.remove_Report_Type_Failure({ error: error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const addReportType = createEffect(
  (
    actions = inject(Actions),
    reportTableService = inject(ReportTableService)
  ) => {
    return actions.pipe(
      ofType(ReportTypeActions.add_Report_Type),
      mergeMap((action) =>
        reportTableService.addReportType(action.reportType).pipe(
          map((res: ReportType) =>
            ReportTypeActions.add_Report_Type_Success({
              reportType: res,
              successMessage: 'Report Type added successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(ReportTypeActions.add_Report_Type_Failure({ error: error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const editReportType = createEffect(
  (
    actions = inject(Actions),
    reportTableService = inject(ReportTableService)
  ) => {
    return actions.pipe(
      ofType(ReportTypeActions.edit_Report_Type),
      mergeMap((action) =>
        reportTableService
          .editReportType(action.reportTypeId, action.reportType)
          .pipe(
            map((res: ReportType) =>
              ReportTypeActions.edit_Report_Type_Success({
                reportType: res,
                successMessage: 'Report Type edited successfully!',
              })
            ),
            catchError((error: HttpErrorResponse) =>
              of(ReportTypeActions.edit_Report_Type_Failure({ error: error }))
            )
          )
      )
    );
  },
  {
    functional: true,
  }
);

export const openReportTypeDialog = createEffect(
  (
    actions = inject(Actions),
    dialog = inject(MatDialog),
    dialogOptions = inject(DialogOptions)
  ) => {
    return actions.pipe(
      ofType(ReportTypeActions.open_Report_Type_Dialog),
      tap((action) => {
        dialog.open(ReportTypeAddEditComponent, {
          ...dialogOptions,
          data: {
            reportType: action.reportType,
          },
        });
      })
    );
  },
  {
    functional: true,
    dispatch: false,
  }
);

import { Crane } from '../../interfaces/crane.interface';
import { MaintenanceState } from '../../interfaces/maintenance-state.interface';
import { maintenanceInitialState } from './maintenance.state';

export const craneInitialState: CraneState = {
  cranes: [],
  ...maintenanceInitialState,
};

export interface CraneState extends MaintenanceState {
  cranes: Crane[];
}

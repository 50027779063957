<div
  *ngIf="cranesState()"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  cdkDragBoundary=".cdk-overlay-container"
>
  <div mat-dialog-title>
    <h4>{{ isAdd ? 'Add ' : 'Edit ' }} Crane</h4>
  </div>
  <mat-dialog-content *lhaLoading="cranesState().loading.createEdit">
    <form [formGroup]="form" (ngSubmit)="saveCrane()">
      <div class="form__block">
        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>Name</mat-label>
            <input matInput formControlName="name" />
            <mat-error *ngIf="form.controls.name.hasError('required')">
              Crane name is required.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form__box">
          <lha-single-select
            [options]="locations()"
            formControlName="locationId"
            bindValue="locationId"
            bindLabel="name"
            placeholder="Location"
          />
          <div
            *ngIf="
              form.controls.locationId.invalid &&
              form.controls.locationId.touched
            "
          >
            <mat-error *ngIf="form.controls.locationId.hasError('required')">
              Location is required.
            </mat-error>
          </div>
        </div>
        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>Type</mat-label>
            <input matInput formControlName="type" />
          </mat-form-field>
        </div>
        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>Maximum Weight</mat-label>
            <input
              matInput
              formControlName="maximumWeight"
              type="number"
              placeholder="Maximum Weight"
            />
            <mat-error
              *ngIf="form.controls.maximumWeight.hasError('greaterThan')"
            >
              Maximum Weight must be a number greater than 0.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>Maximum Radius</mat-label>
            <input
              matInput
              formControlName="maximumRadius"
              type="number"
              placeholder="Maximum Radius"
            />
            <mat-error
              *ngIf="form.controls.maximumRadius.hasError('greaterThan')"
            >
              Maximum Radius must be a number greater than 0.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>Radius For Maximum Weight</mat-label>
            <input
              matInput
              formControlName="radiusForMaximumWeight"
              type="number"
              placeholder="Radius For Maximum Weight"
            />
            <mat-error
              *ngIf="
                form.controls.radiusForMaximumWeight.hasError('greaterThan')
              "
            >
              Radius for maximum weight must be a number greater than 0.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>Weight For Maximum Radius</mat-label>
            <input
              matInput
              formControlName="weightForMaximumRadius"
              type="number"
              placeholder="Weight For Maximum Radius"
            />
            <mat-error
              *ngIf="
                form.controls.weightForMaximumRadius.hasError('greaterThan')
              "
            >
              Weight for maximum radius must be a number greater than 0.
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <mat-dialog-actions align="end">
        <button mat-raised-button color="primary" type="submit">
          {{ isAdd ? 'Add ' : 'Save ' }}
        </button>
        <button
          mat-raised-button
          color="warn"
          type="button"
          mat-dialog-close=""
        >
          Cancel
        </button>
      </mat-dialog-actions>
    </form>
  </mat-dialog-content>
</div>

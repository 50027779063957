import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Squad } from './interfaces/squad.interface';

@Injectable({ providedIn: 'root' })
export class SquadsService {
  private readonly http = inject(HttpClient);

  loadSquads(): Observable<Squad[]> {
    return this.http.get<Squad[]>('/api/squad');
  }

  loadSquadsByLocation(locationId: string): Observable<Squad[]> {
    return this.http.get<Squad[]>(`/api/squad/bylocationid/${locationId}`);
  }

  loadSquad(id: string): Observable<Squad> {
    return this.http.get<Squad>(`/api/squad/${id}`);
  }

  removeSquad(id: string): Observable<Squad> {
    return this.http.delete<Squad>(`/api/squad/${id}`);
  }

  addSquad(squad: Squad): Observable<Squad> {
    return this.http.post<Squad>('/api/squad', squad);
  }

  editSquad(id: string, squad: Squad): Observable<Squad> {
    return this.http.put<Squad>(`/api/squad/${id}`, squad);
  }
}

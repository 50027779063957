import { createFeature, createReducer, createSelector } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { usersInitialState } from './current-user.state';
import { CurrentUserActions } from './current-user.actions';

export const currentUserReducer = createReducer(
  usersInitialState,
  immerOn(CurrentUserActions.load_Current_User, (state) => {
    state.loading = true;
  }),

  immerOn(CurrentUserActions.load_Current_User_Success, (state, { user }) => {
    state.user = user;
    state.loading = false;
  }),
  immerOn(CurrentUserActions.load_Current_User_Failure, (state) => {
    state.loading = false;
  }),
);

export const currentUserFeature = createFeature({
  name: 'currentUser',
  reducer: currentUserReducer,
  extraSelectors: ({ selectUser }) => ({
    selectCurrentUserExists: createSelector(
      selectUser,
      (selectUser) => !!selectUser.userId
    ),
  }),
});

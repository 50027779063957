import {inject, Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {BlockingActivity} from "./interfaces/blocking-activity.interface";
import {SearchQuery} from "../../../../../components/src/lib/interfaces/search-query.interface";

@Injectable({providedIn: 'root'})
export class BlockingActivityService {
  private readonly http = inject(HttpClient)

  loadBlockingActivities(query?: SearchQuery): Observable<BlockingActivity[]> {
    return this.http.get<BlockingActivity[]>('/api/blockingactivity');
  }

  loadBlockingActivitiesById(id: string): Observable<BlockingActivity[]> {
    return this.http.get<BlockingActivity[]>(`/api/blockingactivity/${id}`);
  }

  removeBlockingActivity(id: string): Observable<BlockingActivity> {
    return this.http.delete<BlockingActivity>(`/api/blockingactivity/${id}`);
  }

  addBlockingActivity(blockingActivity: BlockingActivity): Observable<BlockingActivity> {
    return this.http.post<BlockingActivity>('/api/blockingactivity', blockingActivity);
  }

  editBlockingActivity(id: string, blockingActivity: BlockingActivity): Observable<BlockingActivity> {
    return this.http.put<BlockingActivity>(`/api/blockingactivity/${id}`, blockingActivity);
  }
}

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { catchError, map, mergeMap, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { SettingsService } from './settings.service';
import { SettingActions } from './setting.actions';
import { AppSettings } from './app-settings.interface';

export const loadSettings = createEffect(
  (
    actions = inject(Actions),
    store = inject(Store),
    settingsService = inject(SettingsService)
  ) => {
    return actions.pipe(
      ofType(SettingActions.load_Settings),
      mergeMap(() =>
        settingsService.getSettings().pipe(
          map((res: AppSettings) =>
            SettingActions.load_Settings_Success({ settings: res })
          ),
          catchError((error: HttpErrorResponse) =>
            of(SettingActions.load_Settings_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const addSetting = createEffect(
  (actions = inject(Actions), settingsService = inject(SettingsService)) => {
    return actions.pipe(
      ofType(SettingActions.add_Settings),
      mergeMap((action) =>
        settingsService.addSettings(action.currency).pipe(
          map((res: AppSettings) =>
            SettingActions.add_Settings_Success({
              settings: res,
              successMessage: 'Setting added successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(SettingActions.add_Settings_Failure({ error: error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const editSetting = createEffect(
  (actions = inject(Actions), settingsService = inject(SettingsService)) => {
    return actions.pipe(
      ofType(SettingActions.edit_Settings),
      mergeMap((action) =>
        settingsService.editSettings(action.currency, action.id).pipe(
          map((res: AppSettings) =>
            SettingActions.edit_Settings_Success({
              settings: res,
              successMessage: 'Setting edited successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(SettingActions.edit_Settings_Failure({ error: error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

import { AfterViewInit, Directive, inject } from '@angular/core';
import { MatOption } from '@angular/material/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'mat-option',
  standalone: true,
})
export class InitialCapitalizeDirective implements AfterViewInit {
  private readonly matOption = inject(MatOption);

  ngAfterViewInit(): void {
    const text = this.matOption._getHostElement().innerText;
    const result = capitalizeFirstLetter(text.toLowerCase().trim());
    this.matOption._getHostElement().innerText = result;
  }
}

export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

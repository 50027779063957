import { Component, inject } from '@angular/core';
import { CellTemplateDirective } from '../../../../../../../../libs/components/src/lib/components/table/cell-template.directive';
import { CustomChipComponent } from '../../../../../../../../libs/components/src/lib/components/custom-chip/custom-chip.component';
import { TableComponent } from '../../../../../../../../libs/components/src/lib/components/table/table.component';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { AssetHistory } from '../../../../../../../../libs/services/src/lib/services/maintenance/interfaces/asset-history.interface';
import { Column } from '../../../../../../../../libs/components/src/lib/components/table/column.model';
import { DatePipe, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';

@Component({
  selector: 'lha-asset-history',
  standalone: true,
  imports: [
    CellTemplateDirective,
    CustomChipComponent,
    TableComponent,
    DatePipe,
    NgIf,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    CdkDrag,
    CdkDragHandle,
  ],
  templateUrl: './asset-history.component.html',
  styleUrls: ['./asset-history.component.scss'],
})
export class AssetHistoryComponent {
  dialogRef = inject(MatDialogRef<AssetHistoryComponent>);
  data: { assetHistory: AssetHistory[] } = inject(MAT_DIALOG_DATA);

  columns: Column<AssetHistory>[] = [
    new Column('clientName', 'Operator', { sortHeader: true }),
    new Column('startDateTime', 'Start Date', { sortHeader: true }),
    new Column('endDateTime', 'End Date', { sortHeader: true }),
  ];
}

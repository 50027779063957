import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SearchQuery } from '../../../../../../../components/src/lib/interfaces/search-query.interface';
import { Activity } from '../../interfaces/activity.interface';
import { errorProps } from '../../../../../../../components/src/lib/functions/utility.functions';
import { Constant } from 'libs/services/src/lib/services/shared/constant.interface';

export const ActivityConfigActions = createActionGroup({
  source: 'Activity Config',
  events: {
    update_Activity_Config_Queries: props<{ query: SearchQuery }>(),
    load_Activity_Config_Page: emptyProps(),
    load_Chargeability_List: emptyProps(),
    load_Chargeability_List_Success: props<{
      chargeabilityList: Constant[];
    }>(),
    load_Chargeability_List_Failure: errorProps(),
    load_Activity_Type_List: emptyProps(),
    load_Activity_Type_List_Success: props<{
      activityTypeList: Constant[];
    }>(),
    load_Activity_Type_List_Failure: errorProps(),
    load_Activity_Configs: emptyProps(),
    load_Activity_Configs_Success: props<{ activities: Activity[] }>(),
    load_Activity_Configs_Failure: errorProps(),
    remove_Activity_Config: props<{ id: string }>(),
    remove_Activity_Config_Success: props<{
      activity: Activity;
      successMessage: string;
    }>(),
    remove_Activity_Config_Failure: errorProps(),
    open_Activity_Config_Dialog: props<{ activity: Activity | null }>(),
    add_Activity_Config: props<{ activity: Activity }>(),
    add_Activity_Config_Success: props<{
      activity: Activity;
      successMessage: string;
    }>(),
    add_Activity_Config_Failure: errorProps(),
    edit_Activity_Config: props<{ activityId: string; activity: Activity }>(),
    edit_Activity_Config_Success: props<{
      activity: Activity;
      successMessage: string;
    }>(),
    edit_Activity_Config_Failure: errorProps(),
    set_Activity_Type: props<{ activityType: string }>(),
    export_Activities: emptyProps(),
    export_Activities_Success: emptyProps(),
    export_Activities_Failure: errorProps(),
  },
});

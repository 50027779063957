import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ClientLocation } from './interfaces/client-locations.interface';

@Injectable({
  providedIn: 'root',
})
export class ClientLocationsService {
  private readonly http = inject(HttpClient);

  loadClientLocations(): Observable<ClientLocation[]> {
    return this.http.get<ClientLocation[]>('/api/clientlocation');
  }

  loadUserClientLocations(): Observable<ClientLocation[]> {
    return this.http.get<ClientLocation[]>('/api/clientlocation/userlocations');
  }

  loadClientLocationsByLocationId(locationId: string): Observable<ClientLocation[]> {
    return this.http.get<ClientLocation[]>(`/api/clientlocation/getByLocationId/${locationId}`);
  }
}

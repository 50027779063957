import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { EmployeeService } from '../../employee.service';
import { catchError, map, mergeMap, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { EmployeeActions } from '../actions/employee.actions';
import { Employee } from '../../interfaces/employee.interface';
import { Store } from '@ngrx/store';
import { employeesFeature } from '../features';
import { MatDialog } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import { EmployeeAddEditComponent } from '../../../../../../../../apps/allocate/src/app/maintenance/shared/components/employee-add-edit/employee-add-edit.component';
import { DialogOptions } from '../../../config/dialog-options';

export const loadEmployees = createEffect(
  (
    actions = inject(Actions),
    store = inject(Store),
    employeeService = inject(EmployeeService)
  ) => {
    return actions.pipe(
      ofType(
        EmployeeActions.load_Employees,
        EmployeeActions.update_Employee_Queries,
        EmployeeActions.remove_Employee_Success,
        EmployeeActions.add_Employee_Success,
        EmployeeActions.edit_Employee_Success
      ),
      concatLatestFrom(() => store.select(employeesFeature.selectQuery)),
      map(([, res]) => res),
      mergeMap(() =>
        employeeService.loadEmployees().pipe(
          map((res: Employee[]) =>
            EmployeeActions.load_Employees_Success({
              employees: res,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(EmployeeActions.load_Employees_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const removeEmployee = createEffect(
  (actions = inject(Actions), employeeService = inject(EmployeeService)) => {
    return actions.pipe(
      ofType(EmployeeActions.remove_Employee),
      mergeMap((action) =>
        employeeService.removeEmployee(action.id).pipe(
          map((res: Employee) =>
            EmployeeActions.remove_Employee_Success({
              employee: res,
              successMessage: 'Employee removed successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(EmployeeActions.remove_Employee_Failure({ error: error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const addEmployee = createEffect(
  (actions = inject(Actions), employeeService = inject(EmployeeService)) => {
    return actions.pipe(
      ofType(EmployeeActions.add_Employee),
      mergeMap((action) =>
        employeeService.addEmployee(action.employee).pipe(
          map((res: Employee) =>
            EmployeeActions.add_Employee_Success({
              employee: res,
              successMessage: 'Employee added successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(EmployeeActions.add_Employee_Failure({ error: error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const editEmployee = createEffect(
  (actions = inject(Actions), employeeService = inject(EmployeeService)) => {
    return actions.pipe(
      ofType(EmployeeActions.edit_Employee),
      mergeMap((action) =>
        employeeService.editEmployee(action.employeeId, action.employee).pipe(
          map((res: Employee) =>
            EmployeeActions.edit_Employee_Success({
              employee: res,
              successMessage: 'Employee edited successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(EmployeeActions.edit_Employee_Failure({ error: error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const openEmployeeDialog = createEffect(
  (
    actions = inject(Actions),
    dialog = inject(MatDialog),
    dialogOptions = inject(DialogOptions)
  ) => {
    return actions.pipe(
      ofType(EmployeeActions.open_Employee_Dialog),
      tap((action) => {
        dialog.open(EmployeeAddEditComponent, {
          ...dialogOptions,
          data: {
            employee: action.employee,
          },
        });
      })
    );
  },
  {
    functional: true,
    dispatch: false,
  }
);

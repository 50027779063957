import {createActionGroup, emptyProps, props} from "@ngrx/store";
import {ActivityCategory} from "../../interfaces/activity-category.interface";
import {ActivityCategoryType} from "../../interfaces/activity-category-type.interface";
import {errorProps} from "../../../../../../../auth/src/lib/store/auth.actions";
import {SearchQuery} from "../../../../../../../components/src/lib/interfaces/search-query.interface";

export const ActivityCategoryTypeActions = createActionGroup({
  source: 'Activity Category Type',
  events: {
    'update_Bulk_Type_Queries': props<{ query: SearchQuery }>(),
    'set_Activity_Category_Type_Id': props<{id: string}>(),
    'open_Activity_Category_Type_Dialog': props<{ id: string; activityCategory: ActivityCategory}>(),
    'load_Activity_Category_Types': emptyProps(),
    'load_Activity_Category_Types_Success': props<{
      activityCategoryTypes: ActivityCategoryType[];
    }>(),
    'load_Activity_Category_Types_Failure': errorProps(),
    'remove_Activity_Category_Type': props<{ id: string }>(),
    'remove_Activity_Category_Type_Success': props<{
      activityCategoryType: ActivityCategoryType;
      successMessage: string;
    }>(),
    'remove_Activity_Category_Type_Failure': errorProps(),
    'add_Activity_Category_Type': props<{
      activityCategoryType: ActivityCategoryType;
    }>(),
    'add_Activity_Category_Type_Success': props<{
      activityCategoryType: ActivityCategoryType;
      successMessage: string;
    }>(),
    'add_Activity_Category_Type_Failure': errorProps(),
    'edit_Activity_Category_Type': props<{
      id: string;
      activityCategoryType: ActivityCategoryType;
    }>(),
    'edit_Activity_Category_Type_Success': props<{
      activityCategoryType: ActivityCategoryType;
      successMessage: string;
    }>(),
    'edit_Activity_Category_Type_Failure': errorProps(),
    'add_Activity_Category_Type_Row': emptyProps(),
    'remove_Activity_Category_Type_Row': emptyProps(),
    'load_Activity_Category_Types_By_Category_Id': props< {id: string}>(),
    'load_Activity_Category_Types_By_Category_Id_Success': props<{
      activityCategoryTypes: ActivityCategoryType[];
    }>(),
    'load_Activity_Category_Types_By_Category_Id_Failure': errorProps(),
  }
})

import { createFeature, createReducer, createSelector } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { ClientLocationActions } from '../actions/client-locations.actions';
import { clientLocationInitialState } from '../state/client-locations-init.state';

export const clientLocationsReducer = createReducer(
  clientLocationInitialState,
  immerOn(ClientLocationActions.load_User_Client_Locations, (state) => {
    state.loading.list = true;
  }),
  immerOn(
    ClientLocationActions.load_User_Client_Locations_Success,
    (state, { clientLocations }) => {
      state.clientLocations = clientLocations;
      state.loading.list = false;
    }
  ),
  immerOn(
    ClientLocationActions.load_User_Client_Locations_Failure,
    (state) => {
      state.loading.list = false;
    }
  ),
  immerOn(
    ClientLocationActions.load_Client_Locations_By_LocationId_Success,
    (state, payload) => {
      state.clientLocationsByLocationId = payload.clientLocations;
    }
  )
);

export const clientLocationsFeature = createFeature({
  name: 'clientLocations',
  reducer: clientLocationsReducer,
  extraSelectors: ({ selectLoading }) => ({
    selectListLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.list
    ),
    selectCreateEditLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.createEdit
    ),
  }),
});

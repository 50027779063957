import { AssetMobileWellState } from '../../interfaces/asset-mobile-well-state.interface';

export const assetMobileWellState: AssetMobileWellState = {
  assetMobileWells: [],
  assetMobileWellId: '',
  loading: {
    list: false,
    createEdit: false,
  },
};

import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SearchQuery } from '../../../../../components/src/lib/interfaces/search-query.interface';
import { LiftingPauseReason } from './interfaces/lifting-pause-reason.interface';

@Injectable({ providedIn: 'root' })
export class LiftingPauseReasonsService {
  private readonly http = inject(HttpClient);

  loadLiftingPauseReasonsByQuery(query: SearchQuery): Observable<LiftingPauseReason[]> {
    return this.http.get<LiftingPauseReason[]>('/api/pausereason/list?includeInactive=true');
  }

  removeLiftingPauseReason(id: string): Observable<LiftingPauseReason> {
    return this.http.delete<LiftingPauseReason>(`/api/pausereason/${id}`);
  }

  addLiftingPauseReason(reason: LiftingPauseReason): Observable<LiftingPauseReason> {
    return this.http.post<LiftingPauseReason>('/api/pausereason', reason);
  }

  editLiftingPauseReason(reason: LiftingPauseReason): Observable<LiftingPauseReason> {
    return this.http.put<LiftingPauseReason>(`/api/pausereason/${reason.pauseReasonId}`, reason);
  }

  loadActiveLiftingPauseReasonList(): Observable<LiftingPauseReason[]> {
    return this.http.get<LiftingPauseReason[]>('/api/pausereason/list');
  }
}

<ng-select
  [items]="items"
  [multiple]="true"
  [closeOnSelect]="false"
  [hideSelected]="false"
  appearance="outline"
  appendTo="body"
  [bindLabel]="bindLabel"
  [bindValue]="bindValue"
  [placeholder]="placeholder"
  (change)="changeValue($event)"
  (blur)="onTouched()"
  [disabled]="disabled"
  [(ngModel)]="currentValue"
>
</ng-select>

import { createFeature, createReducer, createSelector } from '@ngrx/store';
import { AuthActions } from './auth.actions';
import { immerOn } from 'ngrx-immer/store';
import { authInitialState } from './auth.state';
import { currentUserFeature } from '../store/current-user/current-user.features';
import { User } from '../interfaces/user.interface';

export const authReducer = createReducer(
  authInitialState,
  immerOn(AuthActions.login, (state) => {
    state.loading.login = true;
  }),
  immerOn(AuthActions.login_Success, (state) => {
    state.loading.login = false;
    state.isLoggedIn = true;
  }),
  immerOn(
    AuthActions.login_Failure,
    (state) => {
      state.loading.login = false;
    }
  ),
  immerOn(AuthActions.set_State, (state, { loggedIn, tokenExpired }) => {
    const isloggedIn = loggedIn && !tokenExpired;
    state.isLoggedIn = isloggedIn;
  }),

  immerOn(AuthActions.refresh_Token_Success, (state) => {
    state.isLoggedIn = true;
  }),
  immerOn(AuthActions.refresh_Token_Failure, (state) => {
    state.isLoggedIn = false;
  }),
);

export const authFeature = createFeature({
  name: 'auth',
  reducer: authReducer,
  extraSelectors: ({ selectLoading }) => ({
    selectAuthLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.login
    ),
  }),
});

export const selectHasUserPermission = (permissions: string[]) =>
  createSelector(currentUserFeature.selectUser, (user: User) => {
    const url = window.location.pathname.split('/')[1];
    const applicationRoles = user.roles.find(role => role.application === url)?.roles || [];
    return permissions.some(permission => applicationRoles.includes(permission));
  });


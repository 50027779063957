import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf } from '@angular/common';

@Component({
  selector: 'lha-single-select',
  templateUrl: 'single-select.component.html',
  standalone: true,
  imports: [NgSelectModule, FormsModule, MatFormFieldModule, NgIf],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SingleSelectComponent),
      multi: true,
    },
  ],
})
export class SingleSelectComponent implements ControlValueAccessor, OnChanges {
  @Input() placeholder = 'Select items';
  @Input() bindLabel = 'name';
  @Input() bindValue = 'value';
  @Input() disabled = false;
  @Input() loading = false;
  @Input() hideLabelAfterSelect = false;
  @Input() options: any[] = [];
  @Output() selectChange = new EventEmitter<any>();

  selected: any;

  ngOnChanges(): void {
    if (this.options?.length) {
      this.options = this.options.map((item) => {
        return {
          ...item,
          [this.bindLabel]: capitalizeFirstLetter(
            item[this.bindLabel].toLowerCase()
          ),
        };
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: (value: string) => void = () => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: () => void = () => {};

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: any): void {
    if (value || value === 0) {
      this.selected = value;
    } else {
      this.selected = null;
    }
  }

  changeValue(event: any): void {
    this.onChange(this.selectValue(event));
    this.selectChange.emit(this.selectValue(event));
    this.onTouched();
  }

  selectValue(val: any): string {
    return val ? val[this.bindValue] : null;
  }
}

export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

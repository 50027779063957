import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { catchError, map, mergeMap, of, switchMap, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { CargoTypeService } from '../../cargo-type.service';
import { CargoeTypeActions } from '../actions/cargo-type.actions';
import { CargoType } from '../../interfaces/carrgo-type.interface';
import { Store } from '@ngrx/store';
import { cargotypeFeature } from '../features/cargo-type.feature';
import { DialogOptions } from '../../../config/dialog-options';
import { MatDialog } from '@angular/material/dialog';
import { CargoeFamilyActions } from '../actions/cargo-family.actions';
import { CargoTypeAddEditComponent } from 'apps/allocate/src/app/maintenance/shared/components/cargo-type-add-edit/cargo-type-add-edit.component';

export const loadCargoTypes = createEffect(
  (
    actions = inject(Actions),
    store = inject(Store),
    cargoTypeService = inject(CargoTypeService)
  ) => {
    return actions.pipe(
      ofType(CargoeTypeActions.load_CargoType_List),
      concatLatestFrom(() => store.select(cargotypeFeature.selectQuery)),
      map(([, res]) => res),
      mergeMap(() =>
        cargoTypeService.loadCargoTypes().pipe(
          map((res: CargoType[]) =>
            CargoeTypeActions.load_CargoTypes_Success({ CargoTypes: res })
          ),
          catchError((error: HttpErrorResponse) =>
            of(CargoeTypeActions.load_Cargotypes_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const loadCargoTypesByTypeId = createEffect(
  (actions = inject(Actions), cargoTypeService = inject(CargoTypeService)) => {
    return actions.pipe(
      ofType(CargoeTypeActions['load_CargoType_List_By-Family_Id']),
      mergeMap((action) =>
        cargoTypeService.loadCargoTypesByFamilyId(action.familyId).pipe(
          map((res: CargoType[]) =>
            CargoeTypeActions['load_CargoTypes_By-Family_Id_Success']({
              CargoTypes: res,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              CargoeTypeActions['load_Cargotypes_By-Family_Id_Failure']({
                error,
              })
            )
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const loadCargoTypesPage = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(CargoeTypeActions.load_Cargo_Types_Page),
      switchMap(() =>
        of(
          CargoeTypeActions.load_Cargo_Types(),
          CargoeFamilyActions.load_Cargo_Families()
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const loadCargoTypesList = createEffect(
  (
    actions = inject(Actions),
    store = inject(Store),
    cargoTypeService = inject(CargoTypeService)
  ) => {
    return actions.pipe(
      ofType(
        CargoeTypeActions.load_Cargo_Types,
        CargoeTypeActions.remove_Cargo_Type_Success,
        CargoeTypeActions.add_Cargo_Type_Success,
        CargoeTypeActions.edit_Cargo_Type_Success
      ),
      concatLatestFrom(() => store.select(cargotypeFeature.selectQuery)),
      map(([, res]) => res),
      mergeMap((action) =>
        cargoTypeService.loadCargoTypes().pipe(
          map((res: CargoType[]) =>
            CargoeTypeActions.load_Cargo_Types_Success({
              cargoTypes: res,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(CargoeTypeActions.load_Cargo_Types_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const removeCargoType = createEffect(
  (actions = inject(Actions), cargoTypeService = inject(CargoTypeService)) => {
    return actions.pipe(
      ofType(CargoeTypeActions.remove_Cargo_Type),
      mergeMap((action) =>
        cargoTypeService.deleteCargoType(action.id).pipe(
          map(() =>
            CargoeTypeActions.remove_Cargo_Type_Success({
              cargoType: {} as CargoType,
              successMessage: 'Cargo Type successfully removed!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              CargoeTypeActions.remove_Cargo_Type_Failure({
                error: error,
              })
            )
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const addCargoType = createEffect(
  (actions = inject(Actions), cargoTypeService = inject(CargoTypeService)) => {
    return actions.pipe(
      ofType(CargoeTypeActions.add_Cargo_Type),
      mergeMap((action) =>
        cargoTypeService.createCargoType(action.cargoType).pipe(
          map((res: CargoType) =>
            CargoeTypeActions.add_Cargo_Type_Success({
              cargoType: res,
              successMessage: 'Cargo Type added successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(CargoeTypeActions.add_Cargo_Type_Failure({ error: error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const editCargoType = createEffect(
  (actions = inject(Actions), cargoTypeService = inject(CargoTypeService)) => {
    return actions.pipe(
      ofType(CargoeTypeActions.edit_Cargo_Type),
      mergeMap((action) =>
        cargoTypeService
          .updateCargoType(action.cargoTypeId, action.cargoType)
          .pipe(
            map((res: CargoType) =>
              CargoeTypeActions.edit_Cargo_Type_Success({
                cargoType: res,
                successMessage: 'Cargo Type edited successfully!',
              })
            ),
            catchError((error: HttpErrorResponse) =>
              of(
                CargoeTypeActions.edit_Cargo_Type_Failure({
                  error: error,
                })
              )
            )
          )
      )
    );
  },
  {
    functional: true,
  }
);

export const openCargoTypeDialog = createEffect(
  (
    actions = inject(Actions),
    dialog = inject(MatDialog),
    dialogOptions = inject(DialogOptions)
  ) => {
    return actions.pipe(
      ofType(CargoeTypeActions.open_Cargo_Type_Dialog),
      tap((action) => {
        dialog.open(CargoTypeAddEditComponent, {
          ...dialogOptions,
          data: {
            CargoType: action.cargoType,
          },
        });
      })
    );
  },
  {
    functional: true,
    dispatch: false,
  }
);

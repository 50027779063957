<div
  class="asset-mobile-well dialog-container"
  *ngIf="vm$ | async as vm"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  cdkDragBoundary=".cdk-overlay-container"
>
  <lha-table
    [columns]="columns"
    [data]="vm.assetMobileWells"
    [loading]="vm.loading.createEdit"
    [canExportTable]="false"
    tableName="Assets History"
  >
    <h2 tableHeaderLeft>Add Well to {{ data.asset.name }}</h2>
    <ng-container tableHeaderRightBtn>
      <button mat-raised-button (click)="addMobileWell()" color="primary">
        Add
      </button>
      <button
        mat-icon-button
        color="warn"
        mat-dialog-close=""
        class="asset-close"
      >
        <mat-icon>close</mat-icon>
      </button>
    </ng-container>
    <ng-template lhaCellTemplate="wellName" let-item>
      <ng-container *ngIf="!editRows.get(item.mobileWellId)">
        {{ item.wellName }}
      </ng-container>
      <ng-container
        *ngIf="editRows.get(item.mobileWellId)"
        [formGroup]="getFg(item.mobileWellId)"
      >
        <lha-single-select
          [options]="wellAssetList"
          formControlName="wellId"
          bindLabel="name"
          bindValue="assetId"
          placeholder="Well Asset"
        />
        <div
          *ngIf="
            getFg(item.mobileWellId)?.get('wellId')?.invalid &&
            getFg(item.mobileWellId)?.get('wellId')?.touched
          "
        >
          <mat-error
            *ngIf="
              getFg(item.mobileWellId)?.get('wellId')?.hasError('required')
            "
          >
            Well Asset is required.
          </mat-error>
        </div>
      </ng-container>
    </ng-template>
    <ng-template lhaCellTemplate="startDateTime" let-item>
      <ng-container
        *ngIf="editRows.get(item.mobileWellId)"
        [formGroup]="getFg(item.mobileWellId)"
      >
        <mat-form-field appearance="outline" hideRequiredMarker="true">
          <mat-label>Start Date</mat-label>
          <input
            matInput
            [max]="getFg(item.mobileWellId).getRawValue()?.timeAfter"
            formControlName="startDateTime"
            [ngxMatDatetimePicker]="loadDateTime"
            (dateChange)="changeStartDate($event, item.mobileWellId)"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="$any(loadDateTime)"
          ></mat-datepicker-toggle>
          <ngx-mat-datetime-picker
            [defaultTime]="defaultTime"
            #loadDateTime
          ></ngx-mat-datetime-picker>
          <mat-error
            *ngIf="
              getFg(item.mobileWellId)
                ?.get('startDateTime')
                ?.hasError('required')
            "
            >Start Date Time is required</mat-error
          >
        </mat-form-field>
      </ng-container>
      <ng-container *ngIf="!editRows.get(item.mobileWellId)">
        {{ item.startDateTime | date : 'dd-MM-yyyy HH:mm' }}
      </ng-container>
    </ng-template>
    <ng-template lhaCellTemplate="endDateTime" let-item>
      <ng-container
        *ngIf="editRows.get(item.mobileWellId)"
        [formGroup]="getFg(item.mobileWellId)"
      >
        <mat-form-field appearance="outline" hideRequiredMarker="true">
          <mat-label>To Date</mat-label>
          <input
            matInput
            [min]="getFg(item.mobileWellId).getRawValue()?.timeBefore"
            formControlName="endDateTime"
            [ngxMatDatetimePicker]="endDateTime"
            (dateChange)="changeEndDDate($event, item.mobileWellId)"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="$any(endDateTime)"
          ></mat-datepicker-toggle>
          <ngx-mat-datetime-picker
            [defaultTime]="defaultTime"
            #endDateTime
          ></ngx-mat-datetime-picker>
        </mat-form-field>
      </ng-container>
      <ng-container *ngIf="!editRows.get(item.mobileWellId)">
        <lha-custom-chip
          *ngIf="!item.endDateTime"
          [cssClass]="'off'"
          [text]="'Current'"
        >
        </lha-custom-chip>
        <ng-container *ngIf="item.endDateTime">{{
          item.endDateTime | date : 'dd/MM/yyyy HH:mm'
        }}</ng-container>
      </ng-container>
    </ng-template>
    <ng-template lhaCellTemplate="mobileWellId" let-item>
      <button
        mat-icon-button
        color="primary"
        class="btn-save"
        *ngIf="editRows.get(item.mobileWellId)"
        (click)="saveMobileWell(item)"
      >
        <mat-icon>check_circle</mat-icon>
      </button>
      <button
        mat-icon-button
        color="warn"
        *ngIf="editRows.get(item.mobileWellId)"
        (click)="cancelMobileWell(item)"
      >
        <mat-icon>cancel</mat-icon>
      </button>
      <button
        mat-icon-button
        color="primary"
        *ngIf="!editRows.get(item.mobileWellId)"
        (click)="editMobileWell(item)"
      >
        <mat-icon>edit</mat-icon>
      </button>
      <button
        mat-icon-button
        color="warn"
        *ngIf="!editRows.get(item.mobileWellId)"
        (click)="removeMobileWell(item)"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </ng-template>
  </lha-table>
</div>

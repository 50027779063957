import { createFeature, createReducer, createSelector } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { craneInitialState } from '../states/crane.state';
import { CraneActions } from '../actions/crane.actions';

export const cranesReducer = createReducer(
  craneInitialState,
  immerOn(CraneActions.load_Cranes, (state) => {
    state.loading.list = true;
  }),
  immerOn(CraneActions.update_Crane_Queries, (state, { query }) => {
    state.query = query;
    state.loading.list = true;
  }),
  immerOn(CraneActions.load_Cranes_Success, (state, { cranes }) => {
    state.cranes = cranes;
    state.loading.list = false;
  }),
  immerOn(
    CraneActions.load_Cranes_Failure,
    CraneActions.remove_Crane_Success,
    CraneActions.remove_Crane_Failure,
    (state) => {
      state.loading.list = false;
    }
  ),
  immerOn(CraneActions.add_Crane, CraneActions.edit_Crane, (state) => {
    state.loading.createEdit = true;
  }),
  immerOn(
    CraneActions.add_Crane_Success,
    CraneActions.add_Crane_Failure,
    CraneActions.edit_Crane_Success,
    CraneActions.edit_Crane_Failure,
    (state) => {
      state.loading.createEdit = false;
    }
  )
);

export const cranesFeature = createFeature({
  name: 'cranes',
  reducer: cranesReducer,
  extraSelectors: ({ selectLoading }) => ({
    selectListLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.list
    ),
    selectCreateEditLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.createEdit
    ),
  }),
});

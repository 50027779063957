import { createFeature, createReducer, createSelector } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { employeeInitialState } from '../states/employee.state';
import { EmployeeActions } from '../actions/employee.actions';

export const employeesReducer = createReducer(
  employeeInitialState,
  immerOn(EmployeeActions.load_Employees, (state) => {
    state.loading.list = true;
  }),
  immerOn(EmployeeActions.update_Employee_Queries, (state, { query }) => {
    state.query = query;
    state.loading.list = true;
  }),
  immerOn(EmployeeActions.load_Employees_Success, (state, { employees }) => {
    state.employees = employees;
    state.loading.list = false;
  }),
  immerOn(
    EmployeeActions.load_Employees_Failure,
    EmployeeActions.remove_Employee_Success,
    EmployeeActions.remove_Employee_Failure,
    (state) => {
      state.loading.list = false;
    }
  ),
  immerOn(
    EmployeeActions.add_Employee,
    EmployeeActions.edit_Employee,
    (state) => {
      state.loading.createEdit = true;
    }
  ),
  immerOn(
    EmployeeActions.add_Employee_Success,
    EmployeeActions.add_Employee_Failure,
    EmployeeActions.edit_Employee_Success,
    EmployeeActions.edit_Employee_Failure,
    (state) => {
      state.loading.createEdit = false;
    }
  )
);

export const employeesFeature = createFeature({
  name: 'employees',
  reducer: employeesReducer,
});

import { inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, mergeMap, of } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivityCategoryTypeService } from '../../activity-category-type.service';
import { ActivityCategoryTypeActions } from '../actions/activity-category-type.actions';
import { ActivityCategoryType } from '../../interfaces/activity-category-type.interface';
import { activityCategoryTypeFeature } from '../features/activity-category-type.feature';
import { MatDialog } from '@angular/material/dialog';
import { ActivityCategoryTypeAddEditComponent } from '../../../../../../../../apps/allocate/src/app/maintenance/shared/components/activity-category-type-add-edit/activity-category-type-add-edit.component';

export const loadActivityCategoryTypes = createEffect(
  (
    actions = inject(Actions),
    store = inject(Store),
    activityCategoryTypeService = inject(ActivityCategoryTypeService)
  ) => {
    return actions.pipe(
      ofType(
        ActivityCategoryTypeActions.set_Activity_Category_Type_Id,
        ActivityCategoryTypeActions.load_Activity_Category_Types,
        ActivityCategoryTypeActions.remove_Activity_Category_Type_Success,
        ActivityCategoryTypeActions.add_Activity_Category_Type_Success,
        ActivityCategoryTypeActions.edit_Activity_Category_Type_Success
      ),
      concatLatestFrom(() =>
        store.select(activityCategoryTypeFeature.selectActivityCategoryTypeId)
      ),
      map(([, res]) => res),
      filter((res) => !!res),
      mergeMap((action) =>
        activityCategoryTypeService
          .loadActivityCategoryTypesByCategoryId(action)
          .pipe(
            map((res: ActivityCategoryType[]) =>
              ActivityCategoryTypeActions.load_Activity_Category_Types_Success({
                activityCategoryTypes: res,
              })
            ),
            catchError((error: HttpErrorResponse) =>
              of(
                ActivityCategoryTypeActions.load_Activity_Category_Types_Failure(
                  {
                    error,
                  }
                )
              )
            )
          )
      )
    );
  },
  {
    functional: true,
  }
);

export const loadActivityCategoryTypesByCategoryId = createEffect(
  (
    actions$ = inject(Actions),
    activityCategoryTypeService = inject(ActivityCategoryTypeService)
  ) => {
    return actions$.pipe(
      ofType(
        ActivityCategoryTypeActions.load_Activity_Category_Types_By_Category_Id
      ),
      mergeMap((action) =>
        activityCategoryTypeService
          .loadActivityCategoryTypesByCategoryId(action.id)
          .pipe(
            map((activityCategoryTypes: ActivityCategoryType[]) =>
              ActivityCategoryTypeActions.load_Activity_Category_Types_By_Category_Id_Success(
                {
                  activityCategoryTypes,
                }
              )
            ),
            catchError((error: HttpErrorResponse) =>
              of(
                ActivityCategoryTypeActions.load_Activity_Category_Types_By_Category_Id_Failure(
                  { error }
                )
              )
            )
          )
      )
    );
  },
  { functional: true }
);

export const removeActivityCategoryType = createEffect(
  (
    actions = inject(Actions),
    activityCategoryTypeService = inject(ActivityCategoryTypeService)
  ) => {
    return actions.pipe(
      ofType(ActivityCategoryTypeActions.remove_Activity_Category_Type),
      mergeMap((action) =>
        activityCategoryTypeService.removeActivityCategoryType(action.id).pipe(
          map((res: ActivityCategoryType) =>
            ActivityCategoryTypeActions.remove_Activity_Category_Type_Success({
              activityCategoryType: res,
              successMessage: 'Activity Category Type removed successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              ActivityCategoryTypeActions.remove_Activity_Category_Type_Failure(
                {
                  error: error,
                }
              )
            )
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const addActivityCategoryType = createEffect(
  (
    actions = inject(Actions),
    activityCategoryTypeService = inject(ActivityCategoryTypeService)
  ) => {
    return actions.pipe(
      ofType(ActivityCategoryTypeActions.add_Activity_Category_Type),
      mergeMap((action) =>
        activityCategoryTypeService
          .addActivityCategoryType(action.activityCategoryType)
          .pipe(
            map((res: ActivityCategoryType) =>
              ActivityCategoryTypeActions.add_Activity_Category_Type_Success({
                activityCategoryType: res,
                successMessage: 'Activity Category Type added successfully!',
              })
            ),
            catchError((error: HttpErrorResponse) =>
              of(
                ActivityCategoryTypeActions.add_Activity_Category_Type_Failure({
                  error: error,
                })
              )
            )
          )
      )
    );
  },
  {
    functional: true,
  }
);

export const editActivityCategoryType = createEffect(
  (
    actions = inject(Actions),
    activityCategoryTypeService = inject(ActivityCategoryTypeService)
  ) => {
    return actions.pipe(
      ofType(ActivityCategoryTypeActions.edit_Activity_Category_Type),
      mergeMap((action) =>
        activityCategoryTypeService
          .editActivityCategoryType(action.id, action.activityCategoryType)
          .pipe(
            map((res: ActivityCategoryType) =>
              ActivityCategoryTypeActions.edit_Activity_Category_Type_Success({
                activityCategoryType: res,
                successMessage: 'Activity Category Type edited successfully!',
              })
            ),
            catchError((error: HttpErrorResponse) =>
              of(
                ActivityCategoryTypeActions.edit_Activity_Category_Type_Failure(
                  {
                    error: error,
                  }
                )
              )
            )
          )
      )
    );
  },
  {
    functional: true,
  }
);

export const openActivityCategoryTypeDialog = createEffect(
  (actions = inject(Actions), dialog = inject(MatDialog)) => {
    return actions.pipe(
      ofType(ActivityCategoryTypeActions.open_Activity_Category_Type_Dialog),
      tap((action) => {
        dialog.open(ActivityCategoryTypeAddEditComponent, {
          width: '80vw',
          panelClass: 'asset_dialog',
          autoFocus: false,
          disableClose: true,
          height: '80vh',
          data: {
            id: action.id,
            activityCategory: action.activityCategory,
          },
        });
      })
    );
  },
  {
    functional: true,
    dispatch: false,
  }
);

import { inject, Injectable } from '@angular/core';
import { SearchQuery } from '../../../../../components/src/lib/interfaces/search-query.interface';
import { Observable } from 'rxjs';
import { Unit } from '../interfaces/unit.interface';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class UnitsService {
  private readonly http = inject(HttpClient);

  loadUnits(query?: SearchQuery): Observable<Unit[]> {
    return this.http.get<Unit[]>('/api/unit');
    // TODO: It will be used after creating pagination, sorting, and search on back-end
    // return this.loadMaintenance<Unit[]>(query, 'unit');
  }

  removeUnit(id: string): Observable<Unit> {
    return this.http.delete<Unit>(`/api/unit/${id}`);
  }

  addUnit(unit: Unit): Observable<Unit> {
    return this.http.post<Unit>('/api/unit', unit);
  }

  editUnit(id: string, unit: Unit): Observable<Unit> {
    return this.http.put<Unit>(`/api/unit/${id}`, unit);
  }
}

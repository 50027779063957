import { createFeature, createReducer, createSelector } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { distanceInitialState } from '../states/distance.state';
import { DistanceActions } from '../actions/distance.actions';

export const distancesReducer = createReducer(
  distanceInitialState,
  immerOn(
    DistanceActions.load_Distances,
    DistanceActions.remove_Distance,
    (state) => {
      state.loading.list = true;
    }
  ),
  immerOn(DistanceActions.update_Distance_Queries, (state, { query }) => {
    state.query = query;
    state.loading.list = true;
  }),
  immerOn(DistanceActions.load_Distances_Success, (state, { distances }) => {
    state.distances = distances;
    state.loading.list = false;
  }),
  immerOn(
    DistanceActions.load_Distances_Failure,
    DistanceActions.remove_Distance_Success,
    DistanceActions.remove_Distance_Failure,
    (state) => {
      state.loading.list = false;
    }
  ),
  immerOn(
    DistanceActions.add_Distance,
    DistanceActions.edit_Distance,
    (state) => {
      state.loading.createEdit = true;
    }
  ),
  immerOn(
    DistanceActions.add_Distance_Success,
    DistanceActions.add_Distance_Failure,
    DistanceActions.edit_Distance_Success,
    DistanceActions.edit_Distance_Failure,
    (state) => {
      state.loading.createEdit = false;
    }
  )
);

export const distancesFeature = createFeature({
  name: 'distances',
  reducer: distancesReducer,
  extraSelectors: ({ selectLoading }) => ({
    selectListLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.list
    ),
    selectCreateEditLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.createEdit
    ),
  }),
});

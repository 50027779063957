import { Injectable } from '@angular/core';
import { AuthService, IdToken } from '@auth0/auth0-angular';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TokenExpirationService {
  constructor(private auth: AuthService) {}

  isTokenExpired(): Observable<boolean> {
    return this.auth.idTokenClaims$.pipe(
      map((claims: IdToken | null | undefined) => {
        if (!claims) {
          return true; // No token claims available, consider it expired
        }

        if (!claims.exp) {
          return true; // Token claims don't contain the 'exp' field, consider it expired
        }

        const expirationTime = claims.exp * 1000; // Convert from seconds to milliseconds
        const currentTime = new Date().getTime();
        const test = currentTime > expirationTime
        return test;
      })
    );
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { ActionCreatorProps, props } from '@ngrx/store';
import { DateFilterFn } from '@angular/material/datepicker';

export const makeDateRangeCheck = (
  startDate: string,
  endDate: string,
  skipTime = true,
  sameDate = true
) => {
  const startDateTime = skipTime
    ? new Date(new Date(startDate).toDateString()).getTime()
    : new Date(startDate).getTime();
  const endDateTime = skipTime
    ? new Date(new Date(endDate).toDateString()).getTime()
    : new Date(endDate).getTime();
  return (date: string): boolean => {
    const dateTime = skipTime
      ? new Date(new Date(date).toDateString()).getTime()
      : new Date(date).getTime();
    return sameDate
      ? (!startDate || startDateTime <= dateTime) &&
          (!endDate || (!!date && dateTime <= endDateTime))
      : (!startDate || startDateTime < dateTime) &&
          (!endDate || (!!date && dateTime < endDateTime));
  };
};

export const errorProps = (): ActionCreatorProps<{
  error: HttpErrorResponse;
  customMessage?: string;
}> => {
  return props<{ error: HttpErrorResponse; customMessage?: string }>();
};

export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const dateAfterFilter = (
  date: Date | null
): DateFilterFn<Date | null> => {
  return (d: Date | null) => {
    const endDate = new Date(new Date(date ?? '').toDateString()).getTime();
    const calendarDate = new Date(new Date(d ?? '').toDateString()).getTime();
    return !endDate || endDate >= calendarDate;
  };
};

export const dateBeforeFilter = (
  date: Date | null
): DateFilterFn<Date | null> => {
  return (d: Date | null): boolean => {
    const startDate = new Date(new Date(date ?? '').toDateString()).getTime();
    const calendarDate = new Date(new Date(d ?? '').toDateString()).getTime();
    return !startDate || startDate <= calendarDate;
  };
};

export const toMilliseconds = (hrs: number, min: number, sec: number) =>
  (hrs * 60 * 60 + min * 60 + sec) * 1000;

export const timeAfter = (
  date: Date | null,
  time: { hrs?: number; min?: number; sec?: number }
): Date | null => {
  const dateTime = new Date(
    new Date(date ?? '').getTime() -
      toMilliseconds(time.hrs ?? 0, time.min ?? 0, time.sec ?? 0)
  );
  return !dateTime ? null : dateTime;
};

export const timeBefore = (
  date: Date | null,
  time: { hrs?: number; min?: number; sec?: number }
): Date | null => {
  const dateTime = new Date(
    new Date(date ?? '').getTime() +
      toMilliseconds(time.hrs ?? 0, time.min ?? 0, time.sec ?? 0)
  );
  return !dateTime ? null : dateTime;
};

export const getDefaultTime = (time: {
  hrs?: number;
  min?: number;
  sec?: number;
}): number[] => {
  return [
    time.hrs ?? new Date().getHours(),
    time.min ?? new Date().getMinutes(),
    time.sec ?? new Date().getSeconds(),
  ];
};

export const getTime = (
  date: string | Date,
  skipMilliseconds = true
): number => {
  return skipMilliseconds
    ? new Date(new Date(date).toJSON().replace(/\.\d+Z/, '') + 'Z').getTime()
    : new Date(date).getTime();
};

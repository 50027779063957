import { createFeature, createReducer } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { usersInitialState } from '../states/users.state';
import { UserActions } from '../actions/users.actions';

export const usersReducer = createReducer(
  usersInitialState,
  immerOn(UserActions.remove_User, UserActions.load_Users, (state) => {
    state.loading.list = true;
  }),
  immerOn(UserActions.update_User_Queries, (state, { query }) => {
    state.query = query;
    state.loading.list = true;
  }),

  immerOn(UserActions.load_Users_Success, (state, { users }) => {
    state.users = users;
    state.loading.list = false;
  }),
  immerOn(UserActions.load_User_Roles_Success, (state, { userRoles }) => {
    state.userRoles = userRoles;
  }),
  immerOn(
    UserActions.load_User_Application_Roles_Success,
    (state, { incomingDataApplicationRoles }) => {
      state.applicationRoles = incomingDataApplicationRoles;
      state.loading.list = false;
    }
  ),
  immerOn(
    UserActions.load_Users_Failure,
    UserActions.remove_User_Success,
    UserActions.remove_User_Failure,
    UserActions.load_User_Roles_Success,
    UserActions.load_User_Roles_Failure,
    UserActions.load_User_Application_Roles_Failure,
    (state) => {
      state.loading.list = false;
    }
  ),
  immerOn(
    UserActions.add_User,
    UserActions.edit_User,
    UserActions.edit_User_Details,
    (state) => {
      state.loading.createEdit = true;
    }
  ),
  immerOn(
    UserActions.add_User_Success,
    UserActions.add_User_Failure,
    UserActions.edit_User_Success,
    UserActions.edit_User_Failure,
    UserActions.edit_User_Details_Success,
    UserActions.edit_User_Details_Failure,
    (state) => {
      state.loading.createEdit = false;
    }
  )
);

export const usersFeature = createFeature({
  name: 'users',
  reducer: usersReducer,
  extraSelectors: () => ({}),
});

import { createFeature, createReducer, createSelector } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { operatorsInitialState } from '../states/operators.state';
import { OperatorActions } from '../actions/operators.actions';

export const operatorsReducer = createReducer(
  operatorsInitialState,
  immerOn(
    OperatorActions.remove_Operator,
    OperatorActions.load_Operators,
    (state) => {
      state.loading.list = true;
    }
  ),
  immerOn(OperatorActions.update_Operator_Queries, (state, { query }) => {
    state.query = query;
    state.loading.list = true;
  }),

  immerOn(OperatorActions.load_Operators_Success, (state, { operators }) => {
    state.operators = operators;
    state.loading.list = false;
  }),
  immerOn(
    OperatorActions.load_Operators_Failure,
    OperatorActions.remove_Operator_Success,
    OperatorActions.remove_Operator_Failure,
    (state) => {
      state.loading.list = false;
    }
  ),
  immerOn(
    OperatorActions.add_Operator,
    OperatorActions.edit_Operator,
    (state) => {
      state.loading.createEdit = true;
    }
  ),
  immerOn(
    OperatorActions.add_Operator_Success,
    OperatorActions.add_Operator_Failure,
    OperatorActions.edit_Operator_Success,
    OperatorActions.edit_Operator_Failure,
    (state) => {
      state.loading.createEdit = false;
    }
  )
);

export const operatorsFeature = createFeature({
  name: 'operators',
  reducer: operatorsReducer,
  extraSelectors: ({ selectLoading }) => ({
    selectListLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.list
    ),
    selectCreateEditLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.createEdit
    ),
  }),
});

<div
  *ngIf="squadsState()"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  cdkDragBoundary=".cdk-overlay-container"
>
  <div mat-dialog-title>
    <h4>{{ isAdd ? 'Add ' : 'Edit ' }} Squad</h4>
  </div>
  <mat-dialog-content *lhaLoading="squadsState().loading.createEdit">
    <form [formGroup]="form" (ngSubmit)="saveSquad()">
      <div class="form__block">
        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>Name</mat-label>
            <input matInput formControlName="squadName" />
            <mat-error *ngIf="form.controls.squadName.hasError('required')">
              Squad name is required.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form__box">
          <lha-single-select
            [options]="locations()"
            formControlName="locationId"
            bindValue="locationId"
            bindLabel="name"
            placeholder="Location"
          />
          <div
            *ngIf="
              form.controls.locationId.invalid &&
              form.controls.locationId.touched
            "
          >
            <mat-error *ngIf="form.controls.locationId.hasError('required')">
              Location is required.
            </mat-error>
          </div>
        </div>
        <div class="form__box">
          <lha-multiselect
            formControlName="squadEmployees"
            bindValue="employeeId"
            bindLabel="name"
            [items]="employees()"
            placeholder="Employees"
          >
          </lha-multiselect>
        </div>
      </div>
      <mat-dialog-actions align="end">
        <button mat-raised-button color="primary" type="submit">
          {{ isAdd ? 'Add ' : 'Save ' }}
        </button>
        <button
          mat-raised-button
          color="warn"
          type="button"
          mat-dialog-close=""
        >
          Cancel
        </button>
      </mat-dialog-actions>
    </form>
  </mat-dialog-content>
</div>

import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { LoadingDirective } from '../../../../../../../../libs/components/src/lib/directives/loading.directive';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormField, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { cargoFamilyFeature } from '../../../../../../../../libs/services/src/lib/services/maintenance/store/features';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MultiselectComponent } from '../../../../../../../../libs/components/src/lib/components/multiselect/multiselect.component';
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { SingleSelectComponent } from 'libs/components/src/lib/components/single-select/single-select.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CargoFamily } from 'libs/services/src/lib/services/maintenance/interfaces/carrgo-family.interface';
import { CargoeFamilyActions } from 'libs/services/src/lib/services/maintenance/store/actions/cargo-family.actions';

@Component({
  selector: 'lha-cargo-family-add-edit',
  standalone: true,
  imports: [
    FormsModule,
    LoadingDirective,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    NgForOf,
    ReactiveFormsModule,
    AsyncPipe,
    NgIf,
    MatSlideToggleModule,
    MultiselectComponent,
    CdkDrag,
    CdkDragHandle,
    SingleSelectComponent,
  ],
  templateUrl: './cargo-family-add-edit.component.html',
  styleUrls: ['./cargo-family-add-edit.component.scss'],
})
export class CargoFamilyAddEditComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  dialogRef = inject(MatDialogRef<CargoFamilyAddEditComponent>);
  data: {
    CargoFamily: CargoFamily | null;
  } = inject(MAT_DIALOG_DATA);
  store = inject(Store);
  action = inject(Actions);
  cargoFamilyState = this.store.selectSignal(
    cargoFamilyFeature.selectCargoFamilyState
  );
  isAdd = true;
  selectedCargoFamilyId = '';

  form = new FormGroup({
    name: new FormControl<string>('', Validators.required),
  });

  ngOnInit(): void {
    this.subCloseDialog();
    this.initAddEdit();
  }

  private initAddEdit(): void {
    this.isAdd = !this.data.CargoFamily;
    if (!this.isAdd) {
      this.selectedCargoFamilyId = this.data.CargoFamily!.cargoFamilyId;

      this.patchForm(this.data.CargoFamily!);
    }
  }

  private subCloseDialog(): void {
    this.action
      .pipe(
        ofType(
          CargoeFamilyActions.add_Cargo_Family_Success,
          CargoeFamilyActions.edit_Cargo_Family_Success
        )
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  private patchForm(cargoFamily: CargoFamily): void {
    this.form.patchValue({
      name: cargoFamily.name,
    });
  }

  saveCargoFamily(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      const model = {
        ...this.form.value,
      } as CargoFamily;

      if (this.isAdd) {
        this.store.dispatch(
          CargoeFamilyActions.add_Cargo_Family({
            cargoFamily: model,
          })
        );
      } else {
        this.store.dispatch(
          CargoeFamilyActions.edit_Cargo_Family({
            cargoFamilyId: this.selectedCargoFamilyId,
            cargoFamily: model,
          })
        );
      }
    }
  }
}

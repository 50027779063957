import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Constant } from 'libs/services/src/lib/services/shared/constant.interface';
import { ApplicationRole } from 'libs/auth/src/lib/interfaces/application-role.interface';

@Injectable({
  providedIn: 'root',
})
export class ConstantService {
  private readonly http = inject(HttpClient);

  loadUserRoles(): Observable<Constant[]> {
    return this.http.get<Constant[]>('/api/constant/userroles');
  }

  loadAssetTypes(): Observable<Constant[]> {
    return this.http.get<Constant[]>('/api/constant/assettypes');
  }

  loadTransactionTypes(): Observable<Constant[]> {
    return this.http.get<Constant[]>('/api/constant/transactiontypes');
  }

  loadBulkFluidTypes(): Observable<Constant[]> {
    return this.http.get<Constant[]>('/api/constant/bulkfluidtypes');
  }

  loadChargeabilityTypes(): Observable<Constant[]> {
    return this.http.get<Constant[]>('/api/constant/chargeabilitytypes');
  }

  loadActivityTypeList(): Observable<Constant[]> {
    return this.http.get<Constant[]>('/api/constant/activitytypes');
  }

  loadApplicationRoles(): Observable<ApplicationRole[]> {
    return this.http.get<ApplicationRole[]>('/api/constant/applicationroles');
  }
}

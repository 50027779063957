import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SearchQuery } from '../../../../../../../components/src/lib/interfaces/search-query.interface';
import { DangerousGood } from '../../interfaces/dangerous-good.interface';
import { errorProps } from '../../../../../../../components/src/lib/functions/utility.functions';

export const DangerousGoodActions = createActionGroup({
  source: 'Bulk Types',
  events: {
    update_Dangerous_Good_Queries: props<{ query: SearchQuery }>(),
    load_Dangerous_Good_Page: emptyProps(),
    load_Dangerous_Goods: emptyProps(),
    load_Dangerous_Goods_Success: props<{ dangerousGoods: DangerousGood[] }>(),
    load_Dangerous_Goods_Failure: errorProps(),
    remove_Dangerous_Good: props<{ id: string }>(),
    remove_Dangerous_Good_Success: props<{
      dangerousGood: DangerousGood;
      successMessage: string;
    }>(),
    remove_Dangerous_Good_Failure: errorProps(),
    open_Dangerous_Good_Dialog: props<{
      dangerousGood: DangerousGood | null;
    }>(),
    add_Dangerous_Good: props<{ dangerousGood: DangerousGood }>(),
    add_Dangerous_Good_Success: props<{
      dangerousGood: DangerousGood;
      successMessage: string;
    }>(),
    add_Dangerous_Good_Failure: errorProps(),
    edit_Dangerous_Good: props<{
      dangerousGoodId: string;
      dangerousGood: DangerousGood;
    }>(),
    edit_Dangerous_Good_Success: props<{
      dangerousGood: DangerousGood;
      successMessage: string;
    }>(),
    edit_Dangerous_Good_Failure: errorProps(),

    load_Dangerous_Goods_By_Location_Id: props<{ locationId: string }>(),
    load_Dangerous_Goods_By_Location_Id_Success: props<{
      dangerousGoods: DangerousGood[];
    }>(),
    load_Dangerous_Goods_By_Location_Id_Error: errorProps(),
  },
});

import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SearchQuery } from '../../../../../../../components/src/lib/interfaces/search-query.interface';
import { Unit } from '../../../interfaces/unit.interface';
import { errorProps } from '../../../../../../../components/src/lib/functions/utility.functions';

export const UnitActions = createActionGroup({
  source: 'Unit',
  events: {
    update_Unit_Queries: props<{ query: SearchQuery }>(),
    load_Units: emptyProps(),
    load_Units_Success: props<{ units: Unit[] }>(),
    load_Units_Failure: errorProps(),
    remove_Unit: props<{ id: string }>(),
    remove_Unit_Success: props<{ unit: Unit; successMessage: string }>(),
    remove_Unit_Failure: errorProps(),
    open_Unit_Dialog: props<{ unit: Unit | null }>(),
    add_Unit: props<{ unit: Unit }>(),
    add_Unit_Success: props<{ unit: Unit; successMessage: string }>(),
    add_Unit_Failure: errorProps(),
    edit_Unit: props<{ unitId: string; unit: Unit }>(),
    edit_Unit_Success: props<{ unit: Unit; successMessage: string }>(),
    edit_Unit_Failure: errorProps(),
  },
});

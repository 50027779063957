<div
  *ngIf="dangerousGoodsState()"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  cdkDragBoundary=".cdk-overlay-container"
>
  <div mat-dialog-title>
    <h4>{{ isAdd ? 'Add ' : 'Edit ' }} Dangerous Good</h4>
  </div>
  <mat-dialog-content *lhaLoading="dangerousGoodsState().loading.createEdit">
    <form [formGroup]="form" (ngSubmit)="saveDangerousGood()">
      <div class="form__block">
        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>Un Number</mat-label>
            <input matInput formControlName="unNo" />
            <mat-error *ngIf="form.controls.unNo.hasError('required')">
              Un Number is required.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form__box">
          <lha-multiselect
            formControlName="locationIds"
            bindValue="locationId"
            bindLabel="name"
            [items]="locations()"
            placeholder="Locations"
          >
          </lha-multiselect>
        </div>
      </div>
      <div class="form__block">
        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>Class</mat-label>
            <input matInput formControlName="class" />
            <mat-error *ngIf="form.controls.class.hasError('required')">
              Class is required.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>Subclass</mat-label>
            <input matInput formControlName="subClass" />
            <mat-error *ngIf="form.controls.subClass.hasError('required')">
              Subclass is required.
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="form__block">
        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>Proper Shipping Name</mat-label>
            <input matInput formControlName="properShippingName" />
            <mat-error
              *ngIf="form.controls.properShippingName.hasError('required')"
            >
              Proper Shipping Name is required.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form__box">
          <lha-single-select
            [options]="packingGroupOptions"
            formControlName="packingGroup"
            bindValue="value"
            bindLabel="label"
            placeholder="Packing Group"
          />
          <div
            *ngIf="
              form.controls.packingGroup.invalid &&
              form.controls.packingGroup.touched
            "
          >
            <mat-error *ngIf="form.controls.packingGroup.hasError('required')">
              Packing Group is required.
            </mat-error>
          </div>
        </div>
      </div>
      <mat-dialog-actions align="end">
        <button mat-raised-button color="primary" type="submit">
          {{ isAdd ? 'Add ' : 'Save ' }}
        </button>
        <button
          mat-raised-button
          color="warn"
          type="button"
          mat-dialog-close=""
        >
          Cancel
        </button>
      </mat-dialog-actions>
    </form>
  </mat-dialog-content>
</div>

import { Injectable } from '@angular/core';
import { User } from 'libs/auth/src/lib/interfaces/user.interface';

@Injectable({
  providedIn: 'root',
})
export class RoleCheckService {
  hasOnlyCliRoleInPlan(user: User | undefined): boolean {
    if (user !== null || user !== undefined) {
      const planRole = user!.roles.find((role) => role.application === 'plan');
      return planRole
        ? planRole.roles.length === 1 && planRole.roles.includes('CLI')
        : false;
    }
    return false;
  }

  doesUserHaveFlowPermissions(user: User | undefined): boolean {
    if (user !== null || user !== undefined) {
      const flowRole = user!.roles.find((role) => role.application === 'flow');
      return flowRole ? flowRole.roles.length > 0 : false;
    }
    return false;
  }

  hasOnlyCliRoleInRequest(user: User | undefined): boolean {
    if (user !== null || user !== undefined) {
      const planRole = user!.roles.find(
        (role) => role.application === 'request'
      );
      return planRole
        ? planRole.roles.length === 1 && planRole.roles.includes('CLI')
        : false;
    }
    return false;
  }

  checkUserApplicationRole(user: User | undefined, application: string, role: string): boolean {
    if (user !== null || user !== undefined) {
      const userRole = user!.roles.find(
        (role) => role.application === application
      );
      return userRole ? userRole.roles.includes(role) : false;
    }
    return false;
  }
}

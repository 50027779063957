import {ActivityCategoryState} from "../../interfaces/activity-category-state.interface";
import {maintenanceInitialState} from "./maintenance.state";

export const activityCategoryInitialState: ActivityCategoryState = {
  activityCategories: [],
  activityCategoryTypeList: [],
  activityCategoryType: '',
  isHidden: false,
  ...maintenanceInitialState,
}

import { MaintenanceState } from '../../interfaces/maintenance-state.interface';

export const maintenanceInitialState: MaintenanceState = {
  query: {
    searchTerm: '',
    direction: '',
    active: '',
    pageSize: 10,
    pageIndex: 0,
    length: 0,
  },
  loading: {
    list: false,
    createEdit: false,
    export: false,
  },
  error: {
    list: '',
  },
};

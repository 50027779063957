import { createFeature, createReducer, createSelector } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { squadInitialState } from '../states/squad.state'; // Assuming you have this state
import { SquadActions } from '../actions/squad.actions';

export const squadsReducer = createReducer(
  squadInitialState,
  immerOn(SquadActions.load_Squads, (state) => {
    state.loading.list = true;
  }),
  immerOn(SquadActions.update_Squad_Queries, (state, { query }) => {
    state.query = query;
    state.loading.list = true;
  }),
  immerOn(SquadActions.load_Squads_Success, (state, { squads }) => {
    state.squads = squads;
    state.loading.list = false;
  }),
  immerOn(SquadActions.load_Squads_By_Location_Success, (state, { squads }) => {
    state.squadsByLocation = squads;
  }),
  immerOn(
    SquadActions.load_Squads_Failure,
    SquadActions.remove_Squad_Success,
    SquadActions.remove_Squad_Failure,
    (state) => {
      state.loading.list = false;
    }
  ),
  immerOn(SquadActions.add_Squad, SquadActions.edit_Squad, (state) => {
    state.loading.createEdit = true;
  }),
  immerOn(
    SquadActions.add_Squad_Success,
    SquadActions.add_Squad_Failure,
    SquadActions.edit_Squad_Success,
    SquadActions.edit_Squad_Failure,
    (state) => {
      state.loading.createEdit = false;
    }
  )
);

export const squadsFeature = createFeature({
  name: 'squads',
  reducer: squadsReducer,
});

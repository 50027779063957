import {
  Component,
  computed,
  DestroyRef,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { Subject } from 'rxjs';
import {
  getDefaultTime,
  timeAfter,
  timeBefore,
} from '../../../../../../../../libs/components/src/lib/functions/utility.functions';
import { AssetMobileWellActions } from '../../../../../../../../libs/services/src/lib/services/maintenance/store/actions/assets-mobile-well.actions';
import { CellTemplateDirective } from '../../../../../../../../libs/components/src/lib/components/table/cell-template.directive';
import { CustomChipComponent } from '../../../../../../../../libs/components/src/lib/components/custom-chip/custom-chip.component';
import { AsyncPipe, DatePipe, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TableComponent } from '../../../../../../../../libs/components/src/lib/components/table/table.component';
import { assetMobileWellFeature } from '../../../../../../../../libs/services/src/lib/services/maintenance/store/features';
import { Column } from '../../../../../../../../libs/components/src/lib/components/table/column.model';
import { AssetMobileWell } from '../../../../../../../../libs/services/src/lib/services/maintenance/interfaces/asset-mobile-well.interface';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {
  NGX_MAT_DATE_FORMATS,
  NgxMatDatetimePickerModule,
} from '@angular-material-components/datetime-picker';
import { SingleSelectComponent } from '../../../../../../../../libs/components/src/lib/components/single-select/single-select.component';
import { confirmActions } from 'libs/components/src/lib/store/confirm.actions';
import { Asset } from '../../../../../../../../libs/services/src/lib/services/maintenance/interfaces/asset.interface';
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { NgxMatDatepickerInputEvent } from '@angular-material-components/datetime-picker/lib/datepicker-input-base';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TimezoneService } from 'libs/services/src/lib/services/timezone/timezone.service';
import moment from 'moment';

export const TIME_FORMAT = {
  parse: {
    dateInput: 'DD-MM-YYYY, HH:mm',
  },
  display: {
    dateInput: 'DD-MM-YYYY, HH:mm',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'MMMM',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'lha-asset-mobile-well',
  standalone: true,
  imports: [
    CellTemplateDirective,
    CustomChipComponent,
    DatePipe,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    NgIf,
    TableComponent,
    AsyncPipe,
    FormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    NgxMatDatetimePickerModule,
    ReactiveFormsModule,
    SingleSelectComponent,
    DatePipe,
    CdkDrag,
    CdkDragHandle,
  ],
  providers: [
    {
      provide: NGX_MAT_DATE_FORMATS,
      useValue: TIME_FORMAT,
    },
    DatePipe,
  ],
  templateUrl: './asset-mobile-well.component.html',
  styleUrls: ['./asset-mobile-well.component.scss'],
})
export class AssetMobileWellComponent implements OnInit, OnDestroy {
  private readonly destroyRef = inject(DestroyRef);
  dialogRef = inject(MatDialogRef<AssetMobileWellComponent>);
  data: { id: string; asset: Asset } = inject(MAT_DIALOG_DATA);
  store = inject(Store);
  action = inject(Actions);
  timezoneService = inject(TimezoneService);
  assetMobileWellState = this.store.selectSignal(
    assetMobileWellFeature.selectAssetMobileWellState
  );
  mobileWellList = this.store.selectSignal(
    assetMobileWellFeature.selectAssetMobileWells
  );
  wellAssetList = this.store.selectSignal(
    assetMobileWellFeature.selectAssetByType(['WEL'])
  );
  unsubscribe: Subject<boolean> = new Subject();
  minDifference = 1;
  defaultTime = getDefaultTime({ sec: 0 });
  datePipe = inject(DatePipe);
  columns: Column<AssetMobileWell>[] = [
    new Column('wellName', 'Well Name', { sortHeader: false }),
    new Column('startDateTime', 'Start Date', { sortHeader: false }),
    new Column('endDateTime', 'End Date', { sortHeader: false }),
    new Column('mobileWellId', 'Actions', { sortHeader: false }),
  ];
  editRows: Map<string, FormGroup> = new Map();
  formControl = new FormControl();

  transformedMobileWellsSignal = computed(() =>
    this.assetMobileWellState().assetMobileWells.map((mobileWell) => ({
      ...mobileWell,
      startDateTime: this.timezoneService.fromUTC(
        new Date(mobileWell.startDateTime!)
      ),
      endDateTime: mobileWell.endDateTime
        ? this.timezoneService.fromUTC(new Date(mobileWell.endDateTime))
        : null,
    }))
  );

  ngOnInit(): void {
    this.store.dispatch(
      AssetMobileWellActions.set_Asset_Mobile_Well_Id({ id: this.data.id })
    );
    this.subToActions();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  private subToActions(): void {
    this.action
      .pipe(
        ofType(AssetMobileWellActions.add_Asset_Mobile_Well_Success),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.editRows.delete('add');
      });
    this.action
      .pipe(
        ofType(AssetMobileWellActions.edit_Asset_Mobile_Well_Success),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((res) => {
        this.editRows.delete(res.assetMobileWell.mobileWellId);
      });
  }

  changeStartDate(event: NgxMatDatepickerInputEvent<Date>, id: string): void {
    this.getFg(id)?.patchValue({
      timeBefore: timeBefore(event.value, { min: this.minDifference }),
    });
  }

  changeEndDDate(event: NgxMatDatepickerInputEvent<Date>, id: string): void {
    this.getFg(id)?.patchValue({
      timeAfter: timeAfter(event.value, { min: this.minDifference }),
    });
  }

  addMobileWell(): void {
    if (this.editRows.get('add')) {
      return;
    }
    this.store.dispatch(AssetMobileWellActions.add_Asset_Mobile_Well_Row());
    this.editRows.set('add', this.createAssetFormGroup());
  }

  saveMobileWell(assetMobileWell: AssetMobileWell): void {
    if (this.editRows.get(assetMobileWell.mobileWellId)?.invalid) {
      this.editRows.get(assetMobileWell.mobileWellId)?.markAllAsTouched();
      return;
    }

    const model = {
      ...this.editRows.get(assetMobileWell.mobileWellId)?.value,
      mobileId: this.data.id,
    };

    model.startDateTime = this.timezoneService.toUTC(
      new Date(model.startDateTime)
    );
    model.endDateTime = this.timezoneService.toUTC(new Date(model.endDateTime));

    const isEndDate = model.endDateTime;

    if (assetMobileWell.mobileWellId === 'add') {
      if (this.mobileWellList().length === 1) {
        this.store.dispatch(
          confirmActions.request_Confirmation({
            titles: {
              titles: isEndDate
                ? [
                    `${this.data.asset.name} will be at
              ${this.getAssetNameById(model.wellId, this.wellAssetList())} from
              ${this.formatDate(model.startDateTime)} to ${this.formatDate(
                      model.endDateTime
                    )}.`,
                    'You will not be able to edit the well history for this asset during or before this period.',
                    'Do you want to continue?',
                  ]
                : [
                    `${this.data.asset.name} will be at
              ${this.getAssetNameById(model.wellId, this.wellAssetList())} from
              ${this.formatDate(model.startDateTime)}.`,
                    'You cannot add any history that precedes this date.',
                    'Do you want to continue?',
                  ],
              btnConfirm: 'Yes',
              btnReject: 'No',
            },
            confirm: AssetMobileWellActions.add_Asset_Mobile_Well({
              assetMobileWell: model,
            }),
          })
        );
        return;
      }

      const lastMobileWell =
        this.mobileWellList()[this.mobileWellList().length - 1];

      if (!lastMobileWell.endDateTime) {
        this.store.dispatch(
          confirmActions.request_Confirmation({
            titles: {
              titles: [
                `The end date of ${this.data.asset.name}
              being at ${lastMobileWell.wellName} from ${this.formatDate(
                  lastMobileWell.startDateTime ?? ''
                )}
              does not currently have an end date.`,
                `The system will automatically set the end date to ${this.formatDate(
                  model.startDateTime ?? ''
                )}.`,
                'Do you want to continue?',
              ],
              btnConfirm: 'Yes',
              btnReject: 'No',
            },
            confirm: AssetMobileWellActions.add_Asset_Mobile_Well({
              assetMobileWell: model,
            }),
          })
        );
        return;
      }

      this.store.dispatch(
        AssetMobileWellActions.add_Asset_Mobile_Well({
          assetMobileWell: model,
        })
      );
    } else {
      this.store.dispatch(
        AssetMobileWellActions.edit_Asset_Mobile_Well({
          id: assetMobileWell.mobileWellId,
          assetMobileWell: model,
        })
      );
    }
  }

  cancelMobileWell(assetMobileWell: AssetMobileWell): void {
    this.editRows.delete(assetMobileWell.mobileWellId);
    if (assetMobileWell.mobileWellId === 'add') {
      this.store.dispatch(
        AssetMobileWellActions.remove_Asset_Mobile_Well_Row()
      );
    }
  }

  editMobileWell(assetMobileWell: AssetMobileWell): void {
    this.editRows.set(
      assetMobileWell.mobileWellId,
      this.createAssetFormGroup(assetMobileWell)
    );
  }

  removeMobileWell(assetMobileWell: AssetMobileWell): void {
    this.store.dispatch(
      confirmActions.request_Confirmation({
        titles: {
          title: 'Do you want to remove this Mobile Well Asset?',
          btnConfirm: 'Yes Delete',
        },
        confirm: AssetMobileWellActions.remove_Asset_Mobile_Well({
          id: assetMobileWell.mobileWellId,
        }),
      })
    );
  }

  createAssetFormGroup(assetMobileWell?: AssetMobileWell): FormGroup {
    return new FormGroup({
      wellId: new FormControl(assetMobileWell?.wellId, [Validators.required]),
      startDateTime: new FormControl(
        moment(assetMobileWell?.startDateTime, 'DD-MM-YYYY HH:mm'),
        [Validators.required]
      ),
      endDateTime: new FormControl(
        moment(assetMobileWell?.endDateTime, 'DD-MM-YYYY HH:mm'),
        []
      ),
      timeAfter: new FormControl(
        {
          value: timeAfter(assetMobileWell?.endDateTime ?? null, {
            min: this.minDifference,
          }),
          disabled: true,
        },
        []
      ),
      timeBefore: new FormControl(
        {
          value: timeBefore(assetMobileWell?.startDateTime ?? null, {
            min: this.minDifference,
          }),
          disabled: true,
        },
        []
      ),
    });
  }

  getFg(id: string): FormGroup {
    return this.editRows.get(id) ?? this.createAssetFormGroup();
  }

  private getAssetNameById(id: string, locArr: Asset[]): string {
    return locArr.find((item) => item.assetId === id)?.name ?? '';
  }

  private formatDate(date: string | Date, skipTime = false): string {
    const dateFormat = skipTime ? 'dd/MM/yy' : 'dd/MM/yy HH:mm';
    return this.datePipe.transform(date, dateFormat) || '';
  }
}

import { inject, Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { Cargo } from './interfaces/cargoes.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Location } from './interfaces/location.interface';
import { Vendor } from './interfaces/vendor.interface';
import { CargoUnitType } from './interfaces/cargo-unit-type.interface';

@Injectable({ providedIn: 'root' })
export class CargoService {
  private readonly http = inject(HttpClient);

  loadCargos(
    vendorId: string | undefined,
    showPendingCargoes: boolean,
    from?: string | null,
    to?: string | null
  ): Observable<Cargo[]> {
    let params = new HttpParams()
      .set('returnApprovedOnly', false)
      .set('vendorId', vendorId ?? '')
      .set('showPendingCargoes', showPendingCargoes)
      .set('from', from ?? '')
      .set('to', to ?? '');

    return this.http.get<Cargo[]>(`/api/cargo`, { params });
  }

  loadCargosList(): Observable<Cargo[]> {
    return this.http.get<Cargo[]>('/api/Cargo');
  }

  getCargoDescriptions(): Observable<CargoUnitType[]> {
    return this.http.get<CargoUnitType[]>('/api/Cargo/getCargoDescriptions');
  }

  loadCargosListByLocation(
    locationId: string | undefined
  ): Observable<Cargo[]> {
    if (locationId) {
      return this.http.get<Cargo[]>(`/api/Cargo/bylocation/${locationId}`);
    }
    return EMPTY;
  }

  loadCargosListByLocationWithoutOneOff(
    locationId: string | undefined
  ): Observable<Cargo[]> {
    if (locationId) {
      return this.http.get<Cargo[]>(
        `/api/Cargo/bylocationwithoutoneoff/${locationId}`
      );
    }
    return EMPTY;
  }

  removeCargo(id: string): Observable<Cargo> {
    return this.http.delete<Cargo>(`/api/Cargo/${id}`);
  }

  addCargo(Cargo: Cargo): Observable<Cargo> {
    return this.http.post<Cargo>('/api/Cargo', Cargo);
  }

  editCargo(id: string, Cargo: Cargo): Observable<Cargo> {
    return this.http.put<Cargo>(`/api/Cargo/${id}`, Cargo);
  }

  loadLocationList(): Observable<Location[]> {
    return this.http.get<Location[]>('/api/location');
  }

  approveCargo(id: string): Observable<any> {
    return this.http.post<Cargo>(`/api/Cargo/approveCargo/${id}`, {});
  }

  loadVendors(): Observable<Vendor[]> {
    return this.http.get<Vendor[]>('/api/Vendor');
  }
}

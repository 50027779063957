import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { Subject, takeUntil } from 'rxjs';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { BlockingActivity } from '../../../../../../../../libs/services/src/lib/services/maintenance/interfaces/blocking-activity.interface';
import { blockingActivityFeature } from '../../../../../../../../libs/services/src/lib/services/maintenance/store/features/blocking-activity.feature';
import { BlockingActivityActions } from '../../../../../../../../libs/services/src/lib/services/maintenance/store/actions/blocking-activity.actions';
import { AsyncPipe, NgIf } from '@angular/common';
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { LoadingDirective } from '../../../../../../../../libs/components/src/lib/directives/loading.directive';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SingleSelectComponent } from '../../../../../../../../libs/components/src/lib/components/single-select/single-select.component';
import { PlanModeTypes } from '../../../../../../../../libs/services/src/lib/services/maintenance/locations/enums/location-plan-mode-types/location-plan-types';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  standalone: true,
  templateUrl: 'blocking-activity-add-edit.component.html',
  styleUrls: ['blocking-activity-add-edit.component.scss'],
  selector: 'blocking-activity-add-edit.component',
  imports: [
    AsyncPipe,
    CdkDrag,
    CdkDragHandle,
    FormsModule,
    LoadingDirective,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    NgIf,
    ReactiveFormsModule,
    SingleSelectComponent,
  ],
})
export class BlockingActivityAddEditComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  dialogRef = inject(MatDialogRef<BlockingActivityAddEditComponent>);
  data: { blockingActivity: BlockingActivity } = inject(MAT_DIALOG_DATA);
  store = inject(Store);
  action = inject(Actions);
  blockingActivityState = this.store.selectSignal(
    blockingActivityFeature.selectBlockingActivitiesState
  );
  isAdd = true;
  planModeTypes = PlanModeTypes;
  blockingActivity: BlockingActivity | null = null;
  form = new FormGroup({
    name: new FormControl<string>('', [Validators.required]),
  });

  ngOnInit(): void {
    this.subCloseDialog();
    this.initAddEdit();
  }

  private initAddEdit(): void {
    this.isAdd = !this.data.blockingActivity;
    if (!this.isAdd) {
      this.blockingActivity = this.data.blockingActivity;
      this.patchForm(this.blockingActivity);
    }
  }

  private subCloseDialog(): void {
    this.action
      .pipe(
        ofType(
          BlockingActivityActions.add_Blocking_Activity_Success,
          BlockingActivityActions.edit_Blocking_Activity_Success
        )
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  private patchForm(blockingActivity: BlockingActivity): void {
    this.form.patchValue({
      name: blockingActivity.name,
    });
  }

  saveBlockingActivity(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    const model = {
      ...this.form.value,
    } as BlockingActivity;

    if (this.isAdd) {
      this.store.dispatch(
        BlockingActivityActions.add_Blocking_Activity({
          blockingActivity: model,
        })
      );
    } else {
      this.store.dispatch(
        BlockingActivityActions.edit_Blocking_Activity({
          blockingActivity: model,
          id: this.blockingActivity!.blockingActivityId,
        })
      );
    }
  }
}

import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { authFeature } from '../../../../../libs/auth/src/lib/store/auth.feature';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { Environment } from 'env';

export const apiRequestInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  const environment = inject(Environment);
  const store = inject(Store);
  const router = inject(Router);
  const doc = inject(DOCUMENT);

  const appName = window.location.pathname.split('/')[1];

  if (req.url.startsWith('/api/')) {
    const relativUrl = req.url.substring(5);
    let urlBase = environment.apiUrl;

    // Handle mocked endpoints
    if (environment.mockedEndPoints?.length) {
      for (let i = 0; i < environment.mockedEndPoints.length; i++) {
        const ep = environment.mockedEndPoints[i];
        if (relativUrl.startsWith(ep)) {
          urlBase = environment.mockApiUrl;
          break;
        }
      }
    }

    return store.select(authFeature.selectIsLoggedIn).pipe(
      take(1),
      switchMap(isloggedIn => {
        if (isloggedIn) {
          return authService.getAccessTokenSilently().pipe(
            switchMap(token => {
              const request = req.clone({
                setHeaders: { Authorization: `Bearer ${token}`, Application: `${appName}` },
                url: `${urlBase}/api/${relativUrl}`,
              });

              return next(request);
            }),
          );
        } else {
          authService.logout({ logoutParams: { returnTo: doc.location.origin } });
          router.navigate(['/login']);
          return new Observable<never>();
        }
      }),
    );
  }

  // Fallback for non-API requests
  return next(req);
};

import { ActivityType } from 'libs/services/src/lib/services/maintenance/enums/activity-type.enum';

export const ActivityTypes = [
  {
    value: ActivityType.DryBulk,
    name: 'Dry Bulk',
  },
  {
    value: ActivityType.WetBulk,
    name: 'Wet Bulk',
  },
  {
    value: ActivityType.Personnel,
    name: 'Personnel',
  },
  {
    value: ActivityType.Lifting,
    name: 'Lifting',
  },
];

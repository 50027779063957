import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Employee } from './interfaces/employee.interface';

@Injectable({ providedIn: 'root' })
export class EmployeeService {
  private readonly http = inject(HttpClient);

  loadEmployees(): Observable<Employee[]> {
    return this.http.get<Employee[]>('/api/employee');
  }

  loadEmployee(id: string): Observable<Employee> {
    return this.http.get<Employee>(`/api/employee/${id}`);
  }

  removeEmployee(id: string): Observable<Employee> {
    return this.http.delete<Employee>(`/api/employee/${id}`);
  }

  addEmployee(employee: Employee): Observable<Employee> {
    return this.http.post<Employee>('/api/employee', employee);
  }

  editEmployee(id: string, employee: Employee): Observable<Employee> {
    return this.http.put<Employee>(`/api/employee/${id}`, employee);
  }
}

import {AreaState} from "../../interfaces/area-state.interface";
import {maintenanceInitialState} from "./maintenance.state";

export const areaInitialState: AreaState = {
  areas: [],
  areaId: '',
  areasByLocationId: [],
  ...maintenanceInitialState,
  loading: {
    list: false,
    createEdit: false
  }
}

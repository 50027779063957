<div [ngClass]="isNotTable ? 'search-list' : 'search'">
  <mat-form-field class="form-field" appearance="outline" subscriptSizing="dynamic">
    <mat-icon class="search-icon" *ngIf="!value" matPrefix>search</mat-icon>
    <input
      matInput
      placeholder="Search..."
      [value]="value"
      (input)="emitSearch($event)"
    />
    <mat-icon
      class="clear-search"
      *ngIf="value"
      matSuffix
      (click)="clearSearch()"
      >close</mat-icon
    >
  </mat-form-field>
</div>

import { HttpHeaders } from "@angular/common/http";

export function openFileSelector(
  accept?: string,
  multiple?: boolean
): Promise<Event> {
  return new Promise<Event>((resolve, reject) => {
    const oldUploader = document.getElementById('file-selector-input');
    if (oldUploader) {
      document.body.removeChild(oldUploader);
    }
    const uploadInput = document.createElement('input');
    uploadInput.id = 'file-selector-input';
    uploadInput.type = 'file';
    uploadInput.accept = accept || '*';
    uploadInput.multiple = !!multiple;
    uploadInput.hidden = true;

    document.body.appendChild(uploadInput);

    uploadInput.onchange = (event: Event) => {
      resolve(event);
    };
    uploadInput.onerror = (event: any) => {
      reject(event.target.error);
    };
    uploadInput.click();
  });
}

export function filePatternValidator(file: any, validation: string): boolean {
  const regex = new RegExp(validation);
  let value = '';
  if (file.name) {
    value = file.name.toLowerCase();
  } else if (file.fileName) {
    value = file.fileName.toLowerCase();
  } else {
    value = file.type.toLowerCase();
  }

  if (regex.test(value)) {
    return true;
  }

  return false;
}

export function convertBlobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64data = reader.result as string;
      resolve(base64data);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(blob);
  });
}

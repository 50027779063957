import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { VendorsActions } from '../../../../../../../../libs/services/src/lib/services/maintenance/store/actions/vendor.actions';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { locationsFeature } from '../../../../../../../../libs/services/src/lib/services/maintenance/store/features';
import { LoadingDirective } from '../../../../../../../../libs/components/src/lib/directives/loading.directive';
import { SingleSelectComponent } from '../../../../../../../../libs/components/src/lib/components/single-select/single-select.component';
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Vendor } from 'libs/services/src/lib/services/maintenance/interfaces/vendor.interface';
import { vendorsFeature } from 'libs/services/src/lib/services/maintenance/store/features/vendors.feature';

@Component({
  selector: 'lha-vendors-add-edit',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    NgForOf,
    AsyncPipe,
    LoadingDirective,
    NgIf,
    SingleSelectComponent,
    CdkDrag,
    CdkDragHandle,
  ],
  templateUrl: './vendors-add-edit.component.html',
  styleUrls: ['./vendors-add-edit.component.scss'],
})
export class VendorsAddEditComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  dialogRef = inject(MatDialogRef<VendorsAddEditComponent>);
  data: { vendor: Vendor } = inject(MAT_DIALOG_DATA);
  store = inject(Store);
  action = inject(Actions);
  vendorsState = this.store.selectSignal(vendorsFeature.selectVendorsState);
  locations = this.store.selectSignal(locationsFeature.selectLocations);
  isAdd = true;
  vendor!: Vendor;
  form = new FormGroup({
    vendorName: new FormControl<string>('', [Validators.required]),
    locationId: new FormControl<string>('', [Validators.required]),
    shortName: new FormControl<string>(''),
    longName: new FormControl<string>(''),
    address: new FormControl<string>(''),
    postCode: new FormControl<string>(''),
    city: new FormControl<string>(''),
    country: new FormControl<string>(''),
  });

  ngOnInit(): void {
    this.subCloseDialog();
    this.initAddEdit();
  }

  private initAddEdit(): void {
    this.isAdd = !this.data.vendor;
    if (!this.isAdd) {
      this.vendor = this.data.vendor;
      this.pathForm(this.vendor);
    }
  }

  private subCloseDialog(): void {
    this.action
      .pipe(
        ofType(
          VendorsActions.add_Vendor_Success,
          VendorsActions.edit_Vendor_Success
        )
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  private pathForm(vendor: Vendor): void {
    this.form.patchValue(vendor);
  }

  saveVendor(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const model = {
      ...this.form.value,
    } as Vendor;

    if (this.isAdd) {
      this.store.dispatch(VendorsActions.add_Vendor({ vendor: model }));
    } else {
      this.store.dispatch(
        VendorsActions.edit_Vendor({
          vendor: model,
          vendorId: this.vendor.vendorId,
        })
      );
    }
  }
}

import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Credentials } from '../interfaces/credentials.interface';
import { UserToken } from '../interfaces/user-token.interface';
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private readonly http = inject(HttpClient);
  login(userLogin: Credentials): Observable<UserToken> {
    return this.http.post<UserToken>('/api/auth/token', userLogin);
  }

  refreshToken(): Observable<UserToken> {
    return this.http.get<UserToken>('/api/auth/refreshToken');
  }

  deleteCookies(): Observable<any> {
    return this.http.get<any>('/api/auth/deleteCookies');
  }
}

import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { catchError, map, mergeMap, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivityService } from '../../activity.service';
import { ActivityConfigActions } from '../actions/activity-config.actions';
import { Activity } from '../../interfaces/activity.interface';
import { MatDialog } from '@angular/material/dialog';
import { switchMap, tap } from 'rxjs/operators';
import { ActivityConfigAddEditComponent } from '../../../../../../../../apps/allocate/src/app/maintenance/shared/components/activity-config-add-edit/activity-config-add-edit.component';
import { Store } from '@ngrx/store';
import { activityConfigFeature } from '../features';
import { DialogOptions } from '../../../config/dialog-options';
import { ConstantService } from '../../../constant.service';
import { Constant } from 'libs/services/src/lib/services/shared/constant.interface';
import { FileService } from '../../../file.service';

export const loadActivityConfigPage = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(ActivityConfigActions.load_Activity_Config_Page),
      switchMap(() =>
        of(
          ActivityConfigActions.load_Activity_Configs(),
          ActivityConfigActions.load_Chargeability_List(),
          ActivityConfigActions.load_Activity_Type_List()
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const loadActivityConfigs = createEffect(
  (
    actions = inject(Actions),
    store = inject(Store),
    activityService = inject(ActivityService)
  ) => {
    return actions.pipe(
      ofType(
        ActivityConfigActions.load_Activity_Configs,
        ActivityConfigActions.update_Activity_Config_Queries,
        ActivityConfigActions.remove_Activity_Config_Success,
        ActivityConfigActions.add_Activity_Config_Success,
        ActivityConfigActions.edit_Activity_Config_Success
      ),
      concatLatestFrom(() => store.select(activityConfigFeature.selectQuery)),
      map(([, res]) => res),
      mergeMap((action) =>
        activityService.loadActivities(action).pipe(
          map((res: Activity[]) =>
            ActivityConfigActions.load_Activity_Configs_Success({
              activities: res,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(ActivityConfigActions.load_Activity_Configs_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const removeActivityConfig = createEffect(
  (actions = inject(Actions), activityService = inject(ActivityService)) => {
    return actions.pipe(
      ofType(ActivityConfigActions.remove_Activity_Config),
      mergeMap((action) =>
        activityService.removeActivity(action.id).pipe(
          map((res: Activity) =>
            ActivityConfigActions.remove_Activity_Config_Success({
              activity: res,
              successMessage: 'Activity Config removed successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              ActivityConfigActions.remove_Activity_Config_Failure({
                error: error,
              })
            )
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const loadChargeabilityList = createEffect(
  (actions = inject(Actions), constantService = inject(ConstantService)) => {
    return actions.pipe(
      ofType(ActivityConfigActions.load_Chargeability_List),
      mergeMap(() =>
        constantService.loadChargeabilityTypes().pipe(
          map((res: Constant[]) =>
            ActivityConfigActions.load_Chargeability_List_Success({
              chargeabilityList: res,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(ActivityConfigActions.load_Chargeability_List_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const loadActivityTypeList = createEffect(
  (actions = inject(Actions), constantService = inject(ConstantService)) => {
    return actions.pipe(
      ofType(ActivityConfigActions.load_Activity_Type_List),
      mergeMap(() =>
        constantService.loadActivityTypeList().pipe(
          map((res: Constant[]) =>
            ActivityConfigActions.load_Activity_Type_List_Success({
              activityTypeList: res,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(ActivityConfigActions.load_Activity_Type_List_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const addActivityConfig = createEffect(
  (actions = inject(Actions), activityService = inject(ActivityService)) => {
    return actions.pipe(
      ofType(ActivityConfigActions.add_Activity_Config),
      mergeMap((action) =>
        activityService.addActivity(action.activity).pipe(
          map((res: Activity) =>
            ActivityConfigActions.add_Activity_Config_Success({
              activity: res,
              successMessage: 'Activity Config added successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              ActivityConfigActions.add_Activity_Config_Failure({
                error: error,
              })
            )
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const editActivityConfig = createEffect(
  (actions = inject(Actions), activityService = inject(ActivityService)) => {
    return actions.pipe(
      ofType(ActivityConfigActions.edit_Activity_Config),
      mergeMap((action) =>
        activityService.editActivity(action.activityId, action.activity).pipe(
          map((res: Activity) =>
            ActivityConfigActions.edit_Activity_Config_Success({
              activity: res,
              successMessage: 'Activity Config edited successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              ActivityConfigActions.edit_Activity_Config_Failure({
                error: error,
              })
            )
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const openActivityConfigDialog = createEffect(
  (
    actions = inject(Actions),
    dialog = inject(MatDialog),
    dialogOptions = inject(DialogOptions)
  ) => {
    return actions.pipe(
      ofType(ActivityConfigActions.open_Activity_Config_Dialog),
      tap((action) => {
        dialog.open(ActivityConfigAddEditComponent, {
          ...dialogOptions,
          data: {
            activity: action.activity,
          },
        });
      })
    );
  },
  {
    functional: true,
    dispatch: false,
  }
);

export const exportActivitiesEffect = createEffect(
  (
    actions = inject(Actions),
    activityService = inject(ActivityService),
    fileService = inject(FileService)
  ) => {
    return actions.pipe(
      ofType(ActivityConfigActions.export_Activities),
      mergeMap(() =>
        activityService.exportActivities().pipe(
          map((res: ArrayBuffer) => {
            fileService.downloadFile(res, 'Activities');
            return ActivityConfigActions.export_Activities_Success();
          }),
          catchError((error: HttpErrorResponse) =>
            of(ActivityConfigActions.export_Activities_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

<div class="asset-mobile-well dialog-container"
     *ngIf="vm$ | async as vm"
     cdkDrag
     cdkDragRootElement=".cdk-overlay-pane"
     cdkDragHandle
     cdkDragBoundary=".cdk-overlay-container">
  <lha-table
    [columns]="columns"
    [data]="vm.areas"
    [loading]="vm.loading.createEdit"
    [canExportTable]="false"
    tableName="Area">
    <h2 tableHeaderLeft>Add Area to {{ data.site.name }}</h2>
    <ng-container tableHeaderRightBtn>
      <button mat-raised-button (click)="addArea()" color="primary">
        Add
      </button>
      <button
        mat-icon-button
        color="warn"
        mat-dialog-close=""
        class="asset-close">
        <mat-icon>close</mat-icon>
      </button>
    </ng-container>

    <ng-template lhaCellTemplate="name" let-item>
      <ng-container *ngIf="!editRows.get(item.areaId)">
        {{ item.name }}
      </ng-container>
      <ng-container *ngIf="editRows.get(item.areaId)" [formGroup]="getFg(item.areaId)">
        <mat-form-field appearance="outline">
          <mat-label>Area</mat-label>
          <input matInput formControlName="name" placeholder="Enter Area Type" />
          <mat-error *ngIf="getFg(item.areaId)?.get('name')?.invalid && getFg(item.areaId)?.get('name')?.touched">
            Area is required.
          </mat-error>
        </mat-form-field>
      </ng-container>
    </ng-template>

    <ng-template lhaCellTemplate="areaBlockingActivities" let-item>
      <ng-container *ngIf="!editRows.get(item.areaId)" >
        <lha-multiselect [items]="blockingActivities"></lha-multiselect>
      </ng-container>

      <ng-container *ngIf="editRows.get(item.areaId)">
        <lha-multiselect [items]="blockingActivities"></lha-multiselect>
      </ng-container>
    </ng-template>

    <ng-template lhaCellTemplate="createdDate" let-item>
      {{ item.createdByName }} {{ item.createdDate | date : 'dd-MM-yyyy' }}
    </ng-template>

    <ng-template lhaCellTemplate="areaId" let-item>
      <button
        mat-icon-button
        color="primary"
        class="btn-save"
        *ngIf="editRows.get(item.areaId)"
        (click)="saveArea(item)">
        <mat-icon>check_circle</mat-icon>
      </button>
      <button
        mat-icon-button
        color="warn"
        *ngIf="editRows.get(item.areaId)"
        (click)="cancelArea(item)">
        <mat-icon>cancel</mat-icon>
      </button>
      <button
        mat-icon-button
        color="primary"
        *ngIf="!editRows.get(item.areaId)"
        (click)="editArea(item)">
        <mat-icon>edit</mat-icon>
      </button>
      <button
        mat-icon-button
        color="warn"
        *ngIf="!editRows.get(item.areaId)"
        (click)="removeArea(item)">
        <mat-icon>delete</mat-icon>
      </button>
    </ng-template>
  </lha-table>
</div>

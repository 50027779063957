<div
  *ngIf="loadCellsState()"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  cdkDragBoundary=".cdk-overlay-container"
>
  <div mat-dialog-title>
    <h4>{{ isAdd ? 'Add ' : 'Edit ' }} Load Cell</h4>
  </div>
  <mat-dialog-content *lhaLoading="loadCellsState().loading.createEdit">
    <form [formGroup]="form" (ngSubmit)="saveLoadCell()">
      <div class="form__block">
        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>Name</mat-label>
            <input matInput formControlName="name" />
            <mat-error *ngIf="form.controls.name.hasError('required')">
              Load cell name is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form__box">
          <lha-single-select
            formControlName="locationId"
            bindValue="locationId"
            bindLabel="name"
            [options]="locations()"
            placeholder="Locations"
          >
          </lha-single-select>
        </div>
      </div>
      <div class="form__block">
        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>IP Address</mat-label>
            <input matInput formControlName="ipAddress" />
            <mat-error *ngIf="form.controls.ipAddress.hasError('required')">
              IP Address is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>ID</mat-label>
            <input matInput formControlName="id" />
            <mat-error *ngIf="form.controls.id.hasError('required')">
              ID is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="form__block">
        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>Data Tag</mat-label>
            <input matInput formControlName="dataTag" />
            <mat-error *ngIf="form.controls.dataTag.hasError('required')">
              Data Tag is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>Description</mat-label>
            <input matInput formControlName="description" />
            <mat-error *ngIf="form.controls.description.hasError('required')">
              Description is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="form_block">
        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>Port Number</mat-label>
            <input matInput formControlName="portNumber" />
            <mat-error *ngIf="form.controls.portNumber.hasError('required')">
              Port Number is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <mat-dialog-actions align="end">
        <button mat-raised-button color="primary" type="submit">
          {{ isAdd ? 'Add ' : 'Save ' }}
        </button>
        <button
          mat-raised-button
          color="warn"
          type="button"
          mat-dialog-close=""
        >
          Cancel
        </button>
      </mat-dialog-actions>
    </form>
  </mat-dialog-content>
</div>

import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivityCategoryActions } from '../actions/activity-category.actions';
import { activityCategoryFeature } from '../features/activity-category.feature';
import { catchError, map, mergeMap, of } from 'rxjs';
import { ActivityCategoryService } from '../../activity-category.service';
import { ActivityCategory } from '../../interfaces/activity-category.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import { DialogOptions } from '../../../config/dialog-options';
import { ActivityCategoryAddEditComponent } from '../../../../../../../../apps/allocate/src/app/maintenance/shared/components/activity-category-add-edit/activity-category-add-edit.component';
import { ActivityCategoryTypeActions } from '../actions/activity-category-type.actions';

export const loadActivityCategory = createEffect(
  (
    actions = inject(Actions),
    store = inject(Store),
    activityCategoryService = inject(ActivityCategoryService)
  ) => {
    return actions.pipe(
      ofType(
        ActivityCategoryActions.load_Activity_Category,
        ActivityCategoryActions.update_Activity_Category_Queries,
        ActivityCategoryActions.remove_Activity_Category_Success,
        ActivityCategoryActions.add_Activity_Category_Success,
        ActivityCategoryActions.edit_Activity_Category_Success,
        ActivityCategoryTypeActions.add_Activity_Category_Type_Success,
        ActivityCategoryTypeActions.edit_Activity_Category_Type_Success
      ),
      concatLatestFrom(() => store.select(activityCategoryFeature.selectQuery)),
      map(([, res]) => res),
      mergeMap((action) =>
        activityCategoryService.loadActivityCategories(action).pipe(
          map((res: ActivityCategory[]) =>
            ActivityCategoryActions.load_Activity_Category_Success({
              activityCategory: res,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              ActivityCategoryActions.load_Activity_Category_Failure({ error })
            )
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const removeActivityCategory = createEffect(
  (
    actions = inject(Actions),
    activityCategoryService = inject(ActivityCategoryService)
  ) => {
    return actions.pipe(
      ofType(ActivityCategoryActions.remove_Activity_Category),
      mergeMap((action) =>
        activityCategoryService.removeActivityCategory(action.id).pipe(
          map((res: ActivityCategory) =>
            ActivityCategoryActions.remove_Activity_Category_Success({
              activityCategory: res,
              successMessage: 'Activity Category removed successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              ActivityCategoryActions.remove_Activity_Category_Failure({
                error: error,
              })
            )
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const addActivityCategory = createEffect(
  (
    actions = inject(Actions),
    activityCategoryService = inject(ActivityCategoryService)
  ) => {
    return actions.pipe(
      ofType(ActivityCategoryActions.add_Activity_Category),
      mergeMap((action) =>
        activityCategoryService
          .addActivityCategory(action.activityCategory)
          .pipe(
            map((res: ActivityCategory) =>
              ActivityCategoryActions.add_Activity_Category_Success({
                activityCategory: res,
                successMessage: 'Activity Category added successfully!',
              })
            ),
            catchError((error: HttpErrorResponse) =>
              of(
                ActivityCategoryActions.add_Activity_Category_Failure({
                  error: error,
                })
              )
            )
          )
      )
    );
  },
  {
    functional: true,
  }
);

export const editActivityCategory = createEffect(
  (
    actions = inject(Actions),
    activityCategoryService = inject(ActivityCategoryService)
  ) => {
    return actions.pipe(
      ofType(ActivityCategoryActions.edit_Activity_Category),
      mergeMap((action) =>
        activityCategoryService
          .editActivityCategory(
            action.activityCategoryId,
            action.activityCategory
          )
          .pipe(
            map((res: ActivityCategory) =>
              ActivityCategoryActions.edit_Activity_Category_Success({
                activityCategory: res,
                successMessage: 'Activity Category edited successfully!',
              })
            ),
            catchError((error: HttpErrorResponse) =>
              of(
                ActivityCategoryActions.edit_Activity_Category_Failure({
                  error: error,
                })
              )
            )
          )
      )
    );
  },
  {
    functional: true,
  }
);

export const openActivityCategoryDialog = createEffect(
  (
    actions = inject(Actions),
    dialog = inject(MatDialog),
    dialogOptions = inject(DialogOptions)
  ) => {
    return actions.pipe(
      ofType(ActivityCategoryActions.open_Activity_Category_Dialog),
      tap((action) => {
        dialog.open(ActivityCategoryAddEditComponent, {
          ...dialogOptions,
          data: {
            activityCategory: action.activityCategory,
          },
        });
      })
    );
  },
  {
    functional: true,
    dispatch: false,
  }
);

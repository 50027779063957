import { ValidatorFn } from '@angular/forms';
import { AbstractControl } from '@angular/forms';
import { ValidationErrors } from '@angular/forms';

export function greaterThan(val: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    //control.value === 0 is necessary because if(control.value) will evaluate to false if control.value is 0
    if ((control.value || control.value === 0) && control.value <= val) {
      return { greaterThan: true };
    }

    return null;
  };
}

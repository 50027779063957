import {
  Directive,
  ElementRef,
  inject,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';
import { AttributeData } from './column.model';

@Directive({
  selector: '[lhaAttrData]',
  standalone: true,
})
export class AttrDataDirective implements OnInit {
  private elementRef = inject(ElementRef);
  private renderer = inject(Renderer2);
  @Input() lhaAttrData: AttributeData[] | undefined;

  ngOnInit() {
    if (this.lhaAttrData) {
      this.lhaAttrData.forEach((attribute: AttributeData) => {
        this.renderer.setAttribute(
          this.elementRef.nativeElement,
          attribute.name,
          attribute.value
        );
      });
    }
  }
}

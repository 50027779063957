import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, map, mergeMap, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { LiftingPauseReasonsActions } from '../actions/lifting-pause-reasons.actions';
import { LiftingPauseReasonsService } from '../../lifting-pause-reasons.service';
import { liftingPauseReasonsFeature } from '../features/lifting-pause-reasons.features';

export const loadLiftingPauseReasonsPage$ = createEffect(
  (actions = inject(Actions)) =>
    actions.pipe(
      ofType(LiftingPauseReasonsActions.load_Lifting_Pause_Reasons_Page),
      switchMap(() => of(LiftingPauseReasonsActions.load_Lifting_Pause_Reasons()))
    ),
  { functional: true }
);

export const loadLiftingPauseReasons$ = createEffect(
  (
    actions = inject(Actions),
    service = inject(LiftingPauseReasonsService),
    store = inject(Store)
  ) => {
    return actions.pipe(
      ofType(
        LiftingPauseReasonsActions.load_Lifting_Pause_Reasons,
        LiftingPauseReasonsActions.update_Lifting_Pause_Reasons_Queries,
        LiftingPauseReasonsActions.remove_Lifting_Pause_Reason_Success,
        LiftingPauseReasonsActions.add_Lifting_Pause_Reason_Success,
        LiftingPauseReasonsActions.edit_Lifting_Pause_Reason_Success
      ),
      concatLatestFrom(() => store.select(liftingPauseReasonsFeature.selectQuery)),
      map(([, query]) => query),
      mergeMap((query) =>
        service.loadLiftingPauseReasonsByQuery(query).pipe(
          map((liftingPauseReasons) =>
            LiftingPauseReasonsActions.load_Lifting_Pause_Reasons_Success({ liftingPauseReasons })
          ),
          catchError((error: HttpErrorResponse) =>
            of(LiftingPauseReasonsActions.load_Lifting_Pause_Reasons_Failure({ error }))
          )
        )
      )
    );
  },
  { functional: true }
);

export const loadActiveLiftingPauseReasons$ = createEffect(
  (
    actions = inject(Actions),
    service = inject(LiftingPauseReasonsService),
  ) => {
    return actions.pipe(
      ofType(LiftingPauseReasonsActions.load_Active_Lifting_Pause_Reasons),
      mergeMap(() =>
        service.loadActiveLiftingPauseReasonList().pipe(
          map((liftingPauseReasons) =>
            LiftingPauseReasonsActions.load_Active_Lifting_Pause_Reasons_Success({ liftingPauseReasons })
          ),
          catchError((error: HttpErrorResponse) =>
            of(LiftingPauseReasonsActions.load_Active_Lifting_Pause_Reasons_Failure({ error }))
          )
        )
      )
    );
  },
  { functional: true }
);

export const removeLiftingPauseReason$ = createEffect(
  (actions = inject(Actions), service = inject(LiftingPauseReasonsService)) =>
    actions.pipe(
      ofType(LiftingPauseReasonsActions.remove_Lifting_Pause_Reason),
      mergeMap(({ id }) =>
        service.removeLiftingPauseReason(id).pipe(
          map((liftingPauseReason) =>
            LiftingPauseReasonsActions.remove_Lifting_Pause_Reason_Success({
              liftingPauseReason: liftingPauseReason,
              successMessage: 'Lifting Pause Reason removed successfully!'
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(LiftingPauseReasonsActions.remove_Lifting_Pause_Reason_Failure({ error: error }))
          )
        )
      )
    ),
  { functional: true }
);

export const addLiftingPauseReason$ = createEffect(
  (actions = inject(Actions), service = inject(LiftingPauseReasonsService)) => {
    return actions.pipe(
      ofType(LiftingPauseReasonsActions.add_Lifting_Pause_Reason),
      mergeMap(({ liftingPauseReason }) =>
        service.addLiftingPauseReason(liftingPauseReason).pipe(
          map((liftingPauseReason) =>
            LiftingPauseReasonsActions.add_Lifting_Pause_Reason_Success({
              liftingPauseReason,
              successMessage: 'Lifting Pause Reason added successfully!'
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(LiftingPauseReasonsActions.add_Lifting_Pause_Reason_Failure({ error: error }))
          )
        )
      )
    );
  },
  { functional: true }
);

export const editLiftingPauseReason$ = createEffect(
  (actions = inject(Actions), service = inject(LiftingPauseReasonsService)) => {
    return actions.pipe(
      ofType(LiftingPauseReasonsActions.edit_Lifting_Pause_Reason),
      mergeMap(({ liftingPauseReason }) =>
        service.editLiftingPauseReason(liftingPauseReason).pipe(
          map((liftingPauseReason) =>
            LiftingPauseReasonsActions.edit_Lifting_Pause_Reason_Success({
              liftingPauseReason,
              successMessage: 'Lifting Pause Reason edited successfully!'
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(LiftingPauseReasonsActions.edit_Lifting_Pause_Reason_Failure({ error: error }))
          )
        )
      )
    );
  },
  { functional: true }
);

import { createFeature, createReducer, createSelector } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { dangerousGoodsInitialState } from '../states/dangerous-good.state';
import { DangerousGoodActions } from '../actions/dangerous-good.actions';

export const dangerousGoodsReducer = createReducer(
  dangerousGoodsInitialState,
  immerOn(
    DangerousGoodActions.load_Dangerous_Goods,
    DangerousGoodActions.remove_Dangerous_Good,
    (state) => {
      state.loading.list = true;
    }
  ),
  immerOn(
    DangerousGoodActions.update_Dangerous_Good_Queries,
    (state, { query }) => {
      state.query = query;
      state.loading.list = true;
    }
  ),
  immerOn(
    DangerousGoodActions.load_Dangerous_Goods_Success,
    (state, { dangerousGoods }) => {
      state.dangerousGoods = dangerousGoods;
      state.loading.list = false;
    }
  ),
  immerOn(
    DangerousGoodActions.load_Dangerous_Goods_By_Location_Id_Success,
    (state, payload) => {
      state.dangerousGoodsByLocationId = payload.dangerousGoods;
    }
  ),
  immerOn(
    DangerousGoodActions.load_Dangerous_Goods_Failure,
    DangerousGoodActions.remove_Dangerous_Good_Success,
    DangerousGoodActions.remove_Dangerous_Good_Failure,
    (state) => {
      state.loading.list = false;
    }
  ),
  immerOn(
    DangerousGoodActions.add_Dangerous_Good,
    DangerousGoodActions.edit_Dangerous_Good,
    (state) => {
      state.loading.createEdit = true;
    }
  ),
  immerOn(
    DangerousGoodActions.add_Dangerous_Good_Success,
    DangerousGoodActions.add_Dangerous_Good_Failure,
    DangerousGoodActions.edit_Dangerous_Good_Success,
    DangerousGoodActions.edit_Dangerous_Good_Failure,
    (state) => {
      state.loading.createEdit = false;
    }
  )
);

export const dangerousGoodsFeature = createFeature({
  name: 'dangerousGoods',
  reducer: dangerousGoodsReducer,
  extraSelectors: ({ selectLoading }) => ({
    selectListLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.list
    ),
    selectCreateEditLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.createEdit
    ),
  }),
});

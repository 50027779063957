import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Crane } from '../../../../../../../../libs/services/src/lib/services/maintenance/interfaces/crane.interface';
import { CraneActions } from '../../../../../../../../libs/services/src/lib/services/maintenance/store/actions/crane.actions';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import {
  cranesFeature,
  locationsFeature,
} from '../../../../../../../../libs/services/src/lib/services/maintenance/store/features';
import { LoadingDirective } from '../../../../../../../../libs/components/src/lib/directives/loading.directive';
import { SingleSelectComponent } from '../../../../../../../../libs/components/src/lib/components/single-select/single-select.component';
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { greaterThan } from 'libs/components/src/lib/validators/greaterThan';

@Component({
  selector: 'lha-cranes-add-edit',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    NgForOf,
    AsyncPipe,
    LoadingDirective,
    NgIf,
    SingleSelectComponent,
    CdkDrag,
    CdkDragHandle,
  ],
  templateUrl: './cranes-add-edit.component.html',
  styleUrls: ['./cranes-add-edit.component.scss'],
})
export class CranesAddEditComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  dialogRef = inject(MatDialogRef<CranesAddEditComponent>);
  data: { crane: Crane } = inject(MAT_DIALOG_DATA);
  store = inject(Store);
  action = inject(Actions);
  cranesState = this.store.selectSignal(cranesFeature.selectCranesState);
  locations = this.store.selectSignal(locationsFeature.selectLocations);
  isAdd = true;
  crane!: Crane;
  form = new FormGroup({
    name: new FormControl<string>('', [Validators.required]),
    locationId: new FormControl<string>('', [Validators.required]),
    type: new FormControl<string>(''),
    maximumWeight: new FormControl<number>(0, [greaterThan(0)]),
    radiusForMaximumWeight: new FormControl<number>(0, [greaterThan(0)]),
    maximumRadius: new FormControl<number>(0, [greaterThan(0)]),
    weightForMaximumRadius: new FormControl<number>(0, [greaterThan(0)]),
  });

  ngOnInit(): void {
    this.subCloseDialog();
    this.initAddEdit();
  }

  private initAddEdit(): void {
    this.isAdd = !this.data.crane;
    if (!this.isAdd) {
      this.crane = this.data.crane;
      this.pathForm(this.crane);
    }
  }

  private subCloseDialog(): void {
    this.action
      .pipe(
        ofType(CraneActions.add_Crane_Success, CraneActions.edit_Crane_Success)
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  private pathForm(crane: Crane): void {
    this.form.patchValue(crane);
  }

  saveCrane(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const model = {
      ...this.form.value,
    } as Crane;

    if (this.isAdd) {
      this.store.dispatch(CraneActions.add_Crane({ crane: model }));
    } else {
      this.store.dispatch(
        CraneActions.edit_Crane({
          crane: model,
          craneId: this.crane.craneId,
        })
      );
    }
  }
}

export class Environment {
  apiUrl: string = '';
  mockedEndPoints: string[] = [];
  mockApiUrl: string = '';
}

export class Auth0Env {
  domain: string = '';
  clientId: string = '';
  authorizationParams: { audience?: string } = { audience: '' };
  apiUri: string = '';
  errorPath: string = '';
}

export function loadEnvironment () {
  const url = `assets/environments/environment.json`;
  return fetch(url).then(res => res.json());
}

export function loadAuth0Environment () {
  const url = `assets/environments/auth_config.json`;
  return fetch(url).then(res => {
    return res.json()
  });
}

import { Directive, Input, TemplateRef, inject } from '@angular/core';

@Directive({
  selector: 'ng-template[lhaHeaderCellTemplate]',
  standalone: true,
})
export class HeaderCellTemplateDirective {
  @Input() lhaHeaderCellTemplate!: string;
  template = inject(TemplateRef);
}

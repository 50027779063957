import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { LoadCellActions } from '../actions/load-cell.actions';
import { LoadCellService } from '../../load-cell.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { DialogOptions } from '../../../config/dialog-options';
import { LocationActions } from '../actions/locations.actions';
import { LoadCellAddEditComponent } from 'apps/allocate/src/app/maintenance/shared/components/load-cell-add-edit/load-cell-add-edit.component';

export const load_Load_Cells_Page = createEffect(
  (actions = inject(Actions)) => {
    return actions.pipe(
      ofType(LoadCellActions.load_Load_Cells_Page),
      switchMap(() =>
        of(LoadCellActions.load_Cell_List(), LocationActions.load_Locations())
      )
    );
  },
  {
    functional: true,
  }
);

export const load_Load_Cells_List = createEffect(
  (actions = inject(Actions), service = inject(LoadCellService)) => {
    return actions.pipe(
      ofType(
        LoadCellActions.load_Cell_List,
        LoadCellActions.add_Load_Cell_Success,
        LoadCellActions.update_Load_Cell_Success,
        LoadCellActions.delete_Load_Cell_Success
      ),
      switchMap(() =>
        service.loadCellLists().pipe(
          map((cellList) =>
            LoadCellActions.load_Cell_List_Success({
              cellList,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(LoadCellActions.load_Cell_List_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const load_By_Location_Id = createEffect(
  (actions = inject(Actions), service = inject(LoadCellService)) => {
    return actions.pipe(
      ofType(LoadCellActions.load_Cell_List_By_Location_Id),
      switchMap(({ locationId }) =>
        service.loadCellListsByLocationId(locationId).pipe(
          map((cellList) =>
            LoadCellActions.load_Cell_List_By_Location_Id_Success({
              cellList,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              LoadCellActions.load_Cell_List_By_Location_Id_Failure({
                error,
              })
            )
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const load_By_Id = createEffect(
  (actions = inject(Actions), service = inject(LoadCellService)) => {
    return actions.pipe(
      ofType(LoadCellActions.load_By_Id),
      switchMap(({ loadCellId }) =>
        service.loadById(loadCellId).pipe(
          map((loadCell) =>
            LoadCellActions.load_By_Id_Success({
              loadCell,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(LoadCellActions.load_By_Id_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const add_Load_Cell = createEffect(
  (actions = inject(Actions), service = inject(LoadCellService)) => {
    return actions.pipe(
      ofType(LoadCellActions.add_Load_Cell),
      switchMap(({ loadCell }) =>
        service.addLoadCell(loadCell).pipe(
          map((loadCell) =>
            LoadCellActions.add_Load_Cell_Success({
              loadCell,
              successMessage: 'Load cell added successfully',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(LoadCellActions.add_Load_Cell_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const update_Load_Cell = createEffect(
  (actions = inject(Actions), service = inject(LoadCellService)) => {
    return actions.pipe(
      ofType(LoadCellActions.update_Load_Cell),
      switchMap(({ loadCell, loadCellId }) =>
        service.updateLoadCell(loadCellId, loadCell).pipe(
          map((loadCell) =>
            LoadCellActions.update_Load_Cell_Success({
              loadCell,
              successMessage: 'Load cell updated successfully',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(LoadCellActions.update_Load_Cell_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const delete_Load_Cell = createEffect(
  (actions = inject(Actions), service = inject(LoadCellService)) => {
    return actions.pipe(
      ofType(LoadCellActions.delete_Load_Cell),
      switchMap(({ loadCellId }) =>
        service.deleteLoadCell(loadCellId).pipe(
          map(() =>
            LoadCellActions.delete_Load_Cell_Success({
              loadCellId,
              successMessage: 'Load cell deleted successfully',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(LoadCellActions.delete_Load_Cell_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const open_Load_Cell_Dialog = createEffect(
  (
    actions = inject(Actions),
    dialog = inject(MatDialog),
    dialogOptions = inject(DialogOptions)
  ) => {
    return actions.pipe(
      ofType(LoadCellActions.open_Load_Cell_Dialog),
      tap((action) => {
        dialog.open(LoadCellAddEditComponent, {
          ...dialogOptions,
          data: {
            loadCell: action.loadCell,
          },
        });
      })
    );
  },
  {
    functional: true,
    dispatch: false,
  }
);

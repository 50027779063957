import { LoadCell } from '../../interfaces/load-cell.interface';
import { MaintenanceState } from '../../interfaces/maintenance-state.interface';
import { maintenanceInitialState } from './maintenance.state';

export interface LoadCellState extends MaintenanceState {
  loadCells: LoadCell[];
  loadCellByLocationId: LoadCell[];
}

export const initialLoadCellState: LoadCellState = {
  loadCells: [],
  loadCellByLocationId: [],
  ...maintenanceInitialState,
};

import {
  Component,
  DestroyRef,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { LoadingDirective } from '../../../../../../../../libs/components/src/lib/directives/loading.directive';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { Operator } from '../../../../../../../../libs/services/src/lib/services/maintenance/interfaces/operator.interface';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import {
  operatorsFeature,
  locationsFeature,
  reportTypesFeature,
} from '../../../../../../../../libs/services/src/lib/services/maintenance/store/features';
import { Subject, takeUntil } from 'rxjs';
import { OperatorActions } from '../../../../../../../../libs/services/src/lib/services/maintenance/store/actions/operators.actions';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MultiselectComponent } from '../../../../../../../../libs/components/src/lib/components/multiselect/multiselect.component';
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'lha-operator-add-edit',
  standalone: true,
  imports: [
    FormsModule,
    LoadingDirective,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    NgForOf,
    ReactiveFormsModule,
    AsyncPipe,
    NgIf,
    MatSlideToggleModule,
    MultiselectComponent,
    CdkDrag,
    CdkDragHandle,
  ],
  templateUrl: './operator-add-edit.component.html',
  styleUrls: ['./operator-add-edit.component.scss'],
})
export class OperatorAddEditComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  dialogRef = inject(MatDialogRef<OperatorAddEditComponent>);
  data: { operator: Operator } = inject(MAT_DIALOG_DATA);
  store = inject(Store);
  action = inject(Actions);
  operatorState = this.store.selectSignal(
    operatorsFeature.selectOperatorsState
  );
  locations = this.store.selectSignal(locationsFeature.selectLocations);
  reportTypes = this.store.selectSignal(reportTypesFeature.selectReportTypes);
  isAdd = true;
  unsubscribe: Subject<boolean> = new Subject();
  operator: Operator = {
    clientId: '',
    name: '',
    assetNames: [],
    clientReportTypes: [],
    reportTypeNames: [],
    reportTypeIds: [],
    locationIds: [],
    locationNames: [],
    createdByName: '',
    updatedByName: '',
    createByDate: '',
    updatedByDate: '',
    isActive: false,
    customsCompliant: false,
  };
  form = new FormGroup({
    name: new FormControl<string>('', [Validators.required]),
    isActive: new FormControl<boolean>(false),
    reportTypeIds: new FormControl<string[]>([]),
    locationIds: new FormControl<string[]>([]),
    customsCompliant: new FormControl<boolean>(false)
  });

  ngOnInit(): void {
    this.subCloseDialog();
    this.initAddEdit();
  }

  private initAddEdit(): void {
    this.isAdd = !this.data.operator;
    if (!this.isAdd) {
      this.operator = this.data.operator;
      this.pathForm(this.operator);
    }
  }

  private subCloseDialog(): void {
    this.action
      .pipe(
        ofType(
          OperatorActions.add_Operator_Success,
          OperatorActions.edit_Operator_Success
        )
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  private pathForm(operator: Operator): void {
    this.form.patchValue({
      name: operator.name,
      isActive: operator.isActive,
      reportTypeIds: operator.reportTypeIds,
      locationIds: operator.locationIds,
      customsCompliant: operator.customsCompliant
    });
  }

  saveOperator(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    const model = {
      ...this.form.value,
    } as Operator;

    if (this.isAdd) {
      this.store.dispatch(OperatorActions.add_Operator({ operator: model }));
    } else {
      this.store.dispatch(
        OperatorActions.edit_Operator({
          operator: model,
          operatorId: this.operator.clientId,
        })
      );
    }
  }
}

import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { OperatorsService } from '../../operators.service';
import { catchError, map, mergeMap, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { OperatorActions } from '../actions/operators.actions';
import { Operator } from '../../interfaces/operator.interface';
import { Store } from '@ngrx/store';
import { operatorsFeature } from '../features';
import { MatDialog } from '@angular/material/dialog';
import { switchMap, tap } from 'rxjs/operators';
import { OperatorAddEditComponent } from '../../../../../../../../apps/allocate/src/app/maintenance/shared/components/operator-add-edit/operator-add-edit.component';
import { DialogOptions } from '../../../config/dialog-options';
import { LocationActions } from '../actions/locations.actions';
import { ReportTypeActions } from '../actions/report-type.actions';

export const loadOperatorsPage = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(OperatorActions.load_Operators_Page),
      switchMap(() =>
        of(
          OperatorActions.load_Operators(),
          LocationActions.load_Locations(),
          ReportTypeActions.load_Report_Types()
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const loadOperators = createEffect(
  (
    actions = inject(Actions),
    store = inject(Store),
    operatorService = inject(OperatorsService)
  ) => {
    return actions.pipe(
      ofType(
        OperatorActions.load_Operators,
        OperatorActions.update_Operator_Queries,
        OperatorActions.remove_Operator_Success,
        OperatorActions.add_Operator_Success,
        OperatorActions.edit_Operator_Success
      ),
      concatLatestFrom(() => store.select(operatorsFeature.selectQuery)),
      map(([, res]) => res),
      mergeMap((action) =>
        operatorService.loadOperators().pipe(
          map((res: Operator[]) =>
            OperatorActions.load_Operators_Success({ operators: res })
          ),
          catchError((error: HttpErrorResponse) =>
            of(OperatorActions.load_Operators_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const removeOperator = createEffect(
  (actions = inject(Actions), operatorsService = inject(OperatorsService)) => {
    return actions.pipe(
      ofType(OperatorActions.remove_Operator),
      mergeMap((action) =>
        operatorsService.removeOperator(action.id).pipe(
          map((res: Operator) =>
            OperatorActions.remove_Operator_Success({
              operator: res,
              successMessage: 'Operator removed successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(OperatorActions.remove_Operator_Failure({ error: error }))
          )
        )
      ),
      tap((action) => {
        if (action.type === OperatorActions.remove_Operator_Success.type) {
          window.location.reload();
        }
      })
    );
  },
  {
    functional: true,
  }
);

export const addOperator = createEffect(
  (actions = inject(Actions), operatorsService = inject(OperatorsService)) => {
    return actions.pipe(
      ofType(OperatorActions.add_Operator),
      mergeMap((action) =>
        operatorsService.addOperator(action.operator).pipe(
          map((res: Operator) =>
            OperatorActions.add_Operator_Success({
              operator: res,
              successMessage: 'Operator added successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(OperatorActions.add_Operator_Failure({ error: error }))
          )
        )
      ),
      tap((action) => {
        if (action.type === OperatorActions.add_Operator_Success.type) {
          window.location.reload();
        }
      })
    );
  },
  {
    functional: true,
  }
);

export const editOperator = createEffect(
  (
    store = inject(Store),
    actions = inject(Actions),
    operatorsService = inject(OperatorsService)
  ) => {
    return actions.pipe(
      ofType(OperatorActions.edit_Operator),
      mergeMap((action) =>
        operatorsService.editOperator(action.operatorId, action.operator).pipe(
          map((res: Operator) =>
            OperatorActions.edit_Operator_Success({
              operator: res,
              successMessage: 'Operator edited successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(OperatorActions.edit_Operator_Failure({ error: error }))
          )
        )
      ),
      tap((action) => {
        if (action.type === OperatorActions.edit_Operator_Success.type) {
          window.location.reload();
        }
      }),
      tap((action) => {
        if (action.type === OperatorActions.edit_Operator_Failure.type) {
          store.dispatch(OperatorActions.close_Edit_Dialog({ close: true }));
        }
      })
    );
  },
  {
    functional: true,
  }
);

export const openOperatorDialog = createEffect(
  (
    actions = inject(Actions),
    dialog = inject(MatDialog),
    dialogOptions = inject(DialogOptions)
  ) => {
    return actions.pipe(
      ofType(OperatorActions.open_Operator_Dialog),
      tap((action) => {
        dialog.open(OperatorAddEditComponent, {
          ...dialogOptions,
          data: {
            operator: action.operator,
          },
        });
      })
    );
  },
  {
    functional: true,
    dispatch: false,
  }
);

export const closeOperatorDialog = createEffect(
  (actions = inject(Actions), dialog = inject(MatDialog)) => {
    return actions.pipe(
      ofType(OperatorActions.close_Edit_Dialog),
      tap(() => {
        dialog.closeAll();
      })
    );
  },
  {
    functional: true,
    dispatch: false,
  }
);

import { Component, Input } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf } from '@angular/common';

@Component({
  selector: 'lha-loader',
  standalone: true,
  imports: [MatProgressSpinnerModule, NgIf],
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  @Input() loading = false;
  @Input() minHeight = 100;
  @Input() diameter = 50;
}

import {
  AsyncPipe,
  JsonPipe,
  NgClass,
  NgFor,
  NgIf,
  NgStyle,
  NgTemplateOutlet,
} from '@angular/common';
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Input,
  QueryList,
  TemplateRef,
  inject,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnChanges,
} from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { CellTemplateDirective } from './cell-template.directive';
import { Column, ColumnGroup } from './column.model';
import {
  MatPaginator,
  MatPaginatorModule,
  PageEvent,
} from '@angular/material/paginator';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { PageContainerComponent } from '../page-container/page-container.component';
import { ColumnGroupsPipe } from './column-groups.pipe';
import { AttrDataDirective } from './attr-data.directive';
import { ColumnHeadersPipe } from './column-headers.pipe';
import { SearchComponent } from '../search/search.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TableFilter } from './table-filter.interface';
import { MatSelectModule } from '@angular/material/select';
import { OnInit } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { HeaderCellTemplateDirective } from './header-cell-template.directive';
import { LoadingDirective } from '../../directives/loading.directive';
import { InitialCapitalizeDirective } from '../../directives/initial-capitalize.directive';
import { ExcelService } from '../../services/excel.service';
import { SearchQuery } from '../../interfaces/search-query.interface';
import { throwError } from 'rxjs';

@Component({
  selector: 'lha-table',
  standalone: true,
  templateUrl: './table.html',
  styleUrls: ['./table.scss'],
  imports: [
    MatTableModule,
    ColumnHeadersPipe,
    NgFor,
    NgIf,
    AsyncPipe,
    NgTemplateOutlet,
    MatPaginatorModule,
    MatSortModule,
    LoadingDirective,
    JsonPipe,
    MatButtonModule,
    PageContainerComponent,
    AttrDataDirective,
    ColumnGroupsPipe,
    SearchComponent,
    MatProgressSpinnerModule,
    MatSelectModule,
    NgClass,
    InitialCapitalizeDirective,
    RouterLink,
    RouterLinkActive,
    NgStyle,
    MatIconModule,
  ],
})
export class TableComponent implements OnInit, OnChanges, AfterContentChecked {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() columns: Column<any>[] = [];
  @Input() columnsGroup: ColumnGroup[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() data: any[] = [];
  @Input() loading = false;
  @Input() exportLoading = false;
  @Input() pagination = true;
  @Input() canExportTable = true;
  @Input() showSearch = true;
  @Input() searchValue = '';
  @Input() searchBuffer = '';
  @Input() selectableRows = false;
  @Input() link = '..';
  @Input() propertyId = 'id';
  @Input() pageSize = 10;
  @Input() pageIndex = 10;
  @Input() length = 10;
  @Input() tableName = `Table ${new Date().getTime()}`;
  @Input() stickyHeader = true;
  @Input() tableClass = '';
  @Input() filters: TableFilter[] = [];
  @Input() isCustomTable? = false;
  @Output() queryChange = new EventEmitter<SearchQuery>();
  @Output() exportTable = new EventEmitter();
  @Output() changeFilter = new EventEmitter<string>();
  @Output() rowClick = new EventEmitter<any>();
  @ContentChildren(CellTemplateDirective)
  cellTemplateRefs!: QueryList<CellTemplateDirective>;
  @ContentChildren(HeaderCellTemplateDirective)
  headerCellTemplateDirectiveRefs!: QueryList<HeaderCellTemplateDirective>;
  @ViewChild('TABLE') table!: ElementRef<HTMLTableElement>;
  dataSource = new MatTableDataSource<any>(this.data);
  private paginator!: MatPaginator;
  private isFirstLoad = true;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    if(!this.isCustomTable) {
      this.dataSource.paginator = this.paginator;
    }
  }
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.dataSource.sort = ms;
    this.dataSource.sortingDataAccessor = this.dateSorting;
  }
  queries: SearchQuery = {
    searchTerm: '',
    active: '',
    direction: '',
  } as SearchQuery;
  indexGroupArr: number[] = [];
  filterDictionary = new Map<string, string>();
  cdRef = inject(ChangeDetectorRef);
  excelService = inject(ExcelService);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cellTemplates = new Map<string, TemplateRef<any>>();
  headerCellTemplates = new Map<string, TemplateRef<any>>();

  ngOnInit(): void {
    this.filters.forEach((filter: TableFilter) => {
      this.filterDictionary.set(filter.columnName, '');
    });
    this.filterDictionary.set('searchString', '');
    this.dataSource.filterPredicate = this.customFilterPredicate();
    this.dataSource.filter = JSON.stringify(
      Array.from(this.filterDictionary.entries())
    );
    this.createIndexArrLine();
    this.dataSource.sortingDataAccessor = this.dateSorting;
  }

  getPageSizes(): number[] {
      return [5, 10, 20];
  }

  ngOnChanges() {
    this.dataSource = new MatTableDataSource<any>(this.data);
    this.filterDictionary.set('searchString', this.searchValue);
    this.dataSource.filterPredicate = this.customFilterPredicate();
    this.dataSource.filter = JSON.stringify(
      Array.from(this.filterDictionary.entries())
    );
    this.dataSource.sortingDataAccessor = this.dateSorting;
    if(this.searchBuffer !== this.queries.searchTerm) {
      this.isFirstLoad = true;
      this.searchBuffer = this.queries.searchTerm;
    }

    if(this.paginator && this.isFirstLoad && this.isCustomTable) {
      this.paginator.firstPage();
      this.isFirstLoad = false;
    }

    if (this.paginator && !this.isCustomTable) {
      this.paginator.firstPage();
    }

  }

  ngAfterContentChecked() {
    this.cellTemplateRefs.toArray().forEach((i) => {
      this.cellTemplates.set(i.lhaCellTemplate, i.template);
    });
    this.headerCellTemplateDirectiveRefs.toArray().forEach((i) => {
      this.headerCellTemplates.set(i.lhaHeaderCellTemplate, i.template);
    });
    this.cdRef.detectChanges();
  }

  dateSorting(item: Record<string, any>, property: string): any {
    if (property.toLowerCase().includes('date')) {
      return new Date(item[property] ?? '').getTime();
    }
    return item[property];
  }

  createIndexArrLine(): void {
    let acc = 0;
    this.indexGroupArr = this.columnsGroup.map((item) => {
      return (acc += item.colspan);
    });
  }

  onExportTable(): void {
    this.exportTable.emit();
  }

  applyFilter(filterValue: string): void {
    this.queries = {
      ...this.queries,
      searchTerm: filterValue,
    };
    this.queryChange.emit(this.queries);
    this.filterDictionary.set('searchString', filterValue.trim().toLowerCase());

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.dataSource.filter = JSON.stringify(
      Array.from(this.filterDictionary.entries())
    );
  }

  applySelectFilter(filter: TableFilter) {
    if (!filter.selected) {
      this.filterDictionary.set(filter.columnName, '');
      this.changeFilter.emit('');
    } else {
      this.filterDictionary.set(filter.columnName, filter.selected.toString());
      this.changeFilter.emit(filter.selected.toString());
    }
    this.dataSource.filter = JSON.stringify(
      Array.from(this.filterDictionary.entries())
    );
  }

  onChangeSearch(event: Event): void {
    this.queries = {
      ...this.queries,
      searchTerm: (event.target as HTMLInputElement).value ?? '',
    };
    this.queryChange.emit(this.queries);
  }

  onChangeQuery(query: Sort | PageEvent): void {
    this.queries = {
      ...this.queries,
      ...query,
    };
    this.queryChange.emit(this.queries);
  }

  customFilterPredicate() {
    const cols = this.columns;
    return function (data: any, filter: string): boolean {
      const combinedFilter = JSON.parse(filter);
      let ok = true;
      combinedFilter.forEach((element: string[]) => {
        if (element[1] === '') {
          ok = ok && true;
        } else if (element[0] === 'searchString') {
          ok =
            ok &&
            cols.some(function (column) {
              if (!data[column.property]) {
                return false;
              }
              if (
                typeof column.property === 'string' &&
                (column.property.includes('Id') ||
                  column.property.includes('Date'))
              ) {
                return false;
              }
              return data[column.property]
                .toString()
                .toLowerCase()
                .includes(element[1].toLowerCase());
            });
        } else {
          ok = ok && data[element[0]].toString() === element[1];
        }
      });
      return ok;
    };
  }
}

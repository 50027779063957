import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Asset } from './interfaces/asset.interface';
import { SearchQuery } from '../../../../../components/src/lib/interfaces/search-query.interface';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Location } from './interfaces/location.interface';

@Injectable({ providedIn: 'root' })
export class AssetService {
  private readonly http = inject(HttpClient);

  loadAssetList(): Observable<Asset[]> {
    return this.http.get<Asset[]>('/api/asset');
  }

  loadAssets(query: SearchQuery): Observable<Asset[]> {
    // TODO: It will be used after creating pagination, sorting, and search on back-end
    // return this.loadMaintenance<Asset[]>(query, 'assets');
    return this.http.get<Asset[]>('/api/asset');
  }

  loadPorts(): Observable<Asset[]> {
    return this.http.get<Asset[]>('/api/asset/ports');
  }

  removeAsset(id: string): Observable<Asset> {
    return this.http.delete<Asset>(`/api/asset/${id}`);
  }

  addAsset(asset: Asset): Observable<Asset> {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const headers = new HttpHeaders().set('Time-Zone', timezone);
    return this.http.post<Asset>('/api/asset', asset, { headers });
  }

  editAsset(
    id: string,
    asset: Asset,
    addNewHistory: boolean
  ): Observable<Asset> {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const headers = new HttpHeaders().set('Time-Zone', timezone);
    return this.http.put<Asset>(`/api/asset/${id}/${addNewHistory}`, asset, {
      headers,
    });
  }

  clusterHeadAsset(id: string): Observable<Asset[]> {
    return this.http.get<Asset[]>(`/api/asset/cluster-head/${id}`);
  }

  loadLocations(): Observable<Location[]> {
    return this.http.get<Location[]>('/api/location');
  }

  exportAssets(): Observable<ArrayBuffer> {
    return this.http.get('/api/asset/exportassets', {
      responseType: 'arraybuffer',
    });
  }

  loadAssetByLocationId(locationId: string): Observable<Asset[]> {
    return this.http.get<Asset[]>(`/api/asset/getbylocation/${locationId}`);
  }
}

import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SearchQuery } from '../../../../../../../components/src/lib/interfaces/search-query.interface';
import { errorProps } from '../../../../../../../components/src/lib/functions/utility.functions';
import { ClientLocation } from '../../interfaces/client-locations.interface';

export const ClientLocationActions = createActionGroup({

  source: 'ClientLocation',
  events: {
    'update_Vessel_Queries': props<{ query: SearchQuery }>(),
    'load_User_Client_Locations': emptyProps(),
    'load_User_Client_Locations_Success': props<{ clientLocations: ClientLocation[] }>(),
    'load_User_Client_Locations_Failure': errorProps(),

    'Load_Client_Locations_By_LocationId': props<{ locationId: string }>(),
    'Load_Client_Locations_By_LocationId_Success': props<{
      clientLocations: ClientLocation[];
    }>(),
    'Load_Client_Locations_By_LocationId_Error': errorProps(),
  },
});



import { createFeature, createReducer, createSelector } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { activityConfigInitialState } from '../states/activity-config.state';
import { ActivityConfigActions } from '../actions/activity-config.actions';

export const activityConfigReducer = createReducer(
  activityConfigInitialState,
  immerOn(
    ActivityConfigActions.load_Activity_Configs,
    ActivityConfigActions.remove_Activity_Config,
    (state) => {
      state.loading.list = true;
    }
  ),
  immerOn(
    ActivityConfigActions.update_Activity_Config_Queries,
    (state, { query }) => {
      state.query = query;
      state.loading.list = true;
    }
  ),
  immerOn(
    ActivityConfigActions.load_Chargeability_List_Success,
    (state, { chargeabilityList }) => {
      state.chargeabilityList = chargeabilityList;
    }
  ),
  immerOn(
    ActivityConfigActions.set_Activity_Type,
    (state, { activityType }) => {
      state.activityType = activityType;
    }
  ),
  immerOn(
    ActivityConfigActions.load_Activity_Type_List_Success,
    (state, { activityTypeList }) => {
      state.activityTypeList = activityTypeList;
    }
  ),
  immerOn(
    ActivityConfigActions.load_Activity_Configs_Success,
    (state, { activities }) => {
      state.activities = activities;
      state.loading.list = false;
    }
  ),
  immerOn(
    ActivityConfigActions.load_Activity_Configs_Failure,
    ActivityConfigActions.remove_Activity_Config_Success,
    ActivityConfigActions.remove_Activity_Config_Failure,
    ActivityConfigActions.load_Chargeability_List_Success,
    ActivityConfigActions.load_Chargeability_List_Failure,
    ActivityConfigActions.load_Activity_Type_List_Success,
    ActivityConfigActions.load_Activity_Type_List_Failure,
    (state) => {
      state.loading.list = false;
    }
  ),
  immerOn(
    ActivityConfigActions.add_Activity_Config,
    ActivityConfigActions.edit_Activity_Config,
    (state) => {
      state.loading.createEdit = true;
    }
  ),
  immerOn(
    ActivityConfigActions.add_Activity_Config_Success,
    ActivityConfigActions.add_Activity_Config_Failure,
    ActivityConfigActions.edit_Activity_Config_Success,
    ActivityConfigActions.edit_Activity_Config_Failure,
    (state) => {
      state.loading.createEdit = false;
    }
  ),
  immerOn(ActivityConfigActions.export_Activities, (state) => {
    state.loading.export = true;
  }),
  immerOn(
    ActivityConfigActions.export_Activities_Success,
    ActivityConfigActions.export_Activities_Failure,
    (state) => {
      state.loading.export = false;
    }
  )
);

export const activityConfigFeature = createFeature({
  name: 'activityConfig',
  reducer: activityConfigReducer,
  extraSelectors: ({
    selectLoading,
    selectActivities,
    selectActivityType,
    selectChargeabilityList,
  }) => ({
    selectListLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.list
    ),
    selectCreateEditLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.createEdit
    ),
    selectChargeabilityMapList: createSelector(
      selectChargeabilityList,
      selectActivityType,
      (selectChargeabilityList, selectActivityType) =>
        selectChargeabilityList.map((item) => {
          return {
            ...item,
            disabled:
              (['HCL', 'HCD', 'PASS', 'INT'].includes(selectActivityType) &&
                item.value === 'NCH') ||
              (selectActivityType === 'NCHPASS' && item.value !== 'NCH'),
          };
        })
    ),
    selectFilteredActivities: createSelector(
      selectActivities,
      (selectActivities) =>
        selectActivities.filter(
          (item) => !['INT', 'PASS', 'NCHPASS'].includes(item.type)
        )
    ),
  }),
});

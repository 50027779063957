import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import {
  dangerousGoodsFeature,
  locationsFeature,
} from '../../../../../../../../libs/services/src/lib/services/maintenance/store/features';
import { LoadingDirective } from '../../../../../../../../libs/components/src/lib/directives/loading.directive';
import { SingleSelectComponent } from '../../../../../../../../libs/components/src/lib/components/single-select/single-select.component';
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { DangerousGood } from 'libs/services/src/lib/services/maintenance/interfaces/dangerous-good.interface';
import { DangerousGoodActions } from 'libs/services/src/lib/services/maintenance/store/actions/dangerous-good.actions';
import { MultiselectComponent } from '../../../../../../../../libs/components/src/lib/components/multiselect/multiselect.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'lha-bulk-type-add-edit',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    NgForOf,
    AsyncPipe,
    LoadingDirective,
    NgIf,
    MultiselectComponent,
    SingleSelectComponent,
    CdkDrag,
    CdkDragHandle,
  ],
  templateUrl: './dangerous-good-add-edit.component.html',
  styleUrls: ['./dangerous-good-add-edit.component.scss'],
})
export class DangerousGoodAddEditComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  dialogRef = inject(MatDialogRef<DangerousGoodAddEditComponent>);
  data: { DangerousGood: DangerousGood } = inject(MAT_DIALOG_DATA);
  store = inject(Store);
  action = inject(Actions);
  dangerousGoodsState = this.store.selectSignal(
    dangerousGoodsFeature.selectDangerousGoodsState
  );
  locations = this.store.selectSignal(locationsFeature.selectLocations);
  isAdd = true;
  dangerousGood!: DangerousGood;
  form = new FormGroup({
    unNo: new FormControl<string>('', [Validators.required]),
    class: new FormControl<string>('', [Validators.required]),
    subClass: new FormControl<string>('', [Validators.required]),
    properShippingName: new FormControl<string>('', [Validators.required]),
    packingGroup: new FormControl<string>('', [Validators.required]),
    locationIds: new FormControl<string[]>([], [Validators.required]),
  });
  packingGroupOptions = [
    { value: 'I', label: 'I' },
    { value: 'II', label: 'II' },
    { value: 'III', label: 'III' },
  ];

  ngOnInit(): void {
    this.subCloseDialog();
    this.initAddEdit();
  }

  private initAddEdit(): void {
    this.isAdd = !this.data.DangerousGood;
    if (!this.isAdd) {
      this.dangerousGood = this.data.DangerousGood;
      this.pathForm(this.dangerousGood);
    }
  }

  private subCloseDialog(): void {
    this.action
      .pipe(
        ofType(
          DangerousGoodActions.add_Dangerous_Good_Success,
          DangerousGoodActions.edit_Dangerous_Good_Success
        )
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  private pathForm(dangerousGood: DangerousGood): void {
    this.form.patchValue(dangerousGood);
  }

  saveDangerousGood(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const model = {
      ...this.form.value,
    } as DangerousGood;

    if (this.isAdd) {
      this.store.dispatch(
        DangerousGoodActions.add_Dangerous_Good({ dangerousGood: model })
      );
    } else {
      this.store.dispatch(
        DangerousGoodActions.edit_Dangerous_Good({
          dangerousGood: model,
          dangerousGoodId: this.dangerousGood.dangerousGoodId,
        })
      );
    }
  }
}

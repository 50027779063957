
import {maintenanceInitialState} from "./maintenance.state";
import {ActivityCategoryTypeState} from "../../interfaces/activity-category-type-state.interface";

export const activityCategoryTypeInitialState: ActivityCategoryTypeState = {
 activityCategoryTypes: [],
 activityCategoryTypeId: '',
  ...maintenanceInitialState,
  loading: {
    list: false,
    createEdit: false,
  },
}

import { InjectionToken } from '@angular/core';

const DIALOG_OPTIONS = {
  width: '650px',
  panelClass: 'custom_dialog',
  autoFocus: false,
  disableClose: true,
};

export const DialogOptions = new InjectionToken('DIALOG_OPTION', {
  factory: () => DIALOG_OPTIONS,
  providedIn: 'root',
});

import { Pipe, PipeTransform } from '@angular/core';
import { ColumnGroup } from './column.model';

@Pipe({
  name: 'columnGroups',
  standalone: true,
})
export class ColumnGroupsPipe implements PipeTransform {
  transform(columns: ColumnGroup[]): string[] {
    return columns.map((column) => column.columnGroup);
  }
}

import { createFeature, createReducer, createSelector } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { reportTypeInitialState } from '../states/report-type.state';
import { ReportTypeActions } from '../actions/report-type.actions';

export const reportTypesReducer = createReducer(
  reportTypeInitialState,
  immerOn(ReportTypeActions.remove_Report_Type, (state) => {
    state.loading.list = true;
  }),
  immerOn(ReportTypeActions.update_Report_Type_Queries, (state, { query }) => {
    state.query = query;
    state.loading.list = true;
  }),

  immerOn(
    ReportTypeActions.load_Report_Types_Success,
    (state, { reportTypes }) => {
      state.reportTypes = reportTypes;
      state.loading.list = false;
    }
  ),
  immerOn(
    ReportTypeActions.load_Report_Types_Failure,
    ReportTypeActions.remove_Report_Type_Success,
    ReportTypeActions.remove_Report_Type_Failure,
    (state) => {
      state.loading.list = false;
    }
  ),
  immerOn(
    ReportTypeActions.add_Report_Type,
    ReportTypeActions.edit_Report_Type,
    (state) => {
      state.loading.createEdit = true;
    }
  ),
  immerOn(
    ReportTypeActions.add_Report_Type_Success,
    ReportTypeActions.add_Report_Type_Failure,
    ReportTypeActions.edit_Report_Type_Success,
    ReportTypeActions.edit_Report_Type_Failure,
    (state) => {
      state.loading.createEdit = false;
    }
  )
);

export const reportTypesFeature = createFeature({
  name: 'reportTypes',
  reducer: reportTypesReducer,
  extraSelectors: ({ selectLoading }) => ({
    selectListLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.list
    ),
    selectCreateEditLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.createEdit
    ),
  }),
});

import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { NgIf } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { LoadingDirective } from '../../../../../../../../libs/components/src/lib/directives/loading.directive';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { cargoFamilyFeature, cargotypeFeature } from '../../../../../../../../libs/services/src/lib/services/maintenance/store/features';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CargoType } from 'libs/services/src/lib/services/maintenance/interfaces/carrgo-type.interface';
import { CargoeTypeActions } from 'libs/services/src/lib/services/maintenance/store/actions/cargo-type.actions';
import { SingleSelectComponent } from "../../../../../../../../libs/components/src/lib/components/single-select/single-select.component";

@Component({
  selector: 'lha-cargo-type-add-edit',
  standalone: true,
  imports: [
    FormsModule,
    LoadingDirective,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    NgIf,
    MatSlideToggleModule,
    CdkDrag,
    CdkDragHandle,
    SingleSelectComponent
],
  templateUrl: './cargo-type-add-edit.component.html',
  styleUrls: ['./cargo-type-add-edit.component.scss'],
})
export class CargoTypeAddEditComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  dialogRef = inject(MatDialogRef<CargoTypeAddEditComponent>);
  data: {
    CargoType: CargoType | null;
  } = inject(MAT_DIALOG_DATA);
  store = inject(Store);
  action = inject(Actions);
  cargoTypeState = this.store.selectSignal(cargotypeFeature.selectCargoTypeState);
  cargoFamilies = this.store.selectSignal(cargoFamilyFeature.selectCargoFamilies);
  isAdd = true;
  selectedCargoTypeId = '';

  form = new FormGroup({
    name: new FormControl<string>('', Validators.required),
    cargoFamilyId: new FormControl<string>('', Validators.required),
  });

  ngOnInit(): void {
    this.subCloseDialog();
    this.initAddEdit();
  }

  private initAddEdit(): void {
    this.isAdd = !this.data.CargoType;
    if (!this.isAdd) {
      this.selectedCargoTypeId = this.data.CargoType!.cargoTypeId;

      this.patchForm(this.data.CargoType!);
    }
  }

  private subCloseDialog(): void {
    this.action
      .pipe(
        ofType(
          CargoeTypeActions.add_Cargo_Type_Success,
          CargoeTypeActions.edit_Cargo_Type_Success
        )
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  private patchForm(cargoType: CargoType): void {
    this.form.patchValue({
      name: cargoType.name,
      cargoFamilyId: cargoType.cargoFamilyId,
    });
  }

  saveCargoType(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      const model = {
        ...this.form.value,
      } as CargoType;

      if (this.isAdd) {
        this.store.dispatch(
          CargoeTypeActions.add_Cargo_Type({
            cargoType: model,
          })
        );
      } else {
        this.store.dispatch(
          CargoeTypeActions.edit_Cargo_Type({
            cargoTypeId: this.selectedCargoTypeId,
            cargoType: model,
          })
        );
      }
    }
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect } from '@ngrx/effects';
import { filter, tap } from 'rxjs/operators';
import { ErrorHandlerService } from '../../../../services/src/lib/services/error-handler.service';

export const handleError$ = createEffect(
  (
    actions = inject(Actions),
    errorHandlerService = inject(ErrorHandlerService)
  ) => {
    return actions.pipe(
      filter((action) => {
        return action.error !== undefined;
      }),
      tap((error: { error: HttpErrorResponse; customMessage?: string }) => {
        errorHandlerService.handleError(error);
      })
    );
  },
  { functional: true, dispatch: false }
);

export const handleSuccess$ = createEffect(
  (actions = inject(Actions), snackBar = inject(MatSnackBar)) => {
    return actions.pipe(
      filter((action) => {
        return action.successMessage !== undefined;
      }),
      tap((action: unknown) => {
        const msg = (action as { successMessage: string }).successMessage;
        snackBar.open(msg, 'Close', {
          duration: 10_000,
          panelClass: 'snackbar--success',
        });
      })
    );
  },
  { functional: true, dispatch: false }
);

import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SearchQuery } from '../../../../../../../components/src/lib/interfaces/search-query.interface';
import { User } from '../../../../../../../auth/src/lib/interfaces/user.interface';
import { Constant } from 'libs/services/src/lib/services/shared/constant.interface';
import { errorProps } from '../../../../../../../components/src/lib/functions/utility.functions';
import { ApplicationRole } from 'libs/auth/src/lib/interfaces/application-role.interface';

export const UserActions = createActionGroup({
  source: 'User',
  events: {
    update_User_Queries: props<{ query: SearchQuery }>(),
    load_Users_Page: emptyProps(),
    load_Users: emptyProps(),
    load_Users_Success: props<{ users: User[] }>(),
    load_Users_Failure: errorProps(),
    load_User_Application_Roles: emptyProps(),
    load_User_Application_Roles_Success: props<{
      incomingDataApplicationRoles: ApplicationRole[];
    }>(),
    load_User_Application_Roles_Failure: errorProps(),
    load_Current_User: emptyProps(),
    load_Current_User_Success: props<{ user: User }>(),
    load_Current_User_Failure: errorProps(),
    load_User_Roles: emptyProps(),
    load_User_Roles_Success: props<{ userRoles: Constant[] }>(),
    load_User_Roles_Failure: errorProps(),
    remove_User: props<{ id: string }>(),
    remove_User_Success: props<{ user: User; successMessage: string }>(),
    remove_User_Failure: errorProps(),
    open_User_Dialog: props<{ user: User | null }>(),
    add_User: props<{ user: User }>(),
    add_User_Success: props<{ user: User; successMessage: string }>(),
    add_User_Failure: errorProps(),
    edit_User: props<{ userId: string; user: User }>(),
    edit_User_Success: props<{ user: User; successMessage: string }>(),
    edit_User_Failure: errorProps(),
    edit_User_Location: props<{ userId: string; user: User }>(),
    edit_User_Location_Success: props<{ user: User; successMessage: string }>(),
    edit_User_Location_Failure: errorProps(),
    edit_User_Details: props<{ userId: string; user: User }>(),
    edit_User_Details_Success: props<{
      user: User;
      successMessage: string;
    }>(),
    edit_User_Details_Failure: errorProps(),
  },
});

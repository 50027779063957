import {
  ComponentRef,
  Directive,
  DoCheck,
  EmbeddedViewRef,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { LoaderComponent } from '../components/loader/loader.component';

@Directive({
  selector: '[lhaLoading]',
  standalone: true,
})
export class LoadingDirective implements OnInit, DoCheck, OnChanges, OnDestroy {
  private readonly templateRef = inject(TemplateRef);
  private readonly vcRef = inject(ViewContainerRef);
  @Input() lhaLoading = false;
  templateView!: EmbeddedViewRef<unknown>;
  loaderRef!: ComponentRef<LoaderComponent>;

  ngOnInit() {
    this.templateView = this.templateRef.createEmbeddedView({});
    this.loaderRef = this.vcRef.createComponent(LoaderComponent, {
      injector: this.vcRef.injector,
      projectableNodes: [this.templateView.rootNodes],
    });

    this.loaderRef.setInput('loading', this.lhaLoading);
  }

  ngOnChanges() {
    this.loaderRef?.setInput('loading', this.lhaLoading);
  }

  ngDoCheck() {
    this.templateView?.detectChanges();
  }

  ngOnDestroy(): void {
    this.templateView.destroy();
  }
}

import { Squad } from '../../interfaces/squad.interface';
import { MaintenanceState } from '../../interfaces/maintenance-state.interface';
import { maintenanceInitialState } from './maintenance.state';

export const squadInitialState: SquadState = {
  squads: [],
  ...maintenanceInitialState,
};

export interface SquadState extends MaintenanceState {
  squads: Squad[];
}

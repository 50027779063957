import { Portal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Portals } from '../types/portals.type';

@Injectable({ providedIn: 'root' })
export class PortalService {
  private readonly portal$ = new Subject<{
    portal: Portal<unknown> | null;
    name: Portals;
  }>();

  sendPortal(name: Portals, portal: Portal<unknown> | null) {
    this.portal$.next({ portal, name });
  }

  getPortal(name: Portals) {
    return this.portal$.pipe(
      filter((portalRef) => portalRef.name === name),
      map((portalRef) => portalRef.portal)
    );
  }
}

import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { AssetMobileWellService } from '../../asset-mobile-well.service';
import { catchError, map, mergeMap, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { AssetMobileWellActions } from '../actions/assets-mobile-well.actions';
import { Store } from '@ngrx/store';
import { assetMobileWellFeature } from '../features';
import { MatDialog } from '@angular/material/dialog';
import { filter, tap } from 'rxjs/operators';
import { AssetMobileWellComponent } from '../../../../../../../../apps/allocate/src/app/maintenance/shared/components/asset-mobile-well/asset-mobile-well.component';
import { AssetMobileWell } from '../../interfaces/asset-mobile-well.interface';
import { AssetActions } from '../actions/assets.actions';

export const loadAssetMobileWells = createEffect(
  (
    actions = inject(Actions),
    store = inject(Store),
    assetMobileWellService = inject(AssetMobileWellService)
  ) => {
    return actions.pipe(
      ofType(
        AssetMobileWellActions.set_Asset_Mobile_Well_Id,
        AssetMobileWellActions.load_Asset_Mobile_Wells,
        AssetMobileWellActions.remove_Asset_Mobile_Well_Success,
        AssetMobileWellActions.add_Asset_Mobile_Well_Success,
        AssetMobileWellActions.edit_Asset_Mobile_Well_Success
      ),
      concatLatestFrom(() =>
        store.select(assetMobileWellFeature.selectAssetMobileWellId)
      ),
      map(([, res]) => res),
      filter((res) => !!res),
      mergeMap((action) =>
        assetMobileWellService.loadAssetMobileWells(action).pipe(
          map((res: AssetMobileWell[]) =>
            AssetMobileWellActions.load_Asset_Mobile_Wells_Success({
              assetMobileWells: res,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              AssetMobileWellActions.load_Asset_Mobile_Wells_Failure({
                error,
              })
            )
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const removeAssetMobileWell = createEffect(
  (
    actions = inject(Actions),
    assetMobileWellService = inject(AssetMobileWellService)
  ) => {
    return actions.pipe(
      ofType(AssetMobileWellActions.remove_Asset_Mobile_Well),
      mergeMap((action) =>
        assetMobileWellService.removeAssetMobileWell(action.id).pipe(
          map((res: AssetMobileWell) =>
            AssetMobileWellActions.remove_Asset_Mobile_Well_Success({
              assetMobileWell: res,
              successMessage: 'Asset removed successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              AssetMobileWellActions.remove_Asset_Mobile_Well_Failure({
                error: error,
              })
            )
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const addAssetMobileWell = createEffect(
  (
    actions = inject(Actions),
    assetMobileWellService = inject(AssetMobileWellService)
  ) => {
    return actions.pipe(
      ofType(AssetMobileWellActions.add_Asset_Mobile_Well),
      mergeMap((action) =>
        assetMobileWellService
          .addAssetMobileWell(action.assetMobileWell)
          .pipe(
            map((res: AssetMobileWell) =>
              AssetMobileWellActions.add_Asset_Mobile_Well_Success({
                assetMobileWell: res,
                successMessage: 'Asset Mobile added successfully!',
              })
            ),
            catchError((error: HttpErrorResponse) =>
              of(
                AssetMobileWellActions.add_Asset_Mobile_Well_Failure({
                  error: error,
                })
              )
            )
          )
      )
    );
  },
  {
    functional: true,
  }
);

export const editAssetMobileWell = createEffect(
  (
    actions = inject(Actions),
    assetMobileWellService = inject(AssetMobileWellService)
  ) => {
    return actions.pipe(
      ofType(AssetMobileWellActions.edit_Asset_Mobile_Well),
      mergeMap((action) =>
        assetMobileWellService
          .editAssetMobileWell(action.id, action.assetMobileWell)
          .pipe(
            map((res: AssetMobileWell) =>
              AssetMobileWellActions.edit_Asset_Mobile_Well_Success({
                assetMobileWell: res,
                successMessage: 'Asset Mobile edited successfully!',
              })
            ),
            catchError((error: HttpErrorResponse) =>
              of(
                AssetMobileWellActions.edit_Asset_Mobile_Well_Failure({
                  error: error,
                })
              )
            )
          )
      )
    );
  },
  {
    functional: true,
  }
);

export const openAssetMobileWellDialog = createEffect(
  (actions = inject(Actions), dialog = inject(MatDialog)) => {
    return actions.pipe(
      ofType(AssetMobileWellActions.open_Asset_Mobile_Well_Dialog),
      tap((action) => {
        dialog.open(AssetMobileWellComponent, {
          width: '80vw',
          panelClass: 'asset_dialog',
          autoFocus: false,
          disableClose: true,
          height: '80vh',
          data: {
            id: action.id,
            asset: action.asset,
          },
        });
      })
    );
  },
  {
    functional: true,
    dispatch: false,
  }
);

export const openAssetMobileWellDialogC = createEffect(
  (actions = inject(Actions), dialog = inject(MatDialog)) => {
    return actions.pipe(
      ofType(AssetActions.add_Asset_Success),
      filter((item) => item.asset.assetType === 'MOB'),
      tap((action) => {
        dialog.open(AssetMobileWellComponent, {
          width: '80vw',
          panelClass: 'asset_dialog',
          autoFocus: false,
          disableClose: true,
          height: '80vh',
          data: {
            id: action.asset.assetId,
            asset: action.asset,
          },
        });
      })
    );
  },
  {
    functional: true,
    dispatch: false,
  }
);

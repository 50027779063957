import {SiteState} from "../../interfaces/site-state.interface";
import {maintenanceInitialState} from "./maintenance.state";

export const siteInitialState: SiteState = {
  sites: [],
  siteId: '',
  areaList: [],
  area: '',
  ...maintenanceInitialState,
}

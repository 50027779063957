<div
  *ngIf="activityConfigState()"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  cdkDragBoundary=".cdk-overlay-container"
>
  <div mat-dialog-title>
    <div class="dialog_info_header">
      <h4>{{ isAdd ? 'Add ' : 'Edit ' }} Activity</h4>
      <div class="dialog_info" *ngIf="!isAdd">
        <div class="dialog_info__text">i</div>
        <div class="custom-tooltip">
          <div class="custom-tooltip__text" *ngIf="data.activity.createdDate">
            <span *ngIf="data.activity.createdByName"
              >Created By: {{ data.activity.createdByName }}<br
            /></span>
            Created Date: {{ data.activity.createdDate | date : 'dd-MM-yyyy' }}
          </div>
          <div class="custom-tooltip__text" *ngIf="data.activity.updatedDate">
            <span *ngIf="data.activity.updatedByName"
              >Last Updated By: {{ data.activity.updatedByName }}<br
            /></span>
            Updated Date: {{ data.activity.updatedDate | date : 'dd-MM-yyyy' }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <mat-dialog-content *lhaLoading="activityConfigState().loading.createEdit">
    <form [formGroup]="form" (ngSubmit)="saveActivity()">
      <div class="form__block">
        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>Activity Name</mat-label>
            <input matInput type="text" formControlName="name" />
            <mat-error *ngIf="form.controls.name.hasError('required')">
              Activity name is required.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>Activity Code</mat-label>
            <input matInput type="text" formControlName="code" />
            <mat-error *ngIf="form.controls.code.hasError('required')">
              Activity code is required.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form__box">
          <lha-single-select
            [options]="activityConfigState().activityTypeList"
            formControlName="type"
            bindValue="value"
            bindLabel="description"
            placeholder="Activity Type"
            (selectChange)="changeActivityType($event)"
          />
          <div *ngIf="form.controls.type.invalid && form.controls.type.touched">
            <mat-error *ngIf="form.controls.type.hasError('required')">
              Activity type is required.
            </mat-error>
          </div>
        </div>
        <div class="form__box">
          <lha-single-select
            [options]="chargeabilityList()"
            formControlName="chargeability"
            bindValue="value"
            bindLabel="description"
            placeholder="Chargeability"
          />
          <div
            *ngIf="
              form.controls.chargeability.invalid &&
              form.controls.chargeability.touched
            "
          >
            <mat-error *ngIf="form.controls.chargeability.hasError('required')">
              Chargeability is required.
            </mat-error>
          </div>
        </div>
        <div class="form__box">
          <lha-multiselect
            formControlName="parallelActivityIds"
            bindValue="activityId"
            bindLabel="name"
            [items]="activities()"
            placeholder="Parallel Activities"
          >
          </lha-multiselect>
        </div>
      </div>
      <mat-dialog-actions align="end">
        <button mat-raised-button color="primary" type="submit">
          {{ isAdd ? 'Add ' : 'Save ' }}
        </button>
        <button
          mat-raised-button
          color="warn"
          type="button"
          mat-dialog-close=""
        >
          Cancel
        </button>
      </mat-dialog-actions>
    </form>
  </mat-dialog-content>
</div>

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { inject } from '@angular/core';
import { ConfirmComponent } from '../../lib/components/confirm/confirm.component';
import { confirmActions } from './confirm.actions';

export const requestConformation = createEffect(
  (actions = inject(Actions), dialog = inject(MatDialog)) => {
    return actions.pipe(
      ofType(confirmActions.request_Confirmation),
      tap((action) => {
        dialog.open(ConfirmComponent, {
          maxWidth: '650px',
          data: {
            titles: action.titles,
            confirm: action.confirm,
            reject: action.reject,
            cancel: action.cancel,
          },
        });
      })
    );
  },
  {
    functional: true,
    dispatch: false,
  }
);

import { inject, Injectable } from '@angular/core';
import { SearchQuery } from '../../../../../components/src/lib/interfaces/search-query.interface';
import { Observable } from 'rxjs';
import { Activity } from './interfaces/activity.interface';
import { ActivityType } from './interfaces/activity-type.interface';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class ActivityService {
  private readonly http = inject(HttpClient);

  loadActivities(query?: SearchQuery): Observable<Activity[]> {
    return this.http.get<Activity[]>('/api/activity');
    // TODO: It will be used after creating pagination, sorting, and search on back-end
    // return this.loadMaintenance<Activity[]>(query, 'activity');
  }

  removeActivity(id: string): Observable<Activity> {
    return this.http.delete<Activity>(`/api/activity/${id}`);
  }

  addActivity(activity: Activity): Observable<Activity> {
    return this.http.post<Activity>('/api/activity', activity);
  }

  editActivity(id: string, activity: Activity): Observable<Activity> {
    return this.http.put<Activity>(`/api/activity/${id}`, activity);
  }

  loadActivityTypeList(): Observable<ActivityType[]> {
    return this.http.get<ActivityType[]>('/api/activitytype');
  }

  exportActivities(): Observable<ArrayBuffer> {
    return this.http.get('/api/activity/exportActivities', {
      responseType: 'arraybuffer',
    });
  }
}

import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { debounceTime, Subject } from 'rxjs';
import { NgIf, NgClass } from '@angular/common';

@Component({
  selector: 'lha-search',
  standalone: true,
  imports: [MatInputModule, MatIconModule, NgIf, NgClass],
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnDestroy {
  @Input() debounceTime = 500;
  @Input() value = '';
  @Input() isNotTable = false;
  @Output() querySearch = new EventEmitter<string>();
  debouncer: Subject<string> = new Subject<string>();

  constructor() {
    this.debouncer
      .pipe(debounceTime(this.debounceTime))
      .subscribe((value) => this.querySearch.emit(value));
  }

  emitSearch(event: Event): void {
    this.value = (event.target as HTMLInputElement).value;
    this.debouncer.next(this.value);
  }

  clearSearch(): void {
    this.value = '';
    this.querySearch.emit(this.value);
  }

  ngOnDestroy(): void {
    this.debouncer.unsubscribe();
  }
}

import { inject, Injectable } from '@angular/core';
import { SearchQuery } from '../../../../../components/src/lib/interfaces/search-query.interface';
import { Observable } from 'rxjs';
import { DangerousGood } from './interfaces/dangerous-good.interface';
import { HttpClient } from '@angular/common/http';
import { Location } from './interfaces/location.interface';

@Injectable({ providedIn: 'root' })
export class DangerousGoodService {
  private readonly http = inject(HttpClient);

  loadDangerousGoods(query: SearchQuery): Observable<DangerousGood[]> {
    return this.http.get<DangerousGood[]>('/api/dangerousgood');
  }

  loadDangerousGoodsList(): Observable<DangerousGood[]> {
    return this.http.get<DangerousGood[]>('/api/dangerousgood');
  }

  removeDangerousGood(id: string): Observable<DangerousGood> {
    return this.http.delete<DangerousGood>(`/api/dangerousgood/${id}`);
  }

  addDangerousGood(dangerousGood: DangerousGood): Observable<DangerousGood> {
    return this.http.post<DangerousGood>('/api/dangerousgood', dangerousGood);
  }

  editDangerousGood(id: string, dangerousGood: DangerousGood): Observable<DangerousGood> {
    return this.http.put<DangerousGood>(`/api/dangerousgood/${id}`, dangerousGood);
  }

  loadLocationList(): Observable<Location[]> {
    return this.http.get<Location[]>('/api/location');
  }

  loadDangerousGoodsByLocationId(locationId: string) {
    return this.http.get<DangerousGood[]>(`/api/dangerousgood/list/${locationId}`);
  }
}

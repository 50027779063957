import { createFeature, createReducer, createSelector } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { bulkTypeInitialState } from '../states/bulk-type.state';
import { BulkTypeActions } from '../actions/bulk-type.actions';

export const bulkTypesReducer = createReducer(
  bulkTypeInitialState,
  immerOn(
    BulkTypeActions.load_Bulk_Types,
    BulkTypeActions.remove_Bulk_Type,
    (state) => {
      state.loading.list = true;
    }
  ),
  immerOn(BulkTypeActions.update_Bulk_Type_Queries, (state, { query }) => {
    state.query = query;
    state.loading.list = true;
  }),
  immerOn(BulkTypeActions.load_Type_List_Success, (state, { typeList }) => {
    state.typeList = typeList;
  }),
  immerOn(BulkTypeActions.load_Bulk_Types_Success, (state, { bulkTypes }) => {
    state.bulkTypes = bulkTypes;
    state.loading.list = false;
  }),
  immerOn(
    BulkTypeActions.load_Bulk_Types_Failure,
    BulkTypeActions.remove_Bulk_Type_Success,
    BulkTypeActions.remove_Bulk_Type_Failure,
    (state) => {
      state.loading.list = false;
    }
  ),
  immerOn(
    BulkTypeActions.add_Bulk_Type,
    BulkTypeActions.edit_Bulk_Type,
    (state) => {
      state.loading.createEdit = true;
    }
  ),
  immerOn(
    BulkTypeActions.add_Bulk_Type_Success,
    BulkTypeActions.add_Bulk_Type_Failure,
    BulkTypeActions.edit_Bulk_Type_Success,
    BulkTypeActions.edit_Bulk_Type_Failure,
    (state) => {
      state.loading.createEdit = false;
    }
  )
);

export const bulkTypesFeature = createFeature({
  name: 'bulkTypes',
  reducer: bulkTypesReducer,
  extraSelectors: ({ selectLoading }) => ({
    selectListLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.list
    ),
    selectCreateEditLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.createEdit
    ),
  }),
});

import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SearchQuery } from '../../../../../../../components/src/lib/interfaces/search-query.interface';
import { ReportType } from '../../interfaces/report-type.interface';
import { errorProps } from '../../../../../../../components/src/lib/functions/utility.functions';

export const ReportTypeActions = createActionGroup({
  source: 'Report Type',
  events: {
    'update_Report_Type_Queries': props<{ query: SearchQuery }>(),
    'load_Report_Types': emptyProps(),
    'load_Report_Types_Success': props<{ reportTypes: ReportType[] }>(),
    'load_Report_Types_Failure': errorProps(),
    'remove_Report_Type': props<{ id: string }>(),
    'remove_Report_Type_Success': props<{
      reportType: ReportType;
      successMessage: string;
    }>(),
    'remove_Report_Type_Failure': errorProps(),
    'open_Report_Type_Dialog': props<{ reportType: ReportType | null }>(),
    'add_Report_Type': props<{ reportType: ReportType }>(),
    'add_Report_Type_Success': props<{
      reportType: ReportType;
      successMessage: string;
    }>(),
    'add_Report_Type_Failure': errorProps(),
    'edit_Report_Type': props<{
      reportTypeId: string;
      reportType: ReportType;
    }>(),
    'edit_Report_Type_Success': props<{
      reportType: ReportType;
      successMessage: string;
    }>(),
    'edit_Report_Type_Failure': errorProps(),
  },
});

import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { catchError, map, mergeMap, of, switchMap, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { CargoFamilyService } from '../../cargo-family.service';
import { CargoeFamilyActions } from '../actions/cargo-family.actions';
import { CargoFamily } from '../../interfaces/carrgo-family.interface';
import { Store } from '@ngrx/store';
import { cargoFamilyFeature } from '../features/cargo-family.feature';
import { CargoesActions } from '../actions/cargoes.actions';
import { LocationActions } from '../actions/locations.actions';
import { MatDialog } from '@angular/material/dialog';
import { DialogOptions } from '../../../config/dialog-options';
import { CargoFamilyAddEditComponent } from 'apps/allocate/src/app/maintenance/shared/components/cargo-family-add-edit/cargo-family-add-edit.component';

export const loadCargoFamilies = createEffect(
  (
    actions = inject(Actions),
    store = inject(Store),
    cargoFamilyService = inject(CargoFamilyService)
  ) => {
    return actions.pipe(
      ofType(
        CargoeFamilyActions.load_CargoFamily_List,
        CargoesActions.open_Cargo_Dialog,
      ),
      concatLatestFrom(() => store.select(cargoFamilyFeature.selectQuery)),
      map(([, res]) => res),
      mergeMap(() =>
        cargoFamilyService.loadCargoFamilies().pipe(
          map((res: CargoFamily[]) =>
            CargoeFamilyActions.load_CargoFamilies_Success({ CargoFamilies: res })
          ),
          catchError((error: HttpErrorResponse) =>
            of(CargoeFamilyActions.load_CargoFamilies_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const loadCargoFamiliesPage = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(CargoeFamilyActions.load_Cargo_Families_Page),
      switchMap(() =>
        of(
          CargoeFamilyActions.load_Cargo_Families(),
          LocationActions.load_Locations()
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const loadCargoFamiliesList = createEffect(
  (
    actions = inject(Actions),
    store = inject(Store),
    cargoFamilyService = inject(CargoFamilyService)
  ) => {
    return actions.pipe(
      ofType(
        CargoeFamilyActions.load_Cargo_Families,
        CargoeFamilyActions.remove_Cargo_Family_Success,
        CargoeFamilyActions.add_Cargo_Family_Success,
        CargoeFamilyActions.edit_Cargo_Family_Success
      ),
      concatLatestFrom(() => store.select(cargoFamilyFeature.selectQuery)),
      map(([, res]) => res),
      mergeMap((action) =>
        cargoFamilyService.loadCargoFamilies().pipe(
          map((res: CargoFamily[]) =>
            CargoeFamilyActions.load_Cargo_Families_Success({
              cargoFamilys: res,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(CargoeFamilyActions.load_Cargo_Families_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const removeCargoFamily = createEffect(
  (
    actions = inject(Actions),
    cargoFamilyService = inject(CargoFamilyService)
  ) => {
    return actions.pipe(
      ofType(CargoeFamilyActions.remove_Cargo_Family),
      mergeMap((action) =>
        cargoFamilyService.deleteCargoFamily(action.id).pipe(
          map(() =>
            CargoeFamilyActions.remove_Cargo_Family_Success({
              cargoFamily: {} as CargoFamily,
              successMessage: 'Cargo Family successfully removed!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              CargoeFamilyActions.remove_Cargo_Family_Failure({
                error: error,
              })
            )
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const addCargoFamily = createEffect(
  (
    actions = inject(Actions),
    cargoFamilyService = inject(CargoFamilyService)
  ) => {
    return actions.pipe(
      ofType(CargoeFamilyActions.add_Cargo_Family),
      mergeMap((action) =>
        cargoFamilyService.createCargoFamily(action.cargoFamily).pipe(
          map((res: CargoFamily) =>
            CargoeFamilyActions.add_Cargo_Family_Success({
              cargoFamily: res,
              successMessage: 'Cargo Family added successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              CargoeFamilyActions.add_Cargo_Family_Failure({ error: error })
            )
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const editCargoFamily = createEffect(
  (
    actions = inject(Actions),
    cargoFamilyService = inject(CargoFamilyService)
  ) => {
    return actions.pipe(
      ofType(CargoeFamilyActions.edit_Cargo_Family),
      mergeMap((action) =>
        cargoFamilyService
          .updateCargoFamily(action.cargoFamilyId, action.cargoFamily)
          .pipe(
            map((res: CargoFamily) =>
              CargoeFamilyActions.edit_Cargo_Family_Success({
                cargoFamily: res,
                successMessage: 'Cargo Family edited successfully!',
              })
            ),
            catchError((error: HttpErrorResponse) =>
              of(
                CargoeFamilyActions.edit_Cargo_Family_Failure({
                  error: error,
                })
              )
            )
          )
      )
    );
  },
  {
    functional: true,
  }
);

export const openCargoFamilyDialog = createEffect(
  (
    actions = inject(Actions),
    dialog = inject(MatDialog),
    dialogOptions = inject(DialogOptions)
  ) => {
    return actions.pipe(
      ofType(CargoeFamilyActions.open_Cargo_Family_Dialog),
      tap((action) => {
        dialog.open(CargoFamilyAddEditComponent, {
          ...dialogOptions,
          data: {
            CargoFamily: action.cargoFamily,
          },
        });
      })
    );
  },
  {
    functional: true,
    dispatch: false,
  }
);
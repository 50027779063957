import { Directive, Input, TemplateRef, inject } from '@angular/core';

@Directive({
  selector: 'ng-template[lhaCellTemplate]',
  standalone: true,
})
export class CellTemplateDirective {
  @Input() lhaCellTemplate!: string;
  template = inject(TemplateRef);
}

import {
  Component,
  EventEmitter,
  inject,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { AsyncPipe, DatePipe, NgIf } from '@angular/common';
import { TIME_FORMAT } from '../activity-category-type-add-edit/activity-category-type-add-edit.component';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { Site } from '../../../../../../../../libs/services/src/lib/services/maintenance/interfaces/site.interface';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Column } from '../../../../../../../../libs/components/src/lib/components/table/column.model';
import { confirmActions } from 'libs/components/src/lib/store/confirm.actions';
import { Location } from '../../../../../../../../libs/services/src/lib/services/maintenance/interfaces/location.interface';
import { siteFeature } from '../../../../../../../../libs/services/src/lib/services/maintenance/store/features/site.features';
import { SiteActions } from '../../../../../../../../libs/services/src/lib/services/maintenance/store/actions/site.actions';
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { CellTemplateDirective } from '../../../../../../../../libs/components/src/lib/components/table/cell-template.directive';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TableComponent } from '../../../../../../../../libs/components/src/lib/components/table/table.component';
import { SearchQuery } from '../../../../../../../../libs/components/src/lib/interfaces/search-query.interface';
import { maintenanceInitialState } from '../../../../../../../../libs/services/src/lib/services/maintenance/store/states/maintenance.state';
import { AreaActions } from '../../../../../../../../libs/services/src/lib/services/maintenance/store/actions/area.actions';

@Component({
  selector: 'site-add-edit',
  standalone: true,
  imports: [
    AsyncPipe,
    CdkDrag,
    CdkDragHandle,
    CellTemplateDirective,
    DatePipe,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    NgIf,
    ReactiveFormsModule,
    TableComponent,
  ],
  providers: [
    {
      provide: NGX_MAT_DATE_FORMATS,
      useValue: TIME_FORMAT,
    },
    DatePipe,
  ],
  templateUrl: './site-add-edit.component.html',
  styleUrls: ['./site-add-edit.component.scss'],
})
export class SiteAddEditComponent implements OnInit, OnDestroy {
  @Output() save = new EventEmitter<boolean>();
  dialogRef = inject(MatDialogRef<SiteAddEditComponent>);
  store = inject(Store);
  action = inject(Actions);
  query: SearchQuery = maintenanceInitialState.query;
  vm$ = this.store.select(siteFeature.selectSiteState);
  data: { id: string; location: Location } = inject(MAT_DIALOG_DATA);

  unsubscribe: Subject<boolean> = new Subject();
  editRows: Map<string, FormGroup> = new Map();
  siteList$ = this.store.select(siteFeature.selectSites);
  siteList: Site[] = [];
  datePipe = inject(DatePipe);
  columns: Column<Site>[] = [
    new Column('name', 'Site', { sortHeader: false }),
    new Column('createdDate', 'Created Date', { sortHeader: false }),
    new Column('siteId', 'Actions', { sortHeader: false }),
  ];

  ngOnDestroy(): void {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  ngOnInit(): void {
    this.store.dispatch(SiteActions.set_Site_Id({ id: this.data.id }));
    this.subToActions();
    this.subToSite();
  }

  private subToActions() {
    this.action
      .pipe(ofType(SiteActions.add_Site_Success), takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.editRows.delete('add');
      });

    this.action
      .pipe(ofType(SiteActions.edit_Site_Success), takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.editRows.delete(res.site.siteId);
      });
  }

  private subToSite() {
    this.siteList$.pipe(takeUntil(this.unsubscribe)).subscribe((res) => {
      this.siteList = res;
    });
    this.siteList$.pipe(takeUntil(this.unsubscribe)).subscribe((res) => {
      this.siteList = res;
    });
  }

  addSite() {
    if (this.editRows.get('add')) {
      return;
    }
    this.store.dispatch(SiteActions.add_Site_Row());
    this.editRows.set('add', this.createSiteGroup());
  }

  saveSite(site: Site) {
    const model = {
      ...this.editRows.get(site.siteId)?.value,
      locationId: this.data.id,
    };

    if (site.siteId === 'add') {
      this.store.dispatch(
        SiteActions.add_Site({
          site: model,
        })
      );
    } else {
      this.store.dispatch(
        SiteActions.edit_Site({
          id: site.siteId,
          site: model,
        })
      );
    }
    this.save.emit(true);
  }

  cancelSite(site: Site) {
    this.editRows.delete(site.siteId);
    if (site.siteId === 'add') {
      this.store.dispatch(SiteActions.remove_Site_Row());
    }
  }

  editSite(site: Site) {
    this.editRows.set(site.siteId, this.createSiteGroup(site));
  }

  removeSite(site: Site) {
    this.store.dispatch(
      confirmActions.request_Confirmation({
        titles: {
          title: 'Do you you want to remove this Site?',
          btnConfirm: 'Yes Delete',
        },
        confirm: SiteActions.remove_Site({
          id: site.siteId,
        }),
      })
    );
  }

  createSiteGroup(site?: Site): FormGroup {
    return new FormGroup({
      name: new FormControl(site?.name, [Validators.required]),
    });
  }

  getFg(id: string): FormGroup {
    return this.editRows.get(id) ?? this.createSiteGroup();
  }

  openAreaDialog(site: Site) {
    const id = site.siteId;
    this.store.dispatch(AreaActions.open_Area_Dialog({ id, site }));
  }
}

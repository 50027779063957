<div class="asset-mobile-well dialog-container"
     *ngIf="vm$ | async as vm"
     cdkDrag
     cdkDragRootElement=".cdk-overlay-pane"
     cdkDragHandle
     cdkDragBoundary=".cdk-overlay-container">
  <lha-table
    [columns]="columns"
    [data]="vm.sites"
    [loading]="vm.loading.createEdit"
    [canExportTable]="false"
    tableName="Sites">
    <h2 tableHeaderLeft>Add Site to {{ data.location.name }}</h2>
    <ng-container tableHeaderRightBtn>
      <button mat-raised-button (click)="addSite()" color="primary">
        Add
      </button>
      <button
        mat-icon-button
        color="warn"
        mat-dialog-close=""
        class="asset-close">
        <mat-icon>close</mat-icon>
      </button>
    </ng-container>

    <ng-template lhaCellTemplate="name" let-item>
      <ng-container *ngIf="!editRows.get(item.siteId)">
        {{ item.name }}
      </ng-container>
      <ng-container *ngIf="editRows.get(item.siteId)" [formGroup]="getFg(item.siteId)">
        <mat-form-field appearance="outline">
          <mat-label>Site</mat-label>
          <input matInput formControlName="name" placeholder="Enter Site" />
          <mat-error *ngIf="getFg(item.siteId)?.get('name')?.invalid && getFg(item.siteId)?.get('name')?.touched">
            Site is required.
          </mat-error>
        </mat-form-field>
      </ng-container>
    </ng-template>

    <ng-template lhaCellTemplate="createdDate" let-item>
      {{ item.createdByName }} {{ item.createdDate | date : 'dd-MM-yyyy' }}
    </ng-template>

    <ng-template lhaCellTemplate="siteId" let-item>
      <button
        mat-icon-button
        color="primary"
        class="btn-save"
        *ngIf="editRows.get(item.siteId)"
        (click)="saveSite(item)">
        <mat-icon>check_circle</mat-icon>
      </button>
      <button
        mat-icon-button
        color="warn"
        *ngIf="editRows.get(item.siteId)"
        (click)="cancelSite(item)">
        <mat-icon>cancel</mat-icon>
      </button>
      <button
        mat-icon-button
        color="primary"
        *ngIf="!editRows.get(item.siteId)"
        (click)="editSite(item)">
        <mat-icon>edit</mat-icon>
      </button>
      <button
        mat-icon-button
        color="primary"
        *ngIf="!editRows.get(item.siteId)"
        (click)="openAreaDialog(item)">
        <mat-icon>border_all</mat-icon>
      </button>
      <button
        mat-icon-button
        color="warn"
        *ngIf="!editRows.get(item.siteId)"
        (click)="removeSite(item)">
        <mat-icon>delete</mat-icon>
      </button>
    </ng-template>
  </lha-table>
</div>

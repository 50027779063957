import { Pipe, PipeTransform } from '@angular/core';
import { Column } from './column.model';

@Pipe({
  name: 'columnHeaders',
  standalone: true,
})
export class ColumnHeadersPipe implements PipeTransform {
  transform(columns: Column[]): string[] {
    return columns.map((column) => column.property);
  }
}

import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Employee } from '../../../../../../../../libs/services/src/lib/services/maintenance/interfaces/employee.interface';
import { EmployeeActions } from '../../../../../../../../libs/services/src/lib/services/maintenance/store/actions/employee.actions';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { NgIf } from '@angular/common';
import { employeesFeature } from '../../../../../../../../libs/services/src/lib/services/maintenance/store/features';
import { LoadingDirective } from '../../../../../../../../libs/components/src/lib/directives/loading.directive';
import { SingleSelectComponent } from '../../../../../../../../libs/components/src/lib/components/single-select/single-select.component';
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'lha-employees-add-edit',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    LoadingDirective,
    NgIf,
    SingleSelectComponent,
    CdkDrag,
    CdkDragHandle,
  ],
  templateUrl: './employee-add-edit.component.html',
  styleUrls: ['./employee-add-edit.component.scss'],
})
export class EmployeeAddEditComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  dialogRef = inject(MatDialogRef<EmployeeAddEditComponent>);
  data: { employee: Employee } = inject(MAT_DIALOG_DATA);
  store = inject(Store);
  action = inject(Actions);
  employeesState = this.store.selectSignal(
    employeesFeature.selectEmployeesState
  );
  isAdd = true;
  employee!: Employee;
  form = new FormGroup({
    name: new FormControl<string>('', [Validators.required]),
  });

  ngOnInit(): void {
    this.subCloseDialog();
    this.initAddEdit();
  }

  private initAddEdit(): void {
    this.isAdd = !this.data.employee;
    if (!this.isAdd) {
      this.employee = this.data.employee;
      this.pathForm(this.employee);
    }
  }

  private subCloseDialog(): void {
    this.action
      .pipe(
        ofType(
          EmployeeActions.add_Employee_Success,
          EmployeeActions.edit_Employee_Success
        )
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  private pathForm(employee: Employee): void {
    this.form.patchValue(employee);
  }

  saveEmployee(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const model = {
      ...this.form.value,
    } as Employee;

    if (this.isAdd) {
      this.store.dispatch(EmployeeActions.add_Employee({ employee: model }));
    } else {
      this.store.dispatch(
        EmployeeActions.edit_Employee({
          employee: model,
          employeeId: this.employee.employeeId,
        })
      );
    }
  }
}

import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { catchError, map, mergeMap, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { TankTypeService } from '../../tank-type.service';
import { TankTypeActions } from '../actions/tank-type.actions';
import { TankType } from '../../interfaces/tank-type.interface';
import { Store } from '@ngrx/store';
import { tankTypesFeature } from '../features';
import { MatDialog } from '@angular/material/dialog';
import { switchMap, tap } from 'rxjs/operators';
import { TankTypeAddEditComponent } from '../../../../../../../../apps/allocate/src/app/maintenance/shared/components/tank-type-add-edit/tank-type-add-edit.component';
import { DialogOptions } from '../../../config/dialog-options';
import { UnitActions } from '../actions/units.actions';

export const loadTankTypePage = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(TankTypeActions.load_Tank_Type_Page),
      switchMap(() =>
        of(TankTypeActions.load_Tank_Types(), UnitActions.load_Units())
      )
    );
  },
  {
    functional: true,
  }
);

export const loadTankTypes = createEffect(
  (
    actions = inject(Actions),
    store = inject(Store),
    tankTypeService = inject(TankTypeService)
  ) => {
    return actions.pipe(
      ofType(
        TankTypeActions.load_Tank_Types,
        TankTypeActions.update_Tank_Type_Queries,
        TankTypeActions.remove_Tank_Type_Success,
        TankTypeActions.add_Tank_Type_Success,
        TankTypeActions.edit_Tank_Type_Success
      ),
      concatLatestFrom(() => store.select(tankTypesFeature.selectQuery)),
      map(([, res]) => res),
      mergeMap((action) =>
        tankTypeService.loadTankTypes(action).pipe(
          map((res: TankType[]) =>
            TankTypeActions.load_Tank_Types_Success({ tankTypes: res })
          ),
          catchError((error: HttpErrorResponse) =>
            of(TankTypeActions.load_Tank_Types_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const removeTankType = createEffect(
  (actions = inject(Actions), tankTypeService = inject(TankTypeService)) => {
    return actions.pipe(
      ofType(TankTypeActions.remove_Tank_Type),
      mergeMap((action) =>
        tankTypeService.removeTankType(action.id).pipe(
          map((res: TankType) =>
            TankTypeActions.remove_Tank_Type_Success({
              tankType: res,
              successMessage: 'Tank Type removed successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(TankTypeActions.remove_Tank_Type_Failure({ error: error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const addTankType = createEffect(
  (actions = inject(Actions), tankTypeService = inject(TankTypeService)) => {
    return actions.pipe(
      ofType(TankTypeActions.add_Tank_Type),
      mergeMap((action) =>
        tankTypeService.addTankType(action.tankType).pipe(
          map((res: TankType) =>
            TankTypeActions.add_Tank_Type_Success({
              tankType: res,
              successMessage: 'Tank Type added successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(TankTypeActions.add_Tank_Type_Failure({ error: error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const editTankType = createEffect(
  (actions = inject(Actions), tankTypeService = inject(TankTypeService)) => {
    return actions.pipe(
      ofType(TankTypeActions.edit_Tank_Type),
      mergeMap((action) =>
        tankTypeService.editTankType(action.tankId, action.tankType).pipe(
          map((res: TankType) =>
            TankTypeActions.edit_Tank_Type_Success({
              tankType: res,
              successMessage: 'Tank Type edited successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(TankTypeActions.edit_Tank_Type_Failure({ error: error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const openTankTypeDialog = createEffect(
  (
    actions = inject(Actions),
    dialog = inject(MatDialog),
    dialogOptions = inject(DialogOptions)
  ) => {
    return actions.pipe(
      ofType(TankTypeActions.open_Tank_Type_Dialog),
      tap((action) => {
        dialog.open(TankTypeAddEditComponent, {
          ...dialogOptions,
          data: {
            tankType: action.tankType,
          },
        });
      })
    );
  },
  {
    functional: true,
    dispatch: false,
  }
);

import { createFeature, createReducer } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { initialLoadCellState } from '../states/load-cell.state';
import { LoadCellActions } from '../actions/load-cell.actions';

export const loadCellReducer = createReducer(
  initialLoadCellState,

  immerOn(LoadCellActions.load_Cell_List, (state) => {
    state.loading.list = true;
  }),

  immerOn(LoadCellActions.update_Load_Cell_Queries, (state, { query }) => {
    state.query = query;
    state.loading.list = true;
  }),

  immerOn(LoadCellActions.load_Cell_List_Success, (state, payload) => {
    state.loadCells = payload.cellList;
    state.loading.list = false;
  }),

  immerOn(
    LoadCellActions.load_Cell_List_Failure,
    LoadCellActions.delete_Load_Cell_Success,
    LoadCellActions.delete_Load_Cell_Failure,
    (state) => {
      state.loading.list = false;
    }
  ),

  immerOn(
    LoadCellActions.add_Load_Cell,
    LoadCellActions.update_Load_Cell,
    (state) => {
      state.loading.createEdit = true;
    }
  ),

  immerOn(
    LoadCellActions.add_Load_Cell_Success,
    LoadCellActions.add_Load_Cell_Failure,
    LoadCellActions.update_Load_Cell_Success,
    LoadCellActions.update_Load_Cell_Failure,
    (state) => {
      state.loading.createEdit = false;
    }
  ),

  immerOn(
    LoadCellActions.load_Cell_List_By_Location_Id_Success,
    (state, payload) => {
      state.loadCellByLocationId = payload.cellList;
    }
  )
);
export const loadCellFeature = createFeature({
  name: 'LoadCell',
  reducer: loadCellReducer,
});

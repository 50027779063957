import { PlanMode } from "../plan-mode.enum";

export const PlanModeTypes = [
  {
    value: PlanMode.Pool,
    name: 'Pool',
  },
  {
    value: PlanMode.NonPool,
    name: 'Non-Pool',
  },
  {
    value: PlanMode.Both,
    name: 'Both',
  },
];

import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { errorProps } from '../../../../../../../components/src/lib/functions/utility.functions';
import { AssetMobileWell } from '../../interfaces/asset-mobile-well.interface';
import { Asset } from '../../interfaces/asset.interface';

export const AssetMobileWellActions = createActionGroup({
  source: 'Asset Mobile Well',
  events: {
    'set_Asset_Mobile_Well_Id': props<{ id: string }>(),
    'open_Asset_Mobile_Well_Dialog': props<{
      id: string;
      asset: Asset;
    }>(),
    'load_Asset_Mobile_Wells': emptyProps(),
    'load_Asset_Mobile_Wells_Success': props<{
      assetMobileWells: AssetMobileWell[];
    }>(),
    'load_Asset_Mobile_Wells_Failure': errorProps(),
    'remove_Asset_Mobile_Well': props<{ id: string }>(),
    'remove_Asset_Mobile_Well_Success': props<{
      assetMobileWell: AssetMobileWell;
      successMessage: string;
    }>(),
    'remove_Asset_Mobile_Well_Failure': errorProps(),
    'add_Asset_Mobile_Well': props<{
      assetMobileWell: AssetMobileWell;
    }>(),
    'add_Asset_Mobile_Well_Success': props<{
      assetMobileWell: AssetMobileWell;
      successMessage: string;
    }>(),
    'add_Asset_Mobile_Well_Failure': errorProps(),
    'edit_Asset_Mobile_Well': props<{
      id: string;
      assetMobileWell: AssetMobileWell;
    }>(),
    'edit_Asset_Mobile_Well_Success': props<{
      assetMobileWell: AssetMobileWell;
      successMessage: string;
    }>(),
    'edit_Asset_Mobile_Well_Failure': errorProps(),
    'add_Asset_Mobile_Well_Row': emptyProps(),
    'remove_Asset_Mobile_Well_Row': emptyProps(),
  },
});

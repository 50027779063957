import { createFeature, createReducer, createSelector } from '@ngrx/store';
import { activityCategoryTypeInitialState } from '../states/activity-category-type.state';
import { immerOn } from 'ngrx-immer/store';
import { ActivityCategoryTypeActions } from '../actions/activity-category-type.actions';
import { activityCategoryFeature } from './activity-category.feature';

export const activityCategoryTypeReducer = createReducer(
  activityCategoryTypeInitialState,
  immerOn(
    ActivityCategoryTypeActions.remove_Activity_Category_Type,
    ActivityCategoryTypeActions.load_Activity_Category_Types_By_Category_Id,
    ActivityCategoryTypeActions.load_Activity_Category_Types,
    (state) => {
      state.loading.list = true;
    }
  ),
  immerOn(
    ActivityCategoryTypeActions.set_Activity_Category_Type_Id,
    (state, { id }) => {
      state.activityCategoryTypeId = id;
    }
  ),
  immerOn(
    ActivityCategoryTypeActions.load_Activity_Category_Types_By_Category_Id_Success,
    (state, { activityCategoryTypes }) => {
      state.activityCategoryTypes = activityCategoryTypes;
      state.loading.list = false;
    }
  ),
  immerOn(
    ActivityCategoryTypeActions.load_Activity_Category_Types_Success,
    (state, { activityCategoryTypes }) => {
      state.activityCategoryTypes = activityCategoryTypes;
      state.loading.list = false;
    }
  ),
  immerOn(
    ActivityCategoryTypeActions.add_Activity_Category_Type_Row,
    (state) => {
      state.activityCategoryTypes.unshift({
        activityCategoryTypeId: 'add',
        name: '',
        activityCategoryId: '',
        createdByName: '',
        createdDate: null,
        updatedByName: '',
        updatedDate: null,
        deleted: false,
        notes: '',
        hours: 0,
        createOutOfPortActivity: false,
        isInboundLifting: false,
        activityCategory: null,
      });
    }
  ),
  immerOn(
    ActivityCategoryTypeActions.remove_Activity_Category_Type_Row,
    (state) => {
      state.activityCategoryTypes = state.activityCategoryTypes.filter(
        (item) => item.activityCategoryTypeId !== 'add'
      );
    }
  ),
  immerOn(
    ActivityCategoryTypeActions.load_Activity_Category_Types_Failure,
    ActivityCategoryTypeActions.load_Activity_Category_Types_By_Category_Id_Failure,
    ActivityCategoryTypeActions.load_Activity_Category_Types_By_Category_Id_Success,
    ActivityCategoryTypeActions.remove_Activity_Category_Type_Success,
    ActivityCategoryTypeActions.remove_Activity_Category_Type_Failure,
    (state) => {
      state.loading.list = false;
    }
  ),
  immerOn(
    ActivityCategoryTypeActions.add_Activity_Category_Type,
    ActivityCategoryTypeActions.edit_Activity_Category_Type,
    (state) => {
      state.loading.createEdit = true;
    }
  ),
  immerOn(
    ActivityCategoryTypeActions.add_Activity_Category_Type_Success,
    ActivityCategoryTypeActions.add_Activity_Category_Type_Failure,
    ActivityCategoryTypeActions.edit_Activity_Category_Type_Success,
    ActivityCategoryTypeActions.edit_Activity_Category_Type_Failure,
    (state) => {
      state.loading.createEdit = false;
    }
  )
);

export const activityCategoryTypeFeature = createFeature({
  name: 'activityCategoryType',
  reducer: activityCategoryTypeReducer,
  extraSelectors: ({ selectLoading, selectActivityCategoryTypes }) => ({
    selectListLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.list
    ),
    selectCreateEditLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.createEdit
    ),
    selectActivityCategoryTypes: createSelector(
      selectActivityCategoryTypes,
      (activityCategoryTypes) => activityCategoryTypes
    ),
    selectActivityCategory: (activityCategory: string[]) =>
      createSelector(
        activityCategoryFeature.selectActivityCategories,
        (activityCat) =>
          activityCat.filter((item) => activityCategory.includes(item.name))
      ),
  }),
});

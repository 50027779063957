import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SearchQuery } from '../../../../../../../components/src/lib/interfaces/search-query.interface';
import { BulkType } from '../../interfaces/bulk-type.interface';
import { errorProps } from '../../../../../../../components/src/lib/functions/utility.functions';
import { Constant } from 'libs/services/src/lib/services/shared/constant.interface';

export const BulkTypeActions = createActionGroup({
  source: 'Bulk Types',
  events: {
    update_Bulk_Type_Queries: props<{ query: SearchQuery }>(),
    load_Bulk_Type_Page: emptyProps(),
    load_Type_List: emptyProps(),
    load_Type_List_Success: props<{ typeList: Constant[] }>(),
    load_Type_List_Failure: errorProps(),
    load_Bulk_Types: emptyProps(),
    load_Bulk_Types_Success: props<{ bulkTypes: BulkType[] }>(),
    load_Bulk_Types_Failure: errorProps(),
    remove_Bulk_Type: props<{ id: string }>(),
    remove_Bulk_Type_Success: props<{
      bulkType: BulkType;
      successMessage: string;
    }>(),
    remove_Bulk_Type_Failure: errorProps(),
    open_Bulk_Type_Dialog: props<{ bulkType: BulkType | null }>(),
    add_Bulk_Type: props<{ bulkType: BulkType }>(),
    add_Bulk_Type_Success: props<{
      bulkType: BulkType;
      successMessage: string;
    }>(),
    add_Bulk_Type_Failure: errorProps(),
    edit_Bulk_Type: props<{ bulkId: string; bulkType: BulkType }>(),
    edit_Bulk_Type_Success: props<{
      bulkType: BulkType;
      successMessage: string;
    }>(),
    edit_Bulk_Type_Failure: errorProps(),
    load_Bulk_Fluid_Types: emptyProps(),
  },
});

import {
  Component,
  DestroyRef,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { BulkType } from '../../../../../../../../libs/services/src/lib/services/maintenance/interfaces/bulk-type.interface';
import { BulkTypeActions } from '../../../../../../../../libs/services/src/lib/services/maintenance/store/actions/bulk-type.actions';
import { Subject, takeUntil } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import {
  bulkTypesFeature,
  unitsFeature,
} from '../../../../../../../../libs/services/src/lib/services/maintenance/store/features';
import { LoadingDirective } from '../../../../../../../../libs/components/src/lib/directives/loading.directive';
import { SingleSelectComponent } from '../../../../../../../../libs/components/src/lib/components/single-select/single-select.component';
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'lha-bulk-type-add-edit',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    NgForOf,
    AsyncPipe,
    LoadingDirective,
    NgIf,
    SingleSelectComponent,
    CdkDrag,
    CdkDragHandle,
  ],
  templateUrl: './bulk-type-add-edit.component.html',
  styleUrls: ['./bulk-type-add-edit.component.scss'],
})
export class BulkTypeAddEditComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  dialogRef = inject(MatDialogRef<BulkTypeAddEditComponent>);
  data: { bulkType: BulkType } = inject(MAT_DIALOG_DATA);
  store = inject(Store);
  action = inject(Actions);
  bulks = this.store.selectSignal(bulkTypesFeature.selectBulkTypesState);
  units = this.store.selectSignal(unitsFeature.selectUnits);
  isAdd = true;
  unsubscribe: Subject<boolean> = new Subject();
  bulkType!: BulkType;
  form = new FormGroup({
    name: new FormControl<string>('', [Validators.required]),
    unitName: new FormControl<string>('', [Validators.required]),
    fluidType: new FormControl<string>('', [Validators.required]),
  });

  ngOnInit(): void {
    this.subCloseDialog();
    this.initAddEdit();
  }

  private initAddEdit(): void {
    this.isAdd = !this.data.bulkType;
    if (!this.isAdd) {
      this.bulkType = this.data.bulkType;
      this.pathForm(this.bulkType);
    }
  }

  private subCloseDialog(): void {
    this.action
      .pipe(
        ofType(
          BulkTypeActions.add_Bulk_Type_Success,
          BulkTypeActions.edit_Bulk_Type_Success
        )
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  private pathForm(bulkType: BulkType): void {
    this.form.patchValue(bulkType);
  }

  saveBulkType(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const model = {
      ...this.form.value,
    } as BulkType;

    if (this.isAdd) {
      this.store.dispatch(BulkTypeActions.add_Bulk_Type({ bulkType: model }));
    } else {
      this.store.dispatch(
        BulkTypeActions.edit_Bulk_Type({
          bulkType: model,
          bulkId: this.bulkType.bulkTypeId,
        })
      );
    }
  }
}

<div
  *ngIf="locationState()"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  cdkDragBoundary=".cdk-overlay-container"
>
  <div mat-dialog-title>
    <h4>{{ isAdd ? 'Add ' : 'Edit ' }} Location</h4>
  </div>
  <mat-dialog-content *lhaLoading="locationState().loading.createEdit">
    <form [formGroup]="form" (ngSubmit)="saveLocation()" class="form-container">
      <div class="form__box">
        <div class="box_element_style">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>Location Name</mat-label>
            <input matInput formControlName="name" />
            <mat-error *ngIf="form.controls.name.hasError('required')">
              Location name is required.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="box_element_style">
          <lha-single-select
            class="box_element_style"
            [options]="planModeTypes"
            formControlName="planMode"
            bindValue="value"
            bindLabel="name"
            placeholder="Plan Modes"
          />
          <div
            *ngIf="
              form.controls.planMode.invalid && form.controls.planMode.touched
            "
          >
            <mat-error *ngIf="form.controls.planMode.hasError('required')">
              Please select a plan mode
            </mat-error>
          </div>
        </div>
      </div>
      <div class="form__box">
        <div class="box_element_style">
          <lha-single-select
            class="box_element_style"
            [options]="locationState().timezones"
            formControlName="timeZoneInfoId"
            bindValue="id"
            bindLabel="name"
            placeholder="Timezones"
          />
          <div
            *ngIf="
              form.controls.timeZoneInfoId.invalid &&
              form.controls.timeZoneInfoId.touched
            "
          >
            <mat-error
              *ngIf="form.controls.timeZoneInfoId.hasError('required')"
            >
              Please select a timezone
            </mat-error>
          </div>
        </div>
        <mat-slide-toggle formControlName="isActive" class="align-toggle"
          >Is Active</mat-slide-toggle
        >
      </div>
      <mat-dialog-actions align="end">
        <button mat-raised-button color="primary" type="submit">
          {{ isAdd ? 'Add ' : 'Save ' }}
        </button>
        <button
          mat-raised-button
          color="warn"
          type="button"
          mat-dialog-close=""
        >
          Cancel
        </button>
      </mat-dialog-actions>
    </form>
  </mat-dialog-content>
</div>

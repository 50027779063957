import { assetInitialState } from '../../interfaces/asset-state.interface';
import { maintenanceInitialState } from './maintenance.state';

export const assetsInitialState: assetInitialState = {
  assets: [],
  assetsHead: [],
  assetTypesList: [],
  ...maintenanceInitialState,
  loading: {
    list: false,
    createEdit: false,
    loadHead: false,
    export: false,
  },
};

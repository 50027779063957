<div
  *ngIf="distanceState()"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  cdkDragBoundary=".cdk-overlay-container"
>
  <div mat-dialog-title>
    <h4>{{ isAdd ? 'Add ' : 'Edit ' }} Distance</h4>
  </div>
  <mat-dialog-content *lhaLoading="distanceState().loading.createEdit">
    <form [formGroup]="form" (ngSubmit)="saveDistance()">
      <div class="form__block">
        <div class="form__box">
          <lha-single-select
            [options]="assets()"
            formControlName="baseAssetId"
            bindValue="assetId"
            placeholder="First Asset"
          />
          <div
            *ngIf="
              form.controls.baseAssetId.invalid &&
              form.controls.baseAssetId.touched
            "
          >
            <mat-error *ngIf="form.controls.baseAssetId.hasError('required')">
              First asset is required.
            </mat-error>
          </div>
        </div>
        <div class="form__box">
          <lha-single-select
            [options]="assets()"
            formControlName="toAssetId"
            bindValue="assetId"
            placeholder="Second Asset"
          />
          <div
            *ngIf="
              form.controls.baseAssetId.invalid &&
              form.controls.baseAssetId.touched
            "
          >
            <mat-error
              *ngIf="
                (form.controls.toAssetId.hasError('match') ||
                  form.controls.baseAssetId.hasError('match')) &&
                !form.controls.toAssetId.hasError('required') &&
                !form.controls.baseAssetId.hasError('required') &&
                form.controls.baseAssetId.touched
              "
            >
              Second and First Asset must be different.
            </mat-error>
          </div>
          <div
            *ngIf="
              form.controls.toAssetId.invalid && form.controls.toAssetId.touched
            "
          >
            <mat-error *ngIf="form.controls.toAssetId.hasError('required')">
              Second asset is required.
            </mat-error>
          </div>
        </div>
        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>Distance (miles)</mat-label>
            <input
              matInput
              formControlName="distanceInMiles"
              type="number"
              placeholder="Distance"
            />
            <mat-error
              *ngIf="form.controls.distanceInMiles.hasError('greaterThan')"
            >
              Distance must be a number greater than 0.
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <mat-dialog-actions align="end">
        <button mat-raised-button color="primary" type="submit">
          {{ isAdd ? 'Add ' : 'Save ' }}
        </button>
        <button
          mat-raised-button
          color="warn"
          type="button"
          mat-dialog-close=""
        >
          Cancel
        </button>
      </mat-dialog-actions>
    </form>
  </mat-dialog-content>
</div>

import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { catchError, map, mergeMap, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { BulkTypeService } from '../../bulk-type.service';
import { BulkTypeActions } from '../actions/bulk-type.actions';
import { BulkType } from '../../interfaces/bulk-type.interface';
import { MatDialog } from '@angular/material/dialog';
import { switchMap, tap } from 'rxjs/operators';
import { BulkTypeAddEditComponent } from '../../../../../../../../apps/allocate/src/app/maintenance/shared/components/bulk-type-add-edit/bulk-type-add-edit.component';
import { Store } from '@ngrx/store';
import { bulkTypesFeature } from '../features';
import { DialogOptions } from '../../../config/dialog-options';
import { ConstantService } from '../../../constant.service';
import { Constant } from 'libs/services/src/lib/services/shared/constant.interface';
import { TankTypeActions } from '../actions/tank-type.actions';
import { UnitActions } from '../actions/units.actions';

export const loadBulkTypePage = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(BulkTypeActions.load_Bulk_Type_Page),
      switchMap(() =>
        of(
          BulkTypeActions.load_Bulk_Types(),
          BulkTypeActions.load_Bulk_Fluid_Types(),
          TankTypeActions.load_Tank_Types(),
          UnitActions.load_Units()
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const loadBulkTypes = createEffect(
  (
    actions = inject(Actions),
    store = inject(Store),
    bulkTypeService = inject(BulkTypeService)
  ) => {
    return actions.pipe(
      ofType(
        BulkTypeActions.load_Bulk_Types,
        BulkTypeActions.update_Bulk_Type_Queries,
        BulkTypeActions.remove_Bulk_Type_Success,
        BulkTypeActions.add_Bulk_Type_Success,
        BulkTypeActions.edit_Bulk_Type_Success
      ),
      concatLatestFrom(() => store.select(bulkTypesFeature.selectQuery)),
      map(([, res]) => res),
      mergeMap((action) =>
        bulkTypeService.loadBulkTypes(action).pipe(
          map((res: BulkType[]) =>
            BulkTypeActions.load_Bulk_Types_Success({ bulkTypes: res })
          ),
          catchError((error: HttpErrorResponse) =>
            of(BulkTypeActions.load_Bulk_Types_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const removeBulkType = createEffect(
  (actions = inject(Actions), bulkTypeService = inject(BulkTypeService)) => {
    return actions.pipe(
      ofType(BulkTypeActions.remove_Bulk_Type),
      mergeMap((action) =>
        bulkTypeService.removeBulkType(action.id).pipe(
          map((res: BulkType) =>
            BulkTypeActions.remove_Bulk_Type_Success({
              bulkType: res,
              successMessage: 'Bulk Type removed successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(BulkTypeActions.remove_Bulk_Type_Failure({ error: error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const loadBulkFluidTypes = createEffect(
  (actions = inject(Actions), constantService = inject(ConstantService)) => {
    return actions.pipe(
      ofType(BulkTypeActions.load_Bulk_Fluid_Types),
      mergeMap(() =>
        constantService.loadBulkFluidTypes().pipe(
          map((res: Constant[]) =>
            BulkTypeActions.load_Type_List_Success({ typeList: res })
          ),
          catchError((error: HttpErrorResponse) =>
            of(BulkTypeActions.load_Type_List_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const addBulkType = createEffect(
  (actions = inject(Actions), bulkTypeService = inject(BulkTypeService)) => {
    return actions.pipe(
      ofType(BulkTypeActions.add_Bulk_Type),
      mergeMap((action) =>
        bulkTypeService.addBulkType(action.bulkType).pipe(
          map((res: BulkType) =>
            BulkTypeActions.add_Bulk_Type_Success({
              bulkType: res,
              successMessage: 'Bulk Type added successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(BulkTypeActions.add_Bulk_Type_Failure({ error: error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const editBulkType = createEffect(
  (actions = inject(Actions), bulkTypeService = inject(BulkTypeService)) => {
    return actions.pipe(
      ofType(BulkTypeActions.edit_Bulk_Type),
      mergeMap((action) =>
        bulkTypeService.editBulkType(action.bulkId, action.bulkType).pipe(
          map((res: BulkType) =>
            BulkTypeActions.edit_Bulk_Type_Success({
              bulkType: res,
              successMessage: 'Bulk Type edited successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(BulkTypeActions.edit_Bulk_Type_Failure({ error: error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const openBulkTypeDialog = createEffect(
  (
    actions = inject(Actions),
    dialog = inject(MatDialog),
    dialogOptions = inject(DialogOptions)
  ) => {
    return actions.pipe(
      ofType(BulkTypeActions.open_Bulk_Type_Dialog),
      tap((action) => {
        dialog.open(BulkTypeAddEditComponent, {
          ...dialogOptions,
          data: {
            bulkType: action.bulkType,
          },
        });
      })
    );
  },
  {
    functional: true,
    dispatch: false,
  }
);

import {
  NgxMatDatetimePickerModule,
  NgxMatDatepickerInput,
} from '@angular-material-components/datetime-picker';
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { AsyncPipe, DatePipe, NgForOf, NgIf } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { SingleSelectComponent } from '../../../../../../../../libs/components/src/lib/components/single-select/single-select.component';
import { LoadingDirective } from '../../../../../../../../libs/components/src/lib/directives/loading.directive';
import {
  getDefaultTime,
  timeAfter,
  timeBefore,
} from '../../../../../../../../libs/components/src/lib/functions/utility.functions';
import { confirmActions } from 'libs/components/src/lib/store/confirm.actions';
import { Asset } from '../../../../../../../../libs/services/src/lib/services/maintenance/interfaces/asset.interface';
import { Operator } from '../../../../../../../../libs/services/src/lib/services/maintenance/interfaces/operator.interface';
import { AssetActions } from '../../../../../../../../libs/services/src/lib/services/maintenance/store/actions/assets.actions';
import {
  assetsFeature,
  locationsFeature,
  operatorsFeature,
} from '../../../../../../../../libs/services/src/lib/services/maintenance/store/features';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MultiselectComponent } from '../../../../../../../../libs/components/src/lib/components/multiselect/multiselect.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import moment from 'moment';
import { TimezoneService } from 'libs/services/src/lib/services/timezone/timezone.service';
import { DateTime } from 'luxon';

@Component({
  selector: 'lha-asset-add-edit',
  standalone: true,
  imports: [
    FormsModule,
    LoadingDirective,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    NgForOf,
    ReactiveFormsModule,
    AsyncPipe,
    NgIf,
    SingleSelectComponent,
    CdkDrag,
    CdkDragHandle,
    MatDatepickerModule,
    NgxMatDatetimePickerModule,
    DatePipe,
    MatIconModule,
    MatSlideToggleModule,
    MultiselectComponent,
  ],
  templateUrl: './asset-add-edit.component.html',
  styleUrls: ['./asset-add-edit.component.scss'],
  providers: [DatePipe],
})
export class AssetAddEditComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  dialogRef = inject(MatDialogRef<AssetAddEditComponent>);
  data: { asset: Asset } = inject(MAT_DIALOG_DATA);
  store = inject(Store);
  action = inject(Actions);
  datePipe = inject(DatePipe);
  timezoneService = inject(TimezoneService);
  assetState = this.store.selectSignal(assetsFeature.selectAssetsState);
  assetsHeads = this.store.selectSignal(
    assetsFeature.selectFilterAssetsHead(this.data.asset?.assetId)
  );
  operators = this.store.selectSignal(operatorsFeature.selectOperators);
  locations = this.store.selectSignal(locationsFeature.selectLocations);
  isAdd = true;
  asset!: Asset;
  locDependencies = {
    locType: ['POR', 'MOB'],
    locTypeOff: ['OFF', 'WEL'],
  };
  form = new FormGroup({
    name: new FormControl<string>('', [Validators.required]),
    assetType: new FormControl<string | null>(
      {
        value: null,
        disabled: !!this.data?.asset,
      },
      [Validators.required]
    ),
    clientId: new FormControl<string>(
      {
        value: '',
        disabled: !!(
          this.data?.asset &&
          this.locDependencies.locType.includes(this.data.asset.assetType)
        ),
      },
      [Validators.required]
    ),
    startDateTime: new FormControl<Date | null>(
      {
        value: null,
        disabled: !!(
          this.data?.asset &&
          this.locDependencies.locType.includes(this.data.asset.assetType)
        ),
      },
      [Validators.required]
    ),
    endDateTime: new FormControl<Date | null>(null),
    clusterHeadId: new FormControl<string | null>(
      {
        value: null,
        disabled:
          !this.data?.asset ||
          (this.data?.asset &&
            (this.data.asset.isClusterHead || !this.data.asset?.clientId)),
      },
      []
    ),
    addNewHistory: new FormControl<boolean>(false),
    locationIds: new FormControl<string[]>([], [Validators.required]),
  });
  timeAfter: Date | null = null;
  timeBefore: Date | null = null;
  minDifference = 1;
  defaultTime = getDefaultTime({ sec: 0 });

  ngOnInit(): void {
    this.subCloseDialog();
    this.initAddEdit();
    this.subAssetType();
  }

  private initAddEdit(): void {
    this.isAdd = !this.data.asset;
    if (!this.isAdd) {
      this.asset = this.data.asset;
      this.pathForm(this.asset);
      if (this.locDependencies.locTypeOff.includes(this.asset.assetType)) {
        this.timeAfter = timeAfter(this.asset.endDateTime, {
          min: this.minDifference,
        });
        this.timeBefore = timeBefore(this.asset.startDateTime, {
          min: this.minDifference,
        });
        this.store.dispatch(
          AssetActions.load_Cluster_Heads({ id: this.asset.clientId })
        );
      }
    }
  }

  private subCloseDialog(): void {
    this.action
      .pipe(
        ofType(
          AssetActions.add_Asset_Success,
          AssetActions.edit_Asset_Success,
          AssetActions.close_Asset_Dialog
        )
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.dialogRef.close();
        this.store.dispatch(
          AssetActions.load_Cluster_Heads_Success({ assetsHead: [] })
        );
      });
  }

  subAssetType(): void {
    this.form.controls.assetType.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        if (this.locDependencies.locType.includes(value ?? '')) {
          this.form.controls.clientId.disable();
          this.form.controls.clientId.setValue(null);
          this.form.controls.startDateTime.disable();
          this.form.controls.endDateTime.disable();
        } else {
          this.form.controls.clientId.enable();
          this.form.controls.startDateTime.enable();
          this.form.controls.endDateTime.enable();
        }
        if (!value || this.locDependencies.locType.includes(value ?? '')) {
          this.form.controls.clusterHeadId.disable();
        }
      });
  }

  changeOperator(value: string): void {
    if (value) {
      this.store.dispatch(AssetActions.load_Cluster_Heads({ id: value }));
      if (!this.asset?.isClusterHead) {
        this.form.controls.clusterHeadId.enable();
      }
    } else {
      if (!this.asset?.isClusterHead) {
        this.form.controls.clusterHeadId.disable();
      }
      this.store.dispatch(
        AssetActions.load_Cluster_Heads_Success({ assetsHead: [] })
      );
    }
    this.form.controls.clusterHeadId.patchValue(null);
  }

  private pathForm(asset: Asset): void {
    this.form.patchValue({
      ...asset,
      startDateTime: moment(asset.startDateTime, 'DD-MM-YYYY HH:mm').toDate(),
      endDateTime: moment(asset.endDateTime, 'DD-MM-YYYY HH:mm').toDate(),
    });
  }

  onAddNewHistoryChange(checked: boolean): void {
    if (checked) {
      this.form.controls.endDateTime.patchValue(null);
      this.timeAfter = timeAfter(this.form.controls.endDateTime.value, {
        min: this.minDifference,
      });
      if (this.asset.endDateTime) {
        this.changeStartDate(this.asset.endDateTime);
        this.form.controls.startDateTime.patchValue(this.asset.endDateTime);
      } else {
        this.form.controls.startDateTime.patchValue(null);
        this.timeBefore = timeBefore(this.form.controls.startDateTime.value, {
          min: this.minDifference,
        });
      }
      this.form.controls.clientId.patchValue(null);
      this.form.controls.clusterHeadId.patchValue(null);
    } else {
      this.form.controls.startDateTime.patchValue(this.asset.startDateTime);
      this.changeStartDate(this.asset.startDateTime);
      this.form.controls.endDateTime.patchValue(this.asset.endDateTime);
      if (this.asset.endDateTime) {
        this.changeEndDDate(this.asset.endDateTime);
      } else {
        this.timeAfter = timeAfter(this.form.controls.endDateTime.value, {
          min: this.minDifference,
        });
      }
      this.form.controls.clientId.patchValue(this.asset.clientId);
      this.changeOperator(this.asset.clientId.toString());
      this.form.controls.clusterHeadId.patchValue(this.asset.clusterHeadId);
    }
  }

  saveAsset(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const model = {
      ...this.form.value,
    } as Asset;

    model.startDateTime = this.timezoneService.toUTC(
      new Date(model.startDateTime)
    );
    model.endDateTime = this.timezoneService.toUTC(
      new Date(model.endDateTime!)
    );

    const isEndDate = this.form.controls.endDateTime.getRawValue();

    if (this.isAdd) {
      if (this.locDependencies.locType.includes(model.assetType)) {
        this.store.dispatch(AssetActions.add_Asset({ asset: model }));
        return;
      }

      this.store.dispatch(
        confirmActions.request_Confirmation({
          titles: {
            titles: isEndDate
              ? [
                  `${this.getOperatorNameById(
                    model.clientId
                  )} will own this asset from ${this.formatDate(
                    model.startDateTime
                  )}
            to ${this.formatDate(model.endDateTime)}`,
                  'You will not be able to edit the ownership history for this asset before this period.',
                  'Do you want to continue?',
                ]
              : [
                  `${this.getOperatorNameById(
                    model.clientId
                  )} will now own this asset from ${this.formatDate(
                    model.startDateTime
                  )}.`,
                  'You cannot add any history that precedes this date.',
                  'Do you want to continue?',
                ],
            btnConfirm: 'Yes',
            btnReject: 'No',
          },
          confirm: AssetActions.add_Asset({ asset: model }),
        })
      );
    } else {
      model.assetType = this.asset.assetType;
      if (isEndDate && this.form.controls.endDateTime.disabled) {
        model.endDateTime = isEndDate;
      }
      if (this.locDependencies.locType.includes(model.assetType)) {
        this.store.dispatch(
          AssetActions.edit_Asset({
            asset: model,
            assetId: this.asset.assetId,
            addNewHistory: this.form.controls.addNewHistory.value ?? false,
          })
        );
        return;
      }

      const endDateMessage = isEndDate
        ? `to ${this.formatDate(model.endDateTime)}`
        : '';

      const oldEndDateMessage = this.asset.endDateTime
        ? `to ${this.formatDate(this.asset.endDateTime)}`
        : '';

      if (
        !this.form.controls.addNewHistory.value &&
        (model.clientId !== this.asset.clientId ||
          model.startDateTime !== this.asset.startDateTime ||
          model.endDateTime !== this.asset.endDateTime)
      ) {
        this.store.dispatch(
          confirmActions.request_Confirmation({
            titles: {
              titles: [
                'You are editing the asset ownership history of this asset from: ',
                `${this.getOperatorNameById(
                  this.asset.clientId
                )} owning it from ${this.formatDate(
                  this.asset.startDateTime
                )} ${oldEndDateMessage}.`,
                'to',
                `${this.getOperatorNameById(
                  model.clientId
                )} owning it from ${this.formatDate(
                  model.startDateTime
                )} ${endDateMessage}.`,
                'Do you want to continue?',
              ],
              btnConfirm: 'Yes',
              btnReject: 'No',
            },
            confirm: AssetActions.edit_Asset({
              asset: model,
              assetId: this.asset.assetId,
              addNewHistory: this.form.controls.addNewHistory.value ?? false,
            }),
          })
        );
        return;
      }

      if (this.form.controls.addNewHistory.value) {
        this.store.dispatch(
          confirmActions.request_Confirmation({
            titles: {
              titles: [
                ...(this.asset.endDateTime
                  ? [
                      `${this.getOperatorNameById(
                        model.clientId
                      )} will own this asset from ${this.formatDate(
                        model.startDateTime
                      )}
                      ${endDateMessage}.`,
                      'You will not be able to edit the ownership history for ' +
                        'this asset before this period.',
                    ]
                  : [
                      `${this.getOperatorNameById(
                        model.clientId
                      )} will now own this asset from ${this.formatDate(
                        model.startDateTime
                      )}
                      ${endDateMessage}.`,
                      `${this.asset.clientName} will be recorded as owning this
                      asset from ${this.formatDate(
                        this.asset.startDateTime
                      )} to ${this.formatDate(model.startDateTime)}.`,
                      `You will not be able to edit ${this.asset.clientName} ownership history for this asset
                      during or before this period.`,
                    ]),
                'Do you want to continue?',
              ],
              btnConfirm: 'Yes',
              btnReject: 'No',
            },
            confirm: this.asset.isClusterHead
              ? confirmActions.request_Confirmation({
                  titles: {
                    titles: [
                      `Do you want to change all assets in this cluster to have ${this.getOperatorNameById(
                        model.clientId
                      )} assigned as Operator?
                       If you select no, all the child assets of this asset will no longer be in a cluster.
                       Do you wish to proceed?`,
                    ],
                    btnConfirm: 'Yes',
                    btnReject: 'No',
                    btnCancel: 'Cancel',
                  },
                  confirm: AssetActions.edit_Asset({
                    asset: { ...model, updateChildsOperators: true },
                    assetId: this.asset.assetId,
                    addNewHistory:
                      this.form.controls.addNewHistory.value ?? false,
                  }),
                  reject: AssetActions.edit_Asset({
                    asset: { ...model, updateChildsOperators: false },
                    assetId: this.asset.assetId,
                    addNewHistory:
                      this.form.controls.addNewHistory.value ?? false,
                  }),
                })
              : model.clusterHeadId
              ? confirmActions.request_Confirmation({
                  titles: {
                    titles: [
                      `You are moving ${
                        this.asset.name
                      } from ${this.getAssetNameById(
                        this.asset.clusterHeadId
                      )}'s cluster to ${this.getAssetNameById(
                        model.clusterHeadId
                      )}'s cluster`,
                    ],
                    btnConfirm: 'Yes',
                    btnReject: 'No',
                  },
                  confirm: AssetActions.edit_Asset({
                    asset: model,
                    assetId: this.asset.assetId,
                    addNewHistory:
                      this.form.controls.addNewHistory.value ?? false,
                  }),
                })
              : this.asset.clusterHeadId
              ? confirmActions.request_Confirmation({
                  titles: {
                    titles: [
                      `Changing the operator of this ${
                        this.asset.name
                      } will remove it from ${this.getAssetNameById(
                        this.asset.clusterHeadId
                      )}'s cluster. Do you wish to proceed?`,
                    ],
                    btnConfirm: 'Yes',
                    btnReject: 'No',
                  },
                  confirm: AssetActions.edit_Asset({
                    asset: model,
                    assetId: this.asset.assetId,
                    addNewHistory:
                      this.form.controls.addNewHistory.value ?? false,
                  }),
                })
              : AssetActions.edit_Asset({
                  asset: model,
                  assetId: this.asset.assetId,
                  addNewHistory:
                    this.form.controls.addNewHistory.value ?? false,
                }),
          })
        );
        return;
      }

      this.store.dispatch(
        AssetActions.edit_Asset({
          asset: model,
          assetId: this.asset.assetId,
          addNewHistory: this.form.controls.addNewHistory.value ?? false,
        })
      );
    }
  }

  changeStartDate(value: Date): void {
    this.changeAllReadyStartDate();
    this.timeBefore = timeBefore(value, { min: this.minDifference });
  }

  changeEndDDate(value: Date): void {
    this.timeAfter = timeAfter(value, { min: this.minDifference });
  }

  clearStartDate(event: Event): void {
    event.stopPropagation();
    if (
      !this.form.controls.startDateTime.value ||
      this.form.controls.startDateTime.disabled
    ) {
      return;
    }
    this.changeAllReadyStartDate();
    this.form.controls.startDateTime.patchValue(null);
    this.timeBefore = timeBefore(this.form.controls.startDateTime.value, {
      min: this.minDifference,
    });
  }

  clearEndDate(event: Event): void {
    event.stopPropagation();
    if (
      !this.form.controls.endDateTime.value ||
      this.form.controls.endDateTime.disabled
    ) {
      return;
    }
    this.form.controls.endDateTime.patchValue(null);
    this.timeAfter = timeAfter(this.form.controls.endDateTime.value, {
      min: this.minDifference,
    });
  }

  changeAllReadyStartDate(): void {
    if (
      !this.isAdd &&
      this.asset.clientId === this.form.controls.clientId.value &&
      this.form.controls.endDateTime.disabled
    ) {
      this.form.controls.endDateTime.enable();
      this.form.controls.endDateTime.patchValue(null);
      this.timeAfter = timeAfter(null, { min: this.minDifference });
    }
  }

  private getOperatorNameById(id: string): string {
    return this.operators().find((item) => item.clientId === id)?.name ?? '';
  }

  private formatDate(date: string | Date | null): string {
    if (!date) return '';

    let parsedDate: DateTime;

    if (typeof date === 'string') {
      parsedDate = DateTime.fromFormat(date, 'dd-MM-yyyy HH:mm');
    } else {
      parsedDate = DateTime.fromJSDate(date);
    }

    return parsedDate.toFormat('dd/MM/yy HH:mm');
  }

  private getAssetNameById(id: string): string {
    return (
      this.assetState().assets.find((item) => item.assetId === id)?.name ?? ''
    );
  }
}

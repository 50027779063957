import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { LoadingDirective } from '../../../../../../../../libs/components/src/lib/directives/loading.directive';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { SingleSelectComponent } from '../../../../../../../../libs/components/src/lib/components/single-select/single-select.component';
import { Subject } from 'rxjs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MultiselectComponent } from '../../../../../../../../libs/components/src/lib/components/multiselect/multiselect.component';
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { Location } from '../../../../../../../../libs/services/src/lib/services/maintenance/interfaces/location.interface';
import { locationsFeature } from '../../../../../../../../libs/services/src/lib/services/maintenance/store/features';
import { LocationActions } from '../../../../../../../../libs/services/src/lib/services/maintenance/store/actions/locations.actions';
import { PlanModeTypes } from '../../../../../../../../libs/services/src/lib/services/maintenance/locations/enums/location-plan-mode-types/location-plan-types';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'lha-location-add-edit',
  standalone: true,
  imports: [
    FormsModule,
    LoadingDirective,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    NgForOf,
    ReactiveFormsModule,
    SingleSelectComponent,
    AsyncPipe,
    NgIf,
    MatSlideToggleModule,
    MultiselectComponent,
    CdkDrag,
    CdkDragHandle,
  ],
  templateUrl: './location-add-edit.component.html',
  styleUrls: ['./location-add-edit.component.scss'],
})
export class LocationAddEditComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  dialogRef = inject(MatDialogRef<LocationAddEditComponent>);
  data: { location: Location } = inject(MAT_DIALOG_DATA);
  store = inject(Store);
  action = inject(Actions);
  locationState = this.store.selectSignal(
    locationsFeature.selectLocationsState
  );
  isAdd = true;
  unsubscribe: Subject<boolean> = new Subject();
  planModeTypes = PlanModeTypes;
  location: Location | null = null;
  form = new FormGroup({
    name: new FormControl<string>('', [Validators.required]),
    isActive: new FormControl<boolean>(true),
    planMode: new FormControl<number | null>(null, [Validators.required]),
    timeZoneInfoId: new FormControl<string>('', [Validators.required]),
  });

  ngOnInit(): void {
    this.subCloseDialog();
    this.initAddEdit();
  }

  private initAddEdit(): void {
    this.isAdd = !this.data.location;
    if (!this.isAdd) {
      this.location = this.data.location;
      this.patchForm(this.location);
    }
  }

  private subCloseDialog(): void {
    this.action
      .pipe(
        ofType(
          LocationActions.add_Location_Success,
          LocationActions.edit_Location_Success
        )
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  private patchForm(location: Location): void {
    this.form.patchValue({
      name: location.name,
      isActive: location.isActive,
      planMode: location.planMode,
      timeZoneInfoId: location.timeZoneInfoId,
    });
  }

  saveLocation(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    const model = {
      ...this.form.value,
    } as Location;

    if (this.isAdd) {
      this.store.dispatch(LocationActions.add_Location({ location: model }));
    } else {
      this.store.dispatch(
        LocationActions.edit_Location({
          location: model,
          locationId: this.location!.locationId,
        })
      );
    }
  }
}

import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { AppSettings } from './app-settings.interface';
import { errorProps } from './utility.functions';

export const SettingActions = createActionGroup({
  source: 'Setting',
  events: {
    'load_Settings': emptyProps(),
    'load_Settings_Success': props<{ settings: AppSettings }>(),
    'load_Settings_Failure': errorProps(),
    'add_Settings': props<{ currency: string }>(),
    'add_Settings_Success': props<{
      settings: AppSettings;
      successMessage: string;
    }>(),
    'add_Settings_Failure': errorProps(),
    'edit_Settings': props<{ currency: string; id: string }>(),
    'edit_Settings_Success': props<{
      settings: AppSettings;
      successMessage: string;
    }>(),
    'edit_Settings_Failure': errorProps(),
  },
});

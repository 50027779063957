<div
  *ngIf="cargoState()"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  cdkDragBoundary=".cdk-overlay-container"
>
  <div mat-dialog-title>
    <h4>{{ isAdd ? 'Add ' : 'Edit ' }} Cargo</h4>
  </div>
  <mat-dialog-content *lhaLoading="cargoState().loading.createEdit">
    <form [formGroup]="form" (ngSubmit)="saveCargo()">
      <div class="form__block">
        <div class="form__box" *ngIf="!isAdd">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>Name</mat-label>
            <input matInput formControlName="ccuName" />
          </mat-form-field>
        </div>

        <div class="form__box" *ngIf="!isAdd">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>Description</mat-label>
            <input matInput formControlName="description" />
          </mat-form-field>
        </div>

        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>CCU ID</mat-label>
            <input matInput formControlName="ccuId" />
            <mat-error *ngIf="form.controls.ccuId.hasError('required')">
              CCU ID is required.
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form__box">
          <lha-single-select
            [options]="userCargoLocations"
            formControlName="locationId"
            bindValue="locationId"
            placeholder="Location"
            [disabled]="isUser()"
          />
          <div
            *ngIf="
              form.controls.locationId.invalid && form.controls.locationId.touched
            "
          >
            <mat-error *ngIf="form.controls.locationId.hasError('required')">
              Location is required.
            </mat-error>
          </div>
        </div>

        <div class="form__box">
          <lha-single-select
            [options]="familyVm()"
            formControlName="familyId"
            bindValue="cargoFamilyId"
            placeholder="Family"
            (selectChange)="changeFamily($event)"
          />
          <div
            *ngIf="
              form.controls.familyId.invalid && form.controls.familyId.touched
            "
          >
            <mat-error *ngIf="form.controls.familyId.hasError('required')">
              Family is required.
            </mat-error>
          </div>
        </div>

        <div class="form__box">
          <lha-single-select
            [options]="sizeVm()"
            formControlName="sizeId"
            bindValue="cargoSizeId"
            placeholder="Size"
          />
          <div
            *ngIf="form.controls.sizeId.invalid && form.controls.sizeId.touched"
          >
            <mat-error *ngIf="form.controls.sizeId.hasError('required')">
              Size is required.
            </mat-error>
          </div>
        </div>

        <div class="form__box">
          <lha-single-select
            [options]="typeVm()"
            formControlName="typeId"
            bindValue="cargoTypeId"
            placeholder="Type"
          />
        </div>

        <div class="form__box">
          <lha-single-select
            [options]="vendors()"
            formControlName="vendorId"
            bindValue="vendorId"
            bindLabel="vendorName"
            placeholder="Owner"
          />
          <div
            *ngIf="
              form.controls.vendorId.invalid && form.controls.vendorId.touched
            "
          >
            <mat-error *ngIf="form.controls.vendorId.hasError('required')">
              Owner is required.
            </mat-error>
          </div>
        </div>

        <div class="form__box">
          <lha-single-select
            [options]="cargoCategories"
            bindLabel="name"
            bindValue="value"
            formControlName="category"
            placeholder="Category"
          />
          <div
            *ngIf="
              form.controls.category.invalid && form.controls.category.touched
            "
          >
            <mat-error *ngIf="form.controls.category.hasError('required')">
              Category is required.
            </mat-error>
          </div>
        </div>

        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>Length (mm)</mat-label>
            <input matInput type="number" formControlName="length" />
            <mat-error *ngIf="form.controls.length.hasError('required')">
              Length is required.
            </mat-error>
            <mat-error *ngIf="form.controls.length.hasError('greaterThan')"
              >Length should be greater than 0</mat-error
            >
          </mat-form-field>
        </div>

        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>Width (mm)</mat-label>
            <input matInput type="number" formControlName="width" />
            <mat-error *ngIf="form.controls.width.hasError('required')">
              Width is required.
            </mat-error>
            <mat-error *ngIf="form.controls.width.hasError('greaterThan')"
              >Width should be greater than 0</mat-error
            >
          </mat-form-field>
        </div>

        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>Height (mm)</mat-label>
            <input matInput type="number" formControlName="height" />
          </mat-form-field>
        </div>

        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>Tare Mass (kg)</mat-label>
            <input
              matInput
              type="number"
              title="Weight of the empty cargo"
              formControlName="tareMass"
            />
          </mat-form-field>
        </div>

        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>Max Gross Weight (kg)</mat-label>
            <input matInput type="number" formControlName="maxGrossWeight" />
          </mat-form-field>
        </div>
      </div>

      <div class="form__box form__box--mb20">
        <mat-slide-toggle formControlName="isDeckCargo"
          >Is Deck Cargo</mat-slide-toggle
        >
      </div>

      <div class="form__box form__box--mb20">
        <mat-slide-toggle formControlName="isPool">Is Pool</mat-slide-toggle>
      </div>

      <mat-dialog-actions align="end">
        <button mat-raised-button color="primary" type="submit">
          {{ isAdd ? 'Add ' : 'Save ' }}
        </button>
        <button
          mat-raised-button
          color="warn"
          type="button"
          mat-dialog-close=""
        >
          Cancel
        </button>
      </mat-dialog-actions>
    </form>
  </mat-dialog-content>
</div>

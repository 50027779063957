import { ActivityConfigState } from '../../interfaces/activity-state.interface';
import { maintenanceInitialState } from './maintenance.state';

export const activityConfigInitialState: ActivityConfigState = {
  activities: [],
  activityTypeList: [],
  chargeabilityList: [],
  activityType: '',
  ...maintenanceInitialState,
};

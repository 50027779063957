import {inject, Injectable} from "@angular/core";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {ActivityCategory} from "./interfaces/activity-category.interface";
import {SearchQuery} from "../../../../../components/src/lib/interfaces/search-query.interface";

@Injectable({providedIn: 'root'})
export class ActivityCategoryService {
  private readonly http = inject(HttpClient);
  private readonly apiUrl = '/api/activityCategory';

  loadActivityCategories(query?: SearchQuery): Observable<ActivityCategory[]> {
    let params = new HttpParams();
    return this.http.get<ActivityCategory[]>(this.apiUrl, { params });
  }

  removeActivityCategory(id: string): Observable<ActivityCategory> {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const headers = new HttpHeaders().set('Time-Zone', timezone);
    return this.http.delete<ActivityCategory>(`/api/activityCategory/${id}`, {
      headers,
    });
  }

  addActivityCategory(activityCategory: ActivityCategory): Observable<ActivityCategory> {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const headers = new HttpHeaders().set('Time-Zone', timezone);
    return this.http.post<ActivityCategory>(
      '/api/activityCategory', activityCategory,{ headers }
    );
  }

  editActivityCategory(id: string, activityCategory: ActivityCategory): Observable<ActivityCategory> {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const headers = new HttpHeaders().set('Time-Zone', timezone);
    return this.http.put<ActivityCategory>(`/api/activityCategory/${id}`, activityCategory, { headers }
    );
  }
}

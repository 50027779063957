import { CargoCategory } from "./cargo-category.enum";

export const cargoCategories = [
  {
    value: CargoCategory.BigBags,
    name: 'Big Bags',
  },
  {
    value: CargoCategory.Bulk,
    name: 'Bulk',
  },
  {
    value: CargoCategory.DrillStrings,
    name: 'Drill Strings',
  },
  {
    value: CargoCategory.LooseLifts,
    name: 'Loose Lifts',
  },
  {
    value: CargoCategory.OffshoreContainers,
    name: 'Offshore Containers',
  },
  {
    value: CargoCategory.Tubulars,
    name: 'Tubulars',
  }
];

import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthService } from '@auth0/auth0-angular';

export function authGuard() {
  const router = inject(Router);
  const authService = inject(AuthService);
  return authService.isAuthenticated$.pipe(
    map((isLoggedIn) => {
      return isLoggedIn || router.navigate(['login']);
    })
  );
}

import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { errorProps } from '../../../../../../../components/src/lib/functions/utility.functions';
import { CargoSize } from '../../interfaces/carrgo-size.interface';

export const CargoeSizeActions = createActionGroup({
  source: 'CargoSize',
  events: {
    'load_CargoSize_List': emptyProps(),
    'load_CargoSizes_Success': props<{ CargoSizes: CargoSize[] }>(),
    'load_CargoSizes_Failure': errorProps(),
    'load_CargoSize_List_By-Family_Id': props<{ familyId: string }>(),
    'load_CargoSizes_By-Family_Id_Success': props<{ CargoSizes: CargoSize[] }>(),
    'load_CargoSizes_By-Family_Id_Failure': errorProps(),
    'clear_CargoSizes': emptyProps(),

    load_Cargo_Sizes_Page: emptyProps(),
    load_Cargo_Sizes: emptyProps(),
    load_Cargo_Sizes_Success: props<{ cargoSizes: CargoSize[] }>(),
    load_Cargo_Sizes_Failure: errorProps(),
    remove_Cargo_Size: props<{ id: string }>(),
    remove_Cargo_Size_Success: props<{
      cargoSize: CargoSize;
      successMessage: string;
    }>(),
    remove_Cargo_Size_Failure: errorProps(),
    open_Cargo_Size_Dialog: props<{
      cargoSize: CargoSize | null;
    }>(),
    add_Cargo_Size: props<{ cargoSize: CargoSize }>(),
    add_Cargo_Size_Success: props<{
      cargoSize: CargoSize;
      successMessage: string;
    }>(),
    add_Cargo_Size_Failure: errorProps(),
    edit_Cargo_Size: props<{
      cargoSizeId: string;
      cargoSize: CargoSize;
    }>(),
    edit_Cargo_Size_Success: props<{
      cargoSize: CargoSize;
      successMessage: string;
    }>(),
    edit_Cargo_Size_Failure: errorProps(),
  },
});

import { createFeature, createReducer } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';

import { liftingPauseReasonsInitialState } from '../states/lifting-pause-reasons.state';
import { LiftingPauseReasonsActions } from '../actions/lifting-pause-reasons.actions';

export const liftingPauseReasonsReducer = createReducer(
  liftingPauseReasonsInitialState,
  immerOn(
    LiftingPauseReasonsActions.load_Lifting_Pause_Reasons,
    LiftingPauseReasonsActions.remove_Lifting_Pause_Reason,
    (state) => {
      state.loading.list = true;
    }
  ),
  immerOn(LiftingPauseReasonsActions.update_Lifting_Pause_Reasons_Queries, (state, { query }) => {
    state.query = query;
    state.loading.list = true;
  }),
  immerOn(LiftingPauseReasonsActions.load_Lifting_Pause_Reasons_Success, (state, { liftingPauseReasons }) => {
    state.liftingPauseReasons = liftingPauseReasons;
    state.loading.list = false;
  }),
  immerOn(LiftingPauseReasonsActions.load_Active_Lifting_Pause_Reasons_Success, (state, { liftingPauseReasons }) => {
    state.activeLiftingPauseReasons = liftingPauseReasons;
  }),
  immerOn(
    LiftingPauseReasonsActions.load_Lifting_Pause_Reasons_Failure,
    LiftingPauseReasonsActions.remove_Lifting_Pause_Reason_Success,
    LiftingPauseReasonsActions.remove_Lifting_Pause_Reason_Failure,
    (state) => {
      state.loading.list = false;
    }
  ),
  immerOn(
    LiftingPauseReasonsActions.add_Lifting_Pause_Reason,
    LiftingPauseReasonsActions.edit_Lifting_Pause_Reason,
    (state) => {
      state.loading.list = true;
    }
  ),
  immerOn(
    LiftingPauseReasonsActions.add_Lifting_Pause_Reason_Success,
    LiftingPauseReasonsActions.add_Lifting_Pause_Reason_Failure,
    LiftingPauseReasonsActions.edit_Lifting_Pause_Reason_Success,
    LiftingPauseReasonsActions.edit_Lifting_Pause_Reason_Failure,
    (state) => {
      state.loading.list = false;
    }
  )
);

export const liftingPauseReasonsFeature = createFeature({
  name: 'liftingPauseReasons',
  reducer: liftingPauseReasonsReducer,
});

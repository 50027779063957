import {
  Component,
  EventEmitter,
  inject,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { AsyncPipe, DatePipe, NgIf } from '@angular/common';
import { TIME_FORMAT } from '../activity-category-type-add-edit/activity-category-type-add-edit.component';
import { Subject, takeUntil } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AreaActions } from '../../../../../../../../libs/services/src/lib/services/maintenance/store/actions/area.actions';
import { Site } from '../../../../../../../../libs/services/src/lib/services/maintenance/interfaces/site.interface';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { Area } from '../../../../../../../../libs/services/src/lib/services/maintenance/interfaces/area.interface';
import { Column } from '../../../../../../../../libs/components/src/lib/components/table/column.model';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { areaFeature } from '../../../../../../../../libs/services/src/lib/services/maintenance/store/features/area.features';
import { confirmActions } from 'libs/components/src/lib/store/confirm.actions';
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { CellTemplateDirective } from '../../../../../../../../libs/components/src/lib/components/table/cell-template.directive';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TableComponent } from '../../../../../../../../libs/components/src/lib/components/table/table.component';
import { SearchQuery } from '../../../../../../../../libs/components/src/lib/interfaces/search-query.interface';
import { maintenanceInitialState } from '../../../../../../../../libs/services/src/lib/services/maintenance/store/states/maintenance.state';
import { MultiselectComponent } from '../../../../../../../../libs/components/src/lib/components/multiselect/multiselect.component';
import { blockingActivityFeature } from '../../../../../../../../libs/services/src/lib/services/maintenance/store/features';
import { BlockingActivity } from '../../../../../../../../libs/services/src/lib/services/maintenance/interfaces/blocking-activity.interface';

@Component({
  selector: 'site-add-edit',
  standalone: true,
  imports: [
    AsyncPipe,
    CdkDrag,
    CdkDragHandle,
    CellTemplateDirective,
    DatePipe,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    NgIf,
    ReactiveFormsModule,
    TableComponent,
    MultiselectComponent,
  ],
  providers: [
    {
      provide: NGX_MAT_DATE_FORMATS,
      useValue: TIME_FORMAT,
    },
    DatePipe,
  ],
  templateUrl: './area-add-edit.component.html',
  styleUrls: ['./area-add-edit.component.scss'],
})
export class AreaAddEditComponent implements OnInit, OnDestroy {
  @Output() save = new EventEmitter<boolean>();
  dialogRef = inject(MatDialogRef<AreaAddEditComponent>);
  store = inject(Store);
  action = inject(Actions);
  query: SearchQuery = maintenanceInitialState.query;
  vm$ = this.store.select(areaFeature.selectAreaState);
  data: { id: string; site: Site } = inject(MAT_DIALOG_DATA);
  unsubscribe: Subject<boolean> = new Subject();
  editRows: Map<string, FormGroup> = new Map();
  areaList$ = this.store.select(areaFeature.selectAreas);
  blockingActivities$ = this.store.select(
    blockingActivityFeature.selectBlockingActivities
  );
  blockingActivities: BlockingActivity[] = [];
  areaList: Area[] = [];

  columns: Column<Area>[] = [
    new Column('name', 'Area', { sortHeader: false }),
    new Column('areaBlockingActivities', 'Area Blocking Activities', {
      sortHeader: false,
    }),
    new Column('createdDate', 'Created Date', { sortHeader: false }),
    new Column('areaId', 'Actions', { sortHeader: false }),
  ];

  ngOnDestroy(): void {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  ngOnInit(): void {
    this.store.dispatch(AreaActions.set_Area_Id({ id: this.data.id }));
    this.subToActions();
    this.subToArea();
    this.subToBlockingActivities();
  }

  private subToActions() {
    this.action
      .pipe(ofType(AreaActions.add_Area_Success), takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.editRows.delete('add');
      });

    this.action
      .pipe(ofType(AreaActions.edit_Area_Success), takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.editRows.delete(res.area.areaId);
      });
  }

  private subToArea() {
    this.areaList$.pipe(takeUntil(this.unsubscribe)).subscribe((res) => {
      this.areaList = res;
    });
  }

  subToBlockingActivities() {
    this.blockingActivities$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.blockingActivities = res;
      });
  }

  addArea() {
    if (this.editRows.get('add')) {
      return;
    }
    this.store.dispatch(AreaActions.add_Area_Row());
    this.editRows.set('add', this.createAreaGroup());
  }

  saveArea(area: Area) {
    const model = {
      ...this.editRows.get(area.areaId)?.value,
      siteId: this.data.id,
    };

    if (area.areaId === 'add') {
      this.store.dispatch(
        AreaActions.add_Area({
          area: model,
        })
      );
    } else {
      this.store.dispatch(
        AreaActions.edit_Area({
          id: area.areaId,
          area: model,
        })
      );
    }
    this.save.emit(true);
  }

  cancelArea(area: Area) {
    this.editRows.delete(area.areaId);
    if (area.areaId === 'add') {
      this.store.dispatch(AreaActions.remove_Area_Row());
    }
  }

  editArea(area: Area) {
    this.editRows.set(area.areaId, this.createAreaGroup(area));
  }

  removeArea(area: Area) {
    this.store.dispatch(
      confirmActions.request_Confirmation({
        titles: {
          title: 'Do you you want to remove this Area?',
          btnConfirm: 'Yes Delete',
        },
        confirm: AreaActions.remove_Area({
          id: area.areaId,
        }),
      })
    );
  }

  createAreaGroup(area?: Area): FormGroup {
    return new FormGroup({
      name: new FormControl(area?.name, [Validators.required]),
    });
  }

  getFg(id: string): FormGroup {
    return this.editRows.get(id) ?? this.createAreaGroup();
  }
}

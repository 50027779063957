import {Actions, concatLatestFrom, createEffect, ofType} from "@ngrx/effects";
import {inject} from "@angular/core";
import {Store} from "@ngrx/store";
import {catchError, map, mergeMap, of} from "rxjs";
import {tap} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";
import {BlockingActivityActions} from "../actions/blocking-activity.actions";
import {BlockingActivityService} from "../../blocking-activity.service";
import {blockingActivityFeature} from "../features/blocking-activity.feature";
import {BlockingActivity} from "../../interfaces/blocking-activity.interface";
import {MatDialog} from "@angular/material/dialog";
import {DialogOptions} from "../../../config/dialog-options";
import {
  BlockingActivityAddEditComponent
} from "../../../../../../../../apps/allocate/src/app/maintenance/shared/components/blocking-activity-add-edit/blocking-activity-add-edit.component";

export const loadBlockingActivities = createEffect(
  (
    actions = inject(Actions),
    store = inject(Store),
    blockingActivityService = inject(BlockingActivityService)
  ) => {
    return actions.pipe(
      ofType(
        BlockingActivityActions.load_Blocking_Activities,
        BlockingActivityActions.update_Blocking_Activity_Queries,
        BlockingActivityActions.remove_Blocking_Activity_Success,
        BlockingActivityActions.add_Blocking_Activity_Success,
        BlockingActivityActions.edit_Blocking_Activity_Success
      ),
      concatLatestFrom(() => store.select(blockingActivityFeature.selectQuery)),
      map(([, res]) => res),
      mergeMap((action) =>
        blockingActivityService.loadBlockingActivities(action).pipe(
          map((res: BlockingActivity[]) => BlockingActivityActions.load_Blocking_Activities_Success({ blockingActivities: res })),
          catchError((error: HttpErrorResponse) =>
            of(BlockingActivityActions.load_Blocking_Activities_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const removeBlockingActivity = createEffect(
  (actions = inject(Actions), blockingActivityService = inject(BlockingActivityService)) => {
    return actions.pipe(
      ofType(BlockingActivityActions.remove_Blocking_Activity),
      mergeMap((action) =>
        blockingActivityService.removeBlockingActivity(action.id).pipe(
          map((res: BlockingActivity) =>
            BlockingActivityActions.remove_Blocking_Activity_Success({
              blockingActivity: res,
              successMessage: 'Blocking activity removed successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(BlockingActivityActions.remove_Blocking_Activity_Failure({ error: error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const addBlockingActivity = createEffect(
  (actions = inject(Actions), blockingActivityService = inject(BlockingActivityService)) => {
    return actions.pipe(
      ofType(BlockingActivityActions.add_Blocking_Activity),
      mergeMap((action) =>
        blockingActivityService.addBlockingActivity(action.blockingActivity).pipe(
          map((res: BlockingActivity) =>
            BlockingActivityActions.add_Blocking_Activity_Success({
              blockingActivity: res,
              successMessage: 'Blocking activity added successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(BlockingActivityActions.add_Blocking_Activity_Failure({ error: error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const editBlockingActivity = createEffect(
  (actions = inject(Actions), blockingActivityService = inject(BlockingActivityService)) => {
    return actions.pipe(
      ofType(BlockingActivityActions.edit_Blocking_Activity),
      mergeMap((action) =>
        blockingActivityService.editBlockingActivity(action.id, action.blockingActivity).pipe(
          map((res: BlockingActivity) =>
            BlockingActivityActions.edit_Blocking_Activity_Success({
              blockingActivity: res,
              successMessage: 'Blocking activity edited successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(BlockingActivityActions.edit_Blocking_Activity_Failure({ error: error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const openBlockingActivityDialog = createEffect(
  (
    actions = inject(Actions),
    dialog = inject(MatDialog),
    dialogOptions = inject(DialogOptions)
  ) => {
    return actions.pipe(
      ofType(BlockingActivityActions.open_Blocking_Activity_Dialog),
      tap((action) => {
        dialog.open(BlockingActivityAddEditComponent, {
          ...dialogOptions,
          data: {
            blockingActivity: action.blockingActivity,
          },
        });
      })
    );
  },
  {
    functional: true,
    dispatch: false,
  }
);

import { createFeature, createReducer, createSelector } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { cargoeFamilyInitialState } from '../states/cargo-family.state';
import { CargoeFamilyActions } from '../actions/cargo-family.actions';

export const cargoFamilyReducer = createReducer(
  cargoeFamilyInitialState,
  immerOn(
    CargoeFamilyActions.load_CargoFamily_List,
    (state) => {
      state.loading.list = true;
    }
  ),
  immerOn(CargoeFamilyActions.load_CargoFamilies_Success, (state, { CargoFamilies }) => {
    state.cargoFamilies = CargoFamilies;
    state.loading.list = false;
  }),
  immerOn(
    CargoeFamilyActions.load_CargoFamilies_Failure,
    (state) => {
      state.loading.list = false;
    }
  ),
  immerOn(
    CargoeFamilyActions.load_Cargo_Families_Failure,
    (state) => {
      state.loading.list = false;
    }
  ),
  immerOn(
    CargoeFamilyActions.load_Cargo_Families,
    (state) => {
      state.loading.list = true;
    }
  ),
  immerOn(
    CargoeFamilyActions.load_Cargo_Families_Success,
    (state, { cargoFamilys }) => {
      state.loading.list = false;
      state.cargoFamilies = cargoFamilys;
    }
  ),
);

export const cargoFamilyFeature = createFeature({
  name: 'cargoFamily',
  reducer: cargoFamilyReducer,
  extraSelectors: ({ selectLoading }) => ({
    selectListLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.list
    ),
    selectCreateEditLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.createEdit
    ),
  }),
});

import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../interfaces/user.interface';

@Injectable({ providedIn: 'root' })
export class UsersService {
  private readonly http = inject(HttpClient);

  loadUsers(): Observable<User[]> {
    return this.http.get<User[]>('/api/user');
    // TODO: It will be used after creating pagination, sorting, and search on back-end
    // return this.loadMaintenance<Activity[]>(query, 'activity');
  }

  removeUser(id: string): Observable<User> {
    return this.http.delete<User>(`/api/user/${id}`);
  }

  addUser(user: User): Observable<User> {
    return this.http.post<User>('/api/user', user);
  }

  editUser(id: string, user: User): Observable<User> {
    return this.http.put<User>(`/api/user/${id}`, user);
  }

  editUserLocation(id: string, user: User): Observable<User> {
    return this.http.put<User>(`/api/user/${id}/userlocation`, user);
  }

  editUserDetails(id: string, user: User): Observable<User> {
    return this.http.put<User>(`/api/user/${id}/userdetails`, user);
  }

  getCurrentUser(): Observable<User> {
    return this.http.get<User>('/api/user/current-user');
  }
}
